import React, { useState, useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from 'types';
import { CalendarSettings, Restaurant, TimelineReservation } from 'types/calendar';
import Sidebar from 'app/components/Sidebar';
import HeaderControls from 'app/components/CalendarControls/HeaderControls';
import FloorPlan from 'app/components/FloorPlan';
import SidebarStatistics from 'app/components/Sidebar/SidebarStatistics';
import SidebarSection from 'app/components/Sidebar/SidebarSection';
import FilterTime from 'app/components/Filters/FilterTime';
import SidebarReservation from 'app/components/Sidebar/SidebarReservation';
import { useTranslation } from 'react-i18next';
import { Statuses } from 'types/reservation';
import filtersActions from 'redux/actions/filters';
import { SelectedHours } from 'types/filters';
import { filterTimeCheck } from 'utils/filters';
import moment from 'moment';
import FilterActions from 'app/components/Filters/FilterActions';
import { SidebarCardLoader } from 'app/components/Sidebar/SidebarCardLoader';
import { Box } from '@mui/material';
import ReservationsList from 'app/components/ReservationsList';

interface StateProps {
  currentTime: moment.Moment;
  restaurant: Restaurant;
  isLoading: boolean;
  isFloorPlanLoading: boolean;
  reservations: TimelineReservation[];
  calendarSettings: CalendarSettings;
  selectedHours: SelectedHours;
}

interface DispatchProps {
  updateSelectedHours: (selectedHours: SelectedHours) => void;
  resetFilters: () => void;
}

const FloorPlanPage: React.FC<StateProps & DispatchProps> = (props) => {
  const { t } = useTranslation();
  const [floorPlanReservations, setFloorPlanReservations] = useState<TimelineReservation[]>([]);
  const [sidebarReservations, setSidebarReservations] = useState<TimelineReservation[]>([]);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [sidebarBadgeText, setSidebarBadgeText] = useState(0);
  const [selectedTables, setSelectedTables] = useState<number[]>([]);
  const [selectedTime, setSelectedTime] = useState<moment.Moment | null>(null);

  const now = moment(props.currentTime).tz(props.restaurant.settings.timezone);

  const showOngoing = props.calendarSettings.date.isSame(now, 'day') && selectedTables.length <= 0;

  const clearSelections = () => {
    setSelectedTables([]);
    setSelectedTime(null);
  };

  const handleReset = () => {
    props.resetFilters();
    clearSelections();
  };

  const handleSelectTable = (id: number) => {
    setSelectedTables((prev) => (prev.includes(id) ? prev.filter((t) => t !== id) : [...prev, id]));
  };

  useEffect(() => {
    const disabledStatuses = [Statuses.Template];

    if (!props.restaurant.settings.list_show_cancelled) {
      disabledStatuses.push(Statuses.Cancelled);
    }
    if (!props.restaurant.settings.list_show_walk_ins) {
      disabledStatuses.push(Statuses.WalkIn);
    }

    setSidebarReservations(
      props.reservations
        .filter(
          (r) =>
            //status filter
            !disabledStatuses.includes(r.reservation.status) &&
            //time filter
            (selectedTime && !props.selectedHours.includeOngoing
              ? //show reservations starting from selected time
                r.reservation.startTime.isSameOrAfter(selectedTime, 'minute')
              : selectedTime && props.selectedHours.includeOngoing
              ? //show reservations ending from selected time (ongoing + further)
                r.reservation.endTime?.isSameOrAfter(selectedTime, 'minute')
              : !!props.selectedHours.from || !!props.selectedHours.to
              ? //show reservations between selected hours filter
                filterTimeCheck(props.selectedHours, r.reservation, true)
              : // Temporary disabled. Remove after client confirmation that everything is ok.
                // : showOngoing
                // ? //show current time ongoing reservations
                //   r.reservation.startTime.isSameOrBefore(now, "minute") &&
                //   r.reservation.endTime?.isAfter(now, "minute")
                //show all day reservations
                r.reservation) &&
            //table filter
            (selectedTables.length <= 0 ||
              r.reservation.tables
                .map((t) => selectedTables.includes(parseInt(t)))
                .find((t) => t)) &&
            //search filter
            (searchPhrase.length <= 0 ||
              r.title.toLowerCase().includes(searchPhrase.toLowerCase())),
        )
        .sort((a, b) => (a.reservation.startTime.isAfter(b.reservation.startTime) ? 1 : -1)),
    );

    setFloorPlanReservations(
      props.reservations
        .filter(
          (r) =>
            //time filter
            (selectedTime
              ? //show selected time ongoing reservations
                r.reservation.startTime.isSameOrBefore(selectedTime, 'minute') &&
                r.reservation.endTime?.isAfter(selectedTime, 'minute')
              : !!props.selectedHours.from || !!props.selectedHours.to
              ? //show reservations between selected hours filter
                filterTimeCheck(props.selectedHours, r.reservation, true)
              : showOngoing
              ? //show current time ongoing reservations
                r.reservation.startTime.isSameOrBefore(now, 'minute') &&
                r.reservation.endTime?.isAfter(now, 'minute')
              : //don't show any reservations
                false) &&
            //table filter
            (selectedTables.length <= 0 ||
              r.reservation.tables
                .map((t) => selectedTables.includes(parseInt(t)))
                .find((t) => t)) &&
            //search filter
            (searchPhrase.length <= 0 ||
              r.title.toLowerCase().includes(searchPhrase.toLowerCase())),
        )
        .sort((a, b) => (a.reservation.startTime.isAfter(b.reservation.startTime) ? 1 : -1)),
    );
    // eslint-disable-next-line
  }, [
    props.restaurant,
    props.reservations,
    props.calendarSettings,
    searchPhrase,
    props.selectedHours,
    selectedTime,
    selectedTables,
    showOngoing,
  ]);

  useEffect(() => {
    let filtersCount = 0;
    if (props.selectedHours.includeOngoing) filtersCount++;
    if (!!props.selectedHours.from) filtersCount++;
    if (!!props.selectedHours.to) filtersCount++;
    if (!!selectedTime) filtersCount++;
    if (!!selectedTables.length) filtersCount++;

    setSidebarBadgeText(filtersCount);
  }, [props.selectedHours, selectedTime, selectedTables]);

  useEffect(() => {
    setSelectedTime(null);
    setSelectedTables([]);
  }, [props.calendarSettings]);

  return !props.restaurant.nid ? (
    <LinearProgress color="secondary" />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      p={2}
      className="tablein-container tablein-floor-plan"
    >
      <HeaderControls enableDisplaySwitch sidebarBadgeText={sidebarBadgeText} />
      <Box display="flex" flex={1} className={'floor-plan-container'}>
        <FloorPlan
          showTimeline
          enablePrint
          reservations={floorPlanReservations}
          selectedTables={selectedTables}
          onTableSelect={handleSelectTable}
          selectedTime={selectedTime}
          onTimeSelect={setSelectedTime}
          onEmptyClick={clearSelections}
        />
        <Sidebar
          variant="card"
          showAlways
          search
          searchPhrase={searchPhrase}
          onSearchChange={(value) => setSearchPhrase(value)}
          Footer={
            <div className="sidebar-filters">
              <FilterActions resetFilters={handleReset} />
            </div>
          }
          className="no-print"
        >
          <SidebarStatistics isLoading={props.isLoading} reservations={sidebarReservations} />
          <SidebarSection title={t('filtersTitle')}>
            <FilterTime
              display={['time']}
              selectedHours={props.selectedHours}
              updateSelectedHours={props.updateSelectedHours}
            />
          </SidebarSection>
          {props.isLoading ? (
            <SidebarCardLoader noBorder />
          ) : (
            sidebarReservations.map((r) => <SidebarReservation key={r.id} reservation={r} />)
          )}
        </Sidebar>

        {/* For print only */}
        <ReservationsList reservations={sidebarReservations} className="print-only" />
      </Box>
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    currentTime: state.application.currentTime,
    isLoading: state.calendar.reservationsIsLoading,
    isFloorPlanLoading: state.floorPlan.isLoading,
    restaurant: state.calendar.restaurant,
    reservations: state.calendar.reservations,
    calendarSettings: state.calendar.calendarSettings,
    selectedHours: state.filters.selectedHours,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateSelectedHours: bindActionCreators(filtersActions.updateSelectedHours, dispatch),
    resetFilters: bindActionCreators(filtersActions.resetFilters, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlanPage);
