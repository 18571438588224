import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';
import './HeadingPrimary.scss';

interface OwnProps {
  children: ReactNode;
}

const HeadingPrimary: React.FC<OwnProps> = ({ children }) => {
  return (
    <Typography variant={'body1'} className={'heading-primary'} sx={{ color: 'navyBlue' }}>
      {children}
    </Typography>
  );
};

export default HeadingPrimary;
