import React from 'react';
import { AppState } from 'types';
import { connect } from 'react-redux';
import { dateFormatDate } from 'utils/dates';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Restaurant } from 'types/calendar';
import { ReservationState } from 'types/reservation';
import './GuestDetails.scss';
import HeadingPrimary from 'app/components/common/HeadingPrimary/HeadingPrimary';
import { Typography } from '@mui/material';

interface StateProps {
  reservation: ReservationState;
  restaurant: Restaurant;
}

type Props = StateProps;

const GuestDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const dateTimeFormat = dateFormatDate(props.restaurant.settings.date_format_key);
  const arrivedCount = !!props.reservation.guestDetails.arrivedCount
    ? props.reservation.guestDetails.arrivedCount
    : '-';
  const cancelledCount = !!props.reservation.guestDetails.cancelledCount
    ? props.reservation.guestDetails.cancelledCount
    : '-';
  const noShowCount = !!props.reservation.guestDetails.noShowCount
    ? props.reservation.guestDetails.noShowCount
    : '-';
  const lastVisit =
    !!props.reservation.guestDetails.lastVisit && props.reservation.guestDetails.lastVisit !== '0'
      ? moment(props.reservation.guestDetails.lastVisit).format(dateTimeFormat)
      : '-';

  return (
    <>
      <HeadingPrimary>{t('bookingModalGuestHistory')}</HeadingPrimary>

      <div className="modal-guest-history">
        <Typography variant={'body2'} sx={{ fontWeight: 500, color: 'navyBlue' }}>
          {t('bookingModalArrivedGuestsCount')}: {arrivedCount}
        </Typography>

        <Typography variant={'body2'} sx={{ fontWeight: 500, color: 'navyBlue' }}>
          {t('bookingModalCanceledVisitsCount')}: {cancelledCount}
        </Typography>

        <Typography variant={'body2'} sx={{ fontWeight: 500, color: 'navyBlue' }}>
          {t('bookingModalNoShowCount')}: {noShowCount}
        </Typography>

        <Typography variant={'body2'} sx={{ fontWeight: 500, color: 'navyBlue' }}>
          {t('bookingModalLastVisitsCount')}: {lastVisit}
        </Typography>
      </div>
    </>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    reservation: state.reservation,
    restaurant: state.calendar.restaurant,
  };
}

export default connect(mapStateToProps)(GuestDetails);
