import { Statuses } from 'types/reservation';
import moment from 'moment-timezone';
import React from 'react';
import PromotionIcons from '../PromotionIcons';
import { ReactComponent as UserIcon } from 'images/icons/user-light.svg';
import { ReactComponent as CalendarCheckIcon } from 'images/icons/calendar-check-light.svg';
import { ReactComponent as CommentIcon } from 'images/icons/comment-light.svg';
import { ReactComponent as MoneyIcon } from 'images/icons/money-bill.svg';
import { TimelineReservation } from 'types/calendar';
import OriginIcon from '../OriginIcon';
import { StatusesEng } from 'utils/reservations';
import './TimelineItem.scss';
import Badge from '../common/Badge/Badge';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { ReactComponent as SpecialOfferIcon } from 'images/icons/PromotionTypes/ic-offer.svg';
import { PromotionTypes } from 'enums/promotionTypes';

interface Props {
  item: TimelineReservation;
  itemContext: any;
  getItemProps: any;
  getResizeProps: any;
}

const TimelineItem: React.FC<Props> = ({ item, itemContext, getItemProps, getResizeProps }) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const itemProps = { ...getItemProps(item.itemProps) };
  let divProps = {
    ...itemProps,
    style: { ...itemProps.style },
  };
  delete divProps.style.background;
  delete divProps.style.border;
  delete divProps.style.color;

  let title: string;
  let showTime = true;
  let showNoOfGuests = true;
  let showIconCalendar = true;
  // eslint-disable-next-line
  let showOrigin = true;
  let showComment = true;
  let showOffer = true;
  let showVip = true;
  let showBigSpender = item.reservation
    ? item.reservation.showBigSpenderTag && item.reservation.clientIsBigSpender
    : false;

  if (!item.reservation) {
    divProps.className += 'event-approved ';
    showTime = false;
    showNoOfGuests = false;
    showIconCalendar = false;
    showOrigin = false;
    showComment = false;
    showOffer = false;
    showVip = false;
    showBigSpender = false;
  } else {
    switch (item.reservation.status) {
      case Statuses.Blocked:
        title = StatusesEng[Statuses.Blocked];
        divProps.className += 'event-blocked ';
        showNoOfGuests = false;
        showIconCalendar = false;
        showOrigin = false;
        showComment = false;
        showOffer = false;
        showVip = false;
        showBigSpender = false;
        break;
      case Statuses.WalkIn:
        title = StatusesEng[Statuses.WalkIn];
        divProps.className += 'event-walk_in ';
        showIconCalendar = false;
        showOrigin = false;
        showComment = false;
        showOffer = false;
        showVip = false;
        showBigSpender = false;
        break;
      case Statuses.Pending:
        title = item.title;
        divProps.className += 'event-pending  ';
        break;
      case Statuses.WaitingList:
        title = item.title;
        divProps.className += 'event-waiting_list  ';
        break;
      case Statuses.NoShow:
        title = item.title;
        divProps.className += 'event-not_arrived ';
        break;
      case Statuses.Arrived:
        title = item.title;
        divProps.className += 'event-arrived ';
        break;
      case Statuses.Confirmed:
        title = item.title;
        if (item.reservation.startTime.isBefore() && !item.reservation.autoArrivalDisabled) {
          divProps.className += 'event-arrived ';
        } else {
          divProps.className += 'event-approved ';
        }
        break;
      case Statuses.Template:
        title = StatusesEng[Statuses.Template];
        divProps.className += 'event-template ';
        break;
      case Statuses.PreBooked:
        title = StatusesEng[Statuses.PreBooked];
        divProps.className += 'event-pre-booked ';
        break;
      default:
        divProps.className += 'event-approved ';
        title = item.title;
    }
  }
  const child =
    itemProps.style.top &&
    itemProps.style.top.map((top: any, index: number) => {
      let singleDivProps = {
        ...divProps,
      };
      if (index === 0) {
        singleDivProps.className += 'item-first ';
      }
      if (index === itemProps.style.top.length - 1) {
        singleDivProps.className += 'item-last ';
      }
      if (
        index !== itemProps.style.top.length - 1 &&
        itemProps.style.top[index + 1] ===
          itemProps.style.top[index] + parseInt(itemProps.style.height)
      ) {
        singleDivProps.className += 'item-stacked-top ';
      }
      if (
        index > 0 &&
        itemProps.style.top[index] ===
          itemProps.style.top[index - 1] + parseInt(itemProps.style.height)
      ) {
        singleDivProps.className += 'item-stacked-bottom ';
      }

      const key = itemProps.key + '-' + index;
      const style = { ...singleDivProps.style, top: top + 'px' };

      const promotionType = item?.reservation?.specialOffer?.promotionType;
      const hasPriceAmount = item?.reservation?.specialOffer?.price?.amount;

      const showSpecialOfferIcon = promotionType === PromotionTypes.Product;

      return (
        <div {...singleDivProps} key={key} style={style}>
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

          <div
            className="rct-item-content"
            style={{ maxHeight: `${itemContext.dimensions.height}` }}
          >
            {index === 0 && (
              <div className="reservation-wrapper">
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  className="reservation-person"
                >
                  <Box display={'flex'} alignItems={'center'}>
                    {showVip && item.reservation.clientIsVip && (
                      <Badge size={'small'} color={'error'}>
                        VIP
                      </Badge>
                    )}
                    {showBigSpender && (
                      <MoneyIcon className="reservation-wrapper__stats-icon reservation-wrapper__stats-icon--yellow" />
                    )}
                    {title}
                  </Box>

                  <Box>
                    {item.reservation &&
                      ![Statuses.WalkIn].includes(item.reservation.status) &&
                      item.reservation.showInitialTimeBadge &&
                      item.reservation.created &&
                      item.reservation.changed &&
                      item.reservation.created !== item.reservation.changed &&
                      item.reservation.initialTime && (
                        <Box
                          style={{
                            fontSize: 12,
                            marginLeft: 1,
                            color: '#d0483e',
                            border: '1px solid #d0483e',
                            background: '#feefeb',
                            padding: '.25em .4em',
                            fontWeight: 500,
                            lineHeight: 1,
                            textAlign: 'center',
                            borderRadius: '4px',
                          }}
                        >
                          {item.reservation.initialTime &&
                            moment(item.reservation.initialTime).format(
                              item.reservation.timeFormat,
                            )}
                        </Box>
                      )}

                    {item.spinner && (
                      <CircularProgress
                        size={15}
                        className={'reservation-spinner'}
                        color="inherit"
                      />
                    )}
                  </Box>
                </Box>

                <div className="reservation-wrapper__stats">
                  <ul>
                    {showTime && (
                      <li className="reservation-wrapper__stats-time">
                        {moment(item.start).format(item.reservation.timeFormat)}
                      </li>
                    )}

                    {showNoOfGuests && (
                      <li>
                        <UserIcon className="reservation-wrapper__stats-icon" />
                        <span className="reservation-wrapper__stats-count">
                          {item.reservation.numberOfGuests}
                        </span>
                      </li>
                    )}

                    {showIconCalendar && !!item.reservation.guestDetails.arrivedCount && (
                      <li>
                        <CalendarCheckIcon className="reservation-wrapper__stats-icon" />
                        <span className="reservation-wrapper__stats-count">
                          {item.reservation.guestDetails.arrivedCount}
                        </span>
                      </li>
                    )}

                    {showComment && item.reservation.comments.length > 0 && (
                      <li title={item.reservation.comments.map((c) => c.commentBody + '\n').join()}>
                        <CommentIcon className="reservation-wrapper__stats-icon" />
                      </li>
                    )}

                    {showOffer && showSpecialOfferIcon && (
                      <li>
                        <SpecialOfferIcon width={12} height={12} />
                      </li>
                    )}

                    {showOffer && !!item.reservation.specialOffer.promotionType && (
                      <PromotionIcons item={item} />
                    )}

                    {!!item.reservation && (
                      <li>
                        <OriginIcon
                          origin={item.reservation.origin}
                          className="reservation-wrapper__stats-icon"
                        />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>

          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
        </div>
      );
    });
  return <div key={itemProps.key}>{child}</div>;
};

export default TimelineItem;
