import React from 'react';
import { FormHelperText } from '@mui/material';

type Props = {
    children: string | undefined;
};

const errorStyles = {
  color: 'error.main',
  '&:first-letter': {
    textTransform: 'uppercase',
  }
};

export const FormErrorMessage = ({ children }: Props) => (
  <FormHelperText sx={errorStyles}>{children}</FormHelperText>
);