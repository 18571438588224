import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';
import './CustomPhoneInput.scss';
import { useSelector } from 'react-redux';
import { AppState } from 'types';

type Props = {
  inputEl?: any;
  country: string;
  autoFormat: boolean;
  value: string;
  onChange: any;
  onBlur?: any;
  preferredCountries?: string[];
  dropdownStyle: object;
  isReadOnly?: boolean;
};

export const CustomPhoneInput = ({
  inputEl,
  country,
  autoFormat,
  value,
  onChange,
  onBlur,
  preferredCountries,
  dropdownStyle,
  isReadOnly,
}: Props) => {
  const mode = useSelector((state: AppState) => state.theme.mode);

  return (
    <div className={`react-tel-input ${mode === 'dark' ? 'react-tel-input--dark' : ''}`}>
      <PhoneInput
        // @ts-ignore
        ref={inputEl}
        country={country}
        autoFormat={autoFormat}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        preferredCountries={preferredCountries}
        dropdownStyle={dropdownStyle}
        disabled={isReadOnly}
      />
    </div>
  );
};
