import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { ReactComponent as ActivityIcon } from 'images/icons/ic-announcement-icon.svg';
import { ReactComponent as BlockIcon } from 'images/icons/ic-file-lock.svg';
import { ReactComponent as NotesIcon } from 'images/icons/ic-notification-text.svg';
import { ReactComponent as DarkModeIcon } from 'images/icons/ic-dark-mode.svg';
import { ReactComponent as PrinterIcon } from 'images/icons/ic-printer.svg';
import { ReactComponent as ChatIcon } from 'images/icons/ic-message-chat.svg';
import RestaurantSwitcher from 'app/components/Header/RestaurantSwitcher';
import { LogoutButton } from 'app/pages/RestaurantSettings/_components/LogoutButton';
import { useTheme } from '@mui/material/styles';

const quickActions = (t: TFunction) => [
  {
    icon: <ActivityIcon />,
    name: t('quickActionsActivity'),
  },
  {
    icon: <BlockIcon />,
    name: t('quickActionsBlockOnlineBookings'),
  },
  {
    icon: <NotesIcon />,
    name: t('quickActionsAddNotes'),
  },
  {
    icon: <DarkModeIcon />,
    name: t('quickActionsDarkMode'),
  },
  {
    icon: <PrinterIcon />,
    name: t('quickActionsPrintReservations'),
  },
  {
    icon: <ChatIcon />,
    name: t('quickActionsChat'),
  },
];

const QuickActionsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const quickActionsToRender = quickActions(t).map(({ name, icon }) => (
    <Box
      key={name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'brandWhite',
        borderRadius: '5px',
        flex: '1 1 calc(50% - 8px)',
        minHeight: '136px',
        padding: '16px',
        gap: '8px',
      }}
    >
      {icon}
      <Typography
        sx={{
          textAlign: 'center',
          color: 'navyBlue',
          fontSize: 12,
        }}
      >
        {name}
      </Typography>
    </Box>
  ));

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Typography variant="h1" sx={{ fontSize: 20, fontWeight: 700, marginBottom: '16px' }}>
        {t('quickActionsTitle')}
      </Typography>
      <RestaurantSwitcher
        switchButtonStyles={{
          color: 'comet',
          backgroundColor: 'brandWhite',
          marginBottom: '8px',
          minHeight: '40px',
          width: '100%',
          '&:hover': {
            backgroundColor: 'brandWhite',
            boxShadow: 'none',
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <Box
          sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', width: '100%', marginBottom: '8px' }}
        >
          {quickActionsToRender}
        </Box>
        <LogoutButton />
      </Box>
    </Box>
  );
};

export default QuickActionsPage;
