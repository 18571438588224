import React from "react";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import OpenWithOutlinedIcon from '@mui/icons-material/OpenWithOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FloorPlanButton from '../FloorPlanButton';

interface Props {
  isControlMode: boolean;
  onReset: React.MouseEventHandler<HTMLButtonElement>;
  onZoomChange: (delta: number, value?: number) => void;
  onControlModeChange: () => void;
  onPrint?: () => void;
}

const FloorControlPanel = ({
  isControlMode,
  onReset,
  onZoomChange,
  onControlModeChange,
  onPrint
}: Props) => {
  return (
    <div className="floor-plan__control-panel">
      <FloorPlanButton onClick={onReset}>
        <HomeOutlinedIcon />
      </FloorPlanButton>
      
      <FloorPlanButton onClick={() => onZoomChange(-1, 0.2)}>
        <AddOutlinedIcon />
      </FloorPlanButton>

      <FloorPlanButton  onClick={() => onZoomChange(1, 0.2)}>
        <RemoveOutlinedIcon />
      </FloorPlanButton>

      <FloorPlanButton onClick={onControlModeChange} active={isControlMode}>
        <OpenWithOutlinedIcon />
      </FloorPlanButton>

      {!!onPrint && (
        <FloorPlanButton onClick={onPrint}>
          <PrintOutlinedIcon />
        </FloorPlanButton>
      )}
    </div>
  );
};

export default FloorControlPanel;
