import { combineReducers } from 'redux';
import moment from 'moment-timezone';
import { AppState } from 'types';
import { Statuses, Origin } from 'types/reservation';
import { CalendarDisplay, ICalendarDisplay } from 'types/calendar';
import Constants from 'utils/Constants';
import { calendarReducer } from './calendar';
import { reservationReducer } from './reservation';
import { applicationReducer } from './application';
import { authReducer } from './auth';
import { filtersReducer } from './filters';
import { floorPlanReducer } from './floorPlan';
import { notesReducer } from './notes';
import { notificationsReducer } from './notifications';
import { systemReducer } from './system';
import { getThemeFromStorage } from 'utils/themeManagment';
import { themeReducer } from './theme';

export const initialState: AppState = {
  auth: {
    userSettings: {
      roles: [],
      permissionToDelete: false,
      readOnly: false,
      activeLicense: null,
      permissions: {
        accessManagerPages: false,
        accessUserHasStaffPermissions: false,
        accessDashboard: false,
        accessRestaurantSubManager: false,
        accessWebAdmin: false,
        restaurantCanCreateNewRestaurant: false,
        accessSettingsPages: false,
        accessHubspotChat: false,
      },
      uid: 0,
    },
  },
  application: {
    languages: [],
    restaurantsList: [],
    printing: false,
    currentTime: moment(),
    connectionStatus: {
      isServerAvailable: true,
      isOffline: false,
    },
  },
  system: {
    status: 'normal',
  },
  notifications: {
    notifications: [],
  },
  calendar: {
    locale: '',
    fullBookingModalOpen: false,
    fullBookingModalIsLoading: false,
    reservationsIsLoading: false,
    reservationsIsNextPageLoading: false,
    extendedReservationsIsLoading: false,
    searchOpen: false,
    sidebarOpen: JSON.parse(localStorage.getItem(Constants.VARIABLES.tableinSidebarOpen) || 'true'),
    calendarDisplay:
      CalendarDisplay[
        (localStorage.getItem(
          Constants.VARIABLES.tableinCalendarDisplay,
        ) as keyof ICalendarDisplay) || CalendarDisplay.Fixed
      ],
    roomsAndTables: [],
    rooms: [],
    reservationsSettings: {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
      page: 1,
      pagesCount: 1,
    },
    reservations: [],
    today: moment(),
    lateCancellationNoShowPayment: {
      askForPaymentModalIsOpen: false,
      paymentModalIsOpen: false,
    },
    restaurant: {
      settings: {
        open_hours: {},
        phone_required: false,
        default_language: 'en',
        timezone: 'Europe/Vilnius',
        increment: 30,
        date_format_key: 'ymd',
        hide_no_show: false,
        list_show_cancelled: false,
        list_show_walk_ins: false,
        initial_reservation_badge: false,
        exclusive_days: [],
        staff_summary: 0,
        first_day_of_week: 0,
        sms_notify_status: false,
        sms_notify_default_on: false,
        sms_notify_client_table_ready: false,
        auto_arrival_disabled: false,
        show_old_calendar: false,
        show_big_spender_tag: false,
        default_booking_length: 120,
      },
      address: {
        country: 'LT',
      },
      cumoWidgetTimes: [],
      cumoWidgetSpecificTimes: {
        times: {},
      },
      waiters: [],
    },
    calendarSettings: {
      date: moment(),
      startTime: moment(),
      endTime: moment(),
      closed: false,
      controlsSettings: {
        selectedDateIndex: 0,
        startDateFactor: 0,
      },
    },
    reservationListSettings: {
      startDate: null,
      endDate: null,
      advancedSearch: {
        isLoading: false,
        isNextPageLoading: false,
        params: null,
        results: [],
        page: 1,
        pagesCount: 1,
      },
    },
    reservationListStatistics: {
      guestsToday: 0,
      walkIns: 0,
      reservations: 0,
    },
    failedReservationsList: [],
    exportListToCsv: false,
  },
  reservation: {
    status: Statuses.Confirmed,
    numberOfGuests: 0,
    tables: [],
    startTime: moment(),
    name: '',
    email: '',
    phoneNumber: '',
    company: '',
    notifications: {
      sms: false,
      email: true,
      resend: false,
      tableReady: false,
      language: '',
    },
    origin: Origin.Online,
    comments: [],
    newComment: '',
    tags: [],
    clientDetails: '',
    clientTags: [],
    specialOffer: { nid: '_none' },
    waiter: { tid: '_none' },
    originalWaiter: { tid: '_none' },
    specialOffers: [],
    showInitialTimeBadge: false,
    autoArrivalDisabled: false,
    timeFormat: '',
    clientIsVip: false,
    clientIsBigSpender: false,
    showBigSpenderTag: false,
    guestDetails: {
      arrivedCount: 0,
      lastVisit: '',
      cancelledCount: 0,
      noShowCount: 0,
    },
    reservationSavingErrors: [],
    payments: {
      currencies: {},
      payments: [],
    },
    dinningRooms: [],
    lateCancellationNoShow: {
      enabled: 0,
      time: 0,
      maxAmount: 0,
      currency: '',
      chargeMode: '',
      paymentData: {
        id: '',
        object: '',
        cancellation_reason: null,
        client_secret: '',
        created: 0,
        description: null,
        last_setup_error: '',
        livemode: false,
        next_action: null,
        payment_method: '',
        payment_method_types: [],
        status: '',
        usage: '',
        paymentSuccess: false,
        paidAmount: 0,
        paidAt: '',
      },
    },
  },
  filters: {
    selectedStatuses: [],
    dinningRooms: [],
    options: [],
    tag: null,
    selectedHours: {
      from: null,
      to: null,
      includeOngoing: false,
    },
    sort: {
      option: 'visit_time',
      direction: 'asc',
    },
  },
  notes: {
    isDrawerOpen: false,
    createNoteLoading: false,
    deleteNoteLoading: false,
    getNotesLoading: false,
    notes: [],
    selectedNote: null,
  },
  floorPlan: {
    isLoading: false,
    restaurantId: null,
    rooms: [],
    tables: [],
    walls: [],
    texts: [],
  },
  theme: {
    mode: getThemeFromStorage(),
  },
};

export const rootReducer = (history: any) =>
  combineReducers({
    router: history,
    auth: authReducer,
    application: applicationReducer,
    system: systemReducer,
    calendar: calendarReducer,
    reservation: reservationReducer,
    notifications: notificationsReducer,
    filters: filtersReducer,
    notes: notesReducer,
    floorPlan: floorPlanReducer,
    theme: themeReducer,
  });
