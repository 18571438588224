import { useState } from "react";
import {useTranslation} from "react-i18next";
import { UnauthorizedWrapper } from '../../components/UnauthorizedWrapper';
import { MozrestIntegrationForm } from '../../components/MozrestIntegrationForm';
import { LoginForm } from '../../components/LoginForm';
export const MozrestIntegrationPage = () => {
  const { t } = useTranslation();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  
  const subtitle = loggedIn ?
    'Please select restaurant for Mozrest integration' :
    'Please login to continue Mozrest integration';
  
  return (
    <UnauthorizedWrapper title={t('Mozrest')} subtitle={t(subtitle)}>
      { loggedIn ?
        <MozrestIntegrationForm /> :
        <LoginForm onSuccess={() => setLoggedIn(true)}/>
      }
    </UnauthorizedWrapper>
  );
}

export default MozrestIntegrationPage;