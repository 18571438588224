import { RoomOrTable, Table } from 'types/calendar';
import { Box, Button, ButtonProps } from '@mui/material';
import React from 'react';

interface TableButtonProps extends ButtonProps {
  table: RoomOrTable | Table;
}

export const TableButton = ({ table, ...props }: TableButtonProps) => {
  return (
    <Button {...props}>
      {table.title}
      <Box position={'absolute'} bottom={0} fontSize={10}>
        {table.covers_min}-{table.covers_max}
      </Box>
    </Button>
  );
};
