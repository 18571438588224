import React from 'react';
import { AppState } from 'types';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import { timeFormat } from 'utils/dates';
import { CalendarSettings, Restaurant } from 'types/calendar';
import { Button } from '@mui/material';

interface OwnProps {
  roomName: string;
  roomId: number;
  time: Moment;
  blockedHours: object;
  toggle: (roomId: number, time: string, isBlocked: boolean) => void;
}

interface StateProps {
  calendarSettings: CalendarSettings;
  restaurant: Restaurant;
}

type Props = OwnProps & StateProps;

const RoomOpeningHoursItem: React.FC<Props> = (props: Props) => {
  const isBlocked =
    //@ts-ignore
    !!props.blockedHours[props.roomId] &&
    //@ts-ignore
    props.blockedHours[props.roomId].includes(props.time.format('HH:mm'));

  return (
    <Button
      variant={isBlocked ? 'contained' : 'outlined'}
      onClick={() => props.toggle(props.roomId, props.time.format('HH:mm'), isBlocked)}
      color={isBlocked ? 'error' : 'secondary'}
      sx={{ mr: 1, mb: 1 }}
    >
      {props.time.format(timeFormat(props.restaurant.settings.date_format_key))}
    </Button>
  );
  return (
    <button
      className={`exclude-hours-picker__item ${
        isBlocked ? 'exclude-hours-picker__item--inactive' : ''
      }`}
      onClick={() => props.toggle(props.roomId, props.time.format('HH:mm'), isBlocked)}
    >
      {props.time.format(timeFormat(props.restaurant.settings.date_format_key))}
    </button>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    calendarSettings: state.calendar.calendarSettings,
    restaurant: state.calendar.restaurant,
  };
}

export default connect(mapStateToProps)(RoomOpeningHoursItem);
