import React, { ChangeEvent } from 'react';
import moment, { Moment } from 'moment';
import { calculateOpenHoursInDay } from 'utils/dates';
import { AppState } from 'types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TimePicker from 'app/components/BookingModal/TimePicker';
import {
  CalendarSettings,
  CalendarState,
  ReservationListSettings,
  Restaurant,
} from 'types/calendar';
import { SelectedHours } from 'types/filters';
import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { MuiDatePicker } from 'app/components/MuiDatePicker';
import AppConnectionStatusWrapper from 'app/components/AppConnectionStatusWrapper/AppConnectionStatusWrapper';

interface OwnProps {
  display?: ('date' | 'time')[];
  selectedHours?: SelectedHours;
  updateSelectedHours?: (selectedHours: SelectedHours) => void;
  changeCalendarDate?: (val: any, shouldMoveSlider: boolean) => void;
  changeSelectedDateRange?: (start: moment.Moment | null, end: moment.Moment | null) => void;
}

interface StateProps {
  locale: string;
  restaurant: Restaurant;
  calendar: CalendarState;
  calendarSettings: CalendarSettings;
  reservationListSettings: ReservationListSettings;
}

const FilterTime: React.FC<OwnProps & StateProps> = ({
  display = ['date', 'time'],
  selectedHours = { from: null, to: null, includeOngoing: false },
  ...props
}) => {
  const { t } = useTranslation();
  const updateStartTime = (time: moment.Moment) => {
    if (!props.updateSelectedHours) return;
    props.updateSelectedHours({ ...selectedHours, from: time });
  };
  const updateEndTime = (time?: moment.Moment) => {
    if (!props.updateSelectedHours) return;
    props.updateSelectedHours({ ...selectedHours, to: time });
  };
  const updateIncludeIngoing = (e: ChangeEvent<HTMLInputElement>) => {
    if (!props.updateSelectedHours) return;
    props.updateSelectedHours({
      ...selectedHours,
      includeOngoing: e.target.checked,
    });
  };
  const updateStartDate = (date: Moment) => {
    if (!props.changeSelectedDateRange) return;
    props.changeSelectedDateRange(date, props.reservationListSettings.endDate);

    if (date.isSame(props.reservationListSettings.endDate, 'day')) {
      if (!props.changeCalendarDate) return;
      props.changeCalendarDate(moment(date).toDate(), true);
    }
  };
  const updateEndDate = (date: Moment) => {
    if (!props.changeSelectedDateRange) return;
    props.changeSelectedDateRange(props.reservationListSettings.startDate, date);

    if (date.isSame(props.reservationListSettings.startDate, 'day')) {
      if (!props.changeCalendarDate) return;
      props.changeCalendarDate(moment(date).toDate(), true);
    }
  };

  const isDateRange =
    !!props.reservationListSettings.startDate && !!props.reservationListSettings.endDate;

  const defaultStartTime = !isDateRange
    ? calculateOpenHoursInDay(props.calendarSettings.date, props.calendar).start
    : moment().startOf('day');
  const defaultEndTime = !isDateRange
    ? calculateOpenHoursInDay(props.calendarSettings.date, props.calendar).end
    : moment().endOf('day').add(-30, 'minutes');

  return (
    <Stack spacing={2}>
      <AppConnectionStatusWrapper>
        {display.includes('date') && (
          <Box sx={{ display: 'flex', gap: '5px', width: '100%' }}>
            <MuiDatePicker
              fullWidth
              label={t('filtersStartDate')}
              format={props.restaurant.settings.date_format_key}
              onChange={updateStartDate}
              value={
                props.reservationListSettings.startDate
                  ? moment(props.reservationListSettings.startDate)
                  : null
              }
              shouldDisableDate={(date) =>
                (props.reservationListSettings.endDate &&
                  date?.isAfter(props.reservationListSettings.endDate)) ||
                false
              }
            />
            <MuiDatePicker
              fullWidth
              label={t('filtersEndDate')}
              format={props.restaurant.settings.date_format_key}
              onChange={updateEndDate}
              value={
                props.reservationListSettings.endDate
                  ? moment(props.reservationListSettings.endDate)
                  : null
              }
              shouldDisableDate={(date) =>
                (props.reservationListSettings.startDate &&
                  date?.isBefore(props.reservationListSettings.startDate)) ||
                false
              }
            />
          </Box>
        )}
      </AppConnectionStatusWrapper>
      {display.includes('time') && (
        <>
          <Box sx={{ display: 'flex' }}>
            <TimePicker
              startTime={selectedHours.from || defaultStartTime}
              endTime={selectedHours.to || defaultEndTime}
              updateEndTime={updateEndTime}
              updateStartTime={updateStartTime}
              labelStart={t('filtersFrom')}
              labelEnd={t('filtersTo')}
              min={!isDateRange ? defaultStartTime : undefined}
              max={!isDateRange ? defaultEndTime : undefined}
            />
          </Box>
          <FormControlLabel
            style={{ marginTop: 0 }}
            control={
              <Checkbox
                checked={selectedHours.includeOngoing}
                onChange={updateIncludeIngoing}
                className="color-blue-dark-2"
                color="primary"
                inputProps={{
                  'aria-label': 'checkbox',
                }}
                sx={{ pl: 0, mt: 0 }}
              />
            }
            label={t('filtersIncludeOngoing')}
          />
        </>
      )}
    </Stack>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    locale: state.calendar.locale,
    restaurant: state.calendar.restaurant,
    calendarSettings: state.calendar.calendarSettings,
    reservationListSettings: state.calendar.reservationListSettings,
    calendar: state.calendar,
  };
}

export default connect(mapStateToProps)(FilterTime);
