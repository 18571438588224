import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'types';
import moment from 'moment';
import 'moment/locale/lt';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/lv';
import 'moment/locale/ru';
import { dateFormatDate } from 'utils/dates';
import { ReactComponent as TimesIcon } from 'images/icons/close.svg';
import calendarActions from 'redux/actions/calendar';
import Constants from 'utils/Constants';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography } from '@mui/material';
import { CalendarSettings, ReservationListSettings, Settings } from 'types/calendar';
import { MuiDatePicker } from '../MuiDatePicker';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import './CalendarControls.scss';
import CalendarControlsButton from './CalendarControlsButton';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { useScreenSize } from 'hooks/useScreenSize';

import { AppConnectionStatus } from 'types/application';

interface DispatchProps {
  setLocale: () => void;
  changeCalendarDate: (
    val: any,
    shouldMoveSlider: boolean,
    isOffline: boolean | null | undefined,
  ) => void;
  changeDateSlider: (val: any, changeFactor: number, move: boolean) => void;
  changeReservationListDateRange: (start: moment.Moment | null, end: moment.Moment | null) => void;
}

interface StateProps {
  locale: string;
  today: moment.Moment;
  restaurantSettings: Settings;
  calendarSettings: CalendarSettings;
  reservationListSettings: ReservationListSettings;
  printing: boolean;
  connectionStatus: AppConnectionStatus;
}

type Props = StateProps & DispatchProps;

const CalendarControls: React.FC<Props> = (props) => {
  const { isOffline } = props.connectionStatus;
  const { t } = useTranslation();
  const { startDateFactor } = props.calendarSettings.controlsSettings;
  const { isMobile } = useScreenSize();
  const isDateRange =
    !!props.reservationListSettings.startDate && !!props.reservationListSettings.endDate;
  const showDaysLength = Constants.VARIABLES.showDaysLength;

  const changeDate = (val: any, changeFactor = 0, move = true) => {
    props.changeCalendarDate(val, false, isOffline);
    props.changeDateSlider(val, changeFactor, move);
  };

  const resetDateRange = () => {
    props.changeReservationListDateRange(null, null);
  };

  useEffect(() => {
    if (props.locale === '') {
      props.setLocale();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isMobile && !isDateRange && (
        <div className={'calendar-controls__item'}>
          <CalendarControlsButton onClick={() => changeDate('today')}>
            <HomeOutlinedIcon />
          </CalendarControlsButton>
        </div>
      )}
      {!isDateRange && (
        <div className={'calendar-controls__item'}>
          <MuiDatePicker
            id="date-picker-dialog"
            hideInput
            value={props.calendarSettings.date}
            onChange={(day) => changeDate(day && day.toDate())}
          />
        </div>
      )}
      {!isMobile && !isDateRange && (
        <div className="calendar-controls__item">
          <CalendarControlsButton
            onClick={() => changeDate(-1, -1)}
            sx={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              width: 'auto',
            }}
          >
            <ChevronLeftOutlinedIcon />
          </CalendarControlsButton>
          {Array.from({ length: showDaysLength }, (v, k) => k + startDateFactor).map((i) => {
            const date = moment(props.today).add(i, 'day');
            const isSelected = date.isSame(props.calendarSettings.date, 'day');
            return (
              <CalendarControlsButton
                onClick={() => changeDate(date.toDate(), 0, false)}
                key={i}
                active={isSelected}
                sx={{
                  width: 'auto',
                  lineHeight: 1,
                  borderRadius: 0,
                  borderLeft: 'none',
                  flexDirection: 'column',
                  '&:hover': {
                    borderLeft: 'none',
                  },
                }}
              >
                <Typography fontSize={12} variant="caption" display="block" lineHeight="inherit">
                  {t(date.format('ddd'))}
                </Typography>
                <Typography
                  fontSize={12}
                  variant="caption"
                  display="block"
                  className={'highlighted'}
                  lineHeight="inherit"
                >
                  {t(date.format('MMM'))} {date.format('DD')}
                </Typography>
              </CalendarControlsButton>
            );
          })}
          <CalendarControlsButton
            onClick={() => changeDate(+1, +1)}
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeft: 'none',
              width: 'auto',
            }}
          >
            <ChevronRightOutlinedIcon />
          </CalendarControlsButton>
        </div>
      )}

      {(isMobile || props.printing) && !isDateRange && (
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <Typography variant={'body2'} color="secondary">
            {props.calendarSettings.date.format(
              dateFormatDate(props.restaurantSettings.date_format_key),
            )}
          </Typography>
          <Typography variant={'body1'} color="secondary">
            {t(props.calendarSettings.date.format('dddd'))}
          </Typography>
        </Box>
      )}

      {isDateRange && (
        <Box
          display={'flex'}
          alignItems={'center'}
          className="sidebar-card"
          sx={{ marginBottom: 0, marginRight: '15px' }}
        >
          <Typography variant={'body1'} sx={{ fontWeight: 500 }}>
            <Typography component="span" sx={{ color: 'brandYellow', mr: 1, fontWeight: 500 }}>
              {t('Selected')}:
            </Typography>
            {props.reservationListSettings.startDate?.format('YYYY-MM-DD')}
            {' - '}
            {props.reservationListSettings.endDate?.format('YYYY-MM-DD')}
          </Typography>
          <IconButton
            sx={{ ml: 2, backgroundColor: 'blueGray', borderRadius: '3px' }}
            onClick={resetDateRange}
          >
            <TimesIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    locale: state.calendar.locale,
    today: state.calendar.today,
    calendarSettings: state.calendar.calendarSettings,
    restaurantSettings: state.calendar.restaurant.settings,
    reservationListSettings: state.calendar.reservationListSettings,
    printing: state.application.printing,
    connectionStatus: state.application.connectionStatus,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    setLocale: bindActionCreators(calendarActions.setLocale, dispatch),
    changeCalendarDate: bindActionCreators(calendarActions.changeCalendarDate, dispatch),
    changeDateSlider: bindActionCreators(calendarActions.changeDateSlider, dispatch),
    changeReservationListDateRange: bindActionCreators(
      calendarActions.changeReservationListDateRange,
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarControls);
