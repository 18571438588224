import React, { ChangeEvent, useState } from 'react';
import moment from 'moment';
import { DateTimeFormat } from 'utils/dates';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Reply, Restaurant } from 'types/calendar';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import './ReservationCommentsItem.scss';
import AppConnectionStatusWrapper from 'app/components/AppConnectionStatusWrapper/AppConnectionStatusWrapper';

interface OwnProps {
  comment: Reply;
}

interface StateProps {
  restaurant: Restaurant;
}

interface DispatchProps {
  deleteComment: (id: number) => void;
  editComment: (id: number, commentBody: string) => any;
}

type Props = OwnProps & StateProps & DispatchProps;

const ReservationCommentsItem: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editedBody, setEditedBody] = useState(props.comment.commentBody);

  const updateComment = (event: ChangeEvent<HTMLInputElement>) => {
    setEditedBody(event.target.value);
  };

  const deleteComment = () => {
    props.deleteComment(props.comment.id);
    setIsSaving(true);
  };

  const cancelEdit = () => {
    setIsEditing(false);
    setEditedBody(props.comment.commentBody);
  };

  const saveEdit = () => {
    setIsSaving(true);
    props.editComment(props.comment.id, editedBody.trim()).then(() => {
      setIsEditing(false);
      setIsSaving(false);
    });
  };

  return (
    <Box
      sx={{
        marginBottom: '16px',
        paddingBottom: '16px',
        borderBottom: '1px solid',
        borderBottomColor: 'linkWater',
      }}
    >
      {!isEditing && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography sx={{ color: 'navyBlue' }} variant={'body1'}>
              {props.comment.uid ? t('bookingModalCommentStaff') : t('bookingModalCommentClient')}
            </Typography>
            <div className="reply-date mr-1 text-app-secondary">
              <AccessTimeOutlinedIcon sx={{ mr: 1 }} />
              {moment
                .unix(props.comment.changed)
                .format(DateTimeFormat(props.restaurant.settings.date_format_key))}
            </div>
          </Box>
          <Typography
            sx={{
              whiteSpace: 'pre-line',
              fontSize: '13px',
              color: 'navyBlue',
              overflowWrap: 'anywhere',
            }}
          >
            {props.comment.commentBody
              .replace('Paid', t('reservationCommentStatus'))
              .replace('Payment date', t('reservationCommentDate'))
              .replace('Method', t('reservationCommentMethod'))}
          </Typography>
          {!isDeleting ? (
            <AppConnectionStatusWrapper>
              <div className="d-flex">
                <div className="reply-actions">
                  <Button
                    size={'small'}
                    onClick={() => setIsEditing(true)}
                    sx={(theme) => ({
                      color: theme.palette.navyBlue,
                      minWidth: 10,
                    })}
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </Button>

                  <Button
                    size={'small'}
                    onClick={() => setIsDeleting(true)}
                    sx={(theme) => ({
                      color: theme.palette.navyBlue,
                      minWidth: 10,
                    })}
                  >
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                  </Button>
                </div>
              </div>
            </AppConnectionStatusWrapper>
          ) : (
            <>
              <span className="mr-1">{t('bookingModalDeleteComment')}</span>
              <Box display="flex" mt={1} sx={{ gap: '10px' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  disabled={isSaving}
                  onClick={() => setIsDeleting(false)}
                >
                  {t('bookingModalDeleteCommentDecline')}
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  size="small"
                  loading={isSaving}
                  onClick={deleteComment}
                >
                  {t('bookingModalDeleteCommentConfirm')}
                </LoadingButton>
              </Box>
            </>
          )}
        </>
      )}
      {isEditing && (
        <>
          <TextField
            fullWidth
            id="detailsText"
            label="Comment:"
            margin="normal"
            multiline
            rows="4"
            variant="outlined"
            value={editedBody}
            onChange={updateComment}
          />
          <Box display="flex" sx={{ gap: '10px' }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              disabled={isSaving}
              onClick={cancelEdit}
            >
              {t('Cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              disabled={!editedBody || !editedBody.trim()}
              loading={isSaving}
              onClick={saveEdit}
            >
              {t('Save')}
            </LoadingButton>
          </Box>
        </>
      )}
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    restaurant: state.calendar.restaurant,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    deleteComment: bindActionCreators(reservationActions.deleteComment, dispatch),
    editComment: bindActionCreators(reservationActions.editComment, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationCommentsItem);
