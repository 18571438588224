import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface OwnProps {
  children: ReactNode
  className?: string
  onClick?: any
  disableStyling?: boolean
  style?: object
}

const wrapperStyles = {
  backgroundColor: 'brandWhite',
  borderColor: 'linkWater',
  '&:not(:last-child),&:not(:last-of-type)': {
    borderBottomColor: 'linkWater',
  },
}
const CardWrapper: React.FC<OwnProps> = ({ children, className, onClick, disableStyling, style }) => {

  return (
    <Box
      className={className}
      onClick={onClick}
      sx={disableStyling === true ? null : wrapperStyles}
      style={style}
    >
      {children}
    </Box>
  )
}

export default CardWrapper