import { ReservationState, Statuses } from 'types/reservation';
import OriginIcon from 'app/components/OriginIcon';
import { ReactComponent as ShoePrintsIcon } from 'images/icons/shoe-prints-light.svg';
import { ReactComponent as TagIcon } from 'images/icons/tag-light.svg';
import { ReactComponent as UserTagIcon } from 'images/icons/user-tag-light.svg';
import { ReactComponent as SpecialOfferIcon } from 'images/icons/PromotionTypes/ic-offer.svg';
import { ReactComponent as DepositIcon } from 'images/icons/PromotionTypes/ic-deposit.svg';
import { ReactComponent as DiscountIcon } from 'images/icons/PromotionTypes/ic-discount.svg';
import { ReactComponent as EventIcon } from 'images/icons/PromotionTypes/ic-event.svg';
import { ReactComponent as SavedCardIcon } from 'images/icons/PromotionTypes/ic-saved-card.svg';
import { ReactComponent as UsdCircleIcon } from 'images/icons/usd-circle-light.svg';
import { PromotionTypes } from 'enums/promotionTypes';
import { Box } from '@mui/material';

interface OwnProps {
  reservation: ReservationState;
}

const wrapperStyles = {
  marginLeft: '7px',
};

export const ReservationPromotionTypes = ({ reservation }: OwnProps) => {
  const promotionType = reservation?.specialOffer.promotionType;
  const hasPriceAmount = reservation?.specialOffer.price?.amount;
  const discount = reservation?.specialOffer?.discount;
  const showDepositIcon = PromotionTypes.Deposit === promotionType;

  const showSpecialOfferIcon = reservation && promotionType === PromotionTypes.Product;

  const showTagsIcon = !!reservation?.tags.length;

  const showUserIcon = !!reservation?.clientDetails;

  const showShoePrintIcon = reservation?.status === Statuses.WalkIn;

  const showDiscountIcon = PromotionTypes.Discount === promotionType && discount;

  const showEventIcon = PromotionTypes.Event === promotionType;

  const showSavedCardIcon = PromotionTypes.LateCancellation === promotionType;

  if (showSavedCardIcon) {
    return (
      <Box className="sidebar-reservation__icons" sx={{ alignItems: 'center' }}>
        <Box sx={wrapperStyles}>
          <SavedCardIcon width={20} height={20} />
        </Box>
      </Box>
    );
  }

  return (
    <Box className="sidebar-reservation__icons" sx={{ alignItems: 'center' }}>
      {showShoePrintIcon ? (
        <ShoePrintsIcon className="sidebar-reservation__icon" />
      ) : (
        <>
          {showTagsIcon && <TagIcon className="sidebar-reservation__icon" />}

          {showDepositIcon && (
            <Box sx={wrapperStyles}>
              <DepositIcon width={20} height={20} />
            </Box>
          )}

          {showUserIcon && <UserTagIcon className="sidebar-reservation__icon" />}

          {showDiscountIcon && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px', marginLeft: '7px' }}>
              <DiscountIcon width={18} height={18} />
            </Box>
          )}

          {showEventIcon && (
            <Box sx={wrapperStyles}>
              <EventIcon width={18} height={18} />
            </Box>
          )}

          {showSpecialOfferIcon && (
            <Box sx={wrapperStyles}>
              <SpecialOfferIcon width={20} height={20} />
            </Box>
          )}

          {hasPriceAmount && (
            <Box sx={{ ...wrapperStyles, display: 'flex', alignItems: 'center' }}>
              <UsdCircleIcon width={16} height={16} />
            </Box>
          )}

          <OriginIcon origin={reservation?.origin} />
        </>
      )}
    </Box>
  );
};
