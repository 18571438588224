import moment from 'moment';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'types';
import { CalendarSettings, Restaurant } from 'types/calendar';
import { hourFormat } from 'utils/dates';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

interface OwnProps {
  selectedTime?: moment.Moment | null;
  onTimeSelect?: (time: moment.Moment | null) => void;
}

interface StateProps {
  display: string;
  currentTime: moment.Moment;
  calendarSettings: CalendarSettings;
  restaurant: Restaurant;
}

const FloorTimeline: React.FC<StateProps & OwnProps> = ({
  display,
  currentTime,
  calendarSettings,
  restaurant,
  selectedTime,
  onTimeSelect,
}) => {
  const now = moment(currentTime).tz(restaurant.settings.timezone);
  const increment = restaurant.settings.increment || 15;
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  SwiperCore.use([Navigation, FreeMode]);

  const hours = Array(
    moment(calendarSettings.endTime)
      .add(-1, 'minute')
      .diff(moment(calendarSettings.startTime).minutes(0), 'hours') + 1,
  )
    .fill('')
    .map((_, i) => moment(calendarSettings.startTime).add(i, 'hours'));

  const handleSelectTime = (h: number, m: number) => {
    if (!onTimeSelect) return;
    const time = moment(calendarSettings.date).hours(h).minutes(m).seconds(0);

    if (time.isBefore(calendarSettings.startTime) || time.isAfter(calendarSettings.endTime)) return;

    if (!!selectedTime && selectedTime.isSame(time)) {
      onTimeSelect(null);
    } else {
      onTimeSelect(time);
    }
  };

  return (
    <div
      className={`floor-plan__timeline floor-plan__timeline--${display}${
        !onTimeSelect ? ' floor-plan__timeline--static' : ''
      }`}
    >
      <button className="floor-plan__timeline-swiper-btn" ref={prevRef}>
        <NavigateBeforeOutlinedIcon />
      </button>
      <Swiper
        className="floor-plan__timeline-swiper"
        slidesPerView={display === 'Full' ? hours.length : 'auto'}
        navigation={{
          prevEl: prevRef.current!,
          nextEl: nextRef.current!,
        }}
        freeMode={true}
      >
        {hours.map((h) => {
          const isOpening = moment(h).minutes(0).isBefore(calendarSettings.startTime);
          return (
            <SwiperSlide
              key={h.unix()}
              className="floor-plan__timeline-item"
              style={{
                flexBasis: display === 'Full' ? `${95 / hours.length}%` : '160px',
              }}
            >
              <div
                className={`floor-plan__timeline-hour${
                  !isOpening ? ' floor-plan__timeline-hour--active' : ''
                }`}
                onClick={() => {
                  handleSelectTime(h.hour(), 0);
                }}
              >
                {h.format(hourFormat(restaurant.settings.date_format_key))}
              </div>
              <div className="floor-plan__timeline-slots">
                {Array(60 / increment)
                  .fill(h)
                  .map((h, i) => {
                    const time = moment(h)
                      .minutes(i * increment)
                      .seconds(0);
                    const isClosed =
                      time.isBefore(calendarSettings.startTime) ||
                      time.isAfter(calendarSettings.endTime);
                    return (
                      <div
                        key={time.unix()}
                        className={`floor-plan__timeline-minute${
                          selectedTime?.unix() === time.unix()
                            ? ' floor-plan__timeline-minute--active'
                            : ''
                        }${isClosed ? ' floor-plan__timeline-minute--closed' : ''}`}
                        style={{
                          flexBasis: `${100 / (60 / increment)}%`,
                          fontSize:
                            display === 'Full'
                              ? `min(calc(((100vw - 360px) / ${
                                  hours.length * (60 / increment)
                                }) / 2), 14px)`
                              : '14px',
                        }}
                        onClick={() => handleSelectTime(h.hour(), i * increment)}
                      >
                        {time.format('mm')}
                      </div>
                    );
                  })}
              </div>
              {calendarSettings.date.isSame(now, 'day') && now.hour() === h.hour() && (
                <span
                  className="floor-plan__timeline-indicator"
                  style={{ left: `${(now.minute() / 60) * 100}%` }}
                />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <button className="floor-plan__timeline-swiper-btn" ref={nextRef}>
        <NavigateNextOutlinedIcon />
      </button>
    </div>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    display: state.calendar.calendarDisplay,
    currentTime: state.application.currentTime,
    calendarSettings: state.calendar.calendarSettings,
    restaurant: state.calendar.restaurant,
  };
}

export default connect(mapStateToProps)(FloorTimeline);
