import React from 'react';
//@ts-ignore
import { ApexOptions } from 'apexcharts';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { CardContent, CardHeader } from '@mui/material';
//@ts-ignore
import Chart from 'react-apexcharts';

const columnColors = {
  bg: '#f8d3ff',
  series1: '#826af9',
  series2: '#d2b0ff',
};

const series = [
  {
    name: 'First dataset',
    data: [90, 120, 55, 100, 80, 125, 175, 70, 88],
  },
  {
    name: 'Second dataset',
    data: [85, 100, 30, 40, 95, 90, 30, 110, 62],
  },
];

const BigChart = () => {
  const theme = useTheme();

  const options: ApexOptions = {
    chart: {
      offsetX: -10,
      stacked: true,
      parentHeightOffset: 0,
      toolbar: { show: false },
    },
    fill: { opacity: 1 },
    dataLabels: { enabled: false },
    colors: [columnColors.series1, columnColors.series2],
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      labels: { colors: theme.palette.text.secondary },
      markers: {
        offsetY: 1,
        offsetX: -3,
      },
      itemMargin: {
        vertical: 3,
        horizontal: 10,
      },
    },
    stroke: {
      show: true,
      colors: ['transparent'],
    },
    plotOptions: {
      bar: {
        columnWidth: '15%',
        colors: {
          backgroundBarRadius: 10,
          backgroundBarColors: [
            columnColors.bg,
            columnColors.bg,
            columnColors.bg,
            columnColors.bg,
            columnColors.bg,
          ],
        },
      },
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: { show: true },
      },
    },
    yaxis: {
      labels: {
        style: { colors: theme.palette.text.disabled },
      },
    },
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { color: theme.palette.divider },
      categories: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12'],
      crosshairs: {
        stroke: { color: theme.palette.divider },
      },
      labels: {
        style: { colors: theme.palette.text.disabled },
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          plotOptions: {
            bar: {
              columnWidth: '35%',
            },
          },
        },
      },
    ],
  };

  return (
    <Card elevation={6} sx={{ height: '100%' }}>
      <CardHeader
        title="Covers graph"
        sx={{
          flexDirection: ['column', 'row'],
          alignItems: ['flex-start', 'center'],
          '& .MuiCardHeader-action': { mb: 0 },
          '& .MuiCardHeader-content': { mb: [2, 0] },
        }}
      />
      <CardContent>
        {/* @ts-ignore */}
        <Chart type="bar" height={400} options={options} series={series} />
      </CardContent>
    </Card>
  );
};

export default BigChart;
