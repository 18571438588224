import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { AppState } from 'types';
import reservationActions from 'redux/actions/reservation';
import { ReservationState } from 'types/reservation';
import './VipSwitch.scss';

interface OwnProps {
  isReadOnly?: boolean;
}

interface DispatchProps {
  setVip: (isVip: boolean) => void;
  sendVip: (isVip: boolean, clientId: number) => any;
}

interface StateProps {
  reservation: ReservationState;
}

type Props = DispatchProps & StateProps & OwnProps;

const VipSwitch: React.FC<Props> = (props) => {
  const mode = useSelector((state: AppState) => state.theme.mode);

  const onClick = () => {
    const newVip = !props.reservation.clientIsVip;
    props.setVip(newVip);
    if (props.reservation.id !== undefined && props.reservation.clientId !== undefined) {
      props.sendVip(newVip, props.reservation.clientId).catch(() => {
        props.setVip(!newVip);
      });
    }
  };

  return (
    <div className="vip-modal-switch">
      <Button
        disabled={props.isReadOnly}
        variant="contained"
        color="primary"
        size="small"
        className={[
          'vip-btn-switch',
          mode === 'dark' ? 'vip-btn-switch--dark' : '',
          props.reservation.clientIsVip ? 'vip-btn-switch--is-vip' : '',
        ].join(' ')}
        onClick={onClick}
      >
        VIP
      </Button>
    </div>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    reservation: state.reservation,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    setVip: bindActionCreators(reservationActions.setVip, dispatch),
    sendVip: bindActionCreators(reservationActions.sendVip, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VipSwitch);
