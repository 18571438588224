export const PATH = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  MOZREST_INTEGRATION: '/mozrest',
  CALENDAR: '/calendar',
  LIST: '/list',
  FLOOR_PLAN: '/floor-plan',
  REPORTS: '/reports',
  SETTINGS: '/settings',
  SETTINGS_OPTION: 'options',
  SETTINGS_BASIC_INFORMATION: '/settings/options/restaurant-setup/basic-information',
  RESTAURANT_PROFILE: 'restaurant-setup/profile',
  BASIC_INFORMATION: 'restaurant-setup/basic-information',
  TABLE_MANAGEMENT: 'restaurant-setup/table-management',
  TIME_AND_LANGUAGE: 'restaurant-setup/time-and-language',
  QUICK_ACTIONS: '/settings/quick-actions',
};
