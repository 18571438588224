import { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SettingsSubNavigation } from '../SettingsSubNavigation';
import { useScreenSize } from 'hooks/useScreenSize';

export interface INavigationValues {
  visible: boolean;
}

interface NavigationContextProps {
  visible: boolean;
  setNavigationValues: React.Dispatch<React.SetStateAction<INavigationValues>>;
}

export const NavigationContext = createContext<NavigationContextProps>({
  visible: true,
  setNavigationValues: () => {},
});

const SettingsWrapper = () => {
  const [navigationValues, setNavigationValues] = useState({ visible: true });
  const { isMobile } = useScreenSize();

  const showMobileSubNavigation = isMobile ? !navigationValues.visible : true;
  const showOutlet = isMobile ? navigationValues.visible : true;

  return (
    <NavigationContext.Provider
      value={{
        ...navigationValues,
        setNavigationValues,
      }}
    >
      {showMobileSubNavigation && (
        <SettingsSubNavigation
          setNavigationValues={setNavigationValues}
          visible={navigationValues.visible}
        />
      )}
      {showOutlet && <Outlet />}
    </NavigationContext.Provider>
  );
};

export default SettingsWrapper;
