import moment from 'moment-timezone';
import { dateFormatDate, roundDate, timeFormat } from './dates';
import { isTouchDevice } from './deviceHelper';
import { Currencies, ReservationState, Statuses } from '../types/reservation';
import {
  ApiReservation,
  Room,
  RoomOrTable,
  SelectedCalendarDay,
  Settings,
  // eslint-disable-next-line
  Table,
  TimelineReservation,
} from '../types/calendar';
import Constants from './Constants';

export interface IStatusesEng {
  arrived: string;
  blocked: string;
  cancelled: string;
  approved: string;
  not_arrived: string;
  pending: string;
  templet: string;
  walk_in: string;
  pre_booked: string;
}
export const StatusesEng = {
  arrived: 'Seated',
  blocked: 'Blocked',
  cancelled: 'Canceled',
  approved: 'Confirmed',
  not_arrived: 'No Show',
  pending: 'Pending',
  templet: 'Template',
  walk_in: 'Walk-in',
  waiting_list: 'Waiting List',
  pre_booked: 'Pre Booked',
};

export const prepareReservationFromReservationState = (
  reservation: ReservationState,
  restaurantSettings: Settings,
  spinner = false,
): TimelineReservation => {
  return {
    id: reservation.startTime.valueOf() + moment().valueOf(),
    group: reservation.tables,
    title: reservation.name,
    start: reservation.startTime.valueOf(),
    end: reservation.endTime?.valueOf() || reservation.startTime.valueOf(),
    // end: roundDate(moment(
    //   reservation.timeInterval[0].endTime).valueOf(),
    //   moment.duration(+restaurantSettings.increment, 'minutes'),
    //   'ceil',
    // ),
    canResize: !spinner ? false : 'both',
    canMove: !spinner && canReservationMove(),
    spinner: spinner,
    itemProps: {},
    reservation: {
      ...reservation,
      timeFormat: timeFormat(restaurantSettings.date_format_key),
      showInitialTimeBadge: restaurantSettings.initial_reservation_badge,
      autoArrivalDisabled: restaurantSettings.auto_arrival_disabled,
    },
  };
};

export const prepareReservationsFromApi = (
  reservations: ApiReservation[],
  restaurantSettings: Settings,
  allTables: RoomOrTable[],
) => {
  const data: TimelineReservation[] = [];
  reservations.forEach((reservationData: ApiReservation) => {
    const reservation = reservationData.reservation;
    data.push({
      id: reservation.nid,
      group: reservation.tables.map((table) => table.nid.toString()),
      title: reservation.title,
      start: moment(reservation.timeInterval[0].startTime).valueOf(),
      end: roundDate(
        moment(reservation.timeInterval[0].endTime).valueOf(),
        moment.duration(+restaurantSettings.increment, 'minutes'),
        'ceil',
      ),
      canResize: 'both',
      canMove: canReservationMove(),
      spinner: false,
      itemProps: {},
      reservation: {
        id: reservation.nid,
        numberOfGuests: reservation.covers,
        status: reservation.reservationStatus,
        name: reservation.client[0].name,
        email: reservation.client[0].email,
        phoneNumber: reservation.client[0].phone,
        company: reservation.client[0].company,
        language: reservation.language,
        tags: reservation.tags,
        clientId: reservation.client[0].nid,
        clientTags: reservation.client[0].clientTags,
        clientDetails: reservation.client[0].clientDetails,
        tables: reservation.tables.map((table) => table.nid.toString()),
        startTime: moment(reservation.timeInterval[0].startTime),
        endTime: moment(reservation.timeInterval[0].endTime),
        comments: reservation.replies,
        newComment: '',
        averageRating: reservationData.clientAverageFeedback,
        reservationAverageRating: reservationData.reservationAverageFeedback,
        waiter:
          reservation.waiters && reservation.waiters.length > 0
            ? reservation.waiters[0]
            : { tid: '_none' },
        originalWaiter:
          reservation.waiters && reservation.waiters.length > 0
            ? reservation.waiters[0]
            : { tid: '_none' },
        clientIsVip: reservation.client[0].vip,
        clientIsBigSpender: reservation.client[0].big_spender,
        showBigSpenderTag: restaurantSettings.show_big_spender_tag,
        default_booking_length: restaurantSettings.default_booking_length,
        initialTime: reservation.trackedChanges && reservation.trackedChanges.initialTime,
        showInitialTimeBadge: restaurantSettings.initial_reservation_badge,
        autoArrivalDisabled: restaurantSettings.auto_arrival_disabled,
        notifications: {
          sms: reservation.notifications.sms || false,
          email: reservation.notifications.email || false,
          resend: false,
          tableReady: false,
          language: reservation.language,
        },
        origin: reservation.origin,
        specialOffers: [],
        specialOffer:
          reservation.specialOffers && reservation.specialOffers.length > 0
            ? {
                ...reservation.specialOffers[0],
                price: {
                  ...reservation.specialOffers[0].price,
                  // TODO: fast hack. check why there is no amount in some situations
                  total: reservation.covers * (reservation.specialOffers[0].price?.amount || 0),
                },
              }
            : { nid: '_none' },
        created: reservation.created,
        changed: reservation.changed,
        timeFormat: timeFormat(restaurantSettings.date_format_key),
        guestDetails: reservationData.clientData,
        reservationSavingErrors: [],
        payments: reservation.payments || {
          currencies: {},
          payments: [],
        },
        dinningRooms: [
          // @ts-ignore
          ...new Set(
            reservation.tables.map((table) => {
              return allTables.find((tab) => +tab.id === table.nid)?.parent;
            }),
          ),
        ],
        lateCancellationNoShow:
          reservation.specialOffers && reservation.specialOffers.length > 0
            ? {
                enabled: reservation.specialOffers[0].lateCancellationNoShow,
                time: reservation.specialOffers[0].lateCancellationNoShowTime,
                maxAmount: reservation.specialOffers[0].lateCancellationNoShowMaxAmount,
                currency: reservation.specialOffers[0].lateCancellationNoShowAmountCurrency,
                chargeMode: reservation.specialOffers[0].lateCancellationNoShowChargeMode,
                paymentData: reservation.stripePaymentData,
              }
            : {
                enabled: 0,
                time: 0,
                paymentData: null,
              },
      } as ReservationState,
      // canMove: startValue > new Date().getTime(),

      // itemProps: {
      //   "data-tip": faker.hacker.phrase()
      // }
    });
  });
  return data;
};

export const getVisibleReservations = (
  reservations: TimelineReservation[],
  hide_no_show: boolean,
) => {
  return reservations.filter((reservation) => {
    return ![...(hide_no_show ? [Statuses.NoShow] : []), Statuses.Cancelled].includes(
      reservation.reservation.status,
    );
  });
};

export const filterReservationsForTime = (
  reservations: TimelineReservation[],
  startTime: moment.Moment,
  endTime: moment.Moment,
) => {
  return reservations.filter(
    (reservation) =>
      moment(reservation.start).isBetween(startTime, endTime, undefined, '[]') ||
      moment(reservation.end).isBetween(startTime, endTime, undefined, '[]'),
  );
};

export const filterReservationsForDate = (
  reservations: TimelineReservation[],
  startDate: moment.Moment,
  endDate: moment.Moment,
) => {
  return reservations.filter(
    (reservation) =>
      moment(reservation.start).isBetween(startDate, endDate, 'day', '[]') ||
      moment(reservation.end).isBetween(startDate, endDate, 'day', '[]'),
  );
};

export const prepareReservationPayments = (currencies: Currencies) => {
  let payments: string[] = [];
  for (let [currency, totalValue] of Object.entries(currencies)) {
    payments.push(`${(totalValue / 100).toFixed(2)} ${currency}`);
  }
  return payments;
};

export const canReservationMove = () => {
  return !isTouchDevice();
};

export const getReservationTitle = (status: Statuses, title: string) => {
  switch (status) {
    case Statuses.WalkIn:
      return StatusesEng[Statuses.WalkIn];
    case Statuses.Blocked:
      return StatusesEng[Statuses.Blocked];
    default:
      return title;
  }
};

export const getReservationTimeInterval = (
  reservation: TimelineReservation,
  restaurantSettings: Settings,
) => {
  const startTime = reservation.reservation.startTime.format(reservation.reservation.timeFormat);
  const endTime = moment(
    roundDate(
      // @ts-ignore
      reservation.reservation.endTime?.valueOf(),
      moment.duration(+restaurantSettings.increment, 'minutes'),
      'ceil',
    ),
  ).format(reservation.reservation.timeFormat);

  return startTime + ' - ' + endTime;
};

export const getReservationDinningRoomsNames = (
  reservation: TimelineReservation,
  allRooms: Room[],
) => {
  let dinningRooms: string[] = [];
  reservation.reservation.dinningRooms.map((room) => {
    let foundRoom: undefined | string = allRooms.find((el) => el.nid === room)?.title;
    if (foundRoom !== undefined) {
      dinningRooms.push(foundRoom);
    }
    return null;
  });
  return dinningRooms;
};

export const getReservationTablesNames = (
  reservation: TimelineReservation,
  allTables: RoomOrTable[],
) => {
  let tables: string[] = [];
  reservation.reservation.tables.map((table) => {
    let foundTable: undefined | string = allTables.find((el) => el.id === table)?.title;
    if (foundTable !== undefined) {
      tables.push(foundTable);
    }
    return null;
  });
  return tables;
};

export const getReservationSingleTableName = (tableId: string, allTables: RoomOrTable[]) => {
  const table = allTables.find((t) => t.id === tableId);
  return table ? table.title : '';
};

export const getReservationCommentBodies = (reservation: TimelineReservation) => {
  let commentBodies: string[] = [];
  reservation.reservation.comments.map((comment) => commentBodies.push(comment.commentBody));
  return commentBodies;
};

export const convertReservationTimestampToDateTime = (
  timestamp: number,
  reservation: TimelineReservation,
  restaurantSettings: Settings,
) => {
  const dateTimeFormat =
    dateFormatDate(restaurantSettings.date_format_key) + ' - ' + reservation.reservation.timeFormat;
  return moment.unix(timestamp).format(dateTimeFormat);
};

export const shouldPayForLateCancellationNoShow = (reservation: ReservationState) => {
  const now = moment(new Date());
  const reservationStart = moment(reservation.startTime);
  const duration = moment.duration(reservationStart.diff(now));
  const statuses = [Statuses.NoShow, Statuses.Cancelled];

  return (
    statuses.includes(reservation.status) && // Reservation status is 'not arrived' or 'cancelled'.
    reservation.lateCancellationNoShow?.enabled === 1 && // Event allows to charge for late cancellation.
    !reservation.lateCancellationNoShow.paymentData?.paymentSuccess && // Client did NOT already pay for that.
    duration.asHours() < reservation.lateCancellationNoShow.time && // It's too late for free cancellation.
    reservation.lateCancellationNoShow.paymentData && // Client card data is saved for future use.
    reservation.lateCancellationNoShow.paymentData.id !== ''
  );
};

export const getReservationClassName = (
  status: Statuses,
  startTime: moment.Moment,
  autoArrivalDisabled: boolean,
) => {
  switch (status) {
    case Statuses.Confirmed:
      if (startTime.isBefore() && !autoArrivalDisabled) {
        return 'sidebar-reservation--arrived';
      } else {
        return 'sidebar-reservation--approved';
      }
    case Statuses.Arrived:
      return 'sidebar-reservation--arrived';
    case Statuses.Pending:
    case Statuses.PreBooked:
      return 'sidebar-reservation--pending';
    case Statuses.NoShow:
      return 'sidebar-reservation--not-arrived';
    default:
      return `sidebar-reservation--${status.replace(/_/g, '-')}`;
  }
};

export const exportReservationsToCsv = (
  reservations: TimelineReservation[],
  from: string,
  to: string,
  restaurantSettings: Settings,
  allRooms: Room[],
  allTables: RoomOrTable[],
) => {
  let csvDataFiltered: (string | number)[][] = [
    [
      'Reservation number',
      'Time interval',
      'People',
      'Name',
      'Phone number',
      'Email',
      'Comment',
      'Dining area',
      'Table',
      'Prepayments and promotions',
      'Paid amount',
      'Status',
      'Create date',
      'Update date',
      'Source',
    ],
  ];

  reservations.forEach((reservation) => {
    let data = reservation.reservation;
    const date = reservation.reservation.startTime.format(
      dateFormatDate(restaurantSettings.date_format_key),
    );
    const rooms = getReservationDinningRoomsNames(reservation, allRooms);
    const comments = getReservationCommentBodies(reservation);
    const tables = getReservationTablesNames(reservation, allTables);
    const payments = prepareReservationPayments(reservation.reservation.payments.currencies);

    csvDataFiltered.push([
      reservation.id,
      date + ' ' + getReservationTimeInterval(reservation, restaurantSettings),
      data.numberOfGuests ?? '',
      data.name,
      data.phoneNumber,
      data.email,
      comments.join('\n').replace(/"/g, "'"),
      rooms.join(','),
      tables.join(','),
      reservation.reservation.specialOffer.title ?? '',
      payments.join(' + '),
      StatusesEng[data.status],
      data.created
        ? convertReservationTimestampToDateTime(data.created, reservation, restaurantSettings)
        : '',
      data.changed
        ? convertReservationTimestampToDateTime(data.changed, reservation, restaurantSettings)
        : '',
      data.origin,
    ]);
  });

  // Prepare data for CSV.
  const filename = `reservation-list-${from}-${to}.csv`;
  const csvContent = csvDataFiltered
    .map((e) => {
      return e.map((s) => `"${s}"`).join(',');
    })
    .join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Try to download file.

  // @ts-expect-error
  if (navigator.msSaveBlob) {
    // @ts-expect-error
    navigator.msSaveBlob(blob, filename);
  } else {
    let link = document.createElement('a');
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const getSelectedCalendarDay = (restaurantId: number | undefined) => {
  if (typeof restaurantId === 'undefined') {
    return null;
  }

  const storageKey = `${Constants.VARIABLES.tableinCalendarDayStart}_${restaurantId}`;
  const storageItem = localStorage.getItem(storageKey);

  if (!storageItem) {
    return null;
  }

  const item: SelectedCalendarDay = JSON.parse(storageItem);

  if (moment().isAfter(moment(item.ttl))) {
    localStorage.removeItem(storageKey);

    return null;
  }

  return item.date;
};

export const setSelectedCalendarDay = (date: moment.Moment, restaurantId: number | undefined) => {
  if (typeof restaurantId === 'undefined') {
    return;
  }

  const item: SelectedCalendarDay = {
    restaurantId,
    date: date.format(),
    ttl: moment().add(30, 'minutes').format(),
  };

  localStorage.setItem(
    `${Constants.VARIABLES.tableinCalendarDayStart}_${restaurantId}`,
    JSON.stringify(item),
  );
};
