import React from 'react';
import { Box, Typography } from '@mui/material';
import TableinLogo from 'images/icons/ic-tablein.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/ic-settings.svg';
import { ReactComponent as CalendarIcon } from 'images/icons/ic-calendar.svg';
import { ReactComponent as ReportsIcon } from 'images/icons/ic-reports.svg';
import { ReactComponent as ListIcon } from 'images/icons/ic-list.svg';
import { ReactComponent as FloorPlanIcon } from 'images/icons/ic-floor-plan.svg';
import { ReactComponent as DatabaseIcon } from 'images/icons/ic-guestbook.svg';
import { ReactComponent as HomeIcon } from 'images/icons/ic-home-icon.svg';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { PATH } from 'constants/paths';
import { SettingsNavLink } from '../SettingsNavLink';

const settingsNavigationLinks = (t: TFunction) => [
  {
    to: PATH.SETTINGS_BASIC_INFORMATION,
    icon: <HomeIcon />,
    name: t('navigationHome'),
  },
  {
    to: PATH.CALENDAR,
    icon: <CalendarIcon />,
    name: t('navigationCalendar'),
  },
  {
    to: PATH.LIST,
    icon: <ListIcon />,
    name: t('navigationList'),
  },
  {
    to: PATH.FLOOR_PLAN,
    icon: <FloorPlanIcon />,
    name: t('navigationFloorPlan'),
  },
  {
    to: PATH.REPORTS,
    icon: <ReportsIcon />,
    name: t('navigationReports'),
  },
  {
    to: '/',
    icon: <DatabaseIcon />,
    name: t('navigationDatabase'),
  },
  {
    to: '/',
    icon: <SettingsIcon />,
    name: t('navigationSettings'),
  },
];

export const SettingsNavigation = () => {
  const { t } = useTranslation();

  const settingsNavigationLinksToRender = settingsNavigationLinks(t).map(({ to, name, icon }) => (
    <SettingsNavLink to={to} style={{ textDecoration: 'none' }} key={name}>
      {icon}
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '10px',
          fontWeight: '700',
          maxWidth: '80px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        className="settings-navigation-link"
      >
        {name}
      </Typography>
    </SettingsNavLink>
  ));

  const mainLinks = settingsNavigationLinksToRender.slice(0, -3);
  const lastTwoLinks = settingsNavigationLinksToRender.slice(-3);

  return (
    <Box
      sx={{
        display: { xs: 'none', md: 'flex' },
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '80px',
        backgroundColor: 'darkerBlue',
        padding: '26px 12px',
        height: '100vh',
        position: 'sticky',
        top: 0,
      }}
    >
      <Box
        component="img"
        src={TableinLogo}
        alt={t('tableinLogoAlt')}
        sx={{ width: '52px', height: '13px' }}
        loading="lazy"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          marginTop: '52px',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>{mainLinks}</Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>{lastTwoLinks}</Box>
      </Box>
    </Box>
  );
};
