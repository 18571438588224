import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import './ScrollArea.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
  height?: string;
  width?: string;
}

const ScrollArea = ({
  className,
  height = "100%",
  width = "100%",
  children,
}: Props) => {
  return (
    <Scrollbars
      renderTrackHorizontal={props => (
        <div {...props} style={{ display: "none" }} />
      )}
      renderThumbHorizontal={props => (
        <div {...props} style={{ display: "none" }} />
      )}
      autoHide
      className={`scroll-area${className ? ` ${className}` : ''}`}
      style={{ height, width }}
    >
      {children}
    </Scrollbars>
  );
};

export default ScrollArea;
