import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import applicationActions from 'redux/actions/application';
import calendarActions from 'redux/actions/calendar';
import { useTranslation } from 'react-i18next';
import { ReservationsSettings } from 'types/calendar';
import { useLocation } from 'react-router-dom';
import { Dialog, Button, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useStatus } from 'hooks/useStatus';
import { Capacitor } from '@capacitor/core';

interface StateProps {
  printing: boolean;
  extendedReservationsIsLoading: boolean;
  reservationSettings: ReservationsSettings;
}

interface DispatchProps {
  updatePrinting: (printing: boolean) => void;
  updateExportListToCsv: (exportListToCsv: boolean) => void;
}

interface OwnProps {
  resetFilters: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const FilterActions: React.FC<Props> = ({
  printing,
  extendedReservationsIsLoading,
  updatePrinting,
  updateExportListToCsv,
  resetFilters,
  reservationSettings,
}) => {
  const { isReadOnly } = useStatus();
  const { t } = useTranslation();
  const location = useLocation();
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const isNativePlatform = !Capacitor.isNativePlatform();

  useEffect(() => {
    if (printing) {
      window.print();
      updatePrinting(false);
    }
  }, [printing, updatePrinting]);

  const handleExport = () => {
    if (
      location.pathname === '/list' &&
      reservationSettings.page < reservationSettings.pagesCount
    ) {
      setModalIsVisible(true);
    } else {
      updateExportListToCsv(true);
    }
  };

  const handleModalClose = () => {
    setModalIsVisible(false);
  };

  return (
    <>
      <div className={'sidebar-filters__filter-group'}>
        <Button
          variant="outlined"
          color="secondary"
          className="sidebar-filters__button__reset"
          onClick={resetFilters}
        >
          {t('Reset')}
        </Button>

        {isNativePlatform && (
          <>
            <Button
              disabled={isReadOnly}
              variant="outlined"
              onClick={() => updatePrinting(true)}
              className="sidebar-filters__button__print"
              sx={(theme) => ({
                borderColor: theme.palette.porcelain,
                color: theme.palette.navyBlue,
                minWidth: 50,
              })}
            >
              <PrintOutlinedIcon />
            </Button>

            <Button
              variant="outlined"
              onClick={handleExport}
              disabled={isReadOnly || extendedReservationsIsLoading}
              className={'sidebar-filters__button__download'}
              sx={(theme) => ({
                borderColor: theme.palette.porcelain,
                color: theme.palette.navyBlue,
                minWidth: 50,
              })}
            >
              <FileDownloadOutlinedIcon />
            </Button>
          </>
        )}
      </div>

      <Dialog open={modalIsVisible} maxWidth="sm" fullWidth={true}>
        <DialogTitle>{t('filterDialogExportAllReservations')}</DialogTitle>

        <DialogContent>{t('filterDialogLoadAllReservations')}</DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleModalClose}>
            {t('filterDialogClose')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    printing: state.application.printing,
    extendedReservationsIsLoading: state.calendar.extendedReservationsIsLoading,
    reservationSettings: state.calendar.reservationsSettings,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updatePrinting: bindActionCreators(applicationActions.updatePrinting, dispatch),
    updateExportListToCsv: bindActionCreators(calendarActions.updateExportListToCsv, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterActions);
