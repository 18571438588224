import React, { useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import calendarActions from 'redux/actions/calendar';
import { useTranslation } from 'react-i18next';
import { ReservationState } from 'types/reservation';
import './DeleteReservation.scss';
import { Button, Box } from '@mui/material';
import AppConnectionStatusWrapper from 'app/components/AppConnectionStatusWrapper/AppConnectionStatusWrapper';

interface DispatchProps {
  deleteReservation: (reservation: ReservationState) => void;
  closeFullBookingModal: () => void;
}

interface OwnProps {
  reservation: ReservationState;
}

type Props = DispatchProps & OwnProps;

const DeleteReservation: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState(false);

  const confirmedDeleteReservation = () => {
    props.closeFullBookingModal();
    props.deleteReservation(props.reservation);
  };

  return (
    <AppConnectionStatusWrapper>
      {!confirm ? (
        <Button
          onClick={() => setConfirm(true)}
          color="error"
          sx={{
            height: 'fit-content',
          }}
        >
          {t('bookingModalDeleteConfirmationInfo')}
        </Button>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItem: 'center', gap: '15px' }}>
          <Box>{t('bookingModalDeleteConfirmation')}</Box>
          <Button variant={'contained'} color={'error'} onClick={confirmedDeleteReservation}>
            {t('bookingModalConfirmDelete')}
          </Button>
          <Button
            variant={'outlined'}
            onClick={() => setConfirm(false)}
            sx={(theme) => ({
              borderColor: theme.palette.brandYellow,
              color: theme.palette.brandYellow,
              '&:hover': {
                borderColor: theme.palette.brandYellow,
              },
            })}
          >
            {t('bookingModalCancelDelete')}
          </Button>
        </Box>
      )}
    </AppConnectionStatusWrapper>
  );
};

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    deleteReservation: bindActionCreators(calendarActions.deleteReservation, dispatch),
    closeFullBookingModal: bindActionCreators(calendarActions.closeFullBookingModal, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(DeleteReservation);
