import React from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { AppState } from 'types';
import { useScreenSize } from 'hooks/useScreenSize';
import CalendarControlsButton from '../CalendarControlsButton';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';

interface OwnProps {
  isSidebarOpen: boolean;
  toggleSidebar?: () => void;
}

interface StateProps {
  isSearchOpen: boolean;
}

interface DispatchProps {
  toggleSearch: () => void;
}

type Props = DispatchProps & OwnProps & StateProps;

const CalendarSearchToggleButton: React.FC<Props> = (props) => {
  const { isMobile } = useScreenSize();

  const handleClick = () => {
    if (!isMobile && !props.isSidebarOpen) {
      props.toggleSidebar?.();
      if (!props.isSearchOpen) {
        props.toggleSearch();
      }
    } else {
      props.toggleSearch();
    }
  };

  return (
    <CalendarControlsButton onClick={handleClick}>
      <SearchOutlinedIcon />
    </CalendarControlsButton>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    isSearchOpen: state.calendar.searchOpen,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    toggleSearch: bindActionCreators(calendarActions.toggleSearch, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSearchToggleButton);
