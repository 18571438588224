import React, { ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

interface OwnProps {
  children: ReactNode | ReactNode[];
  sx?: SxProps;
  withSpacing?: boolean;
}

export const StyledCard = ({ children, sx, withSpacing }: OwnProps) => {
  return (
    <Box
      sx={{
        backgroundColor: 'brandWhite',
        padding: '24px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: withSpacing ? '32px' : 0,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
