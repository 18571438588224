import React from 'react';
import { Feedback } from 'types/reservation';

interface OwnProps {
  feedback?: Feedback;
}

type Props = OwnProps;

const FeedbackListItem: React.FC<Props> = (props) => {
  const { feedback } = props;
  return (
    <div>
      <div className={'feedback-item__rates'}>
        <div>
          Food: <span>{feedback?.rateFood}</span>
        </div>
        <div>
          Service: <span>{feedback?.rateService}</span>
        </div>
        <div>
          Ambience: <span>{feedback?.rateAmbience}</span>
        </div>
        <div>
          Cleanliness: <span>{feedback?.rateCleanliness}</span>
        </div>
      </div>
      <div className={'feedback-item__comment'}>{feedback?.rateComment}</div>
    </div>
  );
};

export default FeedbackListItem;
