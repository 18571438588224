import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { AppState } from 'types';
import { ReservationState } from 'types/reservation';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { useTranslation } from 'react-i18next';
import './BigSpenderSwitch.scss';

interface DispatchProps {
  setBigSpender: (isBigSpender: boolean) => void;
  sendBigSpender: (isBigSpender: boolean, clientId: number) => any;
}

interface StateProps {
  reservation: ReservationState;
  showBigSpenderTag: boolean;
}

type Props = DispatchProps & StateProps;

const BigSpenderSwitch: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const mode = useSelector((state: AppState) => state.theme.mode);

  const handleButtonClick = () => {
    const isBigSpender = !props.reservation.clientIsBigSpender;
    props.setBigSpender(isBigSpender);

    if (props.reservation.id !== undefined && props.reservation.clientId !== undefined) {
      props.sendBigSpender(isBigSpender, props.reservation.clientId).catch(() => {
        props.setBigSpender(!isBigSpender);
      });
    }
  };

  if (!props.showBigSpenderTag) {
    return null;
  }

  return (
    <div className="modal-switch">
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={[
          'btn-switch',
          mode === 'dark' ? 'btn-switch--dark' : '',
          props.reservation.clientIsBigSpender ? 'btn-switch--is-big-spender' : '',
        ].join(' ')}
        onClick={handleButtonClick}
      >
        {t('Big Spender')}
      </Button>
    </div>
  );
};

function mapStateToProps(state: AppState) {
  return {
    reservation: state.reservation,
    showBigSpenderTag: state.calendar.restaurant.settings.show_big_spender_tag,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    setBigSpender: bindActionCreators(reservationActions.setBigSpender, dispatch),
    sendBigSpender: bindActionCreators(reservationActions.sendBigSpender, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BigSpenderSwitch);
