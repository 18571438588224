import React, { ChangeEvent } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { Room } from 'types/calendar';

interface Props {
  rooms: Room[];
  selectedRooms: number[];
  updateSelectedRooms: (selectedRooms: number[]) => void;
}

const FilterDiningRoom: React.FC<Props> = (props) => {
  const updateFilter = (event: ChangeEvent<HTMLInputElement>) => {
    let newSelectedRooms: number[] = [...props.selectedRooms];
    if (event.target.checked) {
      newSelectedRooms.push(+event.target.name);
    } else {
      newSelectedRooms = newSelectedRooms.filter((nid) => nid !== +event.target.name);
    }
    props.updateSelectedRooms(newSelectedRooms);
  };
  return (
    <div className={'sidebar-filters__filter-group'}>
      {props.rooms.map((room, index) => (
        <Box key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.selectedRooms.includes(room.nid)}
                onChange={updateFilter}
                name={room.nid.toString()}
                color="primary"
                className="color-blue-dark-2"
                inputProps={{
                  'aria-label': 'checkbox',
                }}
              />
            }
            label={room.title}
            className="w-100"
            key={room.nid}
          />
        </Box>
      ))}
    </div>
  );
};

export default FilterDiningRoom;
