import React from 'react'
import { IconButton, IconButtonProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = IconButtonProps

const DrawerCloseButton: React.FC<Props> = (props: Props) => {
  return (
    <IconButton className="drawer__close-button" {...props}>
      <CloseIcon />
    </IconButton>
  )
}

export default DrawerCloseButton
