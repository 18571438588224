import React from 'react';
import { ReactComponent as Logo } from 'images/logo-tablein.svg';
import { connect, useSelector } from 'react-redux';
import { AppState } from 'types';
import MainMenu from './MainMenu';
import FreeTrialInfo from './FreeTrialInfo';
import RestaurantSwitcher from './RestaurantSwitcher';
import ProfileMenu from './ProfileMenu';
import { oldAppUrlGenerate } from 'utils/url';
import MobileMenu from './MobileMenu';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import { Permissions } from 'types/auth';
import { Card, Stack } from '@mui/material';
import { IconButton, Link } from '@mui/material';
import { InfoOutlined, SettingsOutlined, SearchOutlined } from '@mui/icons-material';
import './Header.scss';
import { TableinElementIds } from 'constants/tableinElementIds';
import { useScreenSize } from 'hooks/useScreenSize';

interface StateProps {
  permissions: Permissions;
}

interface DispatchProps {
  toggleSearch: () => void;
}

type Props = StateProps & DispatchProps;

const Header: React.FC<Props> = (props) => {
  const mode = useSelector((state: AppState) => state.theme.mode);
  const { isMobile } = useScreenSize();

  return (
    <Card
      id={TableinElementIds.header}
      variant="elevation"
      elevation={7}
      className={`main-header ${mode === 'light' ? 'main-header--light' : ''} no-print`}
    >
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        sx={{ px: 2, py: 1, height: '100%' }}
      >
        {/* Mobiles */}
        {isMobile && (
          <Stack direction={'row'} spacing={2}>
            <MobileMenu />
            <IconButton
              onClick={props.toggleSearch}
              edge="end"
              sx={(theme) => ({
                color: theme.palette.white,
              })}
            >
              <SearchOutlined />
            </IconButton>
          </Stack>
        )}

        {/* Desktops */}
        {!isMobile && (
          <>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Logo style={{ height: '20px', width: '83px', verticalAlign: 'middle' }} />
              <MainMenu />
              <FreeTrialInfo />
            </Stack>
          </>
        )}

        <Stack spacing={2} direction="row" alignItems="center">
          <RestaurantSwitcher />

          {!isMobile && (
            <>
              <Link
                sx={(theme) => ({
                  color: theme.palette.brandWhite,
                  '&:hover': { color: theme.palette.brandWhite },
                })}
                display="flex"
                alignItems="center"
                href="https://help.tablein.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InfoOutlined style={{ color: '#fff' }} />
              </Link>
              <Link
                sx={(theme) => ({
                  color: theme.palette.brandWhite,
                  '&:hover': { color: theme.palette.brandWhite },
                })}
                display="flex"
                alignItems="center"
                href={oldAppUrlGenerate('/settings', false)}
              >
                <SettingsOutlined style={{ color: '#fff' }} />
              </Link>
              <ProfileMenu />
            </>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    permissions: state.auth.userSettings.permissions,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    toggleSearch: bindActionCreators(calendarActions.toggleSearch, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
