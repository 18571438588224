import React from 'react';
import { Box, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { PATH } from 'constants/paths';
import { ReactComponent as CalendarIcon } from 'images/icons/ic-calendar-v2.svg';
import { ReactComponent as SearchIcon } from 'images/icons/ic-search-v2.svg';
import { ReactComponent as ListIcon } from 'images/icons/ic-list-v2.svg';
import { ReactComponent as AddIcon } from 'images/icons/ic-plus-circle-v2.svg';
import { ReactComponent as QuickIcon } from 'images/icons/ic-grid-v2.svg';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const tabMenuControls = (t: TFunction) => [
  {
    to: PATH.LIST,
    icon: <ListIcon />,
    name: t('tabMenuList'),
  },
  {
    to: PATH.CALENDAR,
    icon: <CalendarIcon />,
    name: t('tabMenuCalendar'),
  },
  {
    to: '/',
    icon: <AddIcon />,
    name: t('tabMenuAdd'),
    showAddButton: true,
  },
  {
    to: '/',
    icon: <SearchIcon />,
    name: t('tabMenuSearch'),
  },
  {
    to: PATH.QUICK_ACTIONS,
    icon: <QuickIcon />,
    name: t('tabMenuQuick'),
  },
];

export const TabMenu = () => {
  const { t } = useTranslation();

  const tabMenuControlsToRender = tabMenuControls(t).map(({ to, icon, name, showAddButton }) => {
    return (
      <NavLink to={to} key={name} style={{ textDecoration: 'none' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '4px',
            width: '66px',
            position: 'relative',
            '&:hover': {
              '& svg path': {
                stroke: 'darkBlue',
              },
              '.tab-menu-link': {
                color: 'darkBlue',
              },
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: '-2px',
                left: 0,
                width: '100%',
                height: '2px',
                backgroundColor: 'darkBlue',
              },
            },
            ...(showAddButton && {
              backgroundColor: 'darkBlue',
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              margin: '0 16px',
              '&:hover': {
                '& svg path': {
                  stroke: 'brandWhite',
                },
                '.tab-menu-link': {
                  color: 'brandWhite',
                },
              },
            }),
          }}
        >
          {icon}
          <Typography
            className="tab-menu-link"
            sx={{ color: showAddButton ? 'brandWhite' : 'comet', fontSize: 10, fontWeight: 700 }}
          >
            {name}
          </Typography>
        </Box>
      </NavLink>
    );
  });

  return (
    <Box
      sx={{
        backgroundColor: 'brandWhite',
        minHeight: '64px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        borderRadius: '24px 24px 0px 0px',
        boxShadow: '0px -4px 23px -6px rgba(0, 10, 102, 0.08)',
        display: { xs: 'flex', md: 'none' },
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {tabMenuControlsToRender}
    </Box>
  );
};
