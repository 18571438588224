import React, { ReactNode } from 'react'
import './Badge.scss'

interface OwnProps {
  children: ReactNode
  color: 'primary'|'error'
  size: 'normal'|'small'
  onClick?: () => void
}

const Badge: React.FC<OwnProps> = ({ children, color, size, onClick }) => {
  return (
    <span
      className={`b-mark b-mark--${color} ${size === 'small' && 'b-mark--small'}`}
      onClick={onClick}
    >{children}</span>
  )
}

export default Badge