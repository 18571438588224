import React from 'react';
import { AppState } from 'types';
import { connect } from 'react-redux';
import moment from 'moment';
import { DateTimeFormat } from 'utils/dates';
import { ReactComponent as UsdCircleIcon } from 'images/icons/usd-circle-light.svg';
import { useTranslation } from 'react-i18next';
import { ReservationState, Statuses } from 'types/reservation';
import { Restaurant } from 'types/calendar';
import OriginIcon from 'app/components/OriginIcon';
import Grid3x3OutlinedIcon from '@mui/icons-material/Grid3x3Outlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import './ReservationHistory.scss';
import ReservationHistoryListItem from './ReservationHistoryListItem';
import { Box, Typography } from '@mui/material';

interface StateProps {
  reservation: ReservationState;
  restaurant: Restaurant;
}

type Props = StateProps;

const ReservationHistory: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const dateTimeFormat = DateTimeFormat(props.restaurant.settings.date_format_key);

  let payments;
  let lcfInfo;
  const lcfData = props.reservation.lateCancellationNoShow;

  if (props.reservation.payments.payments.length === 1) {
    const payment = props.reservation.payments.payments[0];

    payments = (
      <ReservationHistoryListItem
        attribute={
          <>
            <UsdCircleIcon className="reservation-timeline-icon" style={{ height: '17px' }} />
            {t('bookingModalPayment')}:
          </>
        }
        value={
          <>
            <Typography component="span" sx={{ fontWeight: 600, color: 'brandRed' }}>
              {payment.totalAmount / 100} {payment.currencyCode}
            </Typography>{' '}
            {moment(payment.receivedDate).format(dateTimeFormat)}
          </>
        }
      />
    );
  } else if (props.reservation.payments.payments.length > 1) {
    payments = props.reservation.payments.payments.map((payment, index) => {
      return (
        <ReservationHistoryListItem
          attribute={
            <>
              <UsdCircleIcon className="reservation-timeline-icon" style={{ height: '17px' }} />
              {t('bookingModalPayment')}:
            </>
          }
          value={
            <>
              <Typography component="span" sx={{ fontWeight: 600, color: 'brandRed' }}>
                {payment.totalAmount / 100} {payment.currencyCode}
              </Typography>{' '}
              {moment(payment.receivedDate).format(dateTimeFormat)}
            </>
          }
        />
      );
    });
  }

  if (lcfData.paymentData && lcfData.paymentData.id !== '') {
    if (lcfData.paymentData.paymentSuccess) {
      lcfInfo = (
        <ReservationHistoryListItem
          attribute={
            <>
              <UsdCircleIcon className="reservation-timeline-icon" style={{ height: '17px' }} />
              {props.reservation.status === Statuses.Cancelled
                ? t('bookingModalCancellationFee')
                : t('bookingModalNoShowFee')}
              :
            </>
          }
          value={`${lcfData.paymentData.paidAmount / 100} ${lcfData.currency} ${moment(
            lcfData.paymentData.paidAt,
          ).format(dateTimeFormat)}`}
        />
      );
    } else {
      lcfInfo = (
        <ReservationHistoryListItem
          attribute={
            <>
              <UsdCircleIcon className="reservation-timeline-icon" style={{ height: '17px' }} />
              {t('bookingModalNoShowFee')}:
            </>
          }
          value={t('bookingModalCardSaved')}
        />
      );
    }
  }

  return (
    <Box
      sx={{ padding: '5px 20px', borderRadius: '4px', backgroundColor: 'sand' }}
      className="modal-reservation-timeline"
    >
      <ReservationHistoryListItem
        attribute={
          <>
            <Grid3x3OutlinedIcon className={'reservation-timeline-icon'} />
            {t('bookingModalReservationNumber')}:
          </>
        }
        value={
          <>
            <OriginIcon
              origin={props.reservation.origin}
              className="reservation-timeline-icon-inline"
            />
            {props.reservation.id}
          </>
        }
      />

      <ReservationHistoryListItem
        attribute={
          <>
            <CalendarMonthOutlinedIcon className={'reservation-timeline-icon'} />
            {t('bookingModalReserved')}:
          </>
        }
        value={
          props.reservation.created && moment.unix(props.reservation.created).format(dateTimeFormat)
        }
      />
      {props.reservation.created !== props.reservation.changed && (
        <>
          {props.reservation.initialTime && (
            <ReservationHistoryListItem
              attribute={
                <>
                  <AccessTimeOutlinedIcon className={'reservation-timeline-icon'} />
                  {t('bookingModalVisitTime')}:
                </>
              }
              value={moment(props.reservation.initialTime).format(dateTimeFormat)}
            />
          )}
          <ReservationHistoryListItem
            attribute={
              <>
                <AccessTimeOutlinedIcon className={'reservation-timeline-icon'} />
                {t('bookingModalReservationLastUpdate')}:
              </>
            }
            value={
              props.reservation.changed &&
              moment.unix(props.reservation.changed).format(dateTimeFormat)
            }
          />
        </>
      )}
      {payments}
      {lcfInfo}
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    reservation: state.reservation,
    restaurant: state.calendar.restaurant,
  };
}

export default connect(mapStateToProps)(ReservationHistory);
