import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface OwnProps {
  attribute: ReactNode
  value: ReactNode
}

const ReservationHistoryListItem: React.FC<OwnProps> = ({ attribute, value }) => {
  return (
    <Box display={'flex'} justifyContent={'space-between'} sx={{ color: 'navyBlue' }}>
      <Box display={'flex'}>
        {attribute}
      </Box>

      <Box style={{ fontWeight: 600 }}>
        {value}
      </Box>
    </Box>
  )
}

export default ReservationHistoryListItem