import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  covers?: { min: number; max: number };
}

const FloorPlanInfo: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="floor-plan__info-box">
      <h3>{t("Guests")}:</h3>
      <ul>
        <li>{`min: ${props.covers?.min || 0}`}</li>
        <li>{`max: ${props.covers?.max || 0}`}</li>
      </ul>
    </div>
  );
};

export default FloorPlanInfo;
