import React, { useEffect, useRef, useState, SyntheticEvent } from 'react';
import BookingStatuses from './BookingStatuses';
import { connect } from 'react-redux';
import calendarActions from 'redux/actions/calendar';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import NumberOfGuests from './NumberOfGuests';
import Tables from './Tables';
import ReservationTime from './ReservationTime';
import ContactDetails from './ClientDetails';
import reservationActions from 'redux/actions/reservation';
import NotificationsSettings from './NotificationsSettings';
import WaiterSelect from './WaiterSelect';
import OfferSelect from './OfferSelect';
import ReservationTags from './ReservationTags';
import ReservationHistory from './ReservationHistory';
import DeleteReservation from './DeleteReservation';
import ReservationComments from './ReservationComments';
import GuestDetails from './GuestDetails';
import CancelReservation from './CancelReservation';
import moment, { Moment } from 'moment';
import { SaveButton } from './SaveButton';
import { Alert, Box, Button, Dialog, Divider, FormGroup, Grid, Tooltip } from '@mui/material';
import * as yup from 'yup'; // TODO: maybe not everything??
import { ValidationError as YupValidationError } from 'yup';
import { CloseButton } from '../CloseButton';
import AverageRatingComponent from './AverageRatingComponent';
import { useTranslation } from 'react-i18next';
import {
  getVisibleReservations,
  prepareReservationsFromApi,
  shouldPayForLateCancellationNoShow,
} from 'utils/reservations';
import { ReservationState, SpecialOffer, SpecialOfferData, Statuses } from 'types/reservation';
import {
  CalendarSettings,
  ReservationsSettings,
  Restaurant,
  Room,
  RoomOrTable,
  Table,
  TimelineReservation,
  ValidationError,
} from 'types/calendar';
import { getReservationsCall } from 'api/calendar';
import { usePrevious } from 'utils/hooks';
import VipSwitch from './VipSwitch';
import BigSpenderSwitch from './BigSpenderSwitch';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AppConnectionStatusWrapper from '../AppConnectionStatusWrapper/AppConnectionStatusWrapper';
import { useStatus } from 'hooks/useStatus';
import './BookingModal.scss';
import HeadingPrimary from '../common/HeadingPrimary/HeadingPrimary';
import { addTheBookingLengthToTheTimeAndSubtractOneSecond } from 'utils/addTheBookingLengthToTheTimeAndSubtractOneSecond';
import { useScreenSize } from 'hooks/useScreenSize';

const statuses = [
  Statuses.Confirmed,
  Statuses.Arrived,
  Statuses.Pending,
  Statuses.WalkIn,
  Statuses.NoShow,
  Statuses.Blocked,
  Statuses.WaitingList,
  // Statuses.Cancelled,
  // Uncomment after client approval.
  // Statuses.Template,
];

interface StateProps {
  fullBookingModalOpen: boolean;
  fullBookingModalIsLoading: boolean;
  reservation: ReservationState;
  permissionToDelete: boolean;
  restaurant: Restaurant;
  rooms: Room[];
  calendarSettings: CalendarSettings;
  reservationsSettings: ReservationsSettings;
  reservations: TimelineReservation[];
  allTables: RoomOrTable[];
  defaultBookingLength: number;
}

interface DispatchProps {
  openFullBookingModal: () => void;
  closeFullBookingModal: () => void;
  saveReservation: () => void;
  loadPayments: (reservationId: number) => void;
  updateReservation: (reservation: ReservationState) => void;
  updateReservationToNewDefaults: (reservation: Partial<ReservationState>) => void;
  loadSpecialOffers: (startTime: moment.Moment) => void;
  addReservationValidationError: (errors: ValidationError[]) => void;
  removeReservationValidationError: () => void;
  openLateCancellationNoShowPaymentConfirmationModal: () => void;
}

type Props = StateProps & DispatchProps;

const footerStyles = {
  display: 'flex',
  padding: '10px 20px',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: '1px solid',
  borderTopColor: 'linkWater',
};

const modalBodyRowStyles = {
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  rowGap: { xs: '1rem', md: 0 },
};

const firstColumnStyles = {
  flex: { xs: 0, md: '0 0 390px' },
  width: { xs: '100%', md: '390px' },
  paddingRight: { xs: 0, md: '20px' },
  marginRight: { xs: 0, md: '20px' },
  borderRight: { xs: 0, md: '1px solid' },
  borderRightColor: { xs: 'none', md: 'linkWater' },
};

const tablesContentStyles = {
  flex: '1 1',
  paddingRight: { xs: 0, md: '20px' },
};

const centerColumnStyles = {
  flex: 1,
  paddingRight: { xs: 0, md: '20px' },
};

const rightColumnStyles = {
  backgroundColor: 'blueGray',
  flex: { xs: 0, md: '0 0 295px' },
  width: { xs: '100%', md: '295px' },
};

const cardStyles = {
  padding: '10px 20px',
};

const modalBodyStyles = {
  padding: '20px',
};

const FullBookingModal: React.FC<Props> = (props) => {
  const { isReadOnly } = useStatus();
  const { t } = useTranslation();
  const modalRef = useRef<HTMLInputElement>(null);
  const [hideContentForTables, setHideContentForTables] = useState(false);
  const [reservations, setReservations] = useState<TimelineReservation[]>([]);
  const prevSelectedDay = usePrevious(props.reservation.startTime.format('YYYY-MM-DD'));
  const prevStoreReservations = usePrevious(props.reservations);

  const { isMobile } = useScreenSize();

  useEffect(() => {
    if (isMobile && modalRef?.current?.offsetTop) {
      modalRef.current.scrollTop = 0;
    }
    // eslint-disable-next-line
  }, [props.reservation.reservationSavingErrors]);

  useEffect(() => {
    if (props.fullBookingModalOpen) {
      props.loadSpecialOffers(props.reservation.startTime);
    }
    // eslint-disable-next-line
  }, [props.reservation.startTime, props.fullBookingModalOpen]);

  useEffect(() => {
    setHideContentForTables(false);
  }, [props.fullBookingModalOpen]);

  const showContentByStatus = ![Statuses.Blocked, Statuses.WalkIn, Statuses.Template].includes(
    props.reservation.status,
  );

  const showNumberOfGuests = ![Statuses.Blocked].includes(props.reservation.status);
  const showReservationTags = ![Statuses.Blocked, Statuses.WalkIn].includes(
    props.reservation.status,
  );
  const showComments = ![Statuses.WalkIn].includes(props.reservation.status);

  const setDefaultValuesAndOpenModal = () => {
    const currentTime = moment(props.calendarSettings.date).second(0).millisecond(0);
    const minutesToAdd =
      Math.ceil(currentTime.minute() / props.restaurant.settings.increment) *
        props.restaurant.settings.increment -
      currentTime.minute();
    const closestTimeSpan = currentTime.add(minutesToAdd, 'minutes');
    const endTime = addTheBookingLengthToTheTimeAndSubtractOneSecond(
      closestTimeSpan,
      props.defaultBookingLength,
    );

    props.updateReservationToNewDefaults({ startTime: closestTimeSpan, endTime });
    props.openFullBookingModal();
  };

  useEffect(() => {
    if (
      prevSelectedDay === props.reservation.startTime.format('YYYY-MM-DD') &&
      prevStoreReservations === props.reservations
    )
      return;

    if (
      props.reservation.startTime.isSameOrAfter(props.reservationsSettings.startDate, 'day') &&
      props.reservation.startTime.isSameOrBefore(props.reservationsSettings.endDate, 'day')
    ) {
      setReservations(
        getVisibleReservations(props.reservations, props.restaurant.settings.hide_no_show),
      );
    } else {
      getReservationsCall(
        props.reservation.startTime.format('YYYY-MM-DD'),
        props.reservation.startTime.format('YYYY-MM-DD'),
        null,
      ).then((r) => {
        const data = prepareReservationsFromApi(r.data, props.restaurant.settings, props.allTables);
        setReservations(getVisibleReservations(data, props.restaurant.settings.hide_no_show));
      });
    }
    // eslint-disable-next-line
  }, [
    props.reservation.startTime,
    props.reservations,
    props.restaurant.settings,
    props.reservationsSettings,
  ]);

  const occupiedTables: number[] = props.rooms
    .reduce((acc: Table[], r: Room) => acc.concat(r.tables), [])
    .filter((t: Table) => {
      const { startTime, endTime } = props.reservation;
      return reservations.some(
        (r) =>
          r.group.includes(t.nid.toString()) &&
          (startTime.isSame(r.reservation.startTime) ||
            startTime.isBetween(r.reservation.startTime, r.reservation.endTime) ||
            (endTime
              ? endTime.isSame(r.reservation.startTime) ||
                endTime.isBetween(r.reservation.startTime, r.reservation.endTime) ||
                r.reservation.startTime.isBetween(startTime, endTime)
              : r.reservation.startTime.isAfter(startTime))) &&
          r.reservation.id !== props.reservation.id,
      );
    })
    .map((t: Table) => t.nid);

  // TODO: 'Restaurant is not open at specified time. Please select another time.'
  const schema = yup.object().shape({
    status: yup.string(),
    name: yup.string().when('status', {
      is: (val: Statuses) => ![Statuses.WalkIn, Statuses.Blocked, Statuses.Template].includes(val),
      then: yup.string().required(t('Name field is required')),
    }),
    numberOfGuests: yup.number().when('status', {
      is: (val: Statuses) => ![Statuses.Blocked].includes(val),
      then: yup
        .number()
        .moreThan(0, t('Guests field is required'))
        .max(999, t('Guests field cannot exceed 999')),
    }),
    specialOffer: yup
      .mixed()
      .test(
        'isOneOfSpecialOffers',
        'Special Offer not valid!',
        function (specialOffer: SpecialOfferData) {
          // Don't use arrow functions
          const offers: SpecialOffer[] = this.resolve(yup.ref('specialOffers'));
          const status: Statuses = this.resolve(yup.ref('status'));

          // Do not validate if there is no offers.
          if (offers.length < 1) {
            return true;
          }

          return (
            [Statuses.Blocked, Statuses.WalkIn].includes(status) ||
            Object.keys(offers).includes(specialOffer.nid.toString())
          );
        },
      ),
    email: yup.string().email(),
    endTime: yup.mixed().required(t('bookingModalEndTimeError')),
    startTime: yup
      .mixed()
      .required(t('bookingModalStartTimeError'))
      .when('endTime', (endTime: moment.Moment, schema) => {
        return schema.test({
          test: (startTime: Moment) => endTime?.isAfter(startTime),
          message: t('bookingModalSelectAnotherTimeError'),
        });
      }),
    tables: yup
      .array()
      .required(t('bookingModalTableRequiredError'))
      .test(
        'is-occupied',
        t('bookingModalTablesAreOccupiedError'),
        (tables: string[]) => !tables.some((t) => occupiedTables.includes(parseInt(t))),
      ),
    phoneNumber: yup
      .string()
      .max(15, t('bookingModalPhoneLengthError'))
      .test(
        'phoneNumberMin',
        t('bookingModalPhoneNumberIsTooShortError'),
        (val) => !val.length || val.length >= 8,
      )
      .when(['$phone_required', 'status'], (phone_required, status, schema) =>
        ![Statuses.Blocked, Statuses.WalkIn].includes(status) && phone_required
          ? schema.required(t('bookingModalPhoneRequiredError'))
          : schema,
      ),
  });

  const validateForm = () => {
    props.removeReservationValidationError();
    schema
      .validate(props.reservation, {
        abortEarly: false,
        context: {
          phone_required: props.restaurant.settings.phone_required,
          occupiedTables,
        },
      })
      .then(() => {
        props.closeFullBookingModal();

        if (shouldPayForLateCancellationNoShow(props.reservation)) {
          props.openLateCancellationNoShowPaymentConfirmationModal();
        } else {
          props.saveReservation();
        }
      })
      .catch((err: YupValidationError) => {
        const errors: ValidationError[] = err.inner?.map((e) => {
          return { field: e.path + '_' + e.type, error: e.message };
        });
        props.addReservationValidationError(errors);
      });
  };

  const getStatuses = () => {
    const result = props.reservation.id
      ? statuses
      : statuses.filter((status) => status !== Statuses.NoShow);
    return props.reservation.status === Statuses.Cancelled
      ? [...result, Statuses.Cancelled]
      : result;
  };

  const mobileReservationButton = isMobile
    ? {
        width: 40,
        height: 40,
        minWidth: 40,
      }
    : {};

  const isBlocked = props.reservation.status === Statuses.Blocked;
  let bookingStatuses = getStatuses();

  if (!!props.reservation.id) {
    bookingStatuses = bookingStatuses.filter((status) => status !== Statuses.Blocked);
  }

  const showDeleteButton = !!props.reservation.id && (isBlocked ? true : props.permissionToDelete);

  const validationErrorMessages = props.reservation.reservationSavingErrors?.length > 0 && (
    <Alert severity="error">
      {props.reservation.reservationSavingErrors.map((error, index) => (
        <Box key={index}>{error.error}</Box>
      ))}
    </Alert>
  );

  const handleCloseModal = (
    event: SyntheticEvent<{}>,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick') {
      return;
    }
    props.closeFullBookingModal();
  };

  return (
    <>
      <AppConnectionStatusWrapper>
        <div className={'calendar-controls__item'}>
          <Button
            onClick={setDefaultValuesAndOpenModal}
            disabled={props.calendarSettings.closed}
            variant={'contained'}
            disableElevation
            sx={(theme) => ({
              background: theme.palette.brandYellow,
              padding: '10px 20px',
              '&:hover': {
                background: theme.palette.gamboge,
              },
              ...mobileReservationButton,
            })}
          >
            {isMobile ? '+' : t('bookingModalAddReservation')}
          </Button>
        </div>
      </AppConnectionStatusWrapper>
      <Dialog
        open={props.fullBookingModalOpen}
        className="full-booking-modal"
        onClose={handleCloseModal}
        maxWidth={false}
      >
        <div className="modal-content">
          <Grid
            container
            display={'flex'}
            justifyContent={'space-between'}
            sx={{ px: 2.5, py: 1.25 }}
          >
            <BookingStatuses statuses={bookingStatuses} />
            <CloseButton onClick={props.closeFullBookingModal} />
          </Grid>

          <Divider />

          <Box ref={modalRef} sx={modalBodyStyles}>
            <div>
              {props.reservation.reservationSavingErrors?.length > 0 && (
                <Alert severity="error" sx={{ mb: 1 }}>
                  {props.reservation.reservationSavingErrors.map((error, index) => (
                    <Box key={index}>{error.error}</Box>
                  ))}
                </Alert>
              )}

              {!isMobile && <Box sx={{ mb: 1 }}>{validationErrorMessages}</Box>}

              <Box sx={modalBodyRowStyles}>
                <Box sx={hideContentForTables ? tablesContentStyles : firstColumnStyles}>
                  {!hideContentForTables && (
                    <>
                      {showNumberOfGuests && <NumberOfGuests isReadOnly={isReadOnly} />}
                      <ReservationTime isReadOnly={isReadOnly} />
                    </>
                  )}
                  <Box mb={2}>
                    <HeadingPrimary>{t('bookingModalTables')}</HeadingPrimary>
                    <Tables
                      setHideContent={setHideContentForTables}
                      occupiedTables={occupiedTables}
                      isReadOnly={isReadOnly}
                    />
                  </Box>
                  {!hideContentForTables && showContentByStatus && (
                    <>
                      <Box mb={2}>
                        <FormGroup>
                          <HeadingPrimary>{t('bookingModalPromotionsAndPayments')}</HeadingPrimary>
                          <OfferSelect isReadOnly={isReadOnly} />
                        </FormGroup>
                      </Box>
                      {!!props.reservation.id && (
                        <FormGroup>
                          <HeadingPrimary>{t('bookingModalReservationTimeline')}</HeadingPrimary>
                          <ReservationHistory />
                        </FormGroup>
                      )}
                    </>
                  )}
                </Box>
                {!hideContentForTables && (showReservationTags || showComments) && (
                  <>
                    <Box sx={centerColumnStyles}>
                      {showReservationTags && (
                        <FormGroup>
                          <HeadingPrimary>
                            {t('bookingModalReservationTags')}
                            <Tooltip
                              title={t('bookingModalReservationTagsTooltip') || ''}
                              aria-label="add reservation tag"
                            >
                              <InfoOutlinedIcon sx={{ ml: 0.5 }} />
                            </Tooltip>
                          </HeadingPrimary>
                          <ReservationTags isReadOnly={isReadOnly} />
                        </FormGroup>
                      )}
                      {showComments && (
                        <FormGroup>
                          <HeadingPrimary>{t('bookingModalComments')}</HeadingPrimary>
                          <ReservationComments reservationId={props.reservation.id} />
                        </FormGroup>
                      )}
                    </Box>
                  </>
                )}
                {showContentByStatus && (
                  <>
                    <Box sx={rightColumnStyles}>
                      <Box sx={cardStyles}>
                        <FormGroup>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexWrap: 'wrap',
                              columnGap: '5px',
                            }}
                          >
                            <HeadingPrimary>{t('bookingModalClientDetails')}</HeadingPrimary>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                              <BigSpenderSwitch />
                              <VipSwitch isReadOnly={isReadOnly} />
                            </Box>
                          </Box>

                          <ContactDetails showAll={true} isReadOnly={isReadOnly} />
                          {!!props.reservation.guestDetails.arrivedCount && <GuestDetails />}
                          {!!props.reservation.averageRating?.feedbackCount &&
                            props.reservation.averageRating?.feedbackCount > 0 && (
                              <AverageRatingComponent
                                averageRating={props.reservation.averageRating}
                              />
                            )}
                        </FormGroup>
                      </Box>
                    </Box>
                    {isMobile && showContentByStatus && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <NotificationsSettings
                          isEdit={!!props.reservation.id}
                          isReadOnly={isReadOnly}
                        />
                        <WaiterSelect isReadOnly={isReadOnly} />
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </div>
            {isMobile && <Box sx={{ marginTop: '20px' }}>{validationErrorMessages}</Box>}
          </Box>
          <Box sx={footerStyles}>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              {!!props.reservation.id && <CancelReservation reservationId={props.reservation.id} />}
              {showDeleteButton && <DeleteReservation reservation={props.reservation} />}
            </Box>
            <Box display="flex" sx={{ alignItems: 'center' }}>
              {!isMobile && showContentByStatus && (
                <>
                  <NotificationsSettings isEdit={!!props.reservation.id} isReadOnly={isReadOnly} />
                  <WaiterSelect isReadOnly={isReadOnly} />
                </>
              )}
              <SaveButton onClick={validateForm} isLoading={props.fullBookingModalIsLoading} />
            </Box>
          </Box>
        </div>
      </Dialog>
    </>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    fullBookingModalOpen: state.calendar.fullBookingModalOpen,
    fullBookingModalIsLoading: state.calendar.fullBookingModalIsLoading,
    reservation: state.reservation,
    permissionToDelete: state.auth.userSettings.permissionToDelete,
    restaurant: state.calendar.restaurant,
    rooms: state.calendar.rooms,
    calendarSettings: state.calendar.calendarSettings,
    reservationsSettings: state.calendar.reservationsSettings,
    reservations: state.calendar.reservations,
    allTables: state.calendar.roomsAndTables.filter(
      (roomOrTable) => roomOrTable.tableId !== undefined,
    ),
    defaultBookingLength: state.calendar.restaurant.settings.default_booking_length,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    openFullBookingModal: bindActionCreators(calendarActions.openFullBookingModal, dispatch),
    closeFullBookingModal: bindActionCreators(calendarActions.closeFullBookingModal, dispatch),
    saveReservation: bindActionCreators(calendarActions.saveReservation, dispatch),
    updateReservation: bindActionCreators(reservationActions.updateReservation, dispatch),
    updateReservationToNewDefaults: bindActionCreators(
      reservationActions.updateReservationToNewDefaults,
      dispatch,
    ),
    loadPayments: bindActionCreators(reservationActions.loadPayments, dispatch),
    loadSpecialOffers: bindActionCreators(reservationActions.loadSpecialOffers, dispatch),
    addReservationValidationError: bindActionCreators(
      reservationActions.addReservationValidationError,
      dispatch,
    ),
    removeReservationValidationError: bindActionCreators(
      reservationActions.removeReservationValidationError,
      dispatch,
    ),
    openLateCancellationNoShowPaymentConfirmationModal: bindActionCreators(
      calendarActions.openLateCancellationNoShowPaymentConfirmationModal,
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FullBookingModal);
