import React from 'react';
import SidebarStatisticsRow, { StatisticsType } from '../SidebarStatisticsRow';
import { useTranslation } from 'react-i18next';
import { Statistics, TimelineReservation } from 'types/calendar';
import { CircularProgress } from '@mui/material';
import { Statuses } from 'types/reservation';
import { Box } from '@mui/material';

const RESERVATIONS_STATUSES = [Statuses.Arrived, Statuses.Confirmed, Statuses.Pending];
const WALKIN_STATUSES = [Statuses.WalkIn];
const GUESTS_TODAY_STATUSES = [
  Statuses.Arrived,
  Statuses.Confirmed,
  Statuses.Pending,
  Statuses.WalkIn,
];
interface Props {
  isLoading?: boolean;
  reservations?: TimelineReservation[];
  statistics?: Statistics;
  variant?: 'section' | 'card';
}

const SidebarStatistics: React.FC<Props> = ({
  variant,
  isLoading,
  statistics: _statistics,
  reservations = [],
}) => {
  const { t } = useTranslation();

  const statistics: Statistics =
    _statistics ||
    reservations.reduce(
      (acc: Statistics, r: TimelineReservation) => {
        if (RESERVATIONS_STATUSES.includes(r.reservation.status)) {
          acc.reservations++;
        }
        if (WALKIN_STATUSES.includes(r.reservation.status)) {
          acc.walkIns++;
        }
        if (GUESTS_TODAY_STATUSES.includes(r.reservation.status)) {
          acc.guestsToday += r.reservation.numberOfGuests;
        }
        return acc;
      },
      { reservations: 0, guestsToday: 0, walkIns: 0 },
    );

  return (
    <Box
      sx={{
        backgroundColor: 'brandWhite',
      }}
      className={`sidebar-statistics${variant === 'card' ? ' sidebar-statistics--card' : ''}`}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: '350px',
          gap: '5px',
        }}
      >
        <SidebarStatisticsRow
          type={StatisticsType.Today}
          count={statistics.guestsToday}
          label={t('sidebarTotalGuests')}
        />
        <SidebarStatisticsRow
          type={StatisticsType.WalkIns}
          count={statistics.walkIns}
          label={t('Walk-ins')}
        />
        <SidebarStatisticsRow
          type={StatisticsType.Reservations}
          count={statistics.reservations}
          label={t('sidebarReservations')}
        />
      </Box>
      {isLoading && (
        <div className="sidebar-statistics__loader">
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};

export default SidebarStatistics;
