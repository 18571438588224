export const NOTES_COLOR_OPTIONS = [
  {
    value: "danger",
    name: "Red",
  },
  {
    value: "info",
    name: "Yellow",
  },
  {
    value: "primary",
    name: "Blue",
  },
  {
    value: "success",
    name: "Green",
  },
  {
    value: "secondary",
    name: "Purple",
  },
];
