import React from 'react';
import { Typography } from '@mui/material';
import CardWrapper from 'app/components/common/CardWrapper/CardWrapper';

interface Props {
  title?: string;
  children: React.ReactNode;
}

const SidebarSection = ({ title, children }: Props) => {
  return (
    <CardWrapper className="sidebar__section">
      {title && (
        <Typography sx={{ color: 'navyBlue' }} variant="h3">
          {title}
        </Typography>
      )}
      {children}
    </CardWrapper>
  );
};

export default SidebarSection;
