import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import { Statuses } from 'types/reservation';
import { IStatusesEng, StatusesEng } from 'utils/reservations';
import { CloseButton } from '../CloseButton';

interface StateProps {
  reservationStatus: string;
  reservationTime: number | undefined;
  modalIsOpen: boolean;
}

interface DispatchProps {
  closeModal: () => void;
  openPaymentModal: () => void;
  saveReservation: () => void;
}

type Props = StateProps & DispatchProps;

const PaymentConfirmationModal = (props: Props) => {
  const { t } = useTranslation();

  const startPayment = () => {
    props.closeModal();
    props.openPaymentModal();
  };

  const saveReservationWithoutPayment = () => {
    props.saveReservation();
    props.closeModal();
  };

  return (
    <Dialog
      open={props.modalIsOpen}
      sx={{
        '& .MuiDialog-scrollPaper': {
          alignItems: 'center',
          '& .MuiPaper-root': {
            maxWidth: '500px',
            height: 'initial',
            '.modal-content': {
              minWidth: 'unset',
            },
          },
        },
      }}
    >
      <DialogTitle>
        <Box display={'flex'} justifyContent={'space-between'}>
          {t(StatusesEng[props.reservationStatus as keyof IStatusesEng])}
          <CloseButton onClick={props.closeModal} />
        </Box>
      </DialogTitle>

      <DialogContent>
        {props.reservationStatus === Statuses.NoShow && (
          <Typography variant={'body1'} textAlign={'center'} sx={{ mt: 2 }}>
            {t('paymentModalNoShowFeeReservationStatus')}
          </Typography>
        )}

        {props.reservationStatus === Statuses.Cancelled && (
          <Typography variant={'body1'} textAlign={'center'}>
            {t('paymentModalCancelledReservationStatus', {
              hours: props.reservationTime,
            })}
          </Typography>
        )}

        <Typography variant={'h5'} component={'p'} textAlign={'center'} sx={{ my: 3 }}>
          <strong>{t('paymentModalChargeGuestCard')}</strong>
        </Typography>
      </DialogContent>

      <Box
        sx={{
          padding: '0px 24px 16px 24px',
          display: 'flex',
          gap: '8px',
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Button onClick={saveReservationWithoutPayment}>{t('paymentModalClose')}</Button>
        <Button variant="contained" color="primary" onClick={startPayment}>
          {t('paymentModalSubmit')}
        </Button>
      </Box>
    </Dialog>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    reservationStatus: state.reservation.status,
    reservationTime: state.reservation.lateCancellationNoShow?.time,
    modalIsOpen: state.calendar.lateCancellationNoShowPayment.askForPaymentModalIsOpen,
  };
}

function mapDispatchProps(dispatch: Dispatch): DispatchProps {
  return {
    closeModal: bindActionCreators(
      calendarActions.closeLateCancellationNoShowPaymentConfirmationModal,
      dispatch,
    ),
    openPaymentModal: bindActionCreators(
      calendarActions.openLateCancellationNoShowPaymentModal,
      dispatch,
    ),
    saveReservation: bindActionCreators(calendarActions.saveReservation, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchProps)(PaymentConfirmationModal);
