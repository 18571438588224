import React from 'react';
import { Button, CircularProgress, Box } from '@mui/material';
import { Translation } from 'react-i18next';
import AppConnectionStatusWrapper from 'app/components/AppConnectionStatusWrapper/AppConnectionStatusWrapper';

interface OwnProps {
  onClick: () => void;
  isLoading: boolean;
  isOffline?: boolean;
}

const wrapperModalSave = {
  margin: '0 0 0 20px',
  position: 'relative',
};

export const SaveButton = (props: OwnProps) => {
  return (
    <AppConnectionStatusWrapper>
      <Box sx={wrapperModalSave}>
        <Button
          variant="contained"
          color="primary"
          disabled={props.isLoading}
          onClick={props.onClick}
        >
          <Translation>{(t) => <>{t('Save')}</>}</Translation>
        </Button>
        {props.isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress size={24} />
          </Box>
        )}
      </Box>
    </AppConnectionStatusWrapper>
  );
};
