import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Badge from 'app/components/common/Badge/Badge';

interface Props {
  tags: string[];
  updateTags: (tags: string[]) => void;
  isReadOnly?: boolean;
}

export const Tags: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [tag, setTag] = useState('');

  const onTagChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTag(event.target.value);
  };

  const onTagKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && tag !== '') {
      const tags = [...props.tags, tag];
      props.updateTags(tags);
      setTag('');
    }
  };

  const onTabButtonClick = (tagId: number) => {
    const tags = props.tags.slice(0, tagId).concat(props.tags.slice(tagId + 1, props.tags.length));
    props.updateTags(tags);
  };

  return (
    <>
      <div>
        {props.tags.map((tag, index) => (
          <Badge
            onClick={() => !props.isReadOnly && onTabButtonClick(index)}
            size={'normal'}
            color={'primary'}
            key={index}
          >
            {tag} {!props.isReadOnly && <span className="pl-1">x</span>}
          </Badge>
        ))}
      </div>
      <Box mb={2}>
        <TextField
          disabled={props.isReadOnly}
          label={t('bookingModalTags')}
          variant="outlined"
          size="small"
          fullWidth
          value={tag}
          onChange={onTagChange}
          onKeyUp={onTagKeyUp}
        />
      </Box>
    </>
  );
};
