import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import { connect } from 'react-redux';
import { AppState } from 'types';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { CalendarSettings } from 'types/calendar';
import CalendarControlsButton from '../CalendarControlsButton';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const refreshStyles = {
  color: 'white',
  background: 'none',
  border: '0',
  textDecoration: 'underline',
  cursor: 'pointer',
  padding: '0',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

interface RefreshButtonProps {
  isText?: boolean;
}

interface DispatchProps {
  changeCalendarDate: (val: Date) => void;
}

interface StateProps {
  calendarSettings: CalendarSettings;
  reservationsIsLoading: boolean;
}

type Props = StateProps & DispatchProps & RefreshButtonProps;

const RefreshButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const onRefresh = () =>
    !props.reservationsIsLoading && props.changeCalendarDate(props.calendarSettings.date.toDate());

  return (
    <>
      {props.isText ? (
        <Button sx={refreshStyles} onClick={onRefresh}>
          {t('Refresh')}
        </Button>
      ) : (
        <CalendarControlsButton onClick={onRefresh}>
          <AutorenewOutlinedIcon
            className={`${props.reservationsIsLoading && 'calendar-controls__button--is-spinning'}`}
          />
        </CalendarControlsButton>
      )}
    </>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    calendarSettings: state.calendar.calendarSettings,
    reservationsIsLoading: state.calendar.reservationsIsLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    changeCalendarDate: bindActionCreators(calendarActions.changeCalendarDate, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RefreshButton);
