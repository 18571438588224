import React, { ChangeEvent } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FiltersOptions } from 'types/filters';

interface OwnProps {
  selectedOptions: FiltersOptions[];
  updateSelectedOptions: (options: FiltersOptions[]) => void;
}

const FilterOptions: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const updateFilter = (event: ChangeEvent<HTMLInputElement>) => {
    let newSelectedOptions = [...props.selectedOptions];
    if (event.target.checked) {
      newSelectedOptions.push(event.target.name as FiltersOptions);
    } else {
      newSelectedOptions = newSelectedOptions.filter((option) => option !== event.target.name);
    }
    props.updateSelectedOptions(newSelectedOptions);
  };
  return (
    <div className={'sidebar-filters__filter-group'}>
      {Object.keys(FiltersOptions).map((option, index) => (
        <Box key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.selectedOptions.includes(option as FiltersOptions)}
                onChange={updateFilter}
                name={option}
                className="color-blue-dark-2"
                color="primary"
                inputProps={{
                  'aria-label': 'checkbox',
                }}
              />
            }
            label={t(option)}
            className="w-100"
            key={option}
          />
        </Box>
      ))}
    </div>
  );
};

export default FilterOptions;
