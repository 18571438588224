import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { AppState } from 'types';
import { connect } from 'react-redux';
import HeaderControls from 'app/components/CalendarControls/HeaderControls';
import ReservationsList from 'app/components/ReservationsList';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import SidebarSearch from 'app/components/Sidebar/SidebarSearch';
import Constants from 'utils/Constants';
import moment from 'moment';
import {
  AdvancedSearchParams,
  CalendarSettings,
  ReservationListSettings,
  Restaurant,
  Statistics,
} from 'types/calendar';
import { FiltersState } from 'types/filters';
import Sidebar from 'app/components/Sidebar';
import SidebarStatistics from 'app/components/Sidebar/SidebarStatistics';
import SidebarFilters from 'app/components/Filters';
import { Box } from '@mui/material';
import { TogglePastReservationsButton } from 'app/components/TogglePastReservationsButton';
import { useScreenSize } from 'hooks/useScreenSize';

interface DispatchProps {
  toggleSidebar: () => void;
  searchReservations: (params: AdvancedSearchParams) => void;
  resetSearchReservations: () => void;
  changeReservationListDateRange: (start: moment.Moment | null, end: moment.Moment | null) => void;
}

interface StateProps {
  restaurant: Restaurant;
  reservationListSettings: ReservationListSettings;
  isSidebarOpen: boolean;
  filters: FiltersState;
  isLoading: boolean;
  reservationListStatistics: Statistics;
  calendarSettings: CalendarSettings;
  currentTime: moment.Moment;
}

type Props = DispatchProps & StateProps;

const ReservationListPage: React.FC<Props> = (props) => {
  const [pastReservationsExist, setPastReservationsExist] = useState(false);
  const [searchPhrase, setSearchPhrase] = React.useState('');
  const [sidebarBadgeText, setSidebarBadgeText] = React.useState(0);
  const [showPast, setShowPast] = React.useState(false);

  const currentTimeWithTimezone = moment(props.currentTime).tz(props.restaurant.settings.timezone);
  const isCalendarDateSameAsCurrentTime = props.calendarSettings.date.isSame(
    currentTimeWithTimezone,
    'day',
  );
  const { isMobile } = useScreenSize();
  const showButton =
    isCalendarDateSameAsCurrentTime && !props.calendarSettings.closed && pastReservationsExist;

  const handleAdvancedSearch = (params: AdvancedSearchParams) => {
    props.searchReservations(params);

    //Close filters on mobile devices
    if (isMobile && props.isSidebarOpen) {
      props.toggleSidebar();
    }
  };

  useEffect(() => {
    // Close sidebar on mobile first visit
    if (isMobile && !localStorage.getItem(Constants.VARIABLES.tableinSidebarOpen)) {
      props.toggleSidebar();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let filtersCount = 0;
    filtersCount += props.filters.selectedStatuses.length;
    filtersCount += props.filters.options.length;
    filtersCount += props.filters.dinningRooms.length;
    if (props.filters.selectedHours.includeOngoing) filtersCount++;
    if (!!props.filters.selectedHours.from) filtersCount++;
    if (!!props.filters.selectedHours.to) filtersCount++;
    if (!!props.filters.tag) filtersCount++;
    if (!!props.reservationListSettings.startDate && !!props.reservationListSettings.endDate)
      filtersCount++;
    setSidebarBadgeText(filtersCount);
  }, [props.filters, props.reservationListSettings]);

  useEffect(() => {
    return () => {
      props.resetSearchReservations();
    };
    // eslint-disable-next-line
  }, []);

  return !props.restaurant.nid ? (
    <LinearProgress color="secondary" />
  ) : (
    <Box display="flex" flexDirection="column" flex={1} p={2}>
      <HeaderControls sidebarBadgeText={sidebarBadgeText} enableToggleSwitch />

      {isMobile && (
        <SidebarSearch
          variant="card"
          searchPhrase={searchPhrase}
          onClick={() => setSearchPhrase('')}
          onChange={(e) => setSearchPhrase(e.target.value)}
          advanced={true}
          onAdvancedSubmit={handleAdvancedSearch}
          onAdvancedReset={props.resetSearchReservations}
        />
      )}

      <Box display="flex" flex={1}>
        <ReservationsList
          searchPhrase={searchPhrase}
          showNotes="no-print"
          showPast={showPast}
          currentTimeWithTimezone={currentTimeWithTimezone}
          isCalendarDateSameAsCurrentTime={isCalendarDateSameAsCurrentTime}
          setPastReservationsExist={setPastReservationsExist}
          pastReservationsButton={
            <TogglePastReservationsButton
              sx={{
                marginBottom: '5px',
              }}
              setShowPast={setShowPast}
              showButton={showButton}
              showPast={showPast}
            />
          }
        />
        <Sidebar
          variant="card"
          search="advanced"
          searchPhrase={searchPhrase}
          onSearchChange={(value) => setSearchPhrase(value)}
          className="no-print"
        >
          <SidebarStatistics
            isLoading={props.isLoading}
            statistics={props.reservationListStatistics}
          />
          <SidebarFilters />
        </Sidebar>
      </Box>
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    restaurant: state.calendar.restaurant,
    reservationListSettings: state.calendar.reservationListSettings,
    isSidebarOpen: state.calendar.sidebarOpen,
    filters: state.filters,
    isLoading: state.calendar.reservationsIsLoading,
    reservationListStatistics: state.calendar.reservationListStatistics,
    currentTime: state.application.currentTime,
    calendarSettings: state.calendar.calendarSettings,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    toggleSidebar: bindActionCreators(calendarActions.toggleSidebar, dispatch),
    searchReservations: bindActionCreators(calendarActions.searchReservations, dispatch),
    resetSearchReservations: bindActionCreators(calendarActions.resetSearchReservation, dispatch),
    changeReservationListDateRange: bindActionCreators(
      calendarActions.changeReservationListDateRange,
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationListPage);
