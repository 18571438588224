import React, { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import { connect } from 'react-redux';
import { AppState } from 'types';
import { FormGroup, TextField, Button, Dialog, Stack, Box, Typography } from '@mui/material';
import { request } from 'utils/api';
import { useSnackbar, BaseVariant } from 'notistack';
import moment from 'moment-timezone';
import { ReservationState } from 'types/reservation';
import { CloseButton } from '../CloseButton';

enum ChargeModeTypes {
  PerPerson = 'person',
  PerReservation = 'reservation',
}

interface StateProps {
  reservation: ReservationState;
  modalIsOpen: boolean;
}

interface DispatchProps {
  closeModal: () => void;
  saveReservation: () => void;
}

type Props = StateProps & DispatchProps;

const PaymentModal: React.FC<Props> = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const maxAmount = props.reservation.lateCancellationNoShow.maxAmount / 100;
  const chargeMode = props.reservation.lateCancellationNoShow.chargeMode;
  const maxDescriptionLength = 255;
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    currency: '',
    singleAmount: '',
    totalAmount: 0,
    description: '',
    reservation: props.reservation,
  });
  const [formErrors, setFormErrors] = useState({
    singleAmount: '',
    description: '',
  });

  useEffect(() => {
    setFormData({
      ...formData,
      currency: props.reservation.lateCancellationNoShow.currency,
      reservation: props.reservation,
    });
    // eslint-disable-next-line
  }, [props.reservation]);

  const closeModal = () => {
    resetForm();
    props.closeModal();
  };

  const resetForm = () => {
    setFormData({
      ...formData,
      singleAmount: '',
      totalAmount: 0,
      description: '',
    });

    setFormErrors({
      singleAmount: '',
      description: '',
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let totalAmount = formData.totalAmount;
    // Format price field
    if (event.target.name === 'singleAmount') {
      let total = event.target.value;

      if (isNaN(Number(total))) {
        // Allow only numbers and dot
        // eslint-disable-next-line
        total = total.replace(/[^0-9\.]/g, '');

        // Allow only one dot
        if (total.split('.').length > 2) {
          total = total.replace(/\.+$/, '');
        }
      }

      event.target.value = total;
      totalAmount =
        chargeMode === ChargeModeTypes.PerPerson
          ? parseFloat(total) * props.reservation.numberOfGuests
          : parseFloat(total);
    }

    // Hide errors
    setFormErrors({
      ...formErrors,
      [event.target.name]: null,
    });

    setFormData({
      ...formData,
      totalAmount,
      [event.target.name]: event.target.value,
    });
  };

  const handlePriceBlur = () => {
    setFormData({
      ...formData,
      singleAmount: Number(formData.singleAmount).toFixed(2),
    });
  };

  const validateForm = () => {
    let errors = {
      singleAmount: '',
      description: '',
    };

    if (formData.singleAmount === '') {
      errors.singleAmount = t('This field is required.');
    } else if (parseFloat(formData.singleAmount) > maxAmount) {
      errors.singleAmount = t('Maximum allowed amount is {{maxAmount}} {{ currency }}.', {
        maxAmount,
        currency: formData.currency,
      });
    }

    if (formData.description.length > maxDescriptionLength) {
      errors.description = t('Maximum description length is {{maxDescriptionLength}}.', {
        maxDescriptionLength,
      });
    }

    if (!errors.singleAmount && !errors.description) {
      submitForm();
    } else {
      setFormErrors(errors);
    }
  };

  const submitForm = () => {
    setIsLoading(true);
    let message: string;
    let variant: BaseVariant;

    request({
      method: 'POST',
      url: '/calendar/late-cancellation-no-show/payment',
      data: formData,
    })
      .then(() => {
        resetForm();
        props.closeModal();
        message = t('Fee charged successfully.');
        variant = 'success';
        props.reservation.lateCancellationNoShow.paymentData.paymentSuccess = true;
        props.reservation.lateCancellationNoShow.paymentData.paidAmount =
          formData.totalAmount * 100;
        props.reservation.lateCancellationNoShow.paymentData.paidAt =
          moment().format('YYYY-MM-DD HH:mm');

        props.saveReservation();
      })
      .catch((response) => {
        message =
          response.status !== 500 ? response.data : t('An error occurred. Please try again later.');
        variant = 'error';
      })
      .finally(() => {
        setIsLoading(false);
        enqueueSnackbar(message, { variant });
      });
  };

  return (
    <Dialog open={props.modalIsOpen} maxWidth="sm" fullWidth={true}>
      <Box sx={{ padding: '16px 24px' }}>
        <FormGroup sx={{ gap: '16px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{t('paymentModalTitle')}</Typography>
            <CloseButton onClick={closeModal} />
          </Box>

          <Stack spacing={2}>
            <TextField
              id="payment-total"
              label={`
                  ${t('paymentModalAmountToPay', { currency: formData.currency })} / 
                  ${
                    chargeMode === ChargeModeTypes.PerReservation
                      ? t('paymentModalChargePerReservation')
                      : t('paymentModalChargePerPerson')
                  }
                `}
              variant="outlined"
              value={formData.singleAmount}
              name="singleAmount"
              onChange={handleInputChange}
              onBlur={handlePriceBlur}
            />
            {formErrors.singleAmount && (
              <span className="text-danger d-block">{formErrors.singleAmount}</span>
            )}

            <TextField
              id="payment-description"
              label={t('paymentModalDescription')}
              variant="outlined"
              value={formData.description}
              name="description"
              onChange={handleInputChange}
            />
            {formErrors.description && (
              <span className="text-danger d-block">{formErrors.description}</span>
            )}

            {formData.singleAmount !== '' && chargeMode === ChargeModeTypes.PerPerson && (
              <>
                {formData.singleAmount} {formData.currency} * {props.reservation.numberOfGuests}{' '}
                person(s) ={' '}
                <strong>
                  {formData.totalAmount} {formData.currency} Total amount
                </strong>
              </>
            )}
          </Stack>

          <Box>
            <Button variant="contained" color="primary" onClick={validateForm} disabled={isLoading}>
              {isLoading ? t('paymentModalLoading') : t('paymentModalConfirmButton')}
            </Button>
          </Box>
        </FormGroup>
      </Box>
    </Dialog>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    reservation: state.reservation,
    modalIsOpen: state.calendar.lateCancellationNoShowPayment.paymentModalIsOpen,
  };
}

function mapDispatchProps(dispatch: Dispatch): DispatchProps {
  return {
    closeModal: bindActionCreators(
      calendarActions.closeLateCancellationNoShowPaymentModal,
      dispatch,
    ),
    saveReservation: bindActionCreators(calendarActions.saveReservation, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchProps)(PaymentModal);
