import { request } from '../utils/api'

export function getLanguagesCall () {
  return request(
    { method: 'get', url: `application/languages` }).then(response => response)
}

export function getRestaurantsListCall () {
  return request(
    { method: 'get', url: `application/restaurants` })
    .then(response => response)
}
