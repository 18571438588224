import React from 'react';
import { oldAppUrlGenerate } from 'utils/url';
import { AppState } from 'types';
import { connect } from 'react-redux';
import { Permissions } from 'types/auth';
import { useAuth } from 'hooks/useAuth';
import {
  AccountCircleOutlined,
  HelpOutline,
  LockOpen,
  LogoutOutlined,
  Payment,
} from '@mui/icons-material';
import {
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Stack,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface StateProps {
  permissions: Permissions;
}

type Props = StateProps;

const menuItemStyles = {
  '&:hover': {
    color: 'navyBlue',
  },
};

const ProfileMenu: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { handleLogout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {props.permissions.accessManagerPages && (
        <>
          <Typography onClick={handleMenu} pt={0.75}>
            <AccountCircleOutlined sx={{ color: '#fff', cursor: 'pointer' }} />
          </Typography>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleClose}
              component={Link}
              href="https://help.tablein.com"
              target="_blank"
              rel="noopener noreferrer"
              sx={menuItemStyles}
            >
              <ListItemIcon>
                <HelpOutline />
              </ListItemIcon>
              <ListItemText>{t('profileMenuHelpResources')}</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={handleClose}
              component={Link}
              href={oldAppUrlGenerate('/settings/subscription', false)}
              sx={menuItemStyles}
            >
              <ListItemIcon>
                <Payment />
              </ListItemIcon>
              <ListItemText>{t('profileMenuSubscriptionAndBilling')}</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={handleClose}
              component={Link}
              href={oldAppUrlGenerate('/settings/account-settings')}
              sx={menuItemStyles}
            >
              <ListItemIcon>
                <LockOpen />
              </ListItemIcon>
              <ListItemText>{t('profileMenuChangePassword')}</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleLogout} component={Link} sx={menuItemStyles}>
              <ListItemIcon>
                <LogoutOutlined />
              </ListItemIcon>
              <ListItemText>{t('profileMenuLogOut')}</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
      {!props.permissions.accessManagerPages && (
        <IconButton onClick={handleLogout} sx={{ padding: 0 }}>
          <LogoutOutlined sx={{ color: '#fff' }} />
        </IconButton>
      )}
    </Stack>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    permissions: state.auth.userSettings.permissions,
  };
}

export default connect(mapStateToProps)(ProfileMenu);
