import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode | ReactNode[];
}

const DrawerContent: React.FC<Props> = props => {
  return (
    <div className="drawer__content">
      {props.children}
    </div>
  )
}

export default DrawerContent
