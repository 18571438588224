import { Action } from ".";
import { Statuses } from "./reservation";

export interface FilterSortState {
  option: string;
  direction: string;
}

export enum FiltersOptions {
  Comments = "Comments",
  Reviews = "Reviews",
  VIP = "VIP",
}

export interface FiltersState {
  selectedStatuses: Statuses[];
  dinningRooms: number[];
  options: FiltersOptions[];
  selectedHours: SelectedHours;
  tag: string | null;
  sort: FilterSortState;
}

export interface SelectedHours {
  from: moment.Moment | null;
  to?: moment.Moment | null;
  includeOngoing: boolean;
}

export enum SortDirections {
  asc = "asc",
  desc = "desc",
}

export type SortOption = {
  title: string;
  value: string;
};

export type SortDirection = {
  title: string;
  value: string;
};

//Actions

export interface SelectedHoursUpdatedAction
  extends Action<{ selectedHours: SelectedHours }> {
  type: "Filters.SelectedHoursUpdated";
}

export interface SelectedStatusesUpdatedAction
  extends Action<{ selectedStatuses: Statuses[] }> {
  type: "Filters.SelectedStatusesUpdated";
}

export interface DiningRoomsFilterUpdatedAction
  extends Action<{ dinningRooms: number[] }> {
  type: "Filters.DiningRoomsFilterUpdated";
}

export interface OptionsFilterUpdatedAction
  extends Action<{ options: FiltersOptions[] }> {
  type: "Filters.OptionsFilterUpdated";
}

export interface TagFilterUpdatedAction extends Action<{ tag: string | null }> {
  type: "Filters.TagFilterUpdated";
}

export interface SortOptionUpdatedAction extends Action<{ value: string }> {
  type: "Filters.SortOptionUpdated";
}

export interface SortDirectionUpdatedAction extends Action<{ value: string }> {
  type: "Filters.SortDirectionUpdated";
}

export interface FiltersResetAction extends Action<{}> {
  type: "Filters.FiltersReset";
}

export type FiltersAction =
  | SelectedStatusesUpdatedAction
  | SelectedHoursUpdatedAction
  | DiningRoomsFilterUpdatedAction
  | FiltersResetAction
  | OptionsFilterUpdatedAction
  | TagFilterUpdatedAction
  | SortOptionUpdatedAction
  | SortDirectionUpdatedAction;
