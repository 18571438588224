import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const SettingsNavLink = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '5px',
  padding: '8px',
  background: 'rgba(255, 255, 255, 0.10)',
  '& .settings-navigation-link': {
    color: theme.palette.brandWhite,
    fontSize: '10px',
    fontWeight: 600,
  },
  '&:hover': {
    backgroundColor: theme.palette.brandWhite,
    '& svg path': {
      fill: theme.palette.darkBlue,
    },
    '& .settings-navigation-link': {
      color: theme.palette.darkBlue,
    },
  },
}));
