import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getRestaurantsListCall } from "../../../api/application";
import { RestaurantListItem } from "../../../types/application";
import { useQuery } from "../../../hooks/useQuery";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver} from "@hookform/resolvers/yup";
import { FormErrorMessage } from "../FormErrorMessage";

const schema = yup.object().shape({
  restaurant: yup.string().required()
});

const defaultValues = {
  restaurant: '',
}
export const MozrestIntegrationForm = () => {
  const { t } = useTranslation();
  const [restaurantList, setRestaurantList] = useState<RestaurantListItem[]>([]);
  const query = useQuery();
  
  const callbackUrl = query.get('mz_callback');
  
  const onSubmit = (data: {restaurant: string}) => {
    window.location.href = `${callbackUrl}?venueId=${data.restaurant}`
  }
  
  useEffect(() => {
    getRestaurantsListCall()
      .then(restaurantsList => {
        setRestaurantList(restaurantsList)
    });
  }, []);
  
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  
  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit((data) => onSubmit(data))}>
      <FormControl fullWidth sx={{ mb: 2}}>
        <InputLabel id="restaurant">Restaurant</InputLabel>
        <Controller
          name="restaurant"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <Select
              labelId="restaurant-id"
              label="Restaurant"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            >
              {restaurantList.map((restaurant: RestaurantListItem) => (
                <MenuItem key={restaurant.nid} value={restaurant.nid}>{restaurant.title}</MenuItem>
              ))}
            </Select>
          )}
        />
        {errors.restaurant && <FormErrorMessage>{errors.restaurant.message}</FormErrorMessage>}
      </FormControl>
      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={{
          mb: 4,
        }}
      >
        {t('connect')}
      </Button>
    </form>
  );
}
