import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { AppState } from 'types';
import { RoomOrTable, TimelineReservation } from 'types/calendar';
import { ReservationState } from 'types/reservation';
import {
  getReservationClassName,
  getReservationSingleTableName,
  getReservationTitle,
} from 'utils/reservations';
import { pad } from 'utils/string';
import { ReactComponent as UserIcon } from 'images/icons/user-light.svg';
import { ReactComponent as MoneyIcon } from 'images/icons/money-bill.svg';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import calendarActions from 'redux/actions/calendar';
import CardWrapper from 'app/components/common/CardWrapper/CardWrapper';
import { ReservationPromotionTypes } from 'app/components/ReservationPromotionTypes';

interface OwnProps {
  variant?: 'normal' | 'card';
  reservation: TimelineReservation;
}

interface StateProps {
  allTables: RoomOrTable[];
}

interface DispatchProps {
  updateReservation: (reservation: ReservationState) => void;
  openFullBookingModal: () => void;
}

type Props = OwnProps & StateProps & DispatchProps;

const SidebarReservation: React.FC<Props> = ({
  variant,
  reservation,
  allTables,
  updateReservation,
  openFullBookingModal,
}) => {
  const { t } = useTranslation();
  const mode = useSelector((state: AppState) => state.theme.mode);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    updateReservation(reservation.reservation);
    openFullBookingModal();
  };

  return (
    <CardWrapper
      className={`sidebar-reservation ${
        mode === 'dark' ? 'sidebar-reservation--dark' : ''
      } sidebar-reservation--${variant} ${getReservationClassName(
        reservation.reservation.status,
        reservation.reservation.startTime,
        reservation.reservation.autoArrivalDisabled,
      )}`}
      onClick={handleClick}
    >
      <div className="sidebar-reservation__header">
        <div className="sidebar-reservation__title-wrapper">
          <h2 className="sidebar-reservation__title">
            {reservation.reservation.clientIsVip && (
              <span className="sidebar-reservation__title-label">{t('VIP')}</span>
            )}
            {reservation.reservation.showBigSpenderTag &&
              reservation.reservation.clientIsBigSpender && (
                <span className="sidebar-reservation__title-icon">
                  <MoneyIcon />
                </span>
              )}
            {getReservationTitle(reservation.reservation.status, reservation.title)}
          </h2>
          <h3 className="sidebar-reservation__subtitle">
            {!!reservation.reservation.guestDetails.arrivedCount && (
              <>
                {' '}
                {reservation.reservation.guestDetails.arrivedCount} {t('visit')}{' '}
              </>
            )}
            {reservation.reservation.company && <> &middot; {reservation.reservation.company}</>}
          </h3>
        </div>
        <div className="sidebar-reservation__badge sidebar-reservation__badge--time">
          {reservation.reservation.startTime.format(reservation.reservation.timeFormat)}
        </div>
      </div>
      <div className="sidebar-reservation__body">
        {reservation.reservation.comments.map((comment) => (
          <div className="sidebar-reservation__body-item" key={comment.id}>
            {comment.commentBody
              .trim()
              .replace('Paid', t('reservationCommentStatus'))
              .replace('Payment date', t('reservationCommentDate'))
              .replace('Method', t('reservationCommentMethod'))}
          </div>
        ))}
      </div>
      <div className="sidebar-reservation__footer">
        <div className="sidebar-reservation__badge sidebar-reservation__badge--table">
          {reservation.reservation.tables[0] &&
            pad(getReservationSingleTableName(reservation.reservation.tables[0], allTables), 12)}
          {reservation.reservation.tables.length > 1 && <>&nbsp;+</>}
        </div>

        <div className="sidebar-reservation__icons sidebar-reservation__icons--guests">
          <UserIcon className="sidebar-reservation__icon" />
          <span className="">{reservation.reservation.numberOfGuests}</span>
        </div>
        <ReservationPromotionTypes reservation={reservation.reservation} />
      </div>
    </CardWrapper>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    allTables: state.calendar.roomsAndTables.filter(
      (roomOrTable) => roomOrTable.tableId !== undefined,
    ),
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateReservation: bindActionCreators(reservationActions.updateReservation, dispatch),
    openFullBookingModal: bindActionCreators(calendarActions.openFullBookingModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarReservation);
