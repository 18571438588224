import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { AppState } from 'types';
import { t } from 'i18next';

interface SelectedTablesGuestsCountProps {
  wrapper?: BoxProps;
  item?: BoxProps;
}

export const SelectedTablesGuestsCount = ({ wrapper, item }: SelectedTablesGuestsCountProps) => {
  const selectedTables = useSelector((state: AppState) => state.reservation.tables);
  const tables = useSelector((state: AppState) => state.calendar.roomsAndTables);

  const selectedCovers = tables.reduce(
    (acc, table) => {
      let min = table.covers_min || 0;
      let max = table.covers_max || 0;

      acc.min += selectedTables.includes(table.id) ? min : 0;
      acc.max += selectedTables.includes(table.id) ? max : 0;
      return acc;
    },
    { min: 0, max: 0 },
  );

  return (
    <Box {...wrapper}>
      <Box fontWeight={'bold'} {...item}>
        {t('bookingModalGuests')}:
      </Box>
      <Box {...item}>min: {selectedCovers.min}</Box>
      <Box {...item}>max: {selectedCovers.max}</Box>
    </Box>
  );
};
