import { SnackbarProvider } from 'notistack';
import React from 'react';
import Notifier from '../Notifier';
import './NotificationsProvider.scss';

interface Props {
  children: React.ReactNode;
}

const NotificationsProvider: React.FC<Props> = ({children}) => {
  return (
    <>
      {/* @ts-ignore */}
      <SnackbarProvider
        autoHideDuration={1000 * 4}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        classes={{
          root: "snackbar-notification",
          /* @ts-ignore */
          variantSuccess: "snackbar-notification__content--success",
        }}
      >
        {children}
        <Notifier/>
      </SnackbarProvider>
    </>
  );
};

export default NotificationsProvider;
