import React from 'react';
import { FloorPlanObject } from 'types/floorPlan';
import { wallSettings } from 'utils/floorPlan';

interface Props {
  wall: FloorPlanObject;
}

const FloorWall = ({ wall }: Props) => {
  if (!wall.position) return null;

  const width = wall.position.width || wallSettings.width;

  return (
    <line
      x1={wall.position.x - width / 2}
      y1={wall.position.y}
      x2={wall.position.x + width / 2}
      y2={wall.position.y}
      stroke="#e6ebf7"
      strokeWidth="10"
      transform={`rotate(${wall.position.angle} ${wall.position.x} ${wall.position.y})`}
    />
  );
};

export default FloorWall;
