import React from 'react';
import { StyledCard } from 'app/components/StyledCard';
import { PATH } from 'constants/paths';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Tooltip } from '@mui/material';
import { TFunction } from 'i18next';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { SubNavLink } from '../SubNavLink';
import { ReactComponent as ChevronRight } from 'images/icons/ic-chevron-right.svg';
import { ReactComponent as HomeIcon } from 'images/icons/ic-home.svg';
import { ReactComponent as ToolIcon } from 'images/icons/ic-tool.svg';
import { ReactComponent as TabletIcon } from 'images/icons/ic-tablet.svg';
import { ReactComponent as HourglassIcon } from 'images/icons/ic-hourglass.svg';
import { ReactComponent as CropIcon } from 'images/icons/ic-crop.svg';
import { ReactComponent as UserIcon } from 'images/icons/ic-user.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/ic-settings-icon.svg';
import { ReactComponent as GlobeIcon } from 'images/icons/ic-globe.svg';
import { ReactComponent as LayoutGridIcon } from 'images/icons/ic-layout-grid.svg';
import { ReactComponent as CodeIcon } from 'images/icons/ic-code-snippet.svg';
import { ReactComponent as AnnouncementIcon } from 'images/icons/ic-announcement.svg';
import { ReactComponent as LayoutLeftIcon } from 'images/icons/ic-layout-left.svg';
import { ReactComponent as MailIcon } from 'images/icons/ic-mail.svg';
import { ReactComponent as EditIcon } from 'images/icons/ic-edit.svg';
import { ReactComponent as AlertIcon } from 'images/icons/ic-alert-circle.svg';
import { ReactComponent as MessageIcon } from 'images/icons/ic-message-smile-circle.svg';
import { ReactComponent as CoinIcon } from 'images/icons/ic-coins-stacked.svg';
import { ReactComponent as SaleIcon } from 'images/icons/ic-sale.svg';
import { ReactComponent as HeartIcon } from 'images/icons/ic-heart.svg';
import { ReactComponent as LifeBouyIcon } from 'images/icons/ic-life-buoy.svg';
import { ReactComponent as GalleryIcon } from 'images/icons/ic-image-plus.svg';
import { ReactComponent as MenuIcon } from 'images/icons/ic-menu-editor.svg';
import { ReactComponent as ListIcon } from 'images/icons/ic-list-icon.svg';
import { ReactComponent as UsersIcon } from 'images/icons/ic-users-icon.svg';
import { ReactComponent as ChatIcon } from 'images/icons/ic-message-chat-square.svg';
import { ReactComponent as UserSecondIcon } from 'images/icons/ic-user-icon.svg';
import { ReactComponent as UsersSecondIcon } from 'images/icons/ic-users-icon.svg';
import { ReactComponent as UsersPlusIcon } from 'images/icons/ic-users-plus.svg';
import { ReactComponent as InboxIcon } from 'images/icons/ic-inbox.svg';
import { useLocation } from 'react-router-dom';
import './SettingsSubNavigation.scss';
import { useScreenSize } from 'hooks/useScreenSize';

const settingsSubNavigationLinks = (t: TFunction) => [
  {
    title: t('settingsRestaurantSetup'),
    tooltip: 'mock tooltip message',
    links: [
      {
        icon: <HomeIcon />,
        to: PATH.BASIC_INFORMATION,
        name: t('settingsBasicInformation'),
      },
      {
        icon: <HomeIcon />,
        to: PATH.RESTAURANT_PROFILE,
        name: t('settingsRestaurantProfile'),
      },
      {
        icon: <TabletIcon />,
        to: PATH.TIME_AND_LANGUAGE,
        name: t('settingsTimeAndLanguage'),
      },
      {
        icon: <ToolIcon />,
        to: PATH.TABLE_MANAGEMENT,
        name: t('settingsTableManagement'),
      },
      {
        icon: <TabletIcon />,
        to: '/',
        name: t('settingsOpeningHours'),
      },
      {
        icon: <HourglassIcon />,
        to: '/',
        name: t('settingsFloorPlanSetup'),
      },
      {
        icon: <CropIcon />,
        to: '/',
        name: t('settingsUserAccounts'),
      },
      {
        icon: <UserIcon />,
        to: '/',
        name: t('settingsReservationManagement'),
      },
    ],
  },
  {
    title: t('settingsOnlineReservations'),
    tooltip: 'mock tooltip message',
    links: [
      {
        icon: <SettingsIcon />,
        to: '/',
        name: t('settingsWidgetSettings'),
      },
      {
        icon: <GlobeIcon />,
        to: '/',
        name: t('settingsWebsite'),
      },
      {
        icon: <LayoutGridIcon />,
        to: '/',
        name: t('settingsReservationTemplates'),
      },
    ],
  },
  {
    title: t('settingsOnlineReservations'),
    tooltip: 'mock tooltip message',
    showBetaLabel: true,
    links: [
      {
        icon: <SettingsIcon />,
        to: '/',
        name: t('settingsWidgetSettings'),
      },
      {
        icon: <CodeIcon />,
        to: '/',
        name: t('settingsWidgetIntegration'),
      },
      {
        icon: <AnnouncementIcon />,
        to: '/',
        name: t('settingsMarketingChannels'),
      },
      {
        icon: <LayoutLeftIcon />,
        to: '/',
        name: t('settingsPoliciesAndTerms'),
      },
    ],
  },
  {
    title: t('settingsNotifications'),
    tooltip: 'mock tooltip message',
    links: [
      {
        icon: <MailIcon />,
        to: '/',
        name: t('settingsEmailSettings'),
      },
      {
        icon: <CodeIcon />,
        to: '/',
        name: t('settingsEmailEditor'),
      },
      {
        icon: <SettingsIcon />,
        to: '/',
        name: t('settingsSMSSettings'),
      },
      {
        icon: <EditIcon />,
        to: '/',
        name: t('settingsSMSEditor'),
      },
    ],
  },
  {
    title: t('settingsTools'),
    tooltip: 'mock tooltip message',
    links: [
      {
        icon: <AlertIcon />,
        to: '/',
        name: t('settingsExperiences'),
      },
      {
        icon: <MessageIcon />,
        to: '/',
        name: t('settingsEvents'),
      },
      {
        icon: <CoinIcon />,
        to: '/',
        name: t('settingsPrepaymentsAndDeposits'),
      },
      {
        icon: <SaleIcon />,
        to: '/',
        name: t('settingsDiscounts'),
      },
      {
        icon: <HeartIcon />,
        to: '/',
        name: t('settingsLoyaltySystem'),
      },
      {
        icon: <LifeBouyIcon />,
        to: '/',
        name: t('settings3rdPartyIntegrations'),
      },
    ],
  },
  {
    title: t('settingsWebsite'),
    tooltip: 'mock tooltip message',
    links: [
      {
        icon: <SettingsIcon />,
        to: '/',
        name: t('settingsWebsiteSettings'),
      },
      {
        icon: <CodeIcon />,
        to: '/',
        name: t('settingsWebsiteIntegration'),
      },
      {
        icon: <GalleryIcon />,
        to: '/',
        name: t('settingsWebsiteGallery'),
      },
      {
        icon: <MenuIcon />,
        to: '/',
        name: t('settingsWebsiteMenuEditor'),
      },
    ],
  },
  {
    title: t('settingsDataManagement'),
    tooltip: 'mock tooltip message',

    links: [
      {
        icon: <ListIcon />,
        to: '/',
        name: t('settingsFullReservationList'),
      },
      {
        icon: <UsersIcon />,
        to: '/',
        name: t('settingsGuestbook'),
      },
      {
        icon: <ChatIcon />,
        to: '/',
        name: t('settingsFeedbackIntegration'),
      },
    ],
  },
  {
    title: t('settingsGeneral'),
    tooltip: 'mock tooltip message',
    links: [
      {
        icon: <UserSecondIcon />,
        to: '/',
        name: t('settingsMyAccount'),
      },
      {
        icon: <UsersSecondIcon />,
        to: '/',
        name: t('settingsStaffAccounts'),
      },
      {
        icon: <UsersPlusIcon />,
        to: '/',
        name: t('settingsSubscriptionAndBillingData'),
      },
      {
        icon: <InboxIcon />,
        to: '/',
        name: t('settingsInvoices'),
      },
    ],
  },
];

interface OwnProps {
  visible: boolean;
  setNavigationValues: any;
}

export const SettingsSubNavigation = ({ setNavigationValues, visible }: OwnProps) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const showMobileSubNavigation = isMobile ? !visible : false;
  const location = useLocation();
  const lastPathFragment = location.pathname.split('/').pop();
  const subNavigationClass = showMobileSubNavigation ? 'mobile' : 'desktop';

  const BetaLabel = (
    <Box className="betaLabel">
      <Typography className="betaText">{t('beta')}</Typography>
    </Box>
  );

  const settingsSubNavigationLinksToRender = settingsSubNavigationLinks(t).map(
    ({ title, tooltip, links, showBetaLabel }, index) => (
      <Box className={`subNavigationContainer ${subNavigationClass}`} key={`${title}-${index}`}>
        <Box className="titleContainer">
          <Typography className="titleText">{title}</Typography>
          <Box className="iconContainer">
            {showBetaLabel && BetaLabel}
            <Tooltip title={tooltip}>
              <HelpOutlinedIcon className="helpIcon" />
            </Tooltip>
          </Box>
        </Box>
        <StyledCard>
          {links.map(({ to, name, icon }) => (
            <SubNavLink
              to={to}
              key={name}
              onClick={() => setNavigationValues({ visible: true })}
              isActive={to.split('/').pop() === lastPathFragment && !isMobile}
            >
              {icon}
              <Box className="linkContent">
                <Box className="linkTextContainer">
                  <Typography className="linkText sub-nav-link">{name}</Typography>
                  {showBetaLabel && BetaLabel}
                </Box>
                <ChevronRight />
              </Box>
            </SubNavLink>
          ))}
        </StyledCard>
      </Box>
    ),
  );

  return (
    <Box className={`settingsSubNavigation ${subNavigationClass}`}>
      {settingsSubNavigationLinksToRender}
    </Box>
  );
};
