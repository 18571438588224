import React, { useEffect, useState } from 'react';
import { AppState } from 'types';
import { connect } from 'react-redux';
import { oldAppUrlGenerate } from 'utils/url';
import { RestaurantListItem } from 'types/application';
import { Permissions } from 'types/auth';
import { Restaurant } from 'types/calendar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, CircularProgress, ListItem, Menu, MenuItem, Box, SxProps } from '@mui/material';
import { changeRestaurantCall } from 'api/auth';
import { setToken } from 'utils/tokenManagement';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import applicationActions from 'redux/actions/application';
import { useTranslation } from 'react-i18next';

interface StateProps {
  restaurant: Restaurant;
  restaurantsList: RestaurantListItem[];
  permissions: Permissions;
}

interface DispatchProps {
  getRestaurantsList: () => void;
}

interface OwnProps {
  switchButtonStyles?: SxProps;
}

type Props = StateProps & DispatchProps & OwnProps;

const buttonStyles: SxProps = {
  textTransform: 'capitalize',
  lineHeight: 'initial',
  color: 'white',
  fontSize: 14,
  fontWeight: 500,
  '&:hover': {
    color: 'brandYellow',
  },
};

const menuItemStyles = {
  '&.MuiMenuItem-root': {
    padding: '16px 12px',
    textDecoration: 'none',
    color: 'navyBlue',
    fontSize: 16,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  '&.Mui-selected': {
    color: 'darkBlue',
  },
  '&.Mui-focusVisible': {
    color: 'darkBlue',
  },
  '&:hover': {
    color: 'darkBlue',
  },
};

const RestaurantSwitcher: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const restaurantListLength = props?.restaurantsList?.length;

  const { accessManagerPages, restaurantCanCreateNewRestaurant, accessRestaurantSubManager } =
    props.permissions;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRestaurantSwitch = (id: string) => {
    setIsLoading(true);
    changeRestaurantCall(id)
      .then((data) => {
        setToken(data);
        navigate(0);
      })
      .finally(() => {
        setIsLoading(false);
        handleClose();
      });
  };

  const isTitleExist = !!props.restaurant.title;
  const showAddRestaurant =
    accessManagerPages && restaurantCanCreateNewRestaurant && !accessRestaurantSubManager;

  useEffect(() => {
    if (!restaurantListLength) {
      props.getRestaurantsList();
    }
  }, []);

  return (
    <ListItem disablePadding>
      <Button
        sx={{
          ...buttonStyles,
          ...(props.switchButtonStyles ?? {}),
        }}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        endIcon={isTitleExist ? <KeyboardArrowDownIcon /> : undefined}
      >
        {isTitleExist ? <>{props.restaurant.title}</> : ''}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
      >
        {!!props?.restaurantsList?.length ? (
          props.restaurantsList.map((restaurantItem) => (
            <MenuItem
              key={restaurantItem.nid}
              sx={menuItemStyles}
              component="a"
              onClick={() => handleRestaurantSwitch(restaurantItem.nid)}
            >
              {restaurantItem.title}
            </MenuItem>
          ))
        ) : (
          <MenuItem onClick={handleClose}>No restaurants</MenuItem>
        )}
        {showAddRestaurant && (
          <MenuItem
            sx={menuItemStyles}
            component="a"
            onClick={handleClose}
            href={oldAppUrlGenerate('/restaurant/add', false)}
          >
            {t('addNewRestaurant')}
          </MenuItem>
        )}
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: 0.6,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Menu>
    </ListItem>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    restaurant: state.calendar.restaurant,
    restaurantsList: state.application.restaurantsList,
    permissions: state.auth.userSettings.permissions,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    getRestaurantsList: bindActionCreators(applicationActions.getRestaurantsList, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantSwitcher);
