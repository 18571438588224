import React, { useEffect } from 'react';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import { Select, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { SpecialOffer } from 'types/reservation';

interface OwnProps {
  isReadOnly?: boolean;
}

interface StateProps {
  offer: string;
  offers: SpecialOffer[];
}

interface DispatchProps {
  updateOffer: (offerNid: string) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const OfferSelect: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [selectedOffer, setSelectedOffer] = React.useState(props.offer);

  const updateOffer = (event: SelectChangeEvent) => {
    if (event.target.value === '') {
      props.updateOffer('_none');
    } else {
      props.updateOffer(event.target.value);
    }
  };

  const Options = Object.keys(props.offers).map((key) => {
    if (key === '_none') {
      return (
        <MenuItem value={key} key={key}>
          {/* @ts-ignore */}
          {t(props.offers[key])}
        </MenuItem>
      );
    } else {
      return (
        <MenuItem value={key} key={key}>
          {/* @ts-ignore */}
          {props.offers[key]}
        </MenuItem>
      );
    }
  });

  // Store originally selected offer in the state.
  useEffect(() => {
    if (!selectedOffer) {
      setSelectedOffer(props.offer);
    }
    // eslint-disable-next-line
  }, [props.offer]);

  // Update selected offer when the offers list changed.
  useEffect(() => {
    if (props.offers.hasOwnProperty(selectedOffer)) {
      props.updateOffer(selectedOffer);
    } else {
      props.updateOffer('_none');
    }
    // eslint-disable-next-line
  }, [selectedOffer, props.offers]);

  return (
    <FormControl variant="outlined" size="small">
      <Select
        id="offer-select-select"
        disabled={props.isReadOnly}
        value={props.offer}
        onChange={updateOffer}
        renderValue={(value: any): any => {
          if (props.offers.length === 0) {
            return <span>{t('bookingModalNoSpecialOffer')}</span>;
          } else if (value === '_none') {
            return (
              <span>
                <Trans>{props.offers[value]}</Trans>
              </span>
            );
          } else {
            return <>{props.offers[value]}</>;
          }
        }}
      >
        {Options}
      </Select>
    </FormControl>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    offer: state.reservation.specialOffer.nid,
    offers: state.reservation.specialOffers,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateOffer: bindActionCreators(reservationActions.updateSpecialOffer, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferSelect);
