import React from 'react';
import StarRatings from 'react-star-ratings';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { AverageRating, RatingItem } from 'types/reservation';
import './AverageRatingComponent.scss';
import { useSelector } from 'react-redux';
import { AppState } from 'types';

interface OwnProps {
  averageRating?: AverageRating;
}

type Props = OwnProps;

const AverageRatingComponent: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { averageRating } = props;
  const mode = useSelector((state: AppState) => state.theme.mode);

  return (
    <div className="mt-2">
      <div className={`ratings ${mode === 'dark' ? 'ratings--dark' : ''}`}>
        <div className="rating-badge">
          <span className="badge badge-rating">
            {!!averageRating?.ratings && averageRating?.ratings.client.average}
          </span>
        </div>
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#f5f5f9',
                maxWidth: 220,
                fontSize: 12,
                border: '1px solid',
                borderColor: '#dadde9',
              },
            },
          }}
          title={
            <div className="m-2 rating-tooltip">
              <div className="mb-1 rating-tooltip__title">{t('AVERAGE RATING')}:</div>
              {_.map(averageRating?.ratings, (rating: RatingItem, type) => {
                if (type !== 'client') {
                  return (
                    <div key={rating.entityId} style={{ display: 'table-row' }}>
                      <div className="d-table-cell pr-1">{t(type)}</div>
                      <div className="d-table-cell pr-1">
                        <StarRatings
                          rating={rating.average / 2}
                          starRatedColor="#f4b630"
                          starDimension="14px"
                          numberOfStars={5}
                          starSpacing="0"
                          name={type}
                        />
                      </div>
                      <div className="d-table-cell">{rating.average}</div>
                    </div>
                  );
                }
              })}
            </div>
          }
        >
          <div className="rating-info">
            <div className="stars">
              <StarRatings
                rating={
                  averageRating?.ratings ? averageRating?.ratings.client.average / 2 : undefined
                }
                starRatedColor="#f4b630"
                starDimension="14px"
                numberOfStars={5}
                starSpacing="0"
                name="rating"
              />
            </div>
            <div className="rating-review">
              {averageRating?.feedbackCount}{' '}
              {averageRating?.feedbackCount === 1 ? t('Review') : t('bookingModalRatingReviews')}
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default AverageRatingComponent;
