import React, { ChangeEvent } from 'react';
import { FormGroup, TextField } from '@mui/material';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  isReadOnly?: boolean;
}

interface StateProps {
  clientDetails: string;
}

interface DispatchProps {
  updateClientDetails: (clientDetails: string) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const ClientNotes: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const updateClientDetails = (event: ChangeEvent<HTMLInputElement>) => {
    props.updateClientDetails(event.target.value);
  };
  return (
    <FormGroup>
      <TextField
        disabled={props.isReadOnly}
        id="detailsText"
        placeholder={t('bookingModalGuestNote')}
        multiline
        rows="2"
        variant="outlined"
        className="w-100"
        value={props.clientDetails}
        onChange={updateClientDetails}
      />
    </FormGroup>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    clientDetails: state.reservation.clientDetails,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateClientDetails: bindActionCreators(reservationActions.updateClientDetails, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientNotes);
