import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import './SubNavLink.scss';

interface SubNavLinkProps {
  isActive?: boolean;
  to: string;
  children: ReactNode;
  onClick: () => void;
}

export const SubNavLink = ({ isActive, children, ...props }: SubNavLinkProps) => {
  return (
    <NavLink
      style={{ textDecoration: 'none' }}
      className={`subNavLink ${isActive ? 'active' : ''}`}
      {...props}
    >
      {children}
    </NavLink>
  );
};
