import moment from "moment";
import { FiltersOptions, SelectedHours } from "../types/filters";
import { ReservationState } from "../types/reservation";

export const filterOptionsCheck = (
  options: FiltersOptions[],
  reservation: ReservationState
) => {
  let results = false;
  options.forEach(option => {
    switch (option) {
      case FiltersOptions.Comments:
        results = results || !!reservation.comments.length;
        break;
      case FiltersOptions.Reviews:
        results =
          results || !!reservation.reservationAverageRating?.feedback?.nid;
        break;
      case FiltersOptions.VIP:
        results = results || reservation.clientIsVip;
        break;
    }
  });
  return results;
};

export const filterTimeCheck = (
  selectedHours: SelectedHours,
  reservation: ReservationState,
  isSingleDay: boolean
) => {
  let validFrom = true;
  let validTo = true;

  const start = moment(reservation.startTime);
  const end = moment(reservation.endTime);

  if (isSingleDay) {
    if (!!selectedHours.from) {
      const from = moment(selectedHours.from);

      validFrom = start.isSameOrAfter(from);

      if (selectedHours.includeOngoing && !validFrom) {
        validFrom = end.isSameOrAfter(from);
      }
    }
    if (!!selectedHours.to) {
      const to = moment(selectedHours.to);

      validTo = start.isSameOrBefore(to);

      if (selectedHours.includeOngoing && !validTo) {
        validTo = end.isSameOrBefore(to);
      }
    }
  } else {
    if (!!selectedHours.from) {
      const from = moment(selectedHours.from);

      validFrom =
        start.hour() > from.hour() ||
        (start.hour() === from.hour() && start.minute() >= from.minute());

      if (selectedHours.includeOngoing && !validFrom) {
        validFrom =
          end.hour() > from.hour() ||
          (end.hour() === from.hour() && end.minute() >= from.minute()) ||
          (end.hour() < start.hour() && end.isAfter(start, "day"));
      }
    }
    if (!!selectedHours.to) {
      const to = moment(selectedHours.to).add(1, "minutes");

      validTo =
        start.hour() < to.hour() ||
        (start.hour() === to.hour() && start.minute() <= to.minute());

      if (selectedHours.includeOngoing && !validTo) {
        validTo =
          (start.hour() < end.hour() && end.hour() < to.hour()) ||
          (end.hour() === to.hour() && end.minute() <= to.minute());
      }
    }
  }

  return validFrom && validTo;
};
