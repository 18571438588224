import React from 'react';
import {
  List,
  ListItemButton,
  IconButton,
  SwipeableDrawer,
  ListItemIcon,
  ListItemText,
  ListItem,
  Box,
} from '@mui/material';
import { oldAppUrlGenerate } from 'utils/url';
import { Trans } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useAuth } from 'hooks/useAuth';

const listWrapperStyles = {
  width: '250px',
  color: 'navyBlue',
  marginTop: `var(--safe-area-inset-top)`,
};

const iconStyles = {
  color: 'navyBlue',
};

const listItemButtonStyles = {
  '&.MuiListItemButton-root': {
    padding: '10px 16px',
  },
  '&.Mui-selected': {
    backgroundColor: 'blueGray',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'blueGray',
  },
  '&:hover': {
    backgroundColor: 'blueGray',
  },
};

const MobileMenu = () => {
  const { handleLogout } = useAuth();
  const [open, setOpen] = React.useState(false);

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  const sideList = () => (
    <Box
      sx={listWrapperStyles}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={listItemButtonStyles}
            component="a"
            href={oldAppUrlGenerate('/settings', false)}
          >
            <ListItemIcon>
              <SettingsOutlinedIcon sx={iconStyles} />
            </ListItemIcon>
            <ListItemText primary={<Trans>mobileMenuSettings</Trans>} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={listItemButtonStyles}
            component="a"
            href={oldAppUrlGenerate('/calendar', false)}
          >
            <ListItemIcon>
              <CalendarMonthOutlinedIcon sx={iconStyles} />
            </ListItemIcon>
            <ListItemText primary={<Trans>mobileMenuCalendar</Trans>} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={listItemButtonStyles} component="a" onClick={handleLogout}>
            <ListItemIcon>
              <LogoutOutlinedIcon sx={iconStyles} />
            </ListItemIcon>
            <ListItemText primary={<Trans>mobileMenuLogout</Trans>} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <IconButton
        aria-label="open drawer"
        onClick={toggleDrawer(true)}
        edge="start"
        className="nav-link"
        sx={(theme) => ({
          color: theme.palette.white,
          marginLeft: 0.2,
        })}
      >
        <MenuIcon />
      </IconButton>

      {/* @ts-ignore */}
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {sideList()}
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
