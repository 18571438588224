import React from 'react';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import containerResizeDetector from '../CustomReactCalendarTimeline/resize-detector/container';
import Timeline, {
  CustomHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from '../CustomReactCalendarTimeline';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import reservationActions from 'redux/actions/reservation';
import TimelineItem from '../TimelineItem';
import { timeFormat } from 'utils/dates';
import { AppState } from 'types';
import { Scrollbars } from 'react-custom-scrollbars';
import { canReservationMove, getVisibleReservations } from 'utils/reservations';
import { ReactComponent as ChevronDownIcon } from 'images/icons/chevron-down.svg';
import { Translation } from 'react-i18next';
import {
  CalendarDisplay,
  CalendarSettings,
  Restaurant,
  RoomOrTable,
  TimelineReservation,
} from 'types/calendar';
import { ReservationState, Statuses } from 'types/reservation';
import './CustomTimeline.scss';
import { IconButton, PaletteMode, Typography } from '@mui/material';

const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start',
  itemTimeEndKey: 'end',
};
interface OwnProps {
  isReadOnly?: boolean;
}

interface StateProps {
  roomsAndTables: RoomOrTable[];
  reservations: TimelineReservation[];
  restaurant: Restaurant;
  calendarSettings: CalendarSettings;
  calendarDisplay: CalendarDisplay;
  theme: PaletteMode;
}

interface DispatchProps {
  openFullBookingModal: () => void;
  updateReservation: (reservation: ReservationState) => void;
  changeTimeReservation: (
    reservationId: string,
    tableIds: string[],
    from: string,
    till: string,
  ) => void;
  updateReservationToNewDefaults: (reservation: Partial<ReservationState>) => void;
}

interface State {
  groups: any;
  items: TimelineReservation[];
  itemToAdd: any;
  visibleTimeStart: any;
  visibleTimeEnd: any;
  startGroupToAdd: any;
  openGroups: any;
  calendarMinWidth: number;
  clickTimestamp: number;
  // originalClick: [number, number],
  scrollMove: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

class CustomTimeline extends React.Component<Props, State> {
  private scrollRef: any;

  constructor(props: any) {
    super(props);
    const visibleTimeStart = this.calculateVisibleStartTime(this.props.calendarSettings.startTime);
    const visibleTimeEnd = this.calculateVisibleEndTime(this.props.calendarSettings.endTime);

    this.state = {
      groups: this.props.roomsAndTables,
      items: this.props.reservations,
      itemToAdd: {},
      visibleTimeStart,
      visibleTimeEnd,
      startGroupToAdd: null,
      // todo: change
      openGroups: Object.assign(
        {},
        ...this.props.roomsAndTables
          .filter((item) => item.root && !item.collapsed)
          .map((item) => ({ [item['id']]: true })),
      ),
      calendarMinWidth: 1800,
      clickTimestamp: 0,
      // originalClick: [0, 0],
      scrollMove: true,
    };
  }

  preventScrollMove = (e: TouchEvent) => {
    if (!this.state.scrollMove) {
      e.preventDefault();
    }
  };

  componentDidMount(): void {
    this.scrollRef && this.scrollRef.addEventListener('touchmove', this.preventScrollMove);
  }

  componentWillUnmount(): void {
    this.scrollRef && this.scrollRef.removeEventListener('touchmove', this.preventScrollMove);
  }

  componentDidUpdate(
    prevProps: Readonly<StateProps & DispatchProps>,
    prevState: Readonly<State>,
    snapshot?: any,
  ): void {
    if (prevProps.roomsAndTables !== this.props.roomsAndTables) {
      this.setState({
        groups: this.props.roomsAndTables,
        openGroups: Object.assign(
          {},
          ...this.props.roomsAndTables
            .filter((item) => item.root && !item.collapsed)
            .map((item) => ({ [item['id']]: true })),
        ),
      });
    }
    if (prevProps.calendarSettings !== this.props.calendarSettings) {
      this.scrollRef && this.scrollRef.addEventListener('touchmove', this.preventScrollMove);

      const calculatedVisibleStartTime = this.calculateVisibleStartTime(
        this.props.calendarSettings.startTime,
      );
      const calculateVisibleEndTime = this.calculateVisibleEndTime(
        this.props.calendarSettings.endTime,
      );
      const diff = moment(calculateVisibleEndTime).diff(
        moment(calculatedVisibleStartTime),
        'hours',
        true,
      );
      this.setState({
        visibleTimeStart: calculatedVisibleStartTime,
        visibleTimeEnd: calculateVisibleEndTime,
        calendarMinWidth: diff * 100 + 150,
      });
    }
    if (prevProps.reservations !== this.props.reservations) {
      this.setState({
        items: this.props.reservations,
      });
    }

    const timezone = this.props.restaurant.settings
      ? this.props.restaurant.settings.timezone
      : undefined;
    const prevTimezone = prevProps.restaurant.settings
      ? prevProps.restaurant.settings.timezone
      : undefined;
    if (timezone && timezone !== prevTimezone) {
      // If needed can be moved to gerRestaurant action
      this.props.restaurant.settings &&
        moment.tz.setDefault(this.props.restaurant.settings.timezone);
    }
  }

  calculateVisibleStartTime = (time: Moment) => {
    return moment(time).startOf('hour').valueOf(); // down to hours
  };

  calculateVisibleEndTime = (time: Moment) => {
    let end = moment(time);
    return end.minute() || end.second() || end.millisecond()
      ? end.add(1, 'hour').startOf('hour').valueOf()
      : end.startOf('hour').valueOf();
  };

  isTableOccupied = (
    tableId: string,
    startTime: number,
    endTime: number,
    reservations: any[],
    reservationId?: number | string,
  ) => {
    return reservations.some((r) => {
      return (
        r.group.includes(tableId) &&
        r.start < endTime &&
        r.end > startTime &&
        r.id !== reservationId
      );
    });
  };

  handleTimeChangeFirst = (
    avisibleTimeStar: Moment,
    avisibleTimeEnd: Moment,
    updateScrollCanvas: any,
  ) => {
    updateScrollCanvas(
      moment(this.state.visibleTimeStart).valueOf(),
      moment(this.state.visibleTimeEnd).valueOf(),
    );
    // this.setState({ visibleTimeStart, visibleTimeEnd });
  };

  handleCanvasClick = () => {};

  handleCanvasClickStart = (groupId: string, time: number, e: MouseEvent) => {
    if (this.props.isReadOnly) {
      return;
    }

    const { groups } = this.state;

    if (e.type === 'touchstart') {
      this.setState({
        clickTimestamp: moment().valueOf(),
        scrollMove: true,
        // originalClick: [e.clientX, e.clientY],
      });
    }

    // Prevent opening reservation modal if gray bar (with room title) was clicked.
    const gr = groups.find((x: any) => x.id === groupId);
    if (gr && gr.root) {
      return;
    }

    const itemToAdd = {
      id: 'new',
      group: [groupId],
      start: moment(time),
      canMove: false,
    };

    this.setState({
      itemToAdd,
      startGroupToAdd: groupId,
    });
  };

  handleMouseMove = (groupId: string, time: number, e: MouseEvent) => {
    const { itemToAdd, groups, startGroupToAdd, openGroups } = this.state;

    if (e.type === 'touchmove') {
      if (!!this.state.clickTimestamp || !this.state.scrollMove) {
        if (moment().valueOf() - this.state.clickTimestamp > 500) {
          this.setState({
            scrollMove: false,
            clickTimestamp: 0,
          });
        } else {
          this.setState({
            itemToAdd: {},
            startGroupToAdd: null,
            clickTimestamp: 0,
          });
          return;
        }
      } else if (this.state.scrollMove) {
        return;
      }
    }

    const gr = groups.find((x: any) => x.id === groupId);

    if (gr && gr.root) {
      return;
    }

    if (!startGroupToAdd) {
      this.handleCanvasClickStart(groupId, time, e);
      return;
    }

    let newGroups: any = [];

    if (startGroupToAdd === groupId) {
      newGroups = [startGroupToAdd];
      const overlappingReservation = this.props.reservations.find((r) => {
        return r.group.includes(groupId) && r.start < time && r.end > itemToAdd.start;
      });
      if (overlappingReservation) {
        time = Math.min(time, overlappingReservation.start);
      }
    } else {
      // Create range.
      const startGroupToAddIndex = groups.findIndex((x: any) => x.id === startGroupToAdd);
      const groupIndex = groups.findIndex((x: any) => x.id === groupId);

      //@ts-ignore
      newGroups = [...Array(Math.abs(startGroupToAddIndex - groupIndex) + 1).keys()].map(
        (i) => groups[i + Math.min(startGroupToAddIndex, groupIndex)].id,
      );
      newGroups = newGroups.filter((gId: string) => {
        const gr = groups.find((x: any) => x.id === gId);
        const isGroupInvalid = this.isTableOccupied(
          gId,
          itemToAdd.start,
          time || itemToAdd.start,
          this.props.reservations,
        );
        return !gr.root && openGroups[gr.parent] && !isGroupInvalid;
      });
    }

    this.setState({
      itemToAdd: { ...itemToAdd, end: moment(time), group: newGroups },
    });
  };

  handleCanvasClickEnd = (groupId: string, time: number, e: MouseEvent) => {
    if (this.props.isReadOnly) {
      return;
    }

    if (e.type === 'touchend' && this.state.scrollMove) {
      return;
    }

    const { itemToAdd } = this.state;
    if (Object.entries(itemToAdd).length !== 0 || itemToAdd.constructor !== Object) {
      if (itemToAdd.end) {
        itemToAdd.end = moment(itemToAdd.end).add(-1, 'second');
      }
      this.openNewReservationDialog(itemToAdd);
    }
    this.setState({
      itemToAdd: {},
      startGroupToAdd: null,
    });
  };

  handleCanvasDoubleClick = () => {};

  handleCanvasContextMenu = () => {};

  handleItemClick = (itemId: number) => {
    const item = this.props.reservations.find((r) => r.id === itemId);
    this.openEditReservationDialog(item);
  };

  handleItemSelect = (itemId: number) => {
    const item = this.props.reservations.find((r) => r.id === itemId);
    this.openEditReservationDialog(item);
  };

  handleItemDoubleClick = (itemId: number) => {
    const item = this.props.reservations.find((r) => r.id === itemId);
    this.openEditReservationDialog(item);
  };

  handleItemContextMenu = () => {};

  handleItemMove = (reservationId: string, dragTime: any, tableIds: string[]) => {
    if (this.props.isReadOnly) {
      return;
    }
    // Can't move on touch devices
    if (!canReservationMove()) {
      return;
    }
    const items = this.state.items.slice();
    const itemIndex = items.findIndex((r) => r.id === Number(reservationId));
    // const table = this.props.roomsAndTables[tableIds]
    let item = items[itemIndex];
    if (typeof item === 'undefined' || item.reservation.status === Statuses.PreBooked) {
      return;
    }
    const endTime = dragTime + (item.end - item.start);
    let isOccupied = false;
    // Prevent move if any table is occupied
    tableIds.forEach((tableId: string) => {
      if (this.isTableOccupied(tableId, dragTime, endTime, items, item.id)) {
        isOccupied = true;
        return;
      }
    });
    // Can't move, table is occupied
    if (isOccupied) {
      return;
    }
    item.spinner = true;
    item.canResize = false;
    item.canMove = false;
    item.end = endTime;
    item.start = dragTime;
    item.group = tableIds;
    this.setState({
      items,
    });

    this.props.changeTimeReservation(reservationId, tableIds, dragTime, endTime);
  };

  handleItemResize = () => {};

  openEditReservationDialog = (item: any) => {
    this.props.updateReservation(item.reservation);
    this.props.openFullBookingModal();
  };

  openNewReservationDialog = (item: any) => {
    const endTime = moment(item.end).add(1, 'seconds');

    this.props.updateReservationToNewDefaults({
      startTime: item.start,
      endTime: item.start.valueOf() === endTime.valueOf() ? undefined : item.end,
      tables: item.group,
    });
    this.props.openFullBookingModal();
  };

  // moveResizeValidator = (action, item: TimelineReservation, time) => {
  //   return !item.spinner ? time : item.start;
  // };

  toggleGroup = (id: number) => {
    const { openGroups } = this.state;
    this.setState({
      openGroups: {
        ...openGroups,
        [id]: !openGroups[id],
      },
    });
  };

  verticalLineClassNamesForGroups = (timeStart: number, timeEnd: number, group: any) => {
    let classes: any = [];
    const { restaurant, calendarSettings } = this.props;
    const currentTimeStart = moment(timeStart);
    const currentHourStart = moment(currentTimeStart).utcOffset(0, true).format('HH:mm');
    const currentTimeEnd = moment(timeEnd);

    classes.push('minutes-' + currentTimeStart.minute());

    if (Object.entries(restaurant.settings.open_hours).length !== 0) {
      const workDayStart = this.props.calendarSettings.startTime;
      const workDayEnd = moment(this.props.calendarSettings.endTime);

      if (workDayEnd.isSameOrBefore(workDayStart)) {
        workDayEnd.add('+1', 'day');
      }
      if (currentTimeEnd.isBefore(workDayStart) || currentTimeStart.isSameOrAfter(workDayEnd)) {
        classes.push('workday-break');
        return classes;
      }
    }

    if (restaurant.cumoWidgetTimes.length === 0) {
      return classes;
    }
    const specificData =
      restaurant.cumoWidgetSpecificTimes.times &&
      restaurant.cumoWidgetSpecificTimes.times[calendarSettings.date.format('YYYY-MM-DD')];
    if (specificData && Object.entries(specificData).length !== 0) {
      const table = specificData[group.id];
      if (table) {
        let is_blocked = table.find(($el: any) => $el === currentHourStart);
        if (is_blocked) {
          classes.push('online-blocked');
        }
      }
      return classes;
    } else {
      const weekday = moment(calendarSettings.date).day();
      const data = restaurant.cumoWidgetTimes.find(
        ($el) => $el.weekday === weekday && $el.room?.nid == group.id,
      ); // eslint-disable-line
      // eqeqeq
      if (!data || !data.times) {
        return classes;
      }

      let is_blocked = data.times.find(($el) => $el === currentHourStart);
      if (is_blocked) {
        classes.push('online-blocked');
      }
    }
    return classes;
  };

  render() {
    const { groups, items, visibleTimeStart, visibleTimeEnd, itemToAdd, openGroups } = this.state;
    const allItems = [...items, itemToAdd];

    const increment = Number(this.props.restaurant.settings.increment);

    const newGroups = groups
      .filter((g: any) => g.root || openGroups[g.parent])
      .map((group: any) => {
        const isExpanded = !!openGroups[group.roomId] && openGroups[group.roomId];

        return Object.assign({}, group, {
          title: group.root ? (
            <div className="row-root">
              <div className="row-root-content">
                <IconButton
                  className={`row-root__button ${isExpanded ? 'row-root__button--is-open' : ''}`}
                  onClick={() => this.toggleGroup(parseInt(group.id))}
                >
                  <ChevronDownIcon width={13} height={13} />
                </IconButton>
                <span className="table-title">{group.title}</span>
              </div>
            </div>
          ) : (
            <div className="table-details">
              <div className="table-name">{group.title}</div>
              <div className="covers-count">
                {group.covers_min}-{group.covers_max}
              </div>
            </div>
          ),
        });
      });

    return (
      <Scrollbars
        autoHide
        renderView={({ style, ...props }) => {
          return (
            <div
              {...props}
              style={{
                ...style,
              }}
            />
          );
        }}
        className={[
          'scroll-area timeline-container',
          this.props.calendarSettings.closed ? 'timeline-container--has-background' : '',
          this.props.theme === 'dark' ? 'timeline-container--dark' : 'timeline-container--light',
        ].join(' ')}
      >
        {!this.props.calendarSettings.closed ? (
          <Timeline
            resizeDetector={containerResizeDetector}
            groups={newGroups}
            items={allItems}
            keys={keys}
            style={
              this.props.calendarDisplay === CalendarDisplay.Fixed
                ? { minWidth: this.state.calendarMinWidth + 'px' }
                : {}
            }
            sidebarWidth={150}
            sidebarContent={<div>Above The Left</div>}
            canMove={canReservationMove()}
            canResize="right"
            canSelect
            scrollRef={(el: any) => (this.scrollRef = el)}
            itemsSorted
            // can be used for not select!
            // itemTouchSendsClick={true}
            stackItems={false}
            visibleTimeStart={visibleTimeStart}
            visibleTimeEnd={visibleTimeEnd}
            onTimeChange={this.handleTimeChangeFirst}
            onCanvasClick={this.handleCanvasClick}
            onCanvasClickStart={this.handleCanvasClickStart}
            onCanvasClickEnd={this.handleCanvasClickEnd}
            onMouseMove={this.handleMouseMove}
            onCanvasDoubleClick={this.handleCanvasDoubleClick}
            onCanvasContextMenu={this.handleCanvasContextMenu}
            onItemClick={this.handleItemClick}
            onItemSelect={this.handleItemSelect}
            onItemContextMenu={this.handleItemContextMenu}
            onItemMove={this.handleItemMove}
            onItemResize={this.handleItemResize}
            onItemDoubleClick={this.handleItemDoubleClick}
            // moveResizeValidator={this.moveResizeValidator}
            itemRenderer={TimelineItem}
            horizontalLineClassNamesForGroup={(group: any) => (group.root ? ['row-root'] : [])}
            verticalLineClassNamesForGroups={this.verticalLineClassNamesForGroups}
            timeSteps={{
              minute: increment,
              hour: increment / 60,
            }}
            lineHeight={43}
            itemHeightRatio={1}
            dragSnap={increment * 60 * 1000}
          >
            <TimelineHeaders className="sticky">
              <CustomHeader height={50} headerData={{ someData: 'data' }} unit="hour">
                {({ headerContext: { intervals }, getRootProps, getIntervalProps }) => {
                  return (
                    <div {...getRootProps()}>
                      {intervals.map((interval: any, index: number) => {
                        const intervalStyle = {
                          lineHeight: '50px',
                          textAlign: 'center',
                        };
                        const incrementFactor = 60 / increment;
                        if (!(index % incrementFactor)) {
                          const intProps = {
                            ...getIntervalProps({
                              interval,
                              style: intervalStyle,
                            }),
                          };
                          intProps.style.width *= incrementFactor;
                          return (
                            <div className="timeslot" {...intProps}>
                              {interval.startTime.format(
                                timeFormat(this.props.restaurant.settings.date_format_key),
                              )}
                            </div>
                          );
                        }
                        return false;
                      })}
                    </div>
                  );
                }}
              </CustomHeader>
            </TimelineHeaders>

            <TimelineMarkers>
              <TodayMarker interval={1000 * 60}>
                {({ styles, date }: { styles: any; date: number }) =>
                  date >= this.state.visibleTimeStart && date <= this.state.visibleTimeEnd ? (
                    <div style={styles} className="rct-now-time" />
                  ) : null
                }
              </TodayMarker>
            </TimelineMarkers>
          </Timeline>
        ) : (
          <Typography variant={'h1'} sx={{ my: 8 }} textAlign={'center'}>
            <Translation>{(t) => t('reservationListRestaurantClosed')}</Translation>
          </Typography>
        )}
      </Scrollbars>
    );
  }
}

function mapStateToProps(state: AppState): StateProps {
  return {
    roomsAndTables: state.calendar.roomsAndTables,
    restaurant: state.calendar.restaurant,
    calendarSettings: state.calendar.calendarSettings,
    calendarDisplay: state.calendar.calendarDisplay,
    reservations: getVisibleReservations(
      state.calendar.reservations,
      state.calendar.restaurant.settings.hide_no_show,
    ),
    theme: state.theme.mode,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    openFullBookingModal: bindActionCreators(calendarActions.openFullBookingModal, dispatch),
    updateReservation: bindActionCreators(reservationActions.updateReservation, dispatch),
    changeTimeReservation: bindActionCreators(calendarActions.changeTimeReservation, dispatch),
    updateReservationToNewDefaults: bindActionCreators(
      reservationActions.updateReservationToNewDefaults,
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTimeline as any);
