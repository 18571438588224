import React from 'react'
import StarRatings from 'react-star-ratings'

interface OwnProps {
  averageRating: number
}

type Props = OwnProps

const FeedbackStars: React.FC<Props> = (props) => {
  return (
    <div className={"feedback-stars"}>
      <StarRatings
        rating={(props.averageRating / 2)}
        starRatedColor="#f4b630"
        starDimension='14px'
        numberOfStars={5}
        starSpacing='0'
        // name={type}
      />
      <div className="feedback-stars__average">
        {props.averageRating}
      </div>
    </div>
  )
}

export default FeedbackStars
