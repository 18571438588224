import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReactComponent as TimesIcon } from 'images/icons/times.svg';
import { AppState } from 'types';
import notesActions from 'redux/actions/notes';
import moment from 'moment';
import { Note, NotesColors } from 'types/notes';

interface StateProps {
  deleteNoteLoading: boolean;
}

interface DispatchProps {
  deleteNote: (id: string, deleteAll: boolean, callback?: () => void) => void;
}

interface DefaultProps {
  note: Note;
  isOpen: boolean;
  onClose: () => void;
}

type Props = DefaultProps & StateProps & DispatchProps;

const NotesDeleteModal: React.FC<Props> = ({
  isOpen,
  onClose,
  note,
  deleteNote,
  deleteNoteLoading,
}) => {
  const { t } = useTranslation();

  const isSingleNote = moment(note.start_date).isSame(moment(note.end_date), 'day');

  const [totalDelete, setTotalDelete] = useState(isSingleNote);

  const handleTotalChange = (e: React.ChangeEvent<{}>, checked: boolean) => {
    setTotalDelete(checked);
  };

  const handleDeleteNote = () => {
    deleteNote(note.id, totalDelete, onClose);
  };

  const handleClose = () => {
    if (deleteNoteLoading) return;
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="delete-note-dialog-title"
      aria-describedby="delete-note-dialog-description"
      className="notes-card__dialog-delete"
    >
      <DialogTitle id="delete-note-dialog-title" className="notes-card__dialog-delete-title">
        <Box display="flex">
          <span className="notes-card__dialog-delete-icon">
            <TimesIcon />
          </span>
          {t('Confirm Delete Note')}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="delete-note-dialog-description"
          className="notes-card__dialog-delete-text"
        >
          {t('Do you really want to delete this note? This process cannot be undone.')}
        </DialogContentText>
        {!isSingleNote && (
          <FormControlLabel
            control={<Checkbox style={{ color: NotesColors['danger'] }} />}
            label="Delete this note for whole period"
            value={totalDelete}
            onChange={handleTotalChange}
          />
        )}
        {deleteNoteLoading && (
          <div className="notes-card__loader">
            <CircularProgress />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className="notes-card__dialog-cancel-btn"
          onClick={handleClose}
          disabled={deleteNoteLoading}
        >
          {t('Cancel')}
        </Button>
        <Button
          className="notes-card__dialog-delete-btn"
          onClick={handleDeleteNote}
          autoFocus
          disabled={deleteNoteLoading}
        >
          {t('Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    deleteNoteLoading: state.notes.deleteNoteLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    deleteNote: bindActionCreators(notesActions.deleteNote, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesDeleteModal);
