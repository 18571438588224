import React from 'react';
import { oldAppUrlGenerate } from 'utils/url';
import { AppState } from 'types';
import { connect } from 'react-redux';
import { LicenceType, License, Permissions } from 'types/auth';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

interface StateProps {
  activeLicense: License | null;
  permissions: Permissions;
}

type Props = StateProps;
const FreeTrialInfo: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const showInfo =
    props.permissions.accessManagerPages &&
    props.activeLicense?.type &&
    [LicenceType.freetrial, LicenceType.freetrial_reservations].includes(props.activeLicense?.type);
  const hasFreeTrialLicense = props.activeLicense?.type === LicenceType.freetrial;
  const hasLimitedReservations = props.activeLicense?.type === LicenceType.freetrial_reservations;

  return (
    <>
      {showInfo && (
        <Box
          sx={(theme) => ({
            color: theme.palette.brandWhite,
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            flexWrap: 'wrap',
          })}
        >
          {hasFreeTrialLicense && (
            <Typography color="white">
              {t('freeTrialPlan')} {t('freeTrialExpires')} {props.activeLicense?.timeLeft}
            </Typography>
          )}
          {hasLimitedReservations && (
            <Typography color="white">
              {t('freeTrialYouHave')} {props.activeLicense?.reservationsLeft}{' '}
              {t('freeTrialReservationsLeft')}
            </Typography>
          )}
          <Button
            size={'small'}
            href={oldAppUrlGenerate('/settings/subscription')}
            variant={'contained'}
            sx={{
              padding: '5px 10px',
              marginLeft: '10px',
            }}
          >
            {t('freeTrialUpgradeNow')}
          </Button>
        </Box>
      )}
    </>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    permissions: state.auth.userSettings.permissions,
    activeLicense: state.auth.userSettings.activeLicense,
  };
}

export default connect(mapStateToProps)(FreeTrialInfo);
