import { useState, MouseEvent } from 'react';
import { Statuses } from 'types/reservation';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import { Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StatusesEng } from 'utils/reservations';
import './BookingStatuses.scss';
import { useScreenSize } from 'hooks/useScreenSize';

interface StateProps {
  status: Statuses;
}

interface DispatchProps {
  updateStatus: (status: Statuses) => void;
}

interface OwnProps {
  statuses: Statuses[];
}

type Props = StateProps & DispatchProps & OwnProps;

const BookingStatuses = (props: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (status: any) => {
    setAnchorEl(null);
    props.updateStatus(status);
  };

  const { isMobile } = useScreenSize();

  return (
    <div className="modal-statuses">
      {isMobile ? (
        <>
          <Button
            aria-controls="modal-mobile-statuses-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={`btn-selected btn-${props.status}`}
          >
            {t(StatusesEng[props.status])}
          </Button>

          <Menu
            id="modal-mobile-statuses-menu"
            anchorEl={anchorEl}
            keepMounted
            disablePortal
            disableAutoFocusItem
            open={open}
            onClose={() => setAnchorEl(null)}
            className="modal-statuses modal-mobile-statuses"
          >
            {props.statuses.map((status) => {
              let className = 'btn-selected btn-' + status;
              return (
                <MenuItem onClick={() => handleClose(status)} key={status}>
                  <Button key={status} type="button" className={className}>
                    {t(StatusesEng[status])}
                  </Button>
                </MenuItem>
              );
            })}
          </Menu>
        </>
      ) : (
        props.statuses.map((status) => {
          let className = 'btn-' + status + ' ';
          if (status === props.status) {
            className += 'btn-selected';
          }

          return (
            <Button
              key={status}
              type="button"
              className={className}
              sx={{ color: 'navyBlue' }}
              onClick={() => props.updateStatus(status)}
            >
              {t(StatusesEng[status])}
            </Button>
          );
        })
      )}
    </div>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    status: state.reservation.status,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateStatus: bindActionCreators(reservationActions.updateStatus, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingStatuses);
