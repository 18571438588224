import React from 'react';
import ScrollArea from '../ScrollArea';
import SidebarSearch from './SidebarSearch';
import { AdvancedSearchParams } from 'types/calendar';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import { connect, useSelector } from 'react-redux';
import { AppState } from 'types';
import './Sidebar.scss';
import CardWrapper from '../common/CardWrapper/CardWrapper';
import { useScreenSize } from 'hooks/useScreenSize';

interface Props {
  variant?: 'normal' | 'card';
  showAlways?: boolean;
  search?: boolean | 'advanced';
  searchPhrase?: string;
  onSearchChange?: (val: string) => void;
  children?: React.ReactNode;
  Footer?: React.ReactNode;
  className?: string;
  disableCardStyling?: boolean;
}
interface StateProps {
  isOpen: boolean;
}
interface DispatchProps {
  searchReservations: (params: AdvancedSearchParams) => void;
  resetSearchReservations: () => void;
}

const Sidebar: React.FC<Props & StateProps & DispatchProps> = ({
  variant = 'normal',
  showAlways,
  isOpen,
  className,
  search,
  searchPhrase = '',
  onSearchChange,
  searchReservations,
  resetSearchReservations,
  children,
  Footer,
  disableCardStyling,
}) => {
  const { isMobile } = useScreenSize();
  const mode = useSelector((state: AppState) => state.theme.mode);

  const handleSearchChange = (e: any) => {
    if (!onSearchChange) return;
    onSearchChange(e.target.value);
  };

  const handleSearchReset = () => {
    if (!onSearchChange) return;
    onSearchChange('');
  };

  return (
    <div
      className={`sidebar sidebar--${isOpen || showAlways ? 'open' : 'closed'}${
        className ? ` ${className}` : ''
      }`}
    >
      <CardWrapper
        disableStyling={disableCardStyling}
        className={`sidebar__wrapper${variant === 'card' ? ' sidebar__wrapper--card' : ''}`}
      >
        {search && !isMobile && (
          <SidebarSearch
            searchPhrase={searchPhrase}
            onClick={handleSearchReset}
            onChange={handleSearchChange}
            advanced={search === 'advanced'}
            onAdvancedSubmit={searchReservations}
            onAdvancedReset={resetSearchReservations}
            variant={variant === 'normal' ? 'card' : 'normal'}
          />
        )}
        <ScrollArea>
          <div
            className={`sidebar__container${variant === 'card' ? ' sidebar__container--card' : ''}`}
          >
            {children}
          </div>
        </ScrollArea>
        {Footer && (
          <div className={`sidebar__footer ${mode === 'dark' ? 'sidebar__footer--dark' : ''}`}>
            {Footer}
          </div>
        )}
      </CardWrapper>
    </div>
  );
};

function mapStateToProps(state: AppState) {
  return {
    isOpen: state.calendar.sidebarOpen,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    searchReservations: bindActionCreators(calendarActions.searchReservations, dispatch),
    resetSearchReservations: bindActionCreators(calendarActions.resetSearchReservation, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
