import React, { ChangeEvent, useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import {
  FormGroup,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
  Box,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { ReactComponent as TimesCircleIcon } from 'images/icons/times-circle-light.svg';
import { useTranslation } from 'react-i18next';
import { Client, GuestDetails } from 'types/calendar';
import { AverageRating } from 'types/reservation';
import { useDebounce } from 'use-debounce';
import { searchClientCall } from 'api/calendar';
import './ClientSearch.scss';

interface CSLProps {
  query: string;
  clickHandler: (client: Client, searched: boolean) => void;
  handleListKeyDown: (event: React.KeyboardEvent) => void;
  setOpen: (open: boolean) => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
}

const ClientSearchList: React.FC<CSLProps> = ({
  query,
  clickHandler,
  handleListKeyDown,
  setLoading,
  setOpen,
  loading,
}) => {
  const [clients, setClients] = useState([]);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await searchClientCall(query);
        setClients(response);
        setIsError(false);
      } catch (error) {
        setIsError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [query, setLoading]);

  if (loading) {
    return <div className="autocomplete-info-text">Loading...</div>;
  }

  if (isError) {
    return <div className="autocomplete-info-text">{t('Guest search failed')}</div>;
  }

  const clickNewHandler = () => {
    const queryIsPhone = isMobilePhone(query);
    clickHandler(
      {
        phone: queryIsPhone ? query : '',
        name: !queryIsPhone ? query : '',
        email: '',
        company: '',
        title: '',
        clientTags: [],
        clientDetails: '',
        vip: false,
        big_spender: false,
      },
      false,
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
        <MenuItem key={'new'} onClick={clickNewHandler}>
          <div className="d-block">
            <div className="font-size-12">{t('New guest')}</div>
            <div className="autocomplete-values">{query}</div>
          </div>
        </MenuItem>
        {clients.length > 0 &&
          clients.map((option: Client) => (
            <MenuItem key={option.nid} onClick={() => clickHandler(option, true)}>
              <div className="d-block">
                <div className="autocomplete-values">{option.name}</div>
                <div className="autocomplete-values">{option.phone}</div>
                <div className="font-size-12">{option.company}</div>
              </div>
            </MenuItem>
          ))}
      </MenuList>
    </ClickAwayListener>
  );
};

interface OwnProps {
  isReadOnly?: boolean;
}

interface DispatchProps {
  updateName: (name: string) => void;
  updatePhoneNumber: (phoneNumber: string) => void;
  updateEmail: (email: string) => void;
  updateCompany: (company: string) => void;
  updateClientDetails: (clientDetails: string) => void;
  updateSearched: (searched: boolean) => void;
  updateAverageRating: (averageRating?: AverageRating) => void;
  updateGuestDetails: (guestDetails: GuestDetails) => void;
  setVip: (isVip: boolean) => void;
  loadAverageRating: (clientId: number) => void;
  loadGuestDetails: (clientId: number) => void;
}

type Props = DispatchProps & OwnProps;

const ClientSearch: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [query, setQuery] = React.useState('');
  const [debouncedQuery] = useDebounce(query, 500);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setOpen(true);
    setQuery(event.target.value);
    if (!event.target.value) {
      setLoading(false);
    }
  };

  const handleClientSelect = (value: Client, searched: boolean) => {
    props.updateName(value.name);
    props.updateEmail(value.email);
    props.updateCompany(value.company);
    props.updatePhoneNumber(value.phone);
    props.updateClientDetails(value.clientDetails);
    props.setVip(value.vip);
    props.updateSearched(searched);
    if (value.nid != null) {
      props.loadAverageRating(value.nid);
      props.loadGuestDetails(value.nid);
    } else {
      props.updateAverageRating(undefined);
      props.updateGuestDetails({
        arrivedCount: 0,
        lastVisit: '',
        cancelledCount: 0,
        noShowCount: 0,
      });
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab' || event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      setOpen(false);
    }
  };

  const handleClearInput = () => {
    setQuery('');
    setOpen(false);
    setLoading(false);
  };

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <FormGroup>
        <TextField
          disabled={props.isReadOnly}
          size="small"
          placeholder={t('bookingModalClientSearch')}
          variant="outlined"
          onChange={handleInputChange}
          ref={setAnchorEl}
          value={query}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loading && (
                  <Box display="flex" sx={{ mr: 1 }}>
                    <CircularProgress color="inherit" size={12} />
                  </Box>
                )}
                {query && (
                  <IconButton onClick={handleClearInput} sx={{ padding: 0 }}>
                    <TimesCircleIcon className="input-icon input-icon--small input-icon--default" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        {debouncedQuery && (
          <Popper
            role="presentation"
            open={open}
            anchorEl={anchorEl}
            transition
            disablePortal
            className="MuiAutocomplete-popper-custom"
            style={{ position: 'absolute', top: '40px', left: '0', zIndex: 2, width: '100%' }}
          >
            <Paper elevation={1}>
              <ClientSearchList
                query={debouncedQuery}
                clickHandler={handleClientSelect}
                handleListKeyDown={handleListKeyDown}
                setLoading={setLoading}
                setOpen={setOpen}
                loading={loading}
              />
            </Paper>
          </Popper>
        )}
      </FormGroup>
    </Box>
  );
};

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateName: bindActionCreators(reservationActions.updateName, dispatch),
    updatePhoneNumber: bindActionCreators(reservationActions.updatePhoneNumber, dispatch),
    updateEmail: bindActionCreators(reservationActions.updateEmail, dispatch),
    updateCompany: bindActionCreators(reservationActions.updateCompany, dispatch),
    updateClientDetails: bindActionCreators(reservationActions.updateClientDetails, dispatch),
    setVip: bindActionCreators(reservationActions.setVip, dispatch),
    updateSearched: bindActionCreators(reservationActions.updateSearched, dispatch),
    updateAverageRating: bindActionCreators(reservationActions.updateAverageRating, dispatch),
    updateGuestDetails: bindActionCreators(reservationActions.updateGuestDetails, dispatch),
    loadAverageRating: bindActionCreators(reservationActions.loadAverageRating, dispatch),
    loadGuestDetails: bindActionCreators(reservationActions.loadGuestDetails, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ClientSearch);
