import React from "react";

import Card from '@mui/material/Card';
import { Box, CardContent, Stack, Typography } from "@mui/material";

type StatsCardProps = {
  title: string
  mainValue: number | string
  extraValue: number | string
  icon?: any
  color?: "brandYellow" | "green" | "brandBlue" | "red" | "grey"
}

const StatsCard = (props: StatsCardProps) => {
  const {
    title,
    mainValue,
    extraValue,
    icon,
    color = "success"
  } = props;

  return (
    <Card elevation={6} sx={{ height: '100%' }}>
      <CardContent color={color} sx={{ height: '100%' }}>

        <Stack justifyContent="space-between" height="100%">

          {/* Top icon */}
          <Box p={2} borderRadius={2} display="flex" alignSelf="start" alignItems="center" justifyContent="center" bgcolor={color} color="white">
            {icon}
          </Box>

          {/* Bottom part */}
          <Stack>
            <Typography fontSize={14} fontWeight={500} color="brandGray" mt={1}>{title}</Typography>
            <Typography fontSize={26} fontWeight={400} color="brandBlueGray">{mainValue}</Typography>
            <Typography color="brandGray">{extraValue}</Typography>
          </Stack>

        </Stack>

      </CardContent>
    </Card>
  )
}

export default StatsCard
