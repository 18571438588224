import axios from 'axios';
import {
  getToken,
  removeToken,
  removeTokenFromCookie,
  responseHasTokenError,
} from './tokenManagement';

// todo: change this depend on env.
export const baseUrl = process.env.REACT_APP_API_BASE_URL;
const version = 'api-app';

export const axiosConfig = {
  withCredentials: true,
  baseURL: baseUrl + version,
};

export const client = axios.create(axiosConfig);

const onSuccess = function (response: any) {
  return response.data;
};

const onError = function (error: any) {
  const token = getToken();

  if (responseHasTokenError(error?.response) && token) {
    removeTokenFromCookie();
    removeToken();
    window.location.href = '/login';
  }

  return Promise.reject(error?.response || error?.message || error);
};

/**
 * Request Wrapper with default success/error actions
 */
export const request = function (options: any) {
  const token = getToken();

  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  if (process.env.REACT_APP_ENV !== 'production') {
    options.params = {
      ...options.params,
      XDEBUG_SESSION_START: 'PHPSTORM',
    };
  }

  return client({
    ...options,
    timeout: process.env.NODE_ENV === 'development' ? 999999 : 7000,
  })
    .then((response) => {
      return onSuccess(response);
    })
    .catch(onError);
};
