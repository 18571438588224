import { Dispatch } from 'redux';
import { getFloorPlanCall } from 'api/floorPlan';
import { AppState } from 'types';
import {
  FloorPlanClearDataAction,
  FloorPlanRoom,
  FloorPlanSetDataAction,
  FloorPlanSetIsLoadingAction,
  FloorPlanTable,
} from 'types/floorPlan';
import { filterObject } from 'utils/object';
import notificationsActions from './notifications';
import { prepareReservationsFromLocalDatabase } from 'app/data/prepareReservationsFromLocalDatabase';
import { queryAllData } from 'app/data/dataservice';

const floorPlanActions = {
  setIsLoading(isLoading: boolean) {
    return {
      type: 'FloorPlan.setIsLoading',
      payload: { isLoading },
    } as FloorPlanSetIsLoadingAction;
  },
  getData(_isOffline?: boolean | null) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      dispatch<any>(floorPlanActions.setIsLoading(true));
      try {
        let payload: any = {};
        if (_isOffline) {
          const allData = await queryAllData();
          const floorPlanData = prepareReservationsFromLocalDatabase(allData).floorPlanData;
          payload = {
            restaurantId: floorPlanData?.restaurantId,
            rooms: floorPlanData?.rooms,
            tables: floorPlanData?.tables,
            walls: floorPlanData?.walls,
            texts: floorPlanData?.texts,
          };
        } else {
          const data = await getFloorPlanCall();
          payload = {
            restaurantId: getState().calendar.restaurant.nid,
            rooms: data.rooms.map((room: FloorPlanRoom) => filterObject(room, 'tables')),
            tables: data.rooms.reduce(
              (acc: FloorPlanTable[], room: FloorPlanRoom) =>
                !!room.tables
                  ? acc.concat(room.tables.map((r) => ({ ...r, room_id: room.nid })))
                  : acc,
              [],
            ),
            walls: data.walls,
            texts: data.texts,
          };
        }
        dispatch<any>(floorPlanActions.setIsLoading(false));
        return dispatch({
          type: 'FloorPlan.setData',
          payload,
        } as FloorPlanSetDataAction);
      } catch (e) {
        dispatch(
          notificationsActions.enqueueSnackbar({
            message: 'Could not get floor plan data. Please try again later.',
            options: { variant: 'error' },
          }),
        );
      }
    };
  },
  clearData() {
    return {
      type: 'FloorPlan.clearData',
    } as FloorPlanClearDataAction;
  },
};

export default floorPlanActions;
