export const isTouchDevice = () => "ontouchstart" in document.documentElement;

export const reloadAfterHidden = () => {
  let hidden;

  if (typeof document.hidden !== "undefined") {
    hidden = "hidden";
  }
  // @ts-ignore
  else if (typeof document.mozHidden !== "undefined") {
    hidden = "mozHidden";
  }
  // @ts-ignore
  else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
  }
  // @ts-ignore
  else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
  }
  // @ts-ignore
  !document[hidden] && window.location.reload();
};
