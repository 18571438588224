import React from 'react';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import CardWrapper from 'app/components/common/CardWrapper/CardWrapper';

interface OwnProps {
  noBorder?: boolean;
  size?: number;
}

export const SidebarCardLoader: React.FC<OwnProps> = (props) => {
  const sidebarClasses = classNames('sidebar-card--loading', {
    'sidebar-card': !props.noBorder,
  });
  return (
    <CardWrapper className={sidebarClasses}>
      <CircularProgress size={props.size} />
    </CardWrapper>
  );
};
