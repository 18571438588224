import { request } from 'utils/api';

export const getMonthlyCovers = (startDate: string, endDate: string) =>
  request({
    method: 'get',
    url: `/restaurant-reports/covers-monthly?start=${startDate}&end=${endDate}`,
  }).then((response) => response);

export const getTotalGuests = (startDate: string, endDate: string) =>
  request({
    method: 'get',
    url: `/restaurant-reports/guests-total?start=${startDate}&end=${endDate}`,
  }).then((response) => response);

export const getRating = (startDate: string, endDate: string) =>
  request({
    method: 'get',
    url: `/restaurant-reports/rating?start=${startDate}&end=${endDate}`,
  }).then((response) => response);

export const getGuests = (startDate: string, endDate: string) =>
  request({
    method: 'get',
    url: `/restaurant-reports/guests?start=${startDate}&end=${endDate}`,
  }).then((response) => response);

export const getReturningClients = (startDate: string, endDate: string) =>
  request({
    method: 'get',
    url: `/restaurant-reports/clients-new-return?start=${startDate}&end=${endDate}`,
  }).then((response) => response);

export const getNoShowArrived = (startDate: string, endDate: string) =>
  request({
    method: 'get',
    url: `/restaurant-reports/no-show-arrived?start=${startDate}&end=${endDate}`,
  }).then((response) => response);

export const getCovers = (startDate: string, endDate: string) =>
  request({
    method: 'get',
    url: `/restaurant-reports/covers?start=${startDate}&end=${endDate}`,
  }).then((response) => response);
