import React, { ChangeEvent, useRef, useState } from 'react';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import { TextField, Stack } from '@mui/material';
import ClientSearch from '../ClientSearch';
import { useTranslation } from 'react-i18next';
import ClientNotes from '../ClientNotes';
import { CustomPhoneInput } from 'app/components/CustomPhoneInput';

interface StateProps {
  name: string;
  phoneNumber: string;
  email: string;
  company: string;
  restaurantCountry: string | undefined;
}

interface DispatchProps {
  updateName: (name: string) => void;
  updatePhoneNumber: (phoneNumber: string) => void;
  updateEmail: (email: string) => void;
  updateCompany: (company: string) => void;
}

interface OwnProps {
  showAll?: boolean | false;
  isReadOnly?: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

const ClientDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // eslint-disable-next-line
  const [oldPhone, setOldPhone] = useState<any>();
  const inputEl = useRef(null);

  React.useEffect(() => {
    // @ts-ignore
    if (!props.phoneNumber.startsWith('+') && !!oldPhone?.dialCode && props.phoneNumber !== '') {
      // @ts-ignore
      props.updatePhoneNumber('+' + oldPhone.dialCode + props.phoneNumber);
    }

    // eslint-disable-next-line
  }, [props.phoneNumber]);
  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.updateName(event.target.value);
  };

  const onPhoneNumberChange = (phone: string) => {
    props.updatePhoneNumber('+' + phone);

    // TODO: add a list of available phone numbers (from
    // state.calendar.restaurant.settings?)
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.updateEmail(event.target.value);
  };

  const onCompanyChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.updateCompany(event.target.value);
  };
  // const phoneError = props.errors.some(e => e.field === 'field_phone')
  // const nameError = props.errors.some(e => e.field === 'field_name')

  const onPhoneNumberBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      props.updatePhoneNumber('');
    }
  };

  return (
    <div>
      <Stack spacing={2} mb={2}>
        <ClientSearch isReadOnly={props.isReadOnly} />
        <div className="MuiFormControl-root MuiTextField-root w-100" ref={setAnchorEl}>
          <CustomPhoneInput
            isReadOnly={props.isReadOnly}
            inputEl={inputEl}
            country={props.restaurantCountry?.toLowerCase() || 'lt'}
            autoFormat={false}
            // @ts-ignore
            value={props.phoneNumber || oldPhone?.dialCode || ''}
            onChange={onPhoneNumberChange}
            onBlur={onPhoneNumberBlur}
            preferredCountries={[props.restaurantCountry?.toLowerCase() || '']}
            dropdownStyle={{ width: anchorEl?.clientWidth ? anchorEl.clientWidth : '250px' }}
          />
        </div>
        <TextField
          disabled={props.isReadOnly}
          id="contact-name"
          size="small"
          placeholder={t('bookingModalClientDetailsName')}
          variant="outlined"
          value={props.name || ''}
          onChange={onNameChange}
        />
        {props.showAll && (
          <TextField
            disabled={props.isReadOnly}
            id="contact-email"
            size="small"
            placeholder={t('bookingModalClientDetailsEmail')}
            variant="outlined"
            value={props.email || ''}
            onChange={onEmailChange}
          />
        )}
        <TextField
          disabled={props.isReadOnly}
          id="contact-company"
          size="small"
          placeholder={t('bookingModalClientDetailsCompany')}
          variant="outlined"
          value={props.company || ''}
          onChange={onCompanyChange}
        />
        <ClientNotes isReadOnly={props.isReadOnly} />
      </Stack>
    </div>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    restaurantCountry: state.calendar.restaurant.address?.country,
    name: state.reservation.name,
    phoneNumber: state.reservation.phoneNumber,
    email: state.reservation.email,
    company: state.reservation.company,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateName: bindActionCreators(reservationActions.updateName, dispatch),
    updatePhoneNumber: bindActionCreators(reservationActions.updatePhoneNumber, dispatch),
    updateEmail: bindActionCreators(reservationActions.updateEmail, dispatch),
    updateCompany: bindActionCreators(reservationActions.updateCompany, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
