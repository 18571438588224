import React from 'react';
import { AppState } from 'types';
import { connect } from 'react-redux';
import { CalendarDisplay } from 'types/calendar';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import CalendarControlsButton from '../CalendarControlsButton';
import { ReactComponent as ArrowsIcon } from 'images/icons/arrows-h-light.svg';

interface StateProps {
  calendarDisplay: CalendarDisplay;
}

interface DispatchProps {
  changeCalendarDisplay: (calendarDisplay: CalendarDisplay) => void;
}

type Props = StateProps & DispatchProps;

const CalendarDisplaySwitch: React.FC<Props> = (props: Props) => {
  const handleChange = () => {
    props.changeCalendarDisplay(
      props.calendarDisplay === CalendarDisplay.Fixed
        ? CalendarDisplay.Full
        : CalendarDisplay.Fixed,
    );
  };

  return (
    <CalendarControlsButton onClick={handleChange}>
      <ArrowsIcon width="100%" height="100%" />
    </CalendarControlsButton>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    calendarDisplay: state.calendar.calendarDisplay,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    changeCalendarDisplay: bindActionCreators(calendarActions.changeCalendarDisplay, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDisplaySwitch);
