import React from 'react';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import { Tags } from '../Tags';

interface OwnProps {
  isReadOnly?: boolean;
}

interface StateProps {
  tags: string[];
}

interface DispatchProps {
  updateTags: (tags: string[]) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const ReservationTags: React.FC<Props> = (props) => {
  return <Tags tags={props.tags} updateTags={props.updateTags} isReadOnly={props.isReadOnly} />;
};

function mapStateToProps(state: AppState): StateProps {
  return {
    tags: state.reservation.tags,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateTags: bindActionCreators(reservationActions.updateTags, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationTags);
