import React from 'react';
import { FloorPlanObject } from 'types/floorPlan';

interface Props {
  text: FloorPlanObject;
}

const FloorText = ({ text }: Props) => {
  if (!text.position) return null;

  const width = text.position.width || 100;
  const fontSize = (2.1 * width) / (text.label?.length || 1);

  return (
    <text
      x={text.position.x - width / 2}
      y={text.position.y + fontSize / 4}
      fontSize={fontSize}
      textLength={width}
      fontWeight={500}
      fill="#7a82a3"
      transform={`rotate(${text.position.angle} ${text.position.x} ${text.position.y})`}
    >
      {text.label}
    </text>
  );
};

export default FloorText;
