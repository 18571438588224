import { Action } from '.';
import { GuestDetails, Reply, ValidationError, Waiter } from './calendar';

export enum Statuses {
  Arrived = 'arrived',
  Blocked = 'blocked',
  Cancelled = 'cancelled',
  Confirmed = 'approved',
  NoShow = 'not_arrived',
  Pending = 'pending',
  Template = 'templet',
  WalkIn = 'walk_in',
  WaitingList = 'waiting_list',
  PreBooked = 'pre_booked',
}

export enum Origin {
  Online = 'online',
  Phone = 'phone',
  None = '',
}

export interface ReservationState {
  id?: number;
  title?: string;
  status: Statuses;
  numberOfGuests: number;
  clientId?: number;
  phoneNumber: string;
  company: string;
  name: string;
  email: string;
  startTime: moment.Moment;
  endTime?: moment.Moment;
  tables: string[];
  notifications: Notifications;
  waiter: Waiter;
  originalWaiter: Waiter;
  comments: Reply[];
  newComment: string;
  initialTime?: string;
  showInitialTimeBadge: boolean;
  autoArrivalDisabled: boolean;
  origin: Origin;
  clientIsVip: boolean;
  clientIsBigSpender: boolean;
  showBigSpenderTag: boolean;
  specialOffer: SpecialOfferData;
  specialOffers: SpecialOffer[];
  tags: string[];
  clientDetails: string;
  clientTags: string[];
  created?: number;
  changed?: number;
  timeFormat: string;
  averageRating?: AverageRating;
  reservationAverageRating?: ReservationAverageRating;
  searched?: boolean;
  guestDetails: GuestDetails;
  reservationSavingErrors: ValidationError[];
  payments: Payments;
  dinningRooms: number[];
  lateCancellationNoShow: LateCancellationNoShow;
}

export interface AverageRating {
  feedbackCount: number;
  ratings: Rating | null;
}

export interface Rating {
  [type: string]: RatingItem;
}

export interface RatingItem {
  average: number;
  entityId: number;
  type: string;
}

export interface ReservationAverageRating {
  feedback: Feedback | null;
}

export interface Feedback {
  rateAmbience: number;
  rateCleanliness: number;
  rateFood: number;
  rateService: number;
  rateComment: string;
  nid: number;
  title: string;
}

export interface TrackedChanges {
  initialTime: string;
}

export interface SpecialOfferData {
  nid: string;
  title?: string;
  promotionType?: string;
  discount?: number;
  price?: Price;
}

export interface Price {
  amount: number;
  currencyCode: string;
  total?: number;
}

export interface SpecialOffer {
  [key: string]: string;
}

export interface Notifications {
  sms: boolean;
  email: boolean;
  resend: boolean;
  tableReady: boolean;
  language: string;
}

export interface Payment {
  currencyCode: string;
  totalAmount: number;
  receivedDate: string;
}

export interface Currencies {
  [total: string]: number;
}

export interface Payments {
  payments: Payment[];
  currencies: Currencies;
}

export interface StripePaymentData {
  id: string;
  object: string;
  cancellation_reason: string | null;
  client_secret: string;
  created: number;
  description: string | null;
  last_setup_error: any;
  livemode: boolean;
  next_action: any;
  payment_method: string;
  payment_method_types: string[];
  status: string;
  usage: string;
  paymentSuccess: boolean;
  paidAmount: number;
  paidAt: string;
}

export interface LateCancellationNoShow {
  enabled: number;
  time: number;
  maxAmount: number;
  currency: string;
  chargeMode: string;
  paymentData: StripePaymentData;
}

//Actions

export interface ReservationUpdatedAction extends Action<{ reservation: ReservationState }> {
  type: 'Reservation.ReservationUpdated';
}

export interface NumberOfGuestsUpdatedAction extends Action<{ numberOfGuests: number }> {
  type: 'Reservation.NumberOfGuestsUpdated';
}

export interface TablesUpdatedAction extends Action<{ tables: string[] }> {
  type: 'Reservation.TablesUpdated';
}

export interface StartTimeUpdatedAction extends Action<{ startTime: moment.Moment }> {
  type: 'Reservation.StartTimeUpdated';
}

export interface EndTimeUpdatedAction extends Action<{ endTime: moment.Moment }> {
  type: 'Reservation.EndTimeUpdated';
}

export interface IdUpdatedAction extends Action<{ id?: number }> {
  type: 'Reservation.IdUpdated';
}

export interface NameUpdatedAction extends Action<{ name: string }> {
  type: 'Reservation.NameUpdated';
}

export interface EmailUpdatedAction extends Action<{ email: string }> {
  type: 'Reservation.EmailUpdated';
}

export interface PhoneNumberUpdatedAction extends Action<{ phoneNumber: string }> {
  type: 'Reservation.PhoneNumberUpdated';
}

export interface CompanyUpdatedAction extends Action<{ company: string }> {
  type: 'Reservation.CompanyUpdated';
}

export interface StatusUpdatedAction extends Action<{ status: Statuses }> {
  type: 'Reservation.StatusUpdated';
}

export interface SearchedUpdatedAction extends Action<{ searched: boolean }> {
  type: 'Reservation.SearchedUpdated';
}

export interface WaiterUpdatedAction extends Action<{ waiter: Waiter }> {
  type: 'Reservation.WaiterUpdated';
}

export interface NewCommentUpdatedAction extends Action<{ newComment: string }> {
  type: 'Reservation.NewCommentUpdated';
}

export interface SpecialOfferUpdatedAction extends Action<{ specialOfferNid: string }> {
  type: 'Reservation.SpecialOfferUpdated';
}

export interface SpecialOffersLoadedAction extends Action<{ specialOffers: SpecialOffer[] }> {
  type: 'Reservation.SpecialOffersLoaded';
}

export interface AverageRatingLoadedAction extends Action<{ averageRating: AverageRating }> {
  type: 'Reservation.AverageRatingLoaded';
}

export interface GuestDetailsLoadedAction extends Action<{ guestDetails: GuestDetails }> {
  type: 'Reservation.GuestDetailsLoaded';
}

export interface TagsUpdatedAction extends Action<{ tags: string[] }> {
  type: 'Reservation.TagsUpdated';
}

export interface ClientDetailsUpdatedAction extends Action<{ clientDetails: string }> {
  type: 'Reservation.ClientDetailsUpdated';
}

export interface ClientTagsUpdatedAction extends Action<{ clientTags: string[] }> {
  type: 'Reservation.ClientTagsUpdated';
}

export interface ClientVipSetAction extends Action<{ isVip: boolean }> {
  type: 'Reservation.ClientVipSet';
}

export interface ClientBigSpenderSetAction extends Action<{ isBigSpender: boolean }> {
  type: 'Reservation.ClientBigSpenderSet';
}

export interface NotificationsUpdatedAction extends Action<{ notifications: Notifications }> {
  type: 'Reservation.NotificationsUpdated';
}

export interface CommentDeletedAction extends Action<{ commentId: number }> {
  type: 'Reservation.CommentDeleted';
}

export interface CommentEditedAction extends Action<{ commentId: number; commentBody: string }> {
  type: 'Reservation.CommentEdited';
}

export interface CommentAddedAction extends Action<{ comment: Reply }> {
  type: 'Reservation.CommentAdded';
}

export interface ReservationValidationErrorAddedAction
  extends Action<{ errors: ValidationError[] }> {
  type: 'Reservation.ReservationValidationErrorAdded';
}

export interface ReservationValidationErrorRemovedAction extends Action<{}> {
  type: 'Reservation.ReservationValidationErrorRemoved';
}

export interface PaymentsLoadedAction extends Action<{ payments: Payments }> {
  type: 'Reservation.PaymentsLoaded';
}

export interface LateCancellationNoShowAction
  extends Action<{ lateCancellationNoShow: LateCancellationNoShow }> {
  type: 'Reservation.LateCancellationNoShowDataLoaded';
}

export type ReservationAction =
  | ReservationUpdatedAction
  | NumberOfGuestsUpdatedAction
  | TablesUpdatedAction
  | StartTimeUpdatedAction
  | EndTimeUpdatedAction
  | IdUpdatedAction
  | NameUpdatedAction
  | EmailUpdatedAction
  | PhoneNumberUpdatedAction
  | SearchedUpdatedAction
  | CompanyUpdatedAction
  | StatusUpdatedAction
  | WaiterUpdatedAction
  | NewCommentUpdatedAction
  | SpecialOfferUpdatedAction
  | SpecialOffersLoadedAction
  | TagsUpdatedAction
  | ClientDetailsUpdatedAction
  | ClientTagsUpdatedAction
  | ClientVipSetAction
  | ClientBigSpenderSetAction
  | NotificationsUpdatedAction
  | CommentDeletedAction
  | CommentEditedAction
  | CommentAddedAction
  | AverageRatingLoadedAction
  | GuestDetailsLoadedAction
  | ReservationValidationErrorAddedAction
  | ReservationValidationErrorRemovedAction
  | PaymentsLoadedAction
  | LateCancellationNoShowAction;
