import React from 'react';
import { Origin } from 'types/reservation';
import { ReactComponent as PhoneAltIcon } from 'images/icons/phone-alt-light.svg';
import { ReactComponent as GlobeIcon } from 'images/icons/globe-light.svg';

interface Props {
  origin: Origin;
  className?: string;
}

const OriginIcon: React.FC<Props> = ({ origin, className }) => {
  switch (origin) {
    case Origin.Online:
      return <GlobeIcon className={className ?? 'sidebar-card__icon'} />;
    case Origin.Phone:
      return <PhoneAltIcon className={className ?? 'sidebar-card__icon'} />;
    case Origin.None:
      return null;
  }
};

export default OriginIcon;
