import React, { useState, useEffect, useRef } from 'react';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import { FormControlLabel, Checkbox, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CalendarState, Room, RoomOrTable } from 'types/calendar';
import { ReservationState } from 'types/reservation';
import FloorPlan from 'app/components/FloorPlan';
import { FloorPlanTable } from 'types/floorPlan';
import './Tables.scss';
import { SelectedTablesGuestsCount } from '../SelectedTablesGuestsCount';
import { TableButton } from '../TableButton';
import { useScreenSize } from 'hooks/useScreenSize';

interface OwnProps {
  setHideContent?: (hideContent: boolean) => void;
  occupiedTables?: number[];
  isReadOnly?: boolean;
}

interface StateProps {
  calendar: CalendarState;
  reservation: ReservationState;
  allTables: RoomOrTable[];
  rooms: Room[];
  floorPlanTables: FloorPlanTable[];
}

interface DispatchProps {
  updateTables: (tables: string[]) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const Tables: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const [showAllTables, setShowAllTables] = useState(false);
  const [showFloorPlan, setShowFloorPlan] = useState(false);
  const previousTables = useRef<string[]>(props.reservation.tables);
  const showTablesCount = props.reservation.tables.length > 3;

  const isTableOccupied = (_id: number | string) => {
    const id = typeof _id === 'string' ? parseInt(_id) : _id;
    if (!id || !props.occupiedTables) return false;
    return props.occupiedTables.includes(id);
  };

  const handleTableClick = (nid: string, confirm?: boolean) => {
    if (isTableOccupied(parseInt(nid))) return;
    const index = props.reservation.tables.indexOf(nid);
    if (index !== -1) {
      props.updateTables([
        ...props.reservation.tables.slice(0, index),
        ...props.reservation.tables.slice(index + 1),
      ]);
    } else {
      props.updateTables([...props.reservation.tables, nid]);
    }
  };

  const handleRemoveTable = (nid: string) => {
    if (props.reservation.tables.includes(nid)) {
      props.updateTables(props.reservation.tables.filter((t) => t !== nid));
    }
  };

  const toggleShowAllTables = () => {
    if (props.setHideContent) props.setHideContent(!showAllTables);
    setShowAllTables(!showAllTables);
  };

  const toggleShowFloorPlan = () => {
    if (props.setHideContent) props.setHideContent(!showFloorPlan);
    setShowFloorPlan(!showFloorPlan);
  };

  const wholeRoomSelected = (nid: number) => {
    const room = props.rooms.find((r) => r.nid === nid);
    if (!room) {
      return false;
    }
    return room.tables.every((t) => isTableOccupied(t.nid) || tableSelected(t.nid));
  };

  const wholeRoomSelectedChanged = (nid: number) => {
    const room = props.rooms.find((r) => r.nid === nid);
    if (!room) {
      return;
    }
    const tablesNotFromTheRoom = props.reservation.tables.filter(
      (nid) => !room.tables.find((table) => table.nid.toString() === nid),
    );
    if (room.tables.every((t) => isTableOccupied(t.nid) || tableSelected(t.nid))) {
      props.updateTables(tablesNotFromTheRoom);
    } else {
      props.updateTables([
        ...tablesNotFromTheRoom,
        ...room.tables.filter((t) => !isTableOccupied(t.nid)).map((t) => t.nid.toString()),
      ]);
    }
  };

  const allTablesSelected = () =>
    props.reservation.tables.length ===
    props.allTables.filter((t) => !isTableOccupied(t.id)).length;

  const selectAllTablesChanged = () => {
    if (allTablesSelected()) {
      props.updateTables([]);
    } else {
      props.updateTables(props.allTables.filter((t) => !isTableOccupied(t.id)).map((t) => t.id));
    }
  };

  const tableSelected = (nid: number) => {
    return props.reservation.tables.includes(nid.toString());
  };

  const handleConfirm = () => {
    if (showFloorPlan) {
      toggleShowFloorPlan();
    }
    if (showAllTables) {
      toggleShowAllTables();
    }
  };

  const handleCancel = () => {
    props.updateTables(previousTables.current);
    if (showFloorPlan) {
      toggleShowFloorPlan();
    }
    if (showAllTables) {
      toggleShowAllTables();
    }
  };

  useEffect(() => {
    if (isMobile && showFloorPlan) {
      toggleShowFloorPlan();
    }
    // eslint-disable-next-line
  }, [isMobile]);

  useEffect(() => {
    if (showAllTables || showFloorPlan) {
      previousTables.current = props.reservation.tables;
    }
    // eslint-disable-next-line
  }, [showAllTables, showFloorPlan]);

  return (
    <div className="modal-tables" aria-label="Tables">
      {!(showAllTables || (showFloorPlan && !isMobile)) && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            {showTablesCount ? (
              <span>{`${props.reservation.tables.length} ${t('tables selected')}`}</span>
            ) : (
              props.allTables.map((table) => {
                if (props.reservation.tables.includes(table.id)) {
                  return (
                    <TableButton
                      table={table}
                      disabled={props.isReadOnly}
                      key={table.id}
                      type="button"
                      sx={(theme) => ({
                        background: theme.palette.brandYellow,
                        color: theme.palette.brandWhite,
                        minWidth: 42,
                        marginRight: 0.3,
                        lineHeight: 2,
                        padding: '0 10px 10px 10px',
                        '&:hover': {
                          background: theme.palette.gamboge,
                        },
                      })}
                      onClick={() => handleRemoveTable(table.id)}
                    />
                  );
                }
                return false;
              })
            )}
            <Button type="button" sx={{ ml: 1 }} onClick={toggleShowAllTables}>
              + {t('bookingModalAddTableButton')}
            </Button>
          </div>
          {!isMobile && (
            <div>
              <Button color={'error'} onClick={toggleShowFloorPlan}>
                {t('bookingModalShowFloorPlanButton')}
              </Button>
            </div>
          )}
        </Box>
      )}
      {showAllTables && (
        <div>
          {props.rooms.map((room, index) => {
            return (
              <div key={room.nid}>
                <Box sx={{ mt: index === 0 ? 0 : 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={props.isReadOnly}
                        checked={wholeRoomSelected(room.nid)}
                        onChange={() => wholeRoomSelectedChanged(room.nid)}
                        color="primary"
                        className="color-blue-dark-2"
                        inputProps={{
                          'aria-label': 'checkbox',
                        }}
                      />
                    }
                    label={room.title}
                  />
                </Box>
                <Box display={'flex'} flexWrap={'wrap'} gap={0.5}>
                  {room.tables.map((table) => {
                    const selected = props.reservation.tables.includes(table.nid.toString());

                    return (
                      <TableButton
                        table={table}
                        key={table.nid}
                        disabled={props.occupiedTables?.includes(table.nid) || props.isReadOnly}
                        sx={(theme) => ({
                          background: selected
                            ? theme.palette.brandYellow
                            : theme.palette.linkWater,
                          color: selected ? theme.palette.brandWhite : theme.palette.navyBlue,
                          minWidth: 42,
                          lineHeight: 2,
                          padding: '0 10px 10px 10px',
                          '&:hover': {
                            background: theme.palette.gamboge,
                          },
                        })}
                        onClick={() => handleTableClick(table.nid.toString())}
                      />
                    );
                  })}
                </Box>
              </div>
            );
          })}
          <FormControlLabel
            control={
              <Checkbox
                disabled={props.isReadOnly}
                checked={allTablesSelected()}
                onChange={selectAllTablesChanged}
                color="primary"
                className="color-blue-dark-2"
                inputProps={{
                  'aria-label': 'checkbox',
                }}
                sx={{
                  color: 'navyBlue',
                  '&:checked': {
                    color: '#fff',
                  },
                }}
              />
            }
            label={t('bookingModalSelectAllTables')}
          />

          <Box display={'flex'} alignItems={'center'} columnGap={2} mt={1}>
            <Button
              disabled={props.isReadOnly}
              sx={(theme) => ({
                background: theme.palette.brandYellow,
                color: theme.palette.brandWhite,
                '&:hover': {
                  background: theme.palette.gamboge,
                },
              })}
              onClick={handleConfirm}
            >
              {t('bookingModalTableConfirmButton')}
            </Button>
            <Button className="btn btn-link btn-cancel-reservation" onClick={handleCancel}>
              {t('bookingModalBackButton')}
            </Button>
            <SelectedTablesGuestsCount wrapper={{ display: 'flex', gap: 0.5, color: '#7a82a3' }} />
          </Box>
        </div>
      )}
      {showFloorPlan && !isMobile && (
        <div className="modal-tables__floor-plan">
          <FloorPlan
            onTableSelect={(id) => handleTableClick(id.toString(), true)}
            selectedTables={props.reservation.tables.map((t) => parseInt(t))}
            selectedStatus={props.reservation.status}
            blockedTables={props.occupiedTables}
          />
          <Box className="modal-tables__action-buttons" sx={{ mt: 1 }}>
            <Button
              disabled={props.isReadOnly}
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={handleConfirm}
            >
              {t('bookingModalTableConfirmButton')}
            </Button>
            <Button size={'small'} onClick={handleCancel}>
              {t('bookingModalBackButton')}
            </Button>
            <SelectedTablesGuestsCount wrapper={{ display: 'flex', gap: 0.5, color: '#7a82a3' }} />
          </Box>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    calendar: state.calendar,
    reservation: state.reservation,
    allTables: state.calendar.roomsAndTables.filter(
      (roomOrTable) => roomOrTable.tableId !== undefined,
    ),
    rooms: state.calendar.rooms,
    floorPlanTables: state.floorPlan.tables,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateTables: bindActionCreators(reservationActions.updateTables, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tables);
