import React, { ReactNode } from 'react';
import { Button } from '@mui/material';

interface OwnProps {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  sx?: object;
  active?: boolean;
}

const CalendarControlsButton: React.FC<OwnProps> = ({
  children,
  onClick,
  disabled,
  sx,
  active,
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      variant={'outlined'}
      sx={(theme) => ({
        background: active ? theme.palette.brandYellow : theme.palette.brandWhite,
        color: active ? theme.palette.brandWhite : theme.palette.navyBlue,
        padding: '9px 7px',
        borderColor: theme.palette.linkWater,
        transition: 'none',
        minWidth: 40,
        width: 40,
        height: 40,
        ...sx,
        '&:hover': {
          background: theme.palette.brandYellow,
          borderColor: theme.palette.linkWater,
          color: theme.palette.brandWhite,
          //@ts-ignore
          ...sx?.['&:hover'],
          '& .highlighted': {
            color: theme.palette.brandWhite,
          },
        },
        '& .highlighted': {
          color: active ? theme.palette.brandWhite : theme.palette.brandYellow,
        },
      })}
    >
      {children}
    </Button>
  );
};

export default CalendarControlsButton;
