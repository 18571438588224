import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer, initialState } from './reducers';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const ReduxExtensionComposeName: string = '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__';
const composedEnhancers =
  process.env.NODE_ENV === 'development' &&
  typeof window === 'object' &&
  //@ts-ignore
  window[ReduxExtensionComposeName]
    ? //@ts-ignore
      window[ReduxExtensionComposeName]
    : compose;

const enhancer = composedEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer(history), initialState, enhancer);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
