import React, { ChangeEvent, useState } from 'react';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import { FormGroup, Box, TextField, Button, Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import './NumberOfGuests.scss';
import HeadingPrimary from 'app/components/common/HeadingPrimary/HeadingPrimary';

interface StateProps {
  numberOfGuests: number;
}

interface DispatchProps {
  updateNumberOfGuests: (numberOfGuests: number) => void;
}

interface OwnProps {
  visibleNumbersNumber?: number;
  isReadOnly?: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

const NumberOfGuests: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const guests8 = useMediaQuery({ query: `(min-width:383px)` });
  const guests7 = useMediaQuery({ query: `(min-width:343px)` });

  const defaultVisibleNumbersNumber = guests8 ? 8 : guests7 ? 7 : 6;

  const [manualNumberOfGuests, setManualNumberOfGuests] = useState(
    props.numberOfGuests > defaultVisibleNumbersNumber ? props.numberOfGuests + '' : '',
  );
  const visibleNumbersOfGuests = Array.from(
    { length: props.visibleNumbersNumber || defaultVisibleNumbersNumber },
    (v, k) => k + 1,
  );

  const onManualNumberOfGuestsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const stringValue = event.target.value;
    const value = stringValue === '' ? 0 : parseInt(event.target.value);
    if (isNaN(value)) {
      return;
    }
    setManualNumberOfGuests(event.target.value);
    props.updateNumberOfGuests(value);
  };

  const onNumberOfGuestsClick = (numberOfGuests: number) => {
    props.updateNumberOfGuests(numberOfGuests);
    setManualNumberOfGuests('' + numberOfGuests);
  };

  return (
    <Box mb={2}>
      <FormGroup>
        <HeadingPrimary>{t('bookingModalNumberOfGuests')}</HeadingPrimary>

        <div className="d-flex justify-content-between">
          <div className="input-group modal-guests-number" aria-label="Statuses">
            <Box display="flex">
              {visibleNumbersOfGuests.map((numberOfGuests) => {
                const active = numberOfGuests === props.numberOfGuests;

                return (
                  <Button
                    disabled={props.isReadOnly}
                    variant={'outlined'}
                    sx={(theme) => ({
                      background: active ? theme.palette.brandYellow : theme.palette.brandWhite,
                      borderColor: theme.palette.linkWater,
                      color: theme.palette.navyBlue,
                      minWidth: 35,
                      padding: '5px',
                      display: 'flex',
                      marginRight: '5px',
                      fontSize: 13,
                      '&:hover': {
                        background: theme.palette.brandYellow,
                        borderColor: active ? theme.palette.brandYellow : theme.palette.brandWhite,
                      },
                    })}
                    key={numberOfGuests}
                    onClick={() => onNumberOfGuestsClick(numberOfGuests)}
                  >
                    <Typography>{numberOfGuests}</Typography>
                  </Button>
                );
              })}
              <TextField
                sx={{ maxWidth: '80px' }}
                size={'small'}
                disabled={props.isReadOnly}
                inputProps={{
                  style: {
                    padding: 5,
                    textAlign: 'center',
                    height: 25,
                  },
                }}
                value={manualNumberOfGuests}
                onChange={onManualNumberOfGuestsChange}
              />
            </Box>
          </div>
        </div>
      </FormGroup>
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    numberOfGuests: state.reservation.numberOfGuests,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateNumberOfGuests: bindActionCreators(reservationActions.updateNumberOfGuests, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberOfGuests);
