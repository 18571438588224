import React, { ChangeEvent } from 'react';
import { Statuses } from 'types/reservation';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { AppState } from 'types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Settings } from 'types/calendar';
import { StatusesEng } from 'utils/reservations';

export const filterAvailableStatuses = (restaurantSettings: Settings) => [
  Statuses.Confirmed,
  Statuses.Arrived,
  Statuses.Pending,
  ...(restaurantSettings.list_show_walk_ins ? [Statuses.WalkIn] : []),
  ...(restaurantSettings.hide_no_show ? [] : [Statuses.NoShow]),
  Statuses.Blocked,
  ...(restaurantSettings.list_show_cancelled ? [Statuses.Cancelled] : []),
  // Statuses.Template,
];

interface OwnProps {
  selectedStatuses: Statuses[];
  updateSelectedStatuses: (statuses: Statuses[]) => void;
}

interface StateProps {
  restaurantSettings: Settings;
}

const FilterStatuses: React.FC<OwnProps & StateProps> = (props) => {
  const { t } = useTranslation();
  const updateFilter = (event: ChangeEvent<HTMLInputElement>) => {
    let newSelectedStatuses = [...props.selectedStatuses];
    if (!event.target.checked) {
      newSelectedStatuses.push(event.target.name as Statuses);
    } else {
      newSelectedStatuses = newSelectedStatuses.filter((status) => status !== event.target.name);
    }
    props.updateSelectedStatuses(newSelectedStatuses);
  };

  return (
    <div className={'sidebar-filters__filter-group'}>
      {filterAvailableStatuses(props.restaurantSettings).map((status, index) => (
        <Box key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!props.selectedStatuses.includes(status)}
                onChange={updateFilter}
                name={status}
                color="primary"
                className={`color-${status} no-tick`}
                inputProps={{
                  'aria-label': 'checkbox',
                }}
              />
            }
            label={t(StatusesEng[status])}
            className="w-100"
          />
        </Box>
      ))}
    </div>
  );
};
function mapStateToProps(state: AppState): StateProps {
  return {
    restaurantSettings: state.calendar.restaurant.settings,
  };
}

export default connect(mapStateToProps)(FilterStatuses);
