import React, { ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'types';
import { Statuses } from 'types/reservation';
import PullToRefresh from 'pulltorefreshjs';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import { SidebarCardLoader } from '../Sidebar/SidebarCardLoader';
import { exportReservationsToCsv } from 'utils/reservations';
import ReservationsListItem from './ReservationsListItem';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import NotesCard from '../Notes/NotesCard';
import { filterOptionsCheck, filterTimeCheck } from 'utils/filters';
import {
  AdvancedSearchParams,
  CalendarSettings,
  ReservationListSettings,
  ReservationsSettings,
  Room,
  RoomOrTable,
  Settings,
  Statistics,
  TimelineReservation,
} from 'types/calendar';
import { FiltersOptions, FiltersState } from 'types/filters';
import { Note } from 'types/notes';
import ScrollArea from '../ScrollArea';
import { usePrevious } from 'utils/hooks';
import { AppConnectionStatus } from 'types/application';
import './ReservationsList.scss';
import { Box, Button, Typography } from '@mui/material';
import { filterNotes } from 'utils/filterNotes';
import CardWrapper from '../common/CardWrapper/CardWrapper';
import { ReservationListClasses } from 'constants/reservationListClasses';
import { TableinElementIds } from 'constants/tableinElementIds';
import { useScreenSize } from 'hooks/useScreenSize';

interface ReservationDay {
  date: moment.Moment;
  reservations: TimelineReservation[];
}
interface StateProps {
  storeReservations: TimelineReservation[];
  rooms: Room[];
  allTables: RoomOrTable[];
  searchOpen: boolean;
  isLoading: boolean;
  reservationsLoaded: boolean;
  nextPageIsLoading: boolean;
  restaurantSettings: Settings;
  reservationsSettings: ReservationsSettings;
  calendarSettings: CalendarSettings;
  reservationListSettings: ReservationListSettings;
  filters: FiltersState;
  extendedReservationsIsLoading: boolean;
  exportListToCsv: boolean;
  notesLoading: boolean;
  notes: Note[];
  connectionStatus: AppConnectionStatus;
}

interface DispatchProps {
  getReservations: (startDate: string, endDate: string) => void;
  searchReservations: (params?: AdvancedSearchParams, page?: number) => void;
  changeReservationsPage: (page: number) => void;
  setReservationListStatistics: (val: Statistics) => void;
  updateExportListToCsv: (exportListToCsv: boolean) => void;
}

interface OwnProps {
  searchPhrase?: string;
  className?: string;
  reservations?: TimelineReservation[];
  showNotes?: boolean | 'no-print';
  showPast?: boolean;
  currentTimeWithTimezone?: moment.Moment;
  isCalendarDateSameAsCurrentTime?: boolean;
  pastReservationsButton?: ReactNode;
  setPastReservationsExist?: (value: boolean) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const ReservationsList: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [reservationList, setReservationList] = React.useState<TimelineReservation[]>([]);
  const [reservationListFiltered, setReservationListFiltered] = React.useState<
    TimelineReservation[]
  >([]);
  const [reservationDays, setReservationDays] = React.useState<ReservationDay[]>([]);
  const { isOffline } = props.connectionStatus;
  const { isMobile } = useScreenSize();
  const prevReservations = usePrevious(props.storeReservations);
  const prevCalendarDate = usePrevious(props.calendarSettings.date);
  const prevStartDate = usePrevious(props.reservationListSettings.startDate);
  const prevEndDate = usePrevious(props.reservationListSettings.endDate);
  const prevSearchParams = usePrevious(props.reservationListSettings.advancedSearch.params);
  const prevSearchResults = usePrevious(props.reservationListSettings.advancedSearch.results);

  React.useEffect(() => {
    if (
      isEqual(prevReservations, props.storeReservations) &&
      isEqual(prevCalendarDate, props.calendarSettings.date) &&
      isEqual(prevStartDate, props.reservationListSettings.startDate) &&
      isEqual(prevEndDate, props.reservationListSettings.endDate) &&
      isEqual(prevSearchParams, props.reservationListSettings.advancedSearch.params) &&
      isEqual(prevSearchResults, props.reservationListSettings.advancedSearch.results)
    ) {
      return;
    }

    if (!!props.reservations) {
      setReservationList(props.reservations);
    } else if (!!props.reservationListSettings.advancedSearch.params) {
      setReservationList(props.reservationListSettings.advancedSearch.results);
    } else if (props.storeReservations.length > 0) {
      setReservationList(props.storeReservations);
    } else {
      setReservationList([]);
    }
    // eslint-disable-next-line
  }, [
    props.reservations,
    props.storeReservations,
    props.calendarSettings.date,
    props.reservationListSettings.startDate,
    props.reservationListSettings.endDate,
    props.reservationListSettings.advancedSearch,
  ]);

  React.useEffect(() => {
    !isOffline &&
      isMobile &&
      PullToRefresh.init({
        mainElement: `#${TableinElementIds.header}`,
        triggerElement: `.${ReservationListClasses.reservationListWrapper}`,
        // @ts-ignore
        onRefresh: function (cb) {
          Promise.resolve(
            props.reservationListSettings.startDate && props.reservationListSettings.endDate
              ? props.getReservations(
                  props.reservationListSettings.startDate.format('YYYY-MM-DD'),
                  props.reservationListSettings.endDate.format('YYYY-MM-DD'),
                )
              : props.getReservations(
                  props.calendarSettings.date.format('YYYY-MM-DD'),
                  props.calendarSettings.date.format('YYYY-MM-DD'),
                ),
          ).then(() => {
            cb();
          });
        },
      });
    return () => {
      PullToRefresh.destroyAll();
    };
    // eslint-disable-next-line
  }, [
    isMobile,
    isOffline,
    props.calendarSettings.date,
    props.reservationListSettings.startDate,
    props.reservationListSettings.endDate,
  ]);

  React.useEffect(() => {
    if (!!props.reservations) {
      setReservationListFiltered(props.reservations);
      return;
    }
    const filteredReservationList = reservationList
      .filter(
        (item) =>
          // Status check
          (!item.reservation.autoArrivalDisabled &&
          item.reservation.startTime.isBefore() &&
          item.reservation.status === Statuses.Confirmed
            ? !props.filters.selectedStatuses.includes(Statuses.Arrived)
            : !props.filters.selectedStatuses.includes(item.reservation.status)) &&
          // Time check
          filterTimeCheck(
            props.filters.selectedHours,
            item.reservation,
            !props.reservationListSettings.startDate || !props.reservationListSettings.endDate,
          ) &&
          // Dining rooms check
          (!props.filters.dinningRooms.length ||
            item.reservation.dinningRooms.some(
              (r) => props.filters.dinningRooms.indexOf(r) >= 0,
            )) &&
          // Options check
          (!props.filters.options.length ||
            filterOptionsCheck(props.filters.options, item.reservation)) &&
          // Tag check
          (!!props.filters.tag ? item.reservation.tags.includes(props.filters.tag) : true) &&
          // Search check
          (!props.searchOpen ||
            !props.searchPhrase ||
            item.title?.toLowerCase().includes(props.searchPhrase?.toLowerCase()) || //Title
            item.id.toString()?.toLowerCase().includes(props.searchPhrase?.toLowerCase()) || //ID
            item.reservation.name?.toLowerCase().includes(props.searchPhrase?.toLowerCase()) || //Guest name
            item.reservation.email?.toLowerCase().includes(props.searchPhrase?.toLowerCase()) || //Email
            item.reservation.phoneNumber
              ?.toLowerCase()
              .includes(props.searchPhrase?.toLowerCase()) || //Guest phone number
            item.reservation.company?.toLowerCase().includes(props.searchPhrase?.toLowerCase())), //Guest company name
        // @ts-ignore
      )
      .sort((a, b) => {
        const sortBy = props.filters.sort.option;
        const value = {
          booking_time: 'created',
          client_name: 'name',
          visit_time: 'startTime',
        };
        const direction = props.filters.sort.direction === 'asc' ? [1, -1] : [-1, 1];

        // @ts-ignore
        return a.reservation[value[sortBy]] &&
          // @ts-ignore
          b.reservation[value[sortBy]] &&
          // @ts-ignore
          a.reservation[value[sortBy]] > b.reservation[value[sortBy]]
          ? direction[0]
          : direction[1];
      });

    const statistics: Statistics = filteredReservationList.reduce(
      (accumulator, item) => {
        if (
          [Statuses.Arrived, Statuses.Pending, Statuses.Confirmed].includes(item.reservation.status)
        ) {
          accumulator.reservations++;
        }
        if (
          [Statuses.Arrived, Statuses.Pending, Statuses.Confirmed, Statuses.WalkIn].includes(
            item.reservation.status,
          )
        ) {
          accumulator.guestsToday += item.reservation.numberOfGuests;
        }
        if ([Statuses.WalkIn].includes(item.reservation.status)) {
          accumulator.walkIns += item.reservation.numberOfGuests;
        }

        return accumulator;
      },
      { guestsToday: 0, reservations: 0, walkIns: 0 } as Statistics,
    );

    props.setReservationListStatistics(statistics);
    setReservationListFiltered(filteredReservationList);

    setReservationDays(
      filteredReservationList.reduce((acc: ReservationDay[], r: TimelineReservation) => {
        const start = moment(r.start);
        const day = acc.find((d) => d.date.isSame(start, 'day'));
        if (!!day) {
          day.reservations = [...day.reservations, r];
          return acc;
        } else {
          return [...acc, { date: start, reservations: [r] }];
        }
      }, []),
    );
    // eslint-disable-next-line
  }, [props.reservations, props.filters, props.searchPhrase, props.searchOpen, reservationList]);

  // Export data to the CSV file.
  React.useEffect(() => {
    if (props.exportListToCsv) {
      let selectedStartDate = (
        !!props.reservations
          ? props.reservations[0].reservation.startTime
          : props.reservationListSettings.startDate && props.reservationListSettings.endDate
          ? props.reservationListSettings.startDate
          : props.calendarSettings.date
      ).format('YYYY-MM-DD');
      let selectedEndDate = (
        !!props.reservations
          ? props.reservations[props.reservations.length - 1].reservation.startTime
          : props.reservationListSettings.startDate && props.reservationListSettings.endDate
          ? props.reservationListSettings.endDate
          : props.calendarSettings.date
      ).format('YYYY-MM-DD');

      exportReservationsToCsv(
        reservationListFiltered,
        selectedStartDate,
        selectedEndDate,
        props.restaurantSettings,
        props.rooms,
        props.allTables,
      );

      props.updateExportListToCsv(false);
    }
    // eslint-disable-next-line
  }, [props.exportListToCsv, reservationListFiltered]);

  const handleShowMore = () => {
    const advancedSearch = props.reservationListSettings.advancedSearch;
    if (!!advancedSearch.params) {
      props.searchReservations(advancedSearch.params, advancedSearch.page + 1);
    } else {
      props.changeReservationsPage(props.reservationsSettings.page + 1);
    }
  };

  const showMore =
    ((props.reservationsSettings.page < props.reservationsSettings.pagesCount &&
      props.reservationListSettings.advancedSearch.results.length === 0) ||
      props.reservationListSettings.advancedSearch.page <
        props.reservationListSettings.advancedSearch.pagesCount) &&
    props.searchPhrase === '' &&
    props.filters.selectedHours.from === null &&
    props.filters.selectedHours.to === null &&
    props.filters.selectedStatuses.length === 0 &&
    props.filters.dinningRooms.length === 0 &&
    props.filters.options.length === 0 &&
    props.filters.tag === null &&
    !props.isLoading;

  const allLoaded =
    props.reservationsSettings.page === props.reservationsSettings.pagesCount &&
    reservationDays.length !== 0 &&
    props.reservationsLoaded;

  const showMoreLoading =
    props.nextPageIsLoading || props.reservationListSettings.advancedSearch.isNextPageLoading;

  const { showPast, isCalendarDateSameAsCurrentTime, currentTimeWithTimezone } = props;

  const todayCurrentReservations = reservationListFiltered.filter((filteredReservation) => {
    if (filteredReservation.reservation.endTime && !showPast && isCalendarDateSameAsCurrentTime) {
      return currentTimeWithTimezone?.isBefore(filteredReservation.reservation.endTime);
    }
    return filteredReservation;
  });

  const pastReservationsExist = reservationListFiltered.some((filteredReservation) => {
    if (filteredReservation.reservation.endTime && isCalendarDateSameAsCurrentTime) {
      return currentTimeWithTimezone?.isAfter(filteredReservation.reservation.endTime);
    }
    return false;
  });

  useEffect(() => {
    if (props.setPastReservationsExist) {
      props.setPastReservationsExist(pastReservationsExist);
    }
  }, [reservationListFiltered]);

  const weekDayNumber = props.calendarSettings.date.days();
  const filteredNotes = filterNotes(weekDayNumber, props.notes);

  return (
    <Box
      className={`${ReservationListClasses.reservationList} reservation-list ${
        props.className ? props.className : ''
      }`}
    >
      <ScrollArea className={ReservationListClasses.reservationListWrapper}>
        <Box>
          {props.showNotes &&
            (props.notesLoading ? (
              <SidebarCardLoader />
            ) : (
              <Box className={`${props.showNotes === 'no-print' ? ' no-print' : ''}`}>
                {filteredNotes.map((n) => (
                  <NotesCard note={n} key={n.id} />
                ))}
              </Box>
            ))}
          {props.pastReservationsButton}
          {!props.reservationsLoaded ||
          (props.filters.options.includes(FiltersOptions.Reviews) &&
            props.extendedReservationsIsLoading) ? (
            <SidebarCardLoader />
          ) : reservationDays.length > 1 ? (
            reservationDays.map((day) => {
              return (
                <Box
                  className="reservation-list__day"
                  key={`reservations-${day.date.format('YYYY-MM-DD')}`}
                >
                  <Typography sx={{ color: 'navyBlue' }}>
                    {t('reservationListDate')} {day.date.format('YYYY MM DD')}
                  </Typography>

                  {day.reservations.map((r, i) => (
                    <ReservationsListItem reservation={r} key={`${r.id}-${i}`} />
                  ))}
                </Box>
              );
            })
          ) : todayCurrentReservations.length > 0 ? (
            todayCurrentReservations.map((reservation, index) => (
              <ReservationsListItem reservation={reservation} key={index} />
            ))
          ) : (
            <CardWrapper className="sidebar-card">
              <Typography
                variant={'h1'}
                textAlign={'center'}
                sx={{
                  my: 8,
                  wordBreak: 'break-word',
                  color: 'navyBlue',
                }}
              >
                {t(
                  props.calendarSettings.closed
                    ? 'reservationListRestaurantClosed'
                    : 'reservationListNoReservations',
                )}
              </Typography>
            </CardWrapper>
          )}
        </Box>
        {showMore && (
          <div className="reservation-list__show-more">
            <Button
              disableElevation
              onClick={handleShowMore}
              disabled={showMoreLoading}
              variant={'contained'}
              sx={(theme) => ({
                background: theme.palette.brandYellow,
                color: theme.palette.brandWhite,
                '&:hover': {
                  background: theme.palette.gamboge,
                },
              })}
            >
              {t(showMoreLoading ? 'reservationListLoading' : 'reservationListShowMore')}
            </Button>
          </div>
        )}

        {allLoaded && (
          <Box textAlign={'center'}>
            <Typography variant="caption" gutterBottom>
              {t('reservationListCompleted')}
            </Typography>
          </Box>
        )}
      </ScrollArea>
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    restaurantSettings: state.calendar.restaurant.settings,
    reservationsSettings: state.calendar.reservationsSettings,
    calendarSettings: state.calendar.calendarSettings,
    reservationListSettings: state.calendar.reservationListSettings,
    searchOpen: state.calendar.searchOpen,
    isLoading:
      state.calendar.reservationsIsLoading &&
      state.calendar.reservationListSettings.advancedSearch.isLoading,
    reservationsLoaded:
      !state.calendar.reservationsIsLoading &&
      !state.calendar.reservationListSettings.advancedSearch.isLoading,
    nextPageIsLoading: state.calendar.reservationsIsNextPageLoading,
    extendedReservationsIsLoading: state.calendar.extendedReservationsIsLoading,
    filters: state.filters,
    storeReservations: state.calendar.reservations
      .filter((reservation) => {
        return ![
          ...(state.calendar.restaurant.settings.list_show_walk_ins ? [] : [Statuses.WalkIn]),
          ...(state.calendar.restaurant.settings.list_show_cancelled ? [] : [Statuses.Cancelled]),
          Statuses.Template,
        ].includes(reservation.reservation.status);
      })
      .sort((a, b) => {
        return a.reservation.startTime.isAfter(b.reservation.startTime) ? 1 : -1;
      }),
    rooms: state.calendar.rooms,
    allTables: state.calendar.roomsAndTables.filter(
      (roomOrTable) => roomOrTable.tableId !== undefined,
    ),
    exportListToCsv: state.calendar.exportListToCsv,
    notesLoading: state.notes.getNotesLoading,
    notes: state.notes.notes,
    connectionStatus: state.application.connectionStatus,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    getReservations: bindActionCreators(calendarActions.getReservations, dispatch),
    searchReservations: bindActionCreators(calendarActions.searchReservations, dispatch),
    changeReservationsPage: bindActionCreators(calendarActions.changeReservationsPage, dispatch),
    setReservationListStatistics: bindActionCreators(
      calendarActions.setReservationListStatistics,
      dispatch,
    ),
    updateExportListToCsv: bindActionCreators(calendarActions.updateExportListToCsv, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationsList);
