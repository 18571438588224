import React, { useEffect } from 'react';
import CustomTimeline from 'app/components/CustomTimeline';
import 'app/components/CustomReactCalendarTimeline/lib/Timeline.scss';
import { connect } from 'react-redux';
import { AppState } from 'types';
import { isInStandaloneMode } from 'utils/makeStyles';
import { LinearProgress } from '@mui/material';
import HeaderControls from 'app/components/CalendarControls/HeaderControls';
import { CalendarSettings, Restaurant, TimelineReservation } from 'types/calendar';
import Sidebar from 'app/components/Sidebar';
import SidebarStatistics from 'app/components/Sidebar/SidebarStatistics';
import { Statuses } from 'types/reservation';
import { filterReservationsForTime } from 'utils/reservations';
import { SidebarCardLoader } from 'app/components/Sidebar/SidebarCardLoader';
import SidebarReservation from 'app/components/Sidebar/SidebarReservation';
import { Note } from 'types/notes';
import NotesCard from 'app/components/Notes/NotesCard';
import { reloadAfterHidden } from 'utils/deviceHelper';
import { Box } from '@mui/material';
import moment from 'moment';
import { TogglePastReservationsButton } from 'app/components/TogglePastReservationsButton';
import { filterNotes } from 'utils/filterNotes';

interface StateProps {
  currentTime: moment.Moment;
  restaurant: Restaurant;
  notesLoading: boolean;
  notes: Note[];
  isLoading: boolean;
  reservations: TimelineReservation[];
  calendarSettings: CalendarSettings;
}

const CalendarPage: React.FC<StateProps> = ({
  restaurant,
  notesLoading,
  notes,
  isLoading,
  reservations,
  calendarSettings,
  currentTime,
}) => {
  const [reservationList, setReservationList] = React.useState<TimelineReservation[]>([]);
  const [searchPhrase, setSearchPhrase] = React.useState('');
  const [showPast, setShowPast] = React.useState(false);

  const currentTimeWithTimezone = moment(currentTime).tz(restaurant.settings.timezone);
  const isCalendarDateSameAsCurrentTime = calendarSettings.date.isSame(
    currentTimeWithTimezone,
    'day',
  );

  useEffect(() => {
    const disabledStatuses = [Statuses.Blocked, Statuses.Template];

    if (!restaurant.settings.list_show_cancelled) {
      disabledStatuses.push(Statuses.Cancelled);
    }
    if (!restaurant.settings.list_show_walk_ins) {
      disabledStatuses.push(Statuses.WalkIn);
    }

    const parsedList = filterReservationsForTime(
      reservations,
      calendarSettings.startTime,
      calendarSettings.endTime,
    )
      .filter(
        (r) =>
          //search filter
          (searchPhrase.length <= 0 ||
            r.title.toLowerCase().includes(searchPhrase.toLowerCase())) &&
          //status filter
          !disabledStatuses.includes(r.reservation.status),
      )
      .sort((a, b) => (a.reservation.startTime.isAfter(b.reservation.startTime) ? 1 : -1)); //sort

    setReservationList(parsedList);
  }, [restaurant, reservations, calendarSettings, searchPhrase]);

  useEffect(() => {
    let visibilityChange: string = '';
    const isStandalone = isInStandaloneMode();

    if (typeof document.hidden !== 'undefined') {
      visibilityChange = 'visibilitychange';
    }
    // @ts-ignore
    else if (typeof document.mozHidden !== 'undefined') {
      visibilityChange = 'mozvisibilitychange';
    }
    // @ts-ignore
    else if (typeof document.msHidden !== 'undefined') {
      visibilityChange = 'msvisibilitychange';
    }
    // @ts-ignore
    else if (typeof document.webkitHidden !== 'undefined') {
      visibilityChange = 'webkitvisibilitychange';
    }

    if (isStandalone) {
      document.addEventListener(visibilityChange, reloadAfterHidden, false);
    }

    return () => {
      if (isStandalone) {
        document.removeEventListener(visibilityChange, reloadAfterHidden, false);
      }
    };
  }, []);

  const todayCurrentReservations = reservationList.filter((reservation) => {
    if (reservation.reservation.endTime && !showPast && isCalendarDateSameAsCurrentTime) {
      return currentTimeWithTimezone.isBefore(reservation.reservation.endTime);
    }
    return reservation;
  });

  const pastReservationsExist = reservationList.some((filteredReservation) => {
    if (filteredReservation.reservation.endTime && isCalendarDateSameAsCurrentTime) {
      return currentTimeWithTimezone?.isAfter(filteredReservation.reservation.endTime);
    }
    return false;
  });

  const showButton =
    isCalendarDateSameAsCurrentTime && !calendarSettings.closed && pastReservationsExist;
  const weekDayNumber = calendarSettings.date.days();
  const filteredNotes = filterNotes(weekDayNumber, notes);

  return (
    <Box display="flex" flexDirection="column" flex={1} p={2} className="tablein-calendar">
      <HeaderControls
        enableDisplaySwitch
        enableToggleSwitch
        isRestaurantClosed={calendarSettings.closed}
      />
      {(isLoading || !restaurant?.nid) && <LinearProgress color="secondary" />}
      <Box display="flex" flex={1}>
        {restaurant?.nid && <CustomTimeline />}
        <Sidebar
          search
          searchPhrase={searchPhrase}
          onSearchChange={(value) => setSearchPhrase(value)}
          disableCardStyling
        >
          <SidebarStatistics isLoading={isLoading} reservations={reservations} variant="card" />
          <TogglePastReservationsButton
            sx={{
              marginBottom: '10px',
            }}
            isLoading={isLoading}
            setShowPast={setShowPast}
            showButton={showButton}
            showPast={showPast}
          />
          {notesLoading ? (
            <SidebarCardLoader />
          ) : (
            filteredNotes.map((n) => <NotesCard note={n} key={n.id} />)
          )}
          {isLoading ? (
            <SidebarCardLoader />
          ) : (
            todayCurrentReservations.map((r) => (
              <SidebarReservation reservation={r} key={r.id} variant="card" />
            ))
          )}
        </Sidebar>
      </Box>
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    currentTime: state.application.currentTime,
    restaurant: state.calendar.restaurant,
    calendarSettings: state.calendar.calendarSettings,
    isLoading: state.calendar.reservationsIsLoading,
    reservations: state.calendar.reservations,
    notes: state.notes.notes,
    notesLoading: state.notes.getNotesLoading,
  };
}

export default connect(mapStateToProps)(CalendarPage);
