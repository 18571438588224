import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Locize from 'i18next-locize-backend';
import store from './redux/store'

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const isProduction = process.env.NODE_ENV === 'production';

const fetchLang = () => {
  let prevLang: null|string = null;

  store.subscribe(async () => {
    const lang = store.getState().calendar.locale;

    if (lang && lang !== prevLang) {
      prevLang = lang;
      await i18n.changeLanguage(lang);
    }
  });
};

i18n
  .use(Locize)
  .use(initReactI18next)
  .init({
    debug: false,
    returnNull: false,
    fallbackLng: 'en',
    lng: 'en',
    backend: {
      projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
      apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
      referenceLng: process.env.REACT_APP_LOCIZE_REF_LANG,
    },
    saveMissing: !isProduction,
  });

fetchLang();

export default i18n;
