import React, { ReactNode } from 'react';
import ReservationListPage from 'app/pages/ReservationList/ReservationListPage';
import { useScreenSize } from 'hooks/useScreenSize';

interface OwnProps {
  children: ReactNode;
}
export const MobileReservationList = ({ children }: OwnProps) => {
  const { isMobile } = useScreenSize();

  return <>{isMobile ? <ReservationListPage /> : children}</>;
};
