export const dbFullImport: any = {
  database : "db-full",
  version : 1,
  encrypted : false,
  mode : "full",
  tables :[
    {
      name: "reservations",
      schema: [
        {column:"id", value: "INTEGER PRIMARY KEY NOT NULL"},
        {column:"title", value:"TEXT NOT NULL"},
        {column: "last_modified", value: "INTEGER DEFAULT (strftime('%s', 'now'))"},
        {column:"status", value:"TEXT NOT NULL"},
        {column:"startTime", value:"TEXT NOT NULL"},
        {column:"endTime", value:"TEXT NOT NULL"},
        {column:"covers", value:"INTEGER"},
        {column:"origin", value:"TEXT NOT NULL"},
        {column:"payments", value:"TEXT NOT NULL"},
        {column:"currencies", value:"TEXT NOT NULL"},
        {column:"tags", value:"TEXT NOT NULL"},
        {column:"dinningRooms", value:"TEXT NOT NULL"},
        {column:"arrivedCount", value:"INTEGER"},
        {column:"cancelledCount", value:"TEXT NOT NULL"},
        {column:"clientId", value:"TEXT NOT NULL"},
        {column:"lastVisit", value:"TEXT NOT NULL"},
        {column:"noShowCount", value:"TEXT NOT NULL"},
        {column:"rateAmbience", value:"INTEGER"},
        {column:"rateCleanliness", value:"INTEGER"},
        {column:"rateFood", value:"INTEGER"},
        {column:"rateService", value:"INTEGER"},
        {column:"rateComment", value:"TEXT"},
        {column:"feedbackId", value:"INTEGER"},
        {column:"feedbackTitle", value:"TEXT"},
        {column:"feedbackCount", value:"INTEGER"},
        {column:"average", value:"INTEGER"},
        {column:"entityId", value:"INTEGER"},
        {column:"type", value:"TEXT"},
      ],
      indexes: [
        {name: "index_title",value: "title"},
        {name: 'index_title_on_last_modified', value: 'last_modified DESC' },
      ],
    },
    {
      name: "tables",
      schema: [
          {column:"reservationId", value: "INTEGER NOT NULL"},
          {column:"tableIds", value:"TEXT NOT NULL"},
      ]
    },
    {
      name: "clients",
      schema: [
        {column:"reservationId", value: "INTEGER NOT NULL"},
        {column:"big_spender", value:"TEXT"},
        {column:"changed", value:"INTEGER NOT NULL"},
        {column:"clientDetails", value:"TEXT"},
        {column:"company", value:"TEXT"},
        {column:"created", value:"INTEGER NOT NULL"},
        {column:"email", value:"TEXT NOT NULL"},
        {column:"name", value:"TEXT NOT NULL"},
        {column:"nid", value:"INTEGER NOT NULL"},
        {column:"phone", value:"TEXT NOT NULL"},
        {column:"subscribed", value:"TEXT"},
        {column:"vip", value:"TEXT"},
      ]
    },
    {
      name: "currencies",
      schema: [
        {column:"reservationId", value: "INTEGER NOT NULL"},
        {column:"currency", value: "TEXT"},
        {column:"totalValue", value: "INTEGER"},
      ]
    },
    {
      name: "special_offers",
      schema: [
        {column:"reservationId", value: "INTEGER NOT NULL"},
        {column:"specialOfferIds", value:"TEXT NOT NULL"},
      ]
    },
    {
      name: "comments",
      schema: [
        {column:"reservationId", value: "INTEGER NOT NULL"},
        {column:"changed", value:"INTEGER NOT NULL"},
        {column:"commentBody", value:"TEXT NOT NULL"},
        {column:"id", value: "INTEGER PRIMARY KEY NOT NULL"},
        {column:"uid", value:"INTEGER NOT NULL"},
      ]
    },
    {
      name: "floor_plan",
      schema: [
        {column:"restaurantId", value: "INTEGER"},
      ]
    },
    {
      name: "floor_plan_rooms",
      schema: [
        {column:"changed", value:"INTEGER"},
        {column:"collapsed", value:"TEXT"},
        {column:"created", value:"INTEGER"},
        {column:"language", value:"TEXT"},
        {column:"nid", value:"INTEGER"},
        {column:"status", value:"TEXT"},
        {column:"title", value:"TEXT"},
      ]
    },
    {
      name: "floor_plan_tables",
      schema: [
        {column:"covers_min", value:"INTEGER"},
        {column:"covers_max", value:"INTEGER"},
        {column:"entity_id", value:"INTEGER"},
        {column:"bundle", value:"TEXT"},
        {column:"restaurant_id", value:"INTEGER"},
        {column:"x", value:"INTEGER"},
        {column:"y", value:"INTEGER"},
        {column:"angle", value:"INTEGER"},
        {column:"width", value:"INTEGER"},
        {column:"height", value:"INTEGER"},
        {column:"shape", value:"TEXT"},
        {column:"nid", value:"INTEGER"},
        {column:"title", value:"TEXT"},
        {column:"language", value:"TEXT"},
        {column:"status", value:"TEXT"},
        {column:"created", value:"INTEGER"},
        {column:"changed", value:"INTEGER"},
        {column:"room_id", value:"INTEGER"},
      ]
    },
    {
      name: "floor_plan_walls",
      schema: [
        {column:"id", value:"INTEGER"},
        {column:"type", value:"TEXT"},
        {column:"restaurant_id", value:"INTEGER"},
        {column:"label", value:"TEXT"},
        {column:"entity_id", value:"INTEGER"},
        {column:"bundle", value:"TEXT"},
        {column:"x", value:"INTEGER"},
        {column:"y", value:"INTEGER"},
        {column:"angle", value:"INTEGER"},
        {column:"width", value:"INTEGER"},
        {column:"height", value:"INTEGER"},
        {column:"shape", value:"TEXT"},
      ]
    },
    {
      name: "floor_plan_texts",
      schema: [
        {column:"id", value:"INTEGER"},
        {column:"type", value:"TEXT"},
        {column:"restaurant_id", value:"INTEGER"},
        {column:"label", value:"TEXT"},
        {column:"entity_id", value:"INTEGER"},
        {column:"bundle", value:"TEXT"},
        {column:"x", value:"INTEGER"},
        {column:"y", value:"INTEGER"},
        {column:"angle", value:"INTEGER"},
        {column:"width", value:"INTEGER"},
        {column:"height", value:"INTEGER"},
        {column:"shape", value:"TEXT"},
    ]
    },
    {
      name: "restaurant",
      schema: [
        {column:"id", value:"INTEGER"},
        {column:"country", value:"TEXT"},
        {column:"changed", value:"INTEGER"},
        {column:"created", value:"INTEGER"},
        {column:"language", value:"TEXT"},
        {column:"status", value:"TEXT"},
        {column:"title", value:"TEXT"},
      ]
    },
    {
      name: "restaurant_settings",
      schema: [
        {column:"restaurant_id", value:"INTEGER"},
        {column:"language", value:"TEXT"},
        {column:"time_zone", value:"TEXT"},
        {column:"date_format", value:"TEXT"},
        {column:"first_day_of_week", value:"INTEGER"},
        {column:"opening_hours", value:"BLOB"},
        {column:"exclusive_days", value:"BLOB"},
        {column:"increment", value:"TEXT"},
        {column:"list_show_cancelled", value:"TEXT"},
        {column:"list_show_walk_ins", value:"TEXT"},
        {column:"show_big_spender_tag", value:"TEXT"},
      ]
    },
    {
      name: "full_tables",
      schema: [
        {column:"id", value:"TEXT"},
        {column:"covers_max", value:"INTEGER"},
        {column:"covers_min", value:"INTEGER"},
        {column:"parent", value:"INTEGER"},
        {column:"root", value:"TEXT"},
        {column:"tableId", value:"INTEGER"},
        {column:"title", value:"TEXT"},
      ]
    },
    {
      name: "rooms",
      schema: [
        {column:"id", value:"TEXT"},
        {column:"collapsed", value:"TEXT"},
        {column:"roomId", value:"INTEGER"},
        {column:"root", value:"TEXT"},
        {column:"title", value:"TEXT"},
      ]
    },
    {
      name: "rooms_and_tables",
      schema: [
        {column:"changed", value:"INTEGER"},
        {column:"collapsed", value:"TEXT"},
        {column:"created", value:"INTEGER"},
        {column:"language", value:"TEXT"},
        {column:"id", value:"TEXT"},
        {column:"status", value:"TEXT"},
        {column:"tables", value:"BLOB"},
        {column:"title", value:"TEXT"},
      ]
    },
    {
      name: "notes",
      schema: [
        {column:"color", value:"TEXT"},
        {column:"days", value:"BLOB"},
        {column:"days_excluded", value:"BLOB"},
        {column:"end_date", value:"TEXT"},
        {column:"id", value:"INTEGER"},
        {column:"name", value:"TEXT"},
        {column:"restaurant_id", value:"INTEGER"},
        {column:"start_date", value:"TEXT"},
        {column:"text", value:"TEXT"},
        {column:"user_id", value:"INTEGER"},
      ]
    }
  ]
};