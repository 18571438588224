import React, { useState, useEffect } from 'react';
import Drawer from '../Drawer';
import DrawerCloseButton from '../Drawer/DrawerCloseButton';
import DrawerHeading from '../Drawer/DrawerHeading';
import DrawerContent from '../Drawer/DrawerContent';
import { AppState } from 'types';
import calendarActions from 'redux/actions/calendar';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import RoomOpeningHours from './RoomOpeningHours';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { CalendarSettings, Restaurant, Room, Settings } from 'types/calendar';
import './OpeningHours.scss';
import { Button, Box, Grid, Typography, Divider } from '@mui/material';
import AppConnectionStatusWrapper from '../AppConnectionStatusWrapper/AppConnectionStatusWrapper';

export interface BlockReservationHours {
  restaurantId?: number;
  hours: NewTime;
  currentDate: string;
}

export interface NewTime {
  [roomId: number]: string[];
}

interface OwnProps {
  onClose: () => void;
  open: boolean;
}

interface DispatchProps {
  blockReservationHours: (data: BlockReservationHours) => void;
}

interface StateProps {
  rooms: Room[];
  calendarSettings: CalendarSettings;
  restaurant: Restaurant;
  restaurantSettings: Settings;
}

type Props = OwnProps & DispatchProps & StateProps;

const OpeningHoursDrawer: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [blockedHours, setBlockedHours] = useState<NewTime>({});
  const [drawerContent, setDrawerContent] = useState<any>(null);
  const [submitButton, setSubmitButton] = useState<any>(null);
  const [bulkControls, setBulkControls] = useState<any>(null);

  useEffect(() => {
    let tempBlockedHours = {};
    let hasCustomSettings = false;
    let weekDayNumber = props.calendarSettings.date.days();

    const specificTimes = props.restaurant.cumoWidgetSpecificTimes.times;

    for (let key in specificTimes) {
      if (!!specificTimes[key] && key === props.calendarSettings.date.format('YYYY-MM-DD')) {
        hasCustomSettings = true;
        tempBlockedHours = specificTimes[key];
      }
    }

    if (!hasCustomSettings) {
      props.restaurant.cumoWidgetTimes.forEach((time) => {
        if (time.weekday === weekDayNumber && !!time.room) {
          tempBlockedHours = {
            ...tempBlockedHours,
            [time.room.nid]: time.times,
          };
        }
      });
    }

    setBlockedHours(tempBlockedHours);
  }, [
    props.restaurant.cumoWidgetSpecificTimes,
    props.restaurant.cumoWidgetTimes,
    props.calendarSettings.date,
  ]);

  useEffect(() => {
    setDrawerContent(
      props.calendarSettings.closed
        ? 'Restaurant is closed'
        : props.rooms.map((room) => (
            <RoomOpeningHours
              room={room}
              blockedHours={blockedHours}
              key={room.nid}
              toggle={toggleHour}
              selectAll={selectAll}
              toggleAll={toggleAll}
              unselectAll={unselectAll}
            />
          )),
    );
    // eslint-disable-next-line
  }, [props.rooms, props.calendarSettings.closed, blockedHours]);

  useEffect(() => {
    setSubmitButton(() => {
      return !props.calendarSettings.closed ? (
        <Button variant={'contained'} color={'secondary'} onClick={submitChanges} size={'small'}>
          {t('Save')}
        </Button>
      ) : (
        ''
      );
    });

    setBulkControls(() => {
      return !props.calendarSettings.closed ? (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item>
            <Typography
              variant={'body1'}
              sx={{ color: 'navyBlue', cursor: 'pointer' }}
              onClick={() => selectAll(props.rooms)}
            >
              {t('Select all')}
            </Typography>
          </Grid>

          <Grid item>
            <Divider orientation={'vertical'} />
          </Grid>

          <Grid item>
            <Typography
              variant={'body1'}
              sx={{ color: 'navyBlue', cursor: 'pointer' }}
              onClick={() => toggleAll(props.rooms)}
            >
              {t('openingHoursToggle')}
            </Typography>
          </Grid>

          <Grid item>
            <Divider orientation={'vertical'} />
          </Grid>

          <Grid item>
            <Typography
              variant={'body1'}
              sx={{ color: 'navyBlue', cursor: 'pointer' }}
              onClick={() => unselectAll(props.rooms)}
            >
              {t('openingHoursUnselectAll')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        ''
      );
    });
    // eslint-disable-next-line
  }, [props.rooms, props.calendarSettings, blockedHours]);

  function toggleHour(roomId: number, time: string, isBlocked: boolean) {
    let newTime: NewTime = { ...blockedHours };
    if (isBlocked) {
      const index = newTime[roomId].indexOf(time);

      if (index > -1) {
        newTime[roomId].splice(index, 1);
      }
    } else {
      !!newTime[roomId] ? newTime[roomId].push(time) : (newTime[roomId] = [time]);

      // Nothig to sort if there is less than two element in array.
      if (newTime[roomId].length > 1) {
        // Sort times in the same order as opening hours.
        // Can't use normal sort because times from next day (for example 00:30)
        // will be at the beginning of the array. They needs to be after 23:59.
        const times: string[] = [];
        const startTime = moment(props.calendarSettings.startTime);
        const endTime = props.calendarSettings.endTime;

        while (startTime <= endTime) {
          if (newTime[roomId].includes(startTime.format('HH:mm'))) {
            times.push(startTime.format('HH:mm'));
          }
          startTime.add(props.restaurantSettings.increment, 'minutes');
        }

        newTime[roomId] = times;
      }
    }

    setBlockedHours(newTime);
  }

  function selectAll(rooms: Room[]) {
    const newTime: NewTime = { ...blockedHours };
    const timesCollection: string[] = [];
    const startTime = moment(props.calendarSettings.startTime);
    const endTime = props.calendarSettings.endTime;

    while (startTime <= endTime) {
      timesCollection.push(startTime.format('HH:mm'));
      startTime.add(props.restaurantSettings.increment, 'minutes');
    }

    rooms.forEach((room) => {
      newTime[room.nid] = timesCollection;
    });

    setBlockedHours(newTime);
  }

  function unselectAll(rooms: Room[]) {
    const newTime: NewTime = { ...blockedHours };

    rooms.forEach((room) => {
      newTime[room.nid] = [];
    });

    setBlockedHours(newTime);
  }

  function toggleAll(rooms: Room[]) {
    const newTime: NewTime = { ...blockedHours };

    rooms.forEach((room) => {
      const startTime = moment(props.calendarSettings.startTime);
      const endTime = props.calendarSettings.endTime;

      while (startTime <= endTime) {
        if (!!newTime[room.nid] && newTime[room.nid].includes(startTime.format('HH:mm'))) {
          const index = newTime[room.nid].indexOf(startTime.format('HH:mm'));

          if (index > -1) {
            newTime[room.nid].splice(index, 1);
          }
        } else {
          !!newTime[room.nid]
            ? newTime[room.nid].push(startTime.format('HH:mm'))
            : (newTime[room.nid] = [startTime.format('HH:mm')]);
        }

        startTime.add(props.restaurantSettings.increment, 'minutes');
      }
    });

    setBlockedHours(newTime);
  }

  function submitChanges() {
    const data: BlockReservationHours = {
      restaurantId: props.restaurant.nid,
      hours: blockedHours,
      currentDate: props.calendarSettings.date.format('YYYY-MM-DD'),
    };
    props.blockReservationHours(data);
    props.onClose();
  }

  return (
    <Drawer className="drawer" anchor="right" open={props.open} onClose={props.onClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1rem',
          gap: '20px',
          paddingTop: 'var(--safe-area-inset-top)',
        }}
      >
        <DrawerHeading>
          <Typography variant={'h6'}>
            {t('openingHoursTitle')} ({props.calendarSettings.date.format('YYYY-MM-DD')})
          </Typography>
          {bulkControls}
        </DrawerHeading>
        <DrawerCloseButton onClick={props.onClose} />
      </Box>
      <DrawerContent>
        {drawerContent}
        <AppConnectionStatusWrapper>{submitButton}</AppConnectionStatusWrapper>
      </DrawerContent>
    </Drawer>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    rooms: state.calendar.rooms,
    calendarSettings: state.calendar.calendarSettings,
    restaurant: state.calendar.restaurant,
    restaurantSettings: state.calendar.restaurant.settings,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    blockReservationHours: bindActionCreators(calendarActions.blockReservationHours, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OpeningHoursDrawer);
