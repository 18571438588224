import React from 'react';
import { AppState } from 'types';
import { connect } from 'react-redux';
import RoomOpeningHoursItem from '../RoomOpeningHoursItem';
import { NewTime } from '../index';
import moment, { Moment } from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { CalendarSettings, Room, Settings } from 'types/calendar';
import { Divider, Grid, Typography } from '@mui/material';

interface OwnProps {
  room: Room;
  blockedHours: NewTime;
  toggle: (roomId: number, time: string, isBlocked: boolean) => void;
  selectAll: (room: Room[]) => void;
  toggleAll: (room: Room[]) => void;
  unselectAll: (room: Room[]) => void;
}

interface StateProps {
  calendarSettings: CalendarSettings;
  restaurantSettings: Settings;
}

type Props = OwnProps & StateProps;

const RoomOpeningHours: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const startTime = moment(props.calendarSettings.startTime);
  const endTime = props.calendarSettings.endTime;
  const hours: Moment[] = [];

  while (startTime <= endTime) {
    hours.push(moment(startTime));
    startTime.add(props.restaurantSettings.increment, 'minutes');
  }

  return (
    <div className="exclude-hours-picker">
      <p className="exclude-hours-picker__title">{props.room.title}</p>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item>
          <Typography
            variant={'body1'}
            sx={{ color: 'navyBlue', cursor: 'pointer' }}
            onClick={() => props.selectAll([props.room])}
          >
            {t('Select all')}
          </Typography>
        </Grid>
        <Grid item>
          <Divider orientation={'vertical'} />
        </Grid>
        <Grid item>
          <Typography
            variant={'body1'}
            sx={{ color: 'navyBlue', cursor: 'pointer' }}
            onClick={() => props.toggleAll([props.room])}
          >
            {t('Toggle')}
          </Typography>
        </Grid>
        <Grid item>
          <Divider orientation={'vertical'} />
        </Grid>
        <Grid item>
          <Typography
            variant={'body1'}
            sx={{ color: 'navyBlue', cursor: 'pointer' }}
            onClick={() => props.unselectAll([props.room])}
          >
            {t('openingHoursUnselectAll')}
          </Typography>
        </Grid>
      </Grid>
      {hours.map((hour) => (
        <RoomOpeningHoursItem
          roomName={props.room.title}
          roomId={props.room.nid}
          time={hour}
          blockedHours={props.blockedHours}
          key={hour.format('HH:mm')}
          toggle={props.toggle}
        />
      ))}
    </div>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    calendarSettings: state.calendar.calendarSettings,
    restaurantSettings: state.calendar.restaurant.settings,
  };
}

export default connect(mapStateToProps)(RoomOpeningHours);
