import React from 'react';
import { Card, CardContent, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

interface Props {
  topValue: string;
  bottomValue: string;
}

const TotalReservations:React.FC<Props> = ({ topValue, bottomValue }) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ backgroundColor: 'brandBlue', color: 'white' }} elevation={2}>
      <CardContent>
        <Typography fontSize={26}>{t('Congratulations!')} <span role="img" aria-labelledby="love">🥳</span></Typography>
        <Typography fontSize={14}>{t('Guests served with TableIn so far')}</Typography>
        <Typography fontSize={26} mt={4}>{topValue}</Typography>
        <Typography fontSize={14}>{bottomValue} {t('reservations')}</Typography>
        {/*<TrophyImg alt='trophy' src='/images/trophy.png' />*/}
      </CardContent>
    </Card>
  )
}

export default TotalReservations