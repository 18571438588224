import React, { useEffect, useRef, useState } from 'react';
import Header from '../Header';
import SystemInfo from '../System/SystemInfo';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import store from 'redux/store';
import applicationActions from 'redux/actions/application';
import authActions from 'redux/actions/auth';
import { LicenceType, UserSettings } from 'types/auth';
import { oldAppUrlGenerate } from 'utils/url';
import moment from 'moment';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import { connect } from 'react-redux';
import { Restaurant } from 'types/calendar';
import { AppConnectionStatus } from 'types/application';
import { initdb } from 'app/data/dataservice';
import { getAuthenticatedUserCall } from 'api/auth';
import { defineCustomElements as jeepSqlite, applyPolyfills } from 'jeep-sqlite/loader';
import './Authenticated.scss';
import { removeToken, removeTokenFromCookie, responseHasTokenError } from 'utils/tokenManagement';
import ConnectionListenerWrapper from '../ConnectionListenerWrapper';

interface DispatchProps {
  getRestaurant: (_isOffline?: boolean | null) => void;
  getTables: (_isOffline?: boolean | null) => void;
  updateTime: (time: moment.Moment) => void;
  updateConnectionStatus: (connectionStatus: AppConnectionStatus) => void;
  getUserSettings: () => void;
}

interface StateProps {
  userSettings: UserSettings;
  restaurant: Restaurant;
  connectionStatus: AppConnectionStatus;
}

type Props = DispatchProps & StateProps;

store.dispatch<any>(applicationActions.getLanguages());

applyPolyfills().then(async () => {
  await jeepSqlite(window);
});

const Authenticated = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const clockRef = useRef<any>(undefined);
  const { isOffline } = props.connectionStatus;
  const navigate = useNavigate();
  const location = useLocation();
  const hasSettings = location.pathname.includes('settings');

  useEffect(() => {
    getAuthenticatedUserCall()
      .then(async () => {
        setIsLoading(false);
        props.getUserSettings();
      })
      .then(() => {
        props.getRestaurant(isOffline);
        props.getTables(isOffline);
      })
      .then(async () => {
        await initdb(isOffline);
      })
      .catch((error) => {
        const responseCode = error?.data?.status || error?.status;
        if (responseHasTokenError(error)) {
          removeTokenFromCookie();
          removeToken();
          navigate('/login');
        }
        if (error?.code >= 500 || responseCode >= 500) {
          store.dispatch({
            type: 'Application.SetConnectionStatus',
            payload: {
              connectionStatus: {
                serverErrorCode: responseCode,
                isOffline: true,
              },
            },
          });
        }
      });
  }, [isOffline]);

  // Make sure that user have active license and redirect to the settings if have not.
  useEffect(() => {
    if (
      props.userSettings.activeLicense &&
      // eslint-disable-next-line
      props.userSettings.activeLicense.type == LicenceType.nolicence
    ) {
      window.location.href = oldAppUrlGenerate('/settings', false);
    }
  }, [props.userSettings]);

  // Update website title.
  useEffect(() => {
    if (props.restaurant.title) {
      document.title = props.restaurant.title;
    }
  }, [props.restaurant]);

  useEffect(() => {
    clockRef.current = setInterval(() => {
      props.updateTime(moment());
    }, 60000);

    return () => {
      clearInterval(clockRef.current);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="tablein-app">
      {!isLoading && (
        <>
          {!hasSettings && <Header />}
          <SystemInfo />
          <ConnectionListenerWrapper>
            <Outlet />
          </ConnectionListenerWrapper>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    userSettings: state.auth.userSettings,
    restaurant: state.calendar.restaurant,
    connectionStatus: state.application.connectionStatus,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    getRestaurant: bindActionCreators(calendarActions.getRestaurant, dispatch),
    getTables: bindActionCreators(calendarActions.getTables, dispatch),
    updateTime: bindActionCreators(applicationActions.updateTime, dispatch),
    updateConnectionStatus: bindActionCreators(applicationActions.updateConnectionStatus, dispatch),
    getUserSettings: bindActionCreators(authActions.getUserSettings, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
