import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnauthorizedWrapper } from '../../components/UnauthorizedWrapper';
import { Network } from '@capacitor/network';
import { LoginForm } from '../../components/LoginForm';
import { Navigate } from 'react-router-dom';
import { getToken } from "../../../utils/tokenManagement";

const LoginPage = () => {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      await Network.removeAllListeners();
      getToken() && setLoggedIn(true);
    })();
  }, []);
  
  if (isLoggedIn) return (
    <Navigate to="/calendar" />
  );

  return (
    <UnauthorizedWrapper
      title={t('loginTitle')}
      subtitle={t('loginSubtitle')}
    >
      <LoginForm onSuccess={() => setLoggedIn(true)}/>
    </UnauthorizedWrapper>
  );
};

export default LoginPage;
