import React, { useEffect } from 'react';
import { Select, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Restaurant, Waiter } from 'types/calendar';
import calendarActions from 'redux/actions/calendar';

interface OwnProps {
  isReadOnly?: boolean;
}

interface StateProps {
  waiter: Waiter;
  originalWaiter: Waiter;
  restaurant: Restaurant;
}

interface DispatchProps {
  updateWaiter: (waiter: Waiter) => void;
  getRestaurantWaiters: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const formControlStyles = {
  width: { xs: '100%', md: '150px' },
};

const WaiterSelect: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const updateWaiter = (event: SelectChangeEvent) => {
    if (event.target.value === '') {
      props.updateWaiter({ tid: '_none' });
    } else {
      props.updateWaiter({ tid: event.target.value });
    }
  };

  const Options = [
    { tid: '_none', name: t('bookingModalWaiterSelect') },
    ...props.restaurant.waiters,
    props.originalWaiter,
  ]
    .filter(
      (waiter, index, self) =>
        index === self.findIndex((t) => t.tid.toString() === waiter.tid.toString()),
    )
    .map((waiter) => (
      <MenuItem value={waiter.tid} key={waiter.tid}>
        {waiter.name}
      </MenuItem>
    ));

  useEffect(() => {
    if (!props.restaurant.waiters.length) {
      props.getRestaurantWaiters();
    }
  }, []);

  return (
    <FormControl variant="outlined" size="small" sx={formControlStyles}>
      <Select
        disabled={props.isReadOnly}
        id="offer-select-select"
        value={props.waiter.tid}
        onChange={updateWaiter}
        MenuProps={{
          classes: {
            paper: 'waiters-dropdown',
          },
        }}
      >
        {Options}
      </Select>
    </FormControl>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    waiter: state.reservation.waiter,
    originalWaiter: state.reservation.originalWaiter,
    restaurant: state.calendar.restaurant,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateWaiter: bindActionCreators(reservationActions.updateWaiter, dispatch),
    getRestaurantWaiters: bindActionCreators(calendarActions.getRestaurantWaiters, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WaiterSelect);
