import React from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';

interface StatsGroupCardProps {
  title: string;
  value: number | string;
  subvalue?: number | string;
  stats?: {
    title: string;
    icon?: any;
    value: {
      guests_online: number;
      guests_phone: number;
      guests_walk_in: number;
      guests_no_show: number;
      guests_cancelled: number;
    }[];
    primaryColor: string;
    secondaryColor: string;
  }[];
}

const StatsGroupCard = ({ title, value, subvalue, stats, ...rest }: StatsGroupCardProps) => {
  return (
    <Card elevation={6} sx={{ height: '100%' }}>
      <CardContent>
        <Stack direction="column" spacing={2}>
          <Typography fontSize={21} fontWeight={400} color="brandBlueGray">
            {title}
          </Typography>
          <Stack direction="column">
            <Typography fontSize={32} fontWeight={500} color="brandBlueGray" mt={3}>
              {value}
            </Typography>
            {subvalue && (
              <Typography fontSize={14} fontWeight={400} color="brandGray">
                {subvalue}
              </Typography>
            )}
          </Stack>

          {stats &&
            stats.map((stat) => (
              <Stack direction="row" spacing={2} key={stat.title}>
                <Box
                  p={2}
                  borderRadius={2}
                  display="flex"
                  alignSelf="start"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor={stat.primaryColor}
                  color={stat.secondaryColor}
                >
                  {stat.icon}
                </Box>
                <Stack justifyContent="center">
                  <Typography fontSize={14} fontWeight={500} color="brandBlueGray">
                    {stat.title}
                  </Typography>
                  <Typography fontSize={14} fontWeight={400} color="brandGray">
                    {/* @ts-ignore */}
                    {stat.value}
                  </Typography>
                </Stack>
              </Stack>
            ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default StatsGroupCard;
