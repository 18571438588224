import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { ProgressOverlay } from '../ProgressOverlay';
import LogoDark from 'images/tablein-logo-dark.png';
import { useSelector } from 'react-redux';
import { AppState } from 'types';

type Props = {
  title: string;
  subtitle: string;
  children: ReactNode;
};

export const UnauthorizedWrapper = ({ title, subtitle, children }: Props) => {
  const mode = useSelector((state: AppState) => state.theme.mode);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'background.default',
        position: 'relative',
      }}
    >
      <Box
        mt={6}
        mb={6}
        mr={3}
        ml={3}
        sx={{
          width: { xs: '100%', sm: '450px' },
        }}
      >
        <Box display="flex" justifyContent="center" mb={1}>
          <img src={LogoDark} alt="Tablein logo" width={150} />
        </Box>
        <Box
          sx={{
            pt: 5,
            pb: 5,
            pl: 4,
            pr: 4,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'brandWhite',
            borderRadius: '10px',
          }}
        >
          <Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5" mb={1} fontSize={24} fontWeight={600} color="navyBlue">
                {title}
              </Typography>
              <Typography color="waterloo">{subtitle}</Typography>
            </Box>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
