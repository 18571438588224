import { AppState } from '../types';
import { useSelector } from 'react-redux';

export const useStatus = () => {
  const isReadOnlyUser = useSelector((state: AppState) => state.auth.userSettings.readOnly);
  const status = useSelector((state: AppState) => state.system.status);
  const { isOffline, isServerAvailable } = useSelector((state: AppState) => state.application.connectionStatus);
  const isReadOnlySystemStatus = status === "read_only"
  const isReadOnly = isReadOnlyUser || isReadOnlySystemStatus || isOffline || !isServerAvailable;

  return {
    isServerAvailable,
    isOffline,
    status,
    isReadOnlyUser,
    isReadOnly,
    isReadOnlySystemStatus,
  };
};
