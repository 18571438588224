import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'types';
import { Statuses } from 'types/reservation';
import FilterStatuses from './FilterStatuses';
import { bindActionCreators, Dispatch } from 'redux';
import filtersActions from 'redux/actions/filters';
import calendarActions from 'redux/actions/calendar';
import FilterDiningRoom from './FilterDiningRoom';
import FilterActions from './FilterActions';
import FilterOptions from './FilterOptions';
import { FiltersOptions, FilterSortState, SelectedHours } from 'types/filters';
import FilterTime from './FilterTime';
import moment from 'moment';
import { CalendarSettings, ReservationListSettings, Room } from 'types/calendar';
import SidebarSection from '../Sidebar/SidebarSection';
import { useTranslation } from 'react-i18next';
import './SidebarFilters.scss';
// import FilterTag from "./FilterTag";
// import FilterSort from "./FilterSort";

interface StateProps {
  selectedStatuses: Statuses[];
  selectedRooms: number[];
  options: FiltersOptions[];
  rooms: Room[];
  selectedHours: SelectedHours;
  selectedTag: string | null;
  calendarSettings: CalendarSettings;
  reservationListSettings: ReservationListSettings;
  sort: FilterSortState;
}

interface DispatchProps {
  updateSelectedStatuses: (selectedStatuses: Statuses[]) => void;
  updateDiningRoomsFilter: (selectedRooms: number[]) => void;
  updateOptionsFilter: (options: FiltersOptions[]) => void;
  changeCalendarDate: (val: moment.Moment | number | string, shouldMoveSlider: boolean) => void;
  changeReservationListDateRange: (
    start: moment.Moment | null,
    end: moment.Moment | null,
    filters?: any,
    isOffline?: any,
  ) => void;
  updateSelectedHours: (selectedHours: SelectedHours) => void;
  updateTagFilter: (tag: string | null) => void;
  updateSortOption: (option: string) => void;
  updateSortDirection: (direction: string) => void;
  resetFilters: () => void;
}

type Props = StateProps & DispatchProps;

const SidebarFilters: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const handleResetFilters = () => {
    props.resetFilters();
    props.changeReservationListDateRange(null, null, {});
  };

  useEffect(() => {
    //Reset reservation list date range on unmount
    return () => {
      handleResetFilters();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={'sidebar-filters'}>
      <SidebarSection title={t('filtersTitle')}>
        <FilterTime
          selectedHours={props.selectedHours}
          updateSelectedHours={props.updateSelectedHours}
          changeCalendarDate={props.changeCalendarDate}
          // @ts-ignore
          changeSelectedDateRange={props.changeReservationListDateRange}
        />
      </SidebarSection>
      <SidebarSection>
        <FilterStatuses
          selectedStatuses={props.selectedStatuses}
          updateSelectedStatuses={props.updateSelectedStatuses}
        />
      </SidebarSection>
      {props.rooms.length > 0 && (
        <SidebarSection>
          <FilterDiningRoom
            rooms={props.rooms}
            selectedRooms={props.selectedRooms}
            updateSelectedRooms={props.updateDiningRoomsFilter}
          />
        </SidebarSection>
      )}
      <SidebarSection>
        <FilterOptions
          selectedOptions={props.options}
          updateSelectedOptions={props.updateOptionsFilter}
        />
      </SidebarSection>
      <FilterActions resetFilters={handleResetFilters} />
    </div>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    calendarSettings: state.calendar.calendarSettings,
    reservationListSettings: state.calendar.reservationListSettings,
    selectedStatuses: state.filters.selectedStatuses,
    selectedRooms: state.filters.dinningRooms,
    rooms: state.calendar.rooms,
    options: state.filters.options,
    selectedHours: state.filters.selectedHours,
    selectedTag: state.filters.tag,
    sort: state.filters.sort,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    changeCalendarDate: bindActionCreators(calendarActions.changeCalendarDate, dispatch),
    changeReservationListDateRange: bindActionCreators(
      calendarActions.changeReservationListDateRange,
      dispatch,
    ),
    updateSelectedHours: bindActionCreators(filtersActions.updateSelectedHours, dispatch),
    updateSelectedStatuses: bindActionCreators(filtersActions.updateSelectedStatuses, dispatch),
    updateDiningRoomsFilter: bindActionCreators(filtersActions.updateDiningRoomsFilter, dispatch),
    updateOptionsFilter: bindActionCreators(filtersActions.updateOptionsFilter, dispatch),
    updateTagFilter: bindActionCreators(filtersActions.updateTagFilter, dispatch),
    updateSortOption: bindActionCreators(filtersActions.updateSortOption, dispatch),
    updateSortDirection: bindActionCreators(filtersActions.updateSortDirection, dispatch),
    resetFilters: bindActionCreators(filtersActions.resetFilters, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarFilters);
