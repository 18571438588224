import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import calendarActions from 'redux/actions/calendar';
import reservationActions from 'redux/actions/reservation';
import { useTranslation } from 'react-i18next';
import { AppState } from 'types';
import { shouldPayForLateCancellationNoShow } from 'utils/reservations';
import { ReservationState, Statuses } from 'types/reservation';
import AppConnectionStatusWrapper from 'app/components/AppConnectionStatusWrapper/AppConnectionStatusWrapper';
import './CancelReservation.scss';
import { Button, Box } from '@mui/material';

interface DispatchProps {
  closeFullBookingModal: () => void;
  updateStatus: (status: Statuses) => void;
  saveReservation: () => void;
  openLateCancellationNoShowPaymentConfirmationModal: () => void;
}

interface StateProps {
  reservation: ReservationState;
}

interface OwnProps {
  reservationId: number;
}

type Props = DispatchProps & StateProps & OwnProps;

const CancelReservation: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState(false);

  const confirmedCancelReservation = () => {
    props.closeFullBookingModal();
    props.reservation.status = Statuses.Cancelled;

    Promise.resolve(props.updateStatus(Statuses.Cancelled)).then(() => {
      if (shouldPayForLateCancellationNoShow(props.reservation)) {
        props.openLateCancellationNoShowPaymentConfirmationModal();
      } else {
        props.saveReservation();
      }
    });
  };

  return (
    <AppConnectionStatusWrapper>
      {!confirm ? (
        <Button color="secondary" onClick={() => setConfirm(true)} sx={{ height: 'fit-content' }}>
          {t('bookingModalCancelReservation')}
        </Button>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItem: 'center', gap: '15px' }}>
          <Box>{t('bookingModalCancelReservation')}</Box>
          <Button variant={'contained'} color={'error'} onClick={confirmedCancelReservation}>
            {t('bookingModalCancelReservationConfirm')}
          </Button>
          <Button
            variant={'outlined'}
            onClick={() => setConfirm(false)}
            sx={(theme) => ({
              borderColor: theme.palette.brandYellow,
              color: theme.palette.brandYellow,
              '&:hover': {
                borderColor: theme.palette.brandYellow,
              },
            })}
          >
            {t('bookingModalCancelReservationDecline')}
          </Button>
        </Box>
      )}
    </AppConnectionStatusWrapper>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    reservation: state.reservation,
  };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    saveReservation: bindActionCreators(calendarActions.saveReservation, dispatch),
    closeFullBookingModal: bindActionCreators(calendarActions.closeFullBookingModal, dispatch),
    updateStatus: bindActionCreators(reservationActions.updateStatus, dispatch),
    openLateCancellationNoShowPaymentConfirmationModal: bindActionCreators(
      calendarActions.openLateCancellationNoShowPaymentConfirmationModal,
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelReservation);
