import React, { useCallback, useEffect, useState } from 'react';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import 'moment/locale/lt';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/lv';
import 'moment/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import calendarActions from 'redux/actions/calendar';
import { calculateOpenHoursInDay } from 'utils/dates';
import { Box, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TimePicker from '../TimePicker';
import { CalendarState, Restaurant } from 'types/calendar';
import { MuiDatePicker } from 'app/components/MuiDatePicker';
import './ReservationTime.scss';
import HeadingPrimary from 'app/components/common/HeadingPrimary/HeadingPrimary';
import { addTheBookingLengthToTheTimeAndSubtractOneSecond } from 'utils/addTheBookingLengthToTheTimeAndSubtractOneSecond';
import { useLocation } from 'react-router-dom';

interface OwnProps {
  isReadOnly?: boolean;
}

interface StateProps {
  locale: string;
  restaurant: Restaurant;
  startTime: moment.Moment;
  endTime: moment.Moment | string;
  calendar: CalendarState;
  defaultBookingLength: number;
}

interface DispatchProps {
  setLocale: () => void;
  updateStartTime: (time: moment.Moment) => void;
  updateEndTime: (time?: moment.Moment) => void;

  changeCalendarDate: (
    val: any,
    shouldMoveSlider: boolean,
    isOffline: boolean | null | undefined,
  ) => void;
  changeDateSlider: (val: any, changeFactor: number, move: boolean) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const ReservationTime: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [endTimeTouched, setEndTimeTouched] = useState<boolean>(false);
  const [datepickerSelectedDate, setDatepickerSelectedDate] = useState<moment.Moment | null>(null);
  const isEndTimeEmpty = props.endTime === 'empty';
  const isListPage = location.pathname.includes('list');

  const checkDisabledDay = (date: Moment) => {
    const { data } = calculateOpenHoursInDay(date, props.calendar);
    return !!data.closed;
  };

  useEffect(() => {
    if (props.locale === '') {
      props.setLocale();
    }
    if (isEndTimeEmpty) {
      setEndTimeWithBookingLength();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!endTimeTouched && isEndTimeEmpty) {
      setEndTimeWithBookingLength();
    }
    // eslint-disable-next-line
  }, [props.startTime]);

  const handleEndTimeUpdate = (endTime?: moment.Moment | undefined) => {
    setEndTimeTouched(true);
    props.updateEndTime(endTime);
  };

  const setEndTimeWithBookingLength = useCallback(() => {
    props.updateEndTime(
      addTheBookingLengthToTheTimeAndSubtractOneSecond(props.startTime, props.defaultBookingLength),
    );
    // eslint-disable-next-line
  }, [props.startTime]);

  const changeDate = (val: any, changeFactor = 0, move = true) => {
    setDatepickerSelectedDate(moment(val));
    props.changeCalendarDate(val, false, false);
    props.changeDateSlider(val, changeFactor, move);
  };

  // Determine the initial date for the datepicker. If a date has already been selected by the user,
  // use that date. If we're on the list page, use the start time from props.
  // Otherwise, use the default date from the calendar settings.
  const initialDatepickerDate = datepickerSelectedDate
    ? datepickerSelectedDate
    : isListPage
    ? props.startTime
    : props.calendar.calendarSettings.date;

  const timeMin = calculateOpenHoursInDay(initialDatepickerDate, props.calendar).start;
  const timeMax = calculateOpenHoursInDay(initialDatepickerDate, props.calendar).end;

  return (
    <Box mb={2}>
      <FormGroup>
        <HeadingPrimary>{t('bookingModalDateAndTime')}</HeadingPrimary>
        <Box sx={{ display: 'flex', gap: '5px' }}>
          <Box width="50%">
            <MuiDatePicker
              fullWidth
              id="date-picker-dialog"
              format={props.restaurant.settings.date_format_key}
              value={initialDatepickerDate}
              onChange={(day) => changeDate(day && day.toDate())}
              shouldDisableDate={checkDisabledDay}
              disabled={props.isReadOnly}
            />
          </Box>
          <TimePicker
            updateStartTime={props.updateStartTime}
            updateEndTime={handleEndTimeUpdate}
            startTime={props.startTime}
            endTime={props.endTime}
            labelStart={t('bookingModalStartTime')}
            labelEnd={t('bookingModalEndTime')}
            min={timeMin}
            max={timeMax}
            isReservationTimepicker={true}
            disabled={props.isReadOnly}
          />
        </Box>
      </FormGroup>
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    locale: state.calendar.locale,
    restaurant: state.calendar.restaurant,
    startTime: state.reservation.startTime,
    endTime: state.reservation.endTime || 'empty',
    calendar: state.calendar,
    defaultBookingLength: state.calendar.restaurant.settings.default_booking_length,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    setLocale: bindActionCreators(calendarActions.setLocale, dispatch),
    updateStartTime: bindActionCreators(reservationActions.updateStartTime, dispatch),
    updateEndTime: bindActionCreators(reservationActions.updateEndTime, dispatch),
    changeCalendarDate: bindActionCreators(calendarActions.changeCalendarDate, dispatch),
    changeDateSlider: bindActionCreators(calendarActions.changeDateSlider, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationTime);
