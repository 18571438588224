import React, { useState } from 'react';
import FullBookingModal from 'app/components/BookingModal';
import CalendarDisplaySwitch from '../CalendarDisplaySwitch';
import RefreshButton from '../RefreshButton';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import CalendarControlsButton from '../CalendarControlsButton';
import CalendarSearchToggleButton from '../CalendarSearchToggleButton';
import OpeningHoursDrawer from 'app/components/OpeningHours';
import NotesDrawer from 'app/components/Notes/NotesDrawer';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import { connect } from 'react-redux';
import { AppState } from 'types';
import { Badge, Box } from '@mui/material';
import PaymentConfirmationModal from 'app/components/PaymentConfirmationModal';
import PaymentModal from 'app/components/PaymentModal';
import CalendarControls from '../index';
import notesActions from 'redux/actions/notes';
import { UserSettings } from 'types/auth';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AppConnectionStatusWrapper from 'app/components/AppConnectionStatusWrapper/AppConnectionStatusWrapper';
import { useScreenSize } from 'hooks/useScreenSize';
// import ThemeToggle from '../ThemeToggle/ThemeToggle';

interface OwnProps {
  enableDisplaySwitch?: boolean;
  enableToggleSwitch?: boolean;
  sidebarBadgeText?: number;
  isRestaurantClosed?: boolean;
}
interface DispatchProps {
  toggleSearch: () => void;
  toggleSidebar: () => void;
  setIsNotesDrawerOpen: (isOpen: boolean) => void;
}
interface StateProps {
  isSearchOpen: boolean;
  isSidebarOpen: boolean;
  isNotesDrawerOpen: boolean;
  isAdvancedSearchActive: boolean;
  user: UserSettings;
}

type Props = OwnProps & DispatchProps & StateProps;

const HeaderControls: React.FC<Props> = (props) => {
  const [isOpeningHoursDrawerOpen, toggleOpeningHoursDrawer] = useState(false);

  const { isMobile } = useScreenSize();

  const handleSidebarToggle = () => {
    if (props.isSidebarOpen && props.isSearchOpen) {
      props.toggleSearch();
    }
    props.toggleSidebar();
  };

  return (
    <>
      <Box className={'calendar-controls'}>
        <Box display={'flex'}>
          <CalendarControls />
        </Box>

        <Box display={'flex'}>
          <FullBookingModal />
          <Box display={'flex'}>
            {!isMobile && (
              <>
                <div className={'calendar-controls__item'}>
                  <RefreshButton />
                </div>
                {/* Temporary disabled. Waiting for new designs. */}
                {/*<div className={'calendar-controls__item'}>*/}
                {/*  <ThemeToggle />*/}
                {/*</div>*/}
              </>
            )}
            <AppConnectionStatusWrapper>
              <div className={'calendar-controls__item'}>
                <CalendarControlsButton onClick={() => toggleOpeningHoursDrawer(true)}>
                  <AccessTimeOutlinedIcon />
                </CalendarControlsButton>
              </div>
            </AppConnectionStatusWrapper>
            {!isMobile && props.enableDisplaySwitch && !props.isRestaurantClosed && (
              <div className={'calendar-controls__item'}>
                <CalendarDisplaySwitch />
              </div>
            )}
            {!isMobile && (
              <AppConnectionStatusWrapper>
                <div className={'calendar-controls__item'}>
                  <CalendarControlsButton onClick={() => props.setIsNotesDrawerOpen(true)}>
                    <NotificationsNoneOutlinedIcon />
                  </CalendarControlsButton>
                </div>
              </AppConnectionStatusWrapper>
            )}
            {!isMobile && (
              <div className={'calendar-controls__item'}>
                <Badge badgeContent={'!'} color="error" invisible={!props.isAdvancedSearchActive}>
                  <CalendarSearchToggleButton
                    isSidebarOpen={props.isSidebarOpen}
                    toggleSidebar={props.toggleSidebar}
                  />
                </Badge>
              </div>
            )}
            <div className={'calendar-controls__item'}>
              <Badge badgeContent={'!'} color="error" invisible={!props.sidebarBadgeText}>
                <CalendarControlsButton
                  onClick={handleSidebarToggle}
                  disabled={!props.enableToggleSwitch}
                >
                  <FormatListBulletedOutlinedIcon />
                </CalendarControlsButton>
              </Badge>
            </div>
            <NotesDrawer
              open={props.isNotesDrawerOpen}
              onClose={() => props.setIsNotesDrawerOpen(false)}
            />
            <OpeningHoursDrawer
              open={isOpeningHoursDrawerOpen}
              onClose={() => toggleOpeningHoursDrawer(false)}
            />
          </Box>
        </Box>
      </Box>
      <AppConnectionStatusWrapper>
        <PaymentConfirmationModal />
        <PaymentModal />
      </AppConnectionStatusWrapper>
    </>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    isAdvancedSearchActive: !!state.calendar.reservationListSettings.advancedSearch.params,
    isSidebarOpen: state.calendar.sidebarOpen,
    isSearchOpen: state.calendar.searchOpen,
    isNotesDrawerOpen: state.notes.isDrawerOpen,
    user: state.auth.userSettings,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    toggleSidebar: bindActionCreators(calendarActions.toggleSidebar, dispatch),
    toggleSearch: bindActionCreators(calendarActions.toggleSearch, dispatch),
    setIsNotesDrawerOpen: bindActionCreators(notesActions.setIsDrawerOpen, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderControls);
