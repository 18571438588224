import React, { ReactNode } from 'react';
import { useStatus } from 'hooks/useStatus';

interface OwnProps {
  children: ReactNode | ReactNode[];
}

const AppConnectionStatusWrapper = ({ children }: OwnProps) => {
  const { isReadOnly } = useStatus();

  if (isReadOnly) {
    return null;
  }
  return <>{children}</>;
};

export default AppConnectionStatusWrapper;
