import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import systemActions from 'redux/actions/system';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RefreshButton from 'app/components/CalendarControls/RefreshButton';
import { useStatus } from 'hooks/useStatus';

const SystemInfo: React.FC = () => {
  const { t } = useTranslation();
  const { isOffline, isReadOnlySystemStatus } = useStatus();
  const dispatch = useDispatch();

  // Check status every 5 min.
  useEffect(() => {
    setInterval(() => {
      //@ts-ignore
      dispatch(systemActions.getStatus());
    }, 1000 * 60 * 5);
  }, [dispatch]);

  return (
    <>
      {isReadOnlySystemStatus && (
        <Alert severity="error">
          {t(
            'We are currently running in read only mode. Creating or updating reservations or any other data is not available.',
          )}
        </Alert>
      )}
      {isOffline && (
        <Alert severity="warning">
          {t(
            'You do not have internet access or the server is not responding. The data may be out of date, try refreshing the application. ',
          )}
          <RefreshButton isText />
        </Alert>
      )}
    </>
  );
};

export default SystemInfo;
