import React from 'react';
import { Typography, Button } from '@mui/material';
import { ReactComponent as LogoutIcon } from 'images/icons/ic-logout.svg';
import { useTranslation } from 'react-i18next';

export const LogoutButton = () => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      sx={{
        display: 'flex',
        gap: '4px',
        backgroundColor: 'brandWhite',
        width: '100%',
        minHeight: '40px',
        '&:hover': {
          backgroundColor: 'brandWhite',
          boxShadow: 'none',
        },
      }}
    >
      <Typography sx={{ fontSize: 12, fontWeight: 700, color: 'flamingo' }}>
        {t('logout')}
      </Typography>
      <LogoutIcon />
    </Button>
  );
};
