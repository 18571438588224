import React from 'react'
import { Drawer as MaterialDrawer, DrawerProps } from '@mui/material'
import './Drawer.scss';

const Drawer: React.FC<DrawerProps> = props => {
  return (
    <MaterialDrawer {...props}>
      <div className="drawer__wrapper">
        {props.children}
      </div>
    </MaterialDrawer>
  )
}

export default Drawer
