import React from 'react';
import { Box, Button, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  isLoading?: boolean;
  showButton: boolean;
  setShowPast: any;
  showPast: boolean;
  sx?: SxProps;
}

export const TogglePastReservationsButton = ({ isLoading, showButton, setShowPast, showPast, sx = {} }: OwnProps) => {
  const { t } = useTranslation();
  return (
    <>
      {!isLoading && showButton && (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', ...sx }}>
          <Button variant="text" onClick={() => setShowPast(!showPast)} sx={{ padding: '0 8px' }}>
            {showPast ? t('Hide past reservations') : t('Show past reservations')}
          </Button>
        </Box>
      )}
    </>
  );
};