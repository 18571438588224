import { Error } from '../types/error';
import Cookie from 'js-cookie';

const TOKEN_KEY = 'access_token';
const TOKEN_COOKIE_NAME = process.env.REACT_APP_JWT_COOKIE_NAME;

export const getToken = () => {
  const cookieToken = getTokenFromCookie();

  if (!!cookieToken) {
    removeTokenFromCookie();
    setToken(cookieToken);

    return cookieToken;
  }

  return localStorage.getItem(TOKEN_KEY);
};

export const setToken = (data: string) => {
  return localStorage.setItem(TOKEN_KEY, data);
};

export const removeToken = () => {
  return localStorage.removeItem(TOKEN_KEY);
};

export const getTokenFromCookie = (): string | undefined => {
  if (TOKEN_COOKIE_NAME) {
    return Cookie.get(TOKEN_COOKIE_NAME);
  }
};

export const removeTokenFromCookie = (): void => {
  const domain = window.location.hostname.split('.');
  domain.shift();

  if (TOKEN_COOKIE_NAME) {
    Cookie.remove(TOKEN_COOKIE_NAME, { path: '/', domain: `.${domain.join('.')}` });
  }
};

export const responseHasTokenError = (error: Error) => {
  const responseCode = error?.data?.status || error?.status;
  return responseCode === 401 || responseCode === 403;
};
