import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { SettingsNavigation } from '../SettingsNavigation';
import { SettingsHeader } from '../SettingsHeader';
import { TabMenu } from '../TabMenu';
import { useAppSelector } from 'redux/hooks';

const NavigationWrapper = () => {
  const accessDashboard = useAppSelector(
    (state) => state.auth.userSettings.permissions.accessDashboard,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        paddingBottom: { xs: '64px', md: 0 },
      }}
    >
      <SettingsNavigation />
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <SettingsHeader />
        <Box
          sx={{
            display: 'flex',
            gap: { xs: '16px', md: '24px' },
            padding: { xs: '16px', md: '24px' },
            flex: 1,
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <TabMenu />
    </Box>
  );
};

export default NavigationWrapper;
