import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { AppState } from 'types';
import { FloorPlanObject, FloorPlanTable } from 'types/floorPlan';
import FloorTable from './FloorTable';
import FloorText from './FloorText';
import FloorWall from './FloorWall';
import FloorControlPanel from './FloorControlPanel';
import FloorTimeline from './FloorTimeline';
import { Restaurant, TimelineReservation } from 'types/calendar';
import moment from 'moment';
import { SelectedHours } from 'types/filters';
import { Statuses } from 'types/reservation';
import { bindActionCreators, Dispatch } from 'redux';
import floorPlanActions from 'redux/actions/floorPlan';
import { LinearProgress } from '@mui/material';
import FloorPlanInfo from './FloorPlanInfo';
import { floorSettings } from 'utils/floorPlan';
import { useDebounce } from 'utils/hooks';
import { useReactToPrint } from 'react-to-print';
import { AppConnectionStatus } from 'types/application';
import './FloorPlan.scss';

interface OwnProps {
  showTimeline?: boolean;
  showInfoBox?: boolean;
  enablePrint?: boolean;
  reservations?: TimelineReservation[];
  selectedTables?: number[];
  selectedTime?: moment.Moment | null;
  selectedStatus?: Statuses;
  blockedTables?: number[];
  onTableSelect?: (id: number) => void;
  onTimeSelect?: (time: moment.Moment | null) => void;
  onEmptyClick?: () => void;
}
interface StateProps {
  currentTime: moment.Moment;
  isFloorPlanLoading: boolean;
  restaurantId: number | null;
  tables: FloorPlanTable[];
  walls: FloorPlanObject[];
  texts: FloorPlanObject[];
  restaurant: Restaurant;
  selectedHours: SelectedHours;
  connectionStatus: AppConnectionStatus;
}

interface DispatchProps {
  getFloorPlan: (_isOffline?: boolean | null) => void;
  clearFloorPlan: () => void;
}

type Props = OwnProps & StateProps & DispatchProps;

const FloorPlan: React.FC<Props> = (props) => {
  const mode = useSelector((state: AppState) => state.theme.mode);
  const { isOffline } = props.connectionStatus;
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [viewport, setViewport] = useState(
    localStorage
      .getItem('tableinFloorPlanViewport')
      ?.split(',')
      ?.map((v) => +v) || floorSettings.viewport,
  );
  const [viewsize, setViewsize] = useState<number[]>([
    floorSettings.width / floorSettings.zoom,
    floorSettings.height / floorSettings.zoom,
  ]);
  const [zoom, setZoom] = useState(
    parseFloat(localStorage.getItem('tableinFloorPlanZoom') || '') || floorSettings.zoom,
  );
  const debouncedZoom = useDebounce(zoom, 300);
  const [controlPressed, setControlPressed] = useState(false);
  const [controlEnabled, setControlEnabled] = useState(false);
  const [mousedown, setMousedown] = useState<number[] | null>(null);

  const controlMode = controlPressed || controlEnabled;

  const viewBox: string = [
    viewport[0] * zoom,
    viewport[1] * zoom,
    viewsize[0] * zoom,
    viewsize[1] * zoom,
  ].join(' ');

  const handleZoom = (delta: number, value = 0.1) => {
    setZoom((prev) => {
      if (delta > 0 && prev <= floorSettings.maxZoom) {
        return (prev += value);
      } else if (delta < 0 && prev >= floorSettings.minZoom) {
        return (prev -= value);
      } else return prev;
    });
  };

  const switchControlMode = () => {
    if (controlPressed) return;
    setControlEnabled((prev) => !prev);
  };

  const selectTable = (id: number) => {
    if (props.onTableSelect) {
      props.onTableSelect(id);
    }
  };

  const handleWheel: React.WheelEventHandler<SVGSVGElement> = (e) => {
    e.persist();
    if (controlPressed) return;
    handleZoom(e.deltaY);
  };

  const handleMouseDown: React.MouseEventHandler<SVGSVGElement> = (e) => {
    if (!controlMode) return;
    setMousedown([e.clientX, e.clientY, viewport[0], viewport[1]]);
  };

  const handleMouseUp: React.MouseEventHandler<SVGSVGElement> = () => {
    if (mousedown !== null) {
      setMousedown(null);
    }
  };

  const handleControl: React.MouseEventHandler<SVGSVGElement> = (e) => {
    if (!controlMode || !mousedown) return;
    const deltaX = e.clientX - mousedown[0];
    const deltaY = e.clientY - mousedown[1];
    setViewport([mousedown[2] - deltaX, mousedown[3] - deltaY]);
  };

  const handleResetView: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (!svgRef.current) return;

    const { x, y } = svgRef.current.getBBox();
    const newViewport = [(x - 20) / floorSettings.zoom, (y - 20) / floorSettings.zoom];

    setViewport(newViewport);
    localStorage.setItem('tableinFloorPlanViewport', newViewport.join(','));

    setZoom(floorSettings.zoom);
    localStorage.setItem('tableinFloorPlanZoom', floorSettings.zoom.toString());

    if (controlEnabled) {
      setControlEnabled(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => svgRef.current,
    pageStyle: `
      @page {
        size: landscape;
      }
    `,
  });

  const handleClick: React.MouseEventHandler<SVGSVGElement> = ({ target, currentTarget }) => {
    if (target !== currentTarget) return;

    if (props.onEmptyClick) {
      props.onEmptyClick();
    }
  };

  useEffect(() => {
    //Enable control mode on "space" key pressed
    let isMounted = true;
    window.addEventListener('keydown', (e) => {
      if (!isMounted) return;
      if (e.code === 'Space') {
        setControlPressed(true);
      }
    });
    window.addEventListener('keyup', (e) => {
      if (!isMounted) return;
      if (e.code === 'Space') {
        setControlPressed(false);
      }
    });

    return () => {
      isMounted = false;
      window.removeEventListener('keydown', (e) => {
        if (!isMounted) return;
        if (e.code === 'Space') {
          setControlPressed(true);
        }
      });
      window.removeEventListener('keyup', (e) => {
        if (!isMounted) return;
        if (e.code === 'Space') {
          setControlPressed(false);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (!props.restaurant.nid || props.restaurant.nid === props.restaurantId) return;

    props.getFloorPlan(isOffline);
    // eslint-disable-next-line
  }, [props.restaurant.nid, props.restaurantId, isOffline]);

  useEffect(() => {
    //Initial viewbox settings
    if (!svgRef.current || (!props.tables.length && !props.walls.length && !props.texts.length))
      return;

    const { x, y, width, height } = svgRef.current.getBBox();

    setViewsize([(width + 40) / floorSettings.zoom, (height + 40) / floorSettings.zoom]);

    if (!localStorage.getItem('tableinFloorPlanViewport')) {
      setViewport([(x - 20) / floorSettings.zoom, (y - 20) / floorSettings.zoom]);
    }
  }, [props.tables, props.walls, props.texts, props.showTimeline]);

  useEffect(() => {
    //Persist user viewport
    if (
      controlMode ||
      viewport.join(',') === floorSettings.viewport.join(',') ||
      viewport.join(',') === localStorage.getItem('tableinFloorPlanViewport')
    )
      return;
    localStorage.setItem('tableinFloorPlanViewport', viewport.join(','));
    // eslint-disable-next-line
  }, [controlMode]);

  useEffect(() => {
    //Persist user zoom
    if (
      debouncedZoom === floorSettings.zoom ||
      debouncedZoom.toString() === localStorage.getItem('tableinFloorPlanZoom')
    )
      return;
    localStorage.setItem('tableinFloorPlanZoom', debouncedZoom.toString());
  }, [debouncedZoom]);

  const parsedTables = useMemo(() => {
    const disabledStatuses = [Statuses.Template];

    return props.tables.map((table) => {
      const reservation = props.reservations
        ?.filter((r) => !disabledStatuses.includes(r.reservation.status))
        ?.find((r) => r.reservation.tables.includes(`${table.nid}`))?.reservation;

      const status = props.blockedTables?.includes(table.nid)
        ? Statuses.Blocked
        : reservation?.status === 'approved' &&
          reservation?.startTime?.isBefore() &&
          !reservation.autoArrivalDisabled
        ? Statuses.Arrived
        : reservation?.status;

      return {
        table,
        status,
      };
    });
    // eslint-disable-next-line
  }, [props.tables, props.reservations]);

  const selectedCovers = props.selectedTables
    ?.map((t) => {
      const table = props.tables.find((table) => table.nid === t);
      return { min: table?.covers_min || 0, max: table?.covers_max || 0 };
    })
    ?.reduce(
      (acc, curr) => {
        acc.min += curr.min;
        acc.max += curr.max;
        return acc;
      },
      { min: 0, max: 0 },
    );

  return (
    <div
      className={`floor-plan__container ${
        mode === 'dark' ? 'floor-plan__container--dark' : ''
      } no-print`}
    >
      {props.showTimeline && (
        <FloorTimeline selectedTime={props.selectedTime} onTimeSelect={props.onTimeSelect} />
      )}
      {props.isFloorPlanLoading && <LinearProgress color="secondary" />}
      {!props.isFloorPlanLoading && (
        <svg
          className={`floor-plan__floor floor-plan__floor--${
            controlPressed || controlMode ? 'control' : 'static'
          } floor-plan__floor--${props.showTimeline ? 'timeline' : 'full'}`}
          ref={svgRef}
          viewBox={viewBox}
          onWheel={handleWheel}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onMouseMove={handleControl}
          onClick={handleClick}
        >
          {props.walls.length > 0 &&
            props.walls.map((wall) => <FloorWall key={wall.id} wall={wall} />)}
          {props.texts.length > 0 &&
            props.texts.map((text) => <FloorText key={text.id} text={text} />)}
          {parsedTables.length > 0 &&
            parsedTables.map((t) => (
              <FloorTable
                key={t.table.nid}
                table={t.table}
                status={t.status}
                selected={props.selectedTables?.includes(t.table.nid)}
                selectedStatus={props.selectedStatus}
                onClick={selectTable}
              />
            ))}
        </svg>
      )}
      {props.showInfoBox && <FloorPlanInfo covers={selectedCovers} />}
      <FloorControlPanel
        isControlMode={controlMode}
        onReset={handleResetView}
        onZoomChange={handleZoom}
        onControlModeChange={switchControlMode}
        onPrint={props.enablePrint ? handlePrint : undefined}
      />
    </div>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    currentTime: state.application.currentTime,
    isFloorPlanLoading: state.floorPlan.isLoading,
    restaurantId: state.floorPlan.restaurantId,
    tables: state.floorPlan.tables,
    walls: state.floorPlan.walls,
    texts: state.floorPlan.texts,
    restaurant: state.calendar.restaurant,
    selectedHours: state.filters.selectedHours,
    connectionStatus: state.application.connectionStatus,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    getFloorPlan: bindActionCreators(floorPlanActions.getData, dispatch),
    clearFloorPlan: bindActionCreators(floorPlanActions.clearData, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlan);
