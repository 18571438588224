import React, { ChangeEvent, useState } from 'react';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import { TextField, FormGroup, Stack } from '@mui/material';
import ReservationCommentsItem from '../ReservationCommentsItem';
import { useTranslation } from 'react-i18next';
import { Reply } from 'types/calendar';
import { LoadingButton } from '@mui/lab';
import AppConnectionStatusWrapper from 'app/components/AppConnectionStatusWrapper/AppConnectionStatusWrapper';

interface StateProps {
  comments: Reply[];
  newComment: string;
}

interface DispatchProps {
  updateNewComment: (comment: string) => void;
  addComment: (comment: string, reservationId: number) => any;
}

interface OwnProps {
  reservationId: number | undefined;
}

type Props = StateProps & DispatchProps & OwnProps;

const ReservationComments: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);

  const updateNewComment = (event: ChangeEvent<HTMLInputElement>) => {
    props.updateNewComment(event.target.value);
  };

  const saveComment = () => {
    setIsSaving(true);

    !!props.reservationId &&
      props.addComment(props.newComment.trim(), props.reservationId).then(() => {
        setIsSaving(false);
        props.updateNewComment('');
      });
  };

  return (
    <>
      <div>
        {props.comments.map((comment) => (
          <ReservationCommentsItem key={comment.id} comment={comment} />
        ))}
      </div>
      <FormGroup>
        <Stack spacing={2} sx={{ alignItems: 'flex-end', position: 'relative' }}>
          <AppConnectionStatusWrapper>
            <TextField
              sx={{ width: '100%' }}
              id="detailsText"
              label={t('bookingModalCommentLabel')}
              multiline
              rows="4"
              variant="outlined"
              value={props.newComment}
              onChange={updateNewComment}
            />
            {!!props.reservationId && (
              <LoadingButton
                sx={{ width: 'fit-content' }}
                variant="contained"
                color="primary"
                size="small"
                disabled={!props.newComment || !props.newComment.trim()}
                loading={isSaving}
                onClick={saveComment}
              >
                {t('bookingModalSaveComment')}
              </LoadingButton>
            )}
          </AppConnectionStatusWrapper>
        </Stack>
      </FormGroup>
    </>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    comments: state.reservation.comments,
    newComment: state.reservation.newComment,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateNewComment: bindActionCreators(reservationActions.updateNewComment, dispatch),
    addComment: bindActionCreators(reservationActions.addComment, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationComments);
