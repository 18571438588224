import React from 'react';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  onClick: () => void;
};

const closeButtonStyles = {
    backgroundColor: 'blueGray',
    color: 'waterloo',
    borderRadius: '4px',
    minWidth: 'fit-content',
    width: '30px',
    height: '30px',
    fontSize: '25px',
    padding: 0,
    '&:hover': { backgroundColor: 'pigPink', color: 'thunderbird' }
}

export const CloseButton = ({ onClick }: Props) => {
  return (
    <Button
      sx={closeButtonStyles}
      onClick={onClick}
    ><CloseIcon /></Button>
  );
};