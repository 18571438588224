export interface LanguagesMap {
  ar: string;
  cs: string;
  da: string;
  el: string;
  en: string;
  et: string;
  ja: string;
  nn: string;
  'pt-pt': string;
  sl: string;
  sv: string;
  'zh-hans': string;
  'zh-hant': string;
  sq: string;
  ca: string;
}

const languagesMap: LanguagesMap = {
  ar: 'sa',
  cs: 'cz',
  da: 'dk',
  el: 'gr',
  en: 'gb',
  et: 'ee',
  ja: 'jp',
  nn: 'no',
  'pt-pt': 'pt',
  sl: 'si',
  sv: 'se',
  'zh-hans': 'cn',
  'zh-hant': 'cn',
  sq: 'al',
  ca: 'es-ct',
};

export const languageToFlag = (language: keyof LanguagesMap) => {
  if (language in languagesMap) {
    return languagesMap[language];
  } else {
    return language;
  }
};
