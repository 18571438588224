import React from 'react';
import { connect, useSelector } from 'react-redux';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import reservationActions from 'redux/actions/reservation';
import { ReactComponent as ShoePrintsIcon } from 'images/icons/shoe-prints-light.svg';
import { ReactComponent as UserIcon } from 'images/icons/user-light.svg';
import PhoneInput from 'react-phone-input-2';
import { ReactComponent as CommentIcon } from 'images/icons/comment-light.svg';
import { ReactComponent as TagIcon } from 'images/icons/tag-light.svg';
import { ReactComponent as UserTagIcon } from 'images/icons/user-tag-light.svg';
import { ReactComponent as SpecialOfferIcon } from 'images/icons/PromotionTypes/ic-offer.svg';
import { ReactComponent as MoneyIcon } from 'images/icons/money-bill.svg';
import AverageRatingComponent from 'app/components/BookingModal/AverageRatingComponent';
import FeedbackListItem from '../FeedbackListItem';
import FeedbackStars from '../FeedbackStars';
import {
  getReservationClassName,
  getReservationDinningRoomsNames,
  getReservationSingleTableName,
  getReservationTimeInterval,
  getReservationTitle,
  prepareReservationPayments,
  StatusesEng,
} from 'utils/reservations';
import { SidebarCardLoader } from 'app/components/Sidebar/SidebarCardLoader';
import { ReactComponent as UsdCircleIcon } from 'images/icons/usd-circle-light.svg';
import { useTranslation } from 'react-i18next';
import { Feedback, ReservationState, Statuses } from 'types/reservation';
import { Room, RoomOrTable, Settings, TimelineReservation } from 'types/calendar';
import OriginIcon from 'app/components/OriginIcon';
import { pad } from 'utils/string';
import { Box, Typography } from '@mui/material';
import CardWrapper from 'app/components/common/CardWrapper/CardWrapper';

interface OwnProps {
  reservation: TimelineReservation;
}

interface StateProps {
  extendedReservationsIsLoading: boolean;
  allTables: RoomOrTable[];
  restaurantSettings: Settings;
  rooms: Room[];
}

interface DispatchProps {
  openFullBookingModal: () => void;
  updateReservation: (reservation: ReservationState) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

const ReservationsListItem: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { reservation } = props;
  const mode = useSelector((state: AppState) => state.theme.mode);

  const handleItemSelect = () => {
    props.updateReservation(reservation.reservation);
    props.openFullBookingModal();
  };

  const averageCount = (feedback: Feedback) => {
    const ratings = [
      feedback.rateAmbience,
      feedback.rateCleanliness,
      feedback.rateFood,
      feedback.rateService,
    ];
    return ratings.reduce((a, b) => a + b) / ratings.length;
  };

  const payments = prepareReservationPayments(props.reservation.reservation.payments.currencies);

  const dinningRooms = () => {
    if (props.rooms.length > 1) {
      return getReservationDinningRoomsNames(props.reservation, props.rooms).map((room, index) => (
        <div className={room.length > 25 ? 'reservation-list__dinning_rooms-wide' : ''} key={index}>
          {room}
        </div>
      ));
    }
  };

  return (
    <CardWrapper
      onClick={handleItemSelect}
      className={`sidebar-reservation sidebar-reservation--card ${getReservationClassName(
        props.reservation.reservation.status,
        props.reservation.reservation.startTime,
        props.reservation.reservation.autoArrivalDisabled,
      )}`}
    >
      <div className={`reservation-list__body ${mode === 'dark' ? 'reservation-list--dark' : ''}`}>
        <div className={'reservation-list__column--left'}>
          <div className="sidebar-reservation__badge sidebar-reservation__badge--time">
            {getReservationTimeInterval(reservation, props.restaurantSettings)}
          </div>
          <div className={'reservation-list__base-info-wrapper'}>
            <div className="sidebar-reservation__badge sidebar-reservation__badge--table">
              {reservation.reservation.tables[0] &&
                pad(
                  getReservationSingleTableName(reservation.reservation.tables[0], props.allTables),
                  12,
                )}
              {reservation.reservation.tables.length > 1 && <>&nbsp;+</>}
            </div>
            <div className="sidebar-reservation__icons sidebar-reservation__icons--guests">
              {![Statuses.Blocked].includes(reservation.reservation.status) && (
                <>
                  <UserIcon className="sidebar-reservation__icon" />
                  <span className="">{reservation.reservation.numberOfGuests}</span>
                </>
              )}
            </div>
            <div className="sidebar-reservation__icons">
              {reservation.reservation.status === Statuses.WalkIn ? (
                <ShoePrintsIcon className="sidebar-reservation__icon" />
              ) : (
                <OriginIcon origin={reservation.reservation.origin} />
              )}
            </div>
            <div className="sidebar-reservation__icons only-vertical-mobile">
              {reservation.reservation.specialOffer.nid !== '_none' && (
                <Box sx={{ marginLeft: '7px' }}>
                  <SpecialOfferIcon width={16} height={16} />
                </Box>
              )}
            </div>
            <div className="sidebar-reservation__icons only-vertical-mobile">
              {!!payments.length && <UsdCircleIcon className="sidebar-reservation__icon" />}
            </div>
          </div>
        </div>
        <div className={'reservation-list__column--center-left'}>
          <div className="sidebar-reservation__title-wrapper">
            <h2 className="sidebar-reservation__title">
              {getReservationTitle(reservation.reservation.status, reservation.title)}
              {reservation.reservation.clientIsVip && (
                <Typography
                  component="span"
                  sx={{ fontWeight: 600, margin: '0 8px', color: 'red' }}
                >
                  VIP
                </Typography>
              )}
              {reservation.reservation.showBigSpenderTag &&
                reservation.reservation.clientIsBigSpender && (
                  <MoneyIcon className="sidebar-reservation__icon" />
                )}
            </h2>
            <h3 className="sidebar-reservation__subtitle">
              {!!reservation.reservation.guestDetails.arrivedCount && (
                <>
                  {' '}
                  {reservation.reservation.guestDetails.arrivedCount} {t('visit')}{' '}
                </>
              )}
              {reservation.reservation.company && <> &middot; {reservation.reservation.company}</>}
            </h3>
            <div>
              {!!reservation.reservation.averageRating?.feedbackCount && (
                <AverageRatingComponent averageRating={reservation.reservation.averageRating} />
              )}
            </div>
            <div className="sidebar-reservation__phone">
              {reservation.reservation.phoneNumber && (
                <PhoneInput
                  value={reservation.reservation.phoneNumber}
                  disabled={true}
                  disableDropdown={true}
                />
              )}
            </div>
          </div>
        </div>
        <div className={'reservation-list__column--center-right'}>
          {reservation.reservation.comments.map((comment) => (
            <div
              className="sidebar-reservation__body-item sidebar-reservation__icons reservation-list__body-item"
              key={comment.id}
            >
              <CommentIcon className="sidebar-reservation__icon" />
              <span>
                {comment.commentBody
                  .trim()
                  .replace('Paid', t('reservationCommentStatus'))
                  .replace('Payment date', t('reservationCommentDate'))
                  .replace('Method', t('reservationCommentMethod'))}
              </span>
            </div>
          ))}
          {reservation.reservation.clientDetails && (
            <div className="sidebar-reservation__body-item sidebar-reservation__icons reservation-list__body-item">
              <UserTagIcon className="sidebar-reservation__icon" />
              <span>{reservation.reservation.clientDetails}</span>
            </div>
          )}
          {props.reservation.reservation.specialOffer.nid !== '_none' && (
            <Box
              className="sidebar-reservation__body-item sidebar-reservation__icons reservation-list__body-item hide-vertical-mobile"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box sx={{ marginLeft: '7px', display: 'flex' }}>
                <SpecialOfferIcon width={16} height={16} />
              </Box>
              <span>{props.reservation.reservation.specialOffer.title}</span>
            </Box>
          )}
          {!!props.reservation.reservation.tags.length && (
            <div className="sidebar-reservation__body-item sidebar-reservation__icons reservation-list__body-item">
              <TagIcon className="sidebar-reservation__icon" />
              <span>{props.reservation.reservation.tags.join(', ')}</span>
            </div>
          )}
        </div>
        <div className={'reservation-list__column--right'}>
          <Box mb={0.5} className="sidebar-reservation__badge sidebar-reservation__badge--status">
            {t(StatusesEng[reservation.reservation.status])}
          </Box>
          <div className={'reservation-list__nid mt-1'}>#{reservation.reservation.id}</div>
          <div className={'reservation-list__paid mt-1'}>
            {!!payments.length && (
              <span className="sidebar-reservation__paid-label">
                {t('Paid')}: {payments.join(' + ')}
              </span>
            )}
          </div>
          <Box mt={0.5} className={'reservation-list__dinning_rooms'}>
            {dinningRooms()}
          </Box>
        </div>
      </div>
      {props.extendedReservationsIsLoading && <SidebarCardLoader noBorder={true} size={15} />}
      {!!reservation.reservation.reservationAverageRating?.feedback && (
        <div className="reservation-list__footer">
          <div className={'reservation-list__column--left'}>
            <div className={'reservation-list__review-title'}>{t('reservationGuestReview')}</div>
            <FeedbackStars
              averageRating={averageCount(
                reservation.reservation.reservationAverageRating.feedback,
              )}
            />
          </div>
          <div className={'reservation-list__column--center'}>
            <FeedbackListItem
              feedback={reservation.reservation.reservationAverageRating.feedback}
            />
          </div>
          <div className={'reservation-list__column--right'}></div>
        </div>
      )}
    </CardWrapper>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    extendedReservationsIsLoading: state.calendar.extendedReservationsIsLoading,
    restaurantSettings: state.calendar.restaurant.settings,
    allTables: state.calendar.roomsAndTables.filter(
      (roomOrTable) => roomOrTable.tableId !== undefined,
    ),
    rooms: state.calendar.rooms,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    openFullBookingModal: bindActionCreators(calendarActions.openFullBookingModal, dispatch),
    updateReservation: bindActionCreators(reservationActions.updateReservation, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationsListItem);
