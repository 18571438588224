import React, { ReactNode } from 'react';
import { Button } from '@mui/material';

interface OwnProps {
  children: ReactNode
  onClick: (attribute?: any) => void
  active?: boolean
}

const FloorPlanButton: React.FC<OwnProps> = ({ children, onClick, active }) => {
  return (
    <Button
      variant={'outlined'}
      onClick={onClick}
      sx={theme => ({
        color: active ? theme.palette.brandWhite : theme.palette.navyBlue,
        background: active ? theme.palette.brandYellow : 'transparent',
        minWidth: 40,
        borderColor: theme.palette.porcelain,
      })}
    >
      {children}
    </Button>
  )
}

export default FloorPlanButton