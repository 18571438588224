import { Action } from '.';
import {
  AverageRating,
  LateCancellationNoShow,
  Payments,
  ReservationAverageRating,
  ReservationState,
  Statuses,
  StripePaymentData,
  TrackedChanges,
} from './reservation';
import { DateFormatKey } from './dates';

export interface CalendarState {
  locale: string;
  fullBookingModalOpen: boolean;
  fullBookingModalIsLoading: boolean;
  sidebarOpen: boolean;
  searchOpen: boolean;
  reservationsIsLoading: boolean;
  reservationsIsNextPageLoading: boolean;
  extendedReservationsIsLoading: boolean;
  calendarDisplay: CalendarDisplay;
  roomsAndTables: RoomOrTable[];
  rooms: Room[];
  reservationsSettings: ReservationsSettings;
  reservations: TimelineReservation[];
  restaurant: Restaurant;
  calendarSettings: CalendarSettings;
  reservationListSettings: ReservationListSettings;
  failedReservationsList: TimelineReservation[];
  today: moment.Moment;
  lateCancellationNoShowPayment: LateCancellationNoShowPayment;
  reservationListStatistics: Statistics;
  exportListToCsv: boolean;
}

export interface LateCancellationNoShowPayment {
  askForPaymentModalIsOpen: boolean;
  paymentModalIsOpen: boolean;
}

export interface ValidationError {
  field?: string;
  error: string;
}

export interface CalendarSettings {
  date: moment.Moment;
  startTime: moment.Moment;
  endTime: moment.Moment;
  closed: boolean;
  controlsSettings: {
    startDateFactor: number;
    selectedDateIndex: number;
  };
}

export interface RoomOrTable {
  id: string;
  roomId?: number;
  tableId?: number;
  parent?: number;
  root: boolean;
  title: string;
  covers_min?: number;
  covers_max?: number;
  collapsed?: boolean;
}

export interface Room {
  tables: Table[];
  nid: number;
  title: string;
  status: boolean;
  collapsed: boolean;
}

export interface Table {
  nid: number;
  title: string;
  status: boolean;
  covers_min: number;
  covers_max: number;
}

export interface ApiSpecialOffer {
  nid: number;
  title: string;
  promotionType: string;
  discount: number;
  price?: {
    amount: number;
    currencyCode: string;
  };
  lateCancellationNoShow: number;
  lateCancellationNoShowTime: number;
  lateCancellationNoShowMaxAmount: number;
  lateCancellationNoShowAmountCurrency: string;
  lateCancellationNoShowChargeMode: string;
}

export interface Reply {
  id: number;
  commentBody: string;
  changed: number;
  uid: number;
}

export interface ApiReservation {
  clientData: GuestDetails;
  clientAverageFeedback?: AverageRating;
  reservationAverageFeedback?: ReservationAverageRating;
  reservation: {
    timeInterval: TimeInterval[];
    tables: Table[];
    nid: number;
    title: string;
    status: boolean;
    covers: number;
    reservationStatus: Statuses;
    client: Client[];
    specialOffers: ApiSpecialOffer[];
    language: string;
    tags: string[];
    //TODO: add array structure
    notifications: {
      email?: boolean;
      sms?: boolean;
    };
    waiters: Waiter[];
    origin: string;
    replies: Reply[];
    trackedChanges?: TrackedChanges;
    created: number;
    changed: number;
    payments: Payments;
    stripePaymentData: StripePaymentData;
    lateCancellationNoShow: LateCancellationNoShow;
  };
}

export interface GuestDetails {
  arrivedCount: number;
  cancelledCount: number;
  noShowCount: number;
  lastVisit: string;
}

export interface ReservationsSettings {
  startDate: moment.Moment;
  endDate: moment.Moment;
  page: number;
  pagesCount: number;
}

export interface TimelineReservation {
  id: number;
  group: string[];
  title: string;
  calendarDate?: moment.Moment;
  start: number;
  end: number;
  canResize: string | false;
  canMove: boolean;
  spinner: boolean;
  itemProps: any;
  reservation: ReservationState;
}

export interface Client {
  nid?: number;
  email: string;
  name: string;
  phone: string;
  company: string;
  title: string;
  clientTags: string[];
  clientDetails: string;
  vip: boolean;
  big_spender: boolean;
}

export interface Restaurant {
  settings: Settings;
  cumoWidgetTimes: CumoWidgetTimes[];
  cumoWidgetSpecificTimes: cumoWidgetSpecificTimes;
  address: RestaurantAddress | null;
  nid?: number;
  title?: string;
  status?: boolean;
  waiters: Waiter[];
}

export interface ApiFormError {
  field: string;
  error: string;
}

interface RestaurantAddress {
  country: string;
}

export interface RestaurantApi {
  restaurant: Restaurant;
}

interface CumoWidgetTimes {
  weekday: number | null;
  times: string[];
  // TODO: can be change to base node interface
  room?: {
    nid: number;
    status: boolean;
    title: string;
  };
}

// TODO: change any
interface cumoWidgetSpecificTimes {
  times: any;
}

export interface Settings {
  open_hours: any;
  phone_required: boolean;
  default_language: string;
  timezone: string;
  increment: number;
  date_format_key: keyof DateFormatKey;
  hide_no_show: boolean;
  list_show_walk_ins: boolean;
  list_show_cancelled: boolean;
  initial_reservation_badge: boolean;
  exclusive_days: any[];
  staff_summary: number;
  first_day_of_week: number;
  sms_notify_status: boolean;
  sms_notify_default_on: boolean;
  sms_notify_client_table_ready: boolean;
  auto_arrival_disabled: boolean;
  show_old_calendar: boolean;
  show_big_spender_tag: boolean;
  default_booking_length: number;
}

export interface Waiter {
  tid: string;
  name?: string;
}

export interface Statistics {
  guestsToday: number;
  walkIns: number;
  reservations: number;
}

interface TimeInterval {
  entity_id: number;
  startTime: string;
  endTime: string;
}

export interface AdvancedSearchParams {
  id: string;
  name: string;
  email: string;
  phone: string;
  company: string;
}

export interface ReservationListAdvancedSearch {
  isLoading: boolean;
  isNextPageLoading: boolean;
  params: AdvancedSearchParams | null;
  results: TimelineReservation[];
  page: number;
  pagesCount: number;
}

export interface ReservationListSettings {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  advancedSearch: ReservationListAdvancedSearch;
}

export interface SelectedCalendarDay {
  restaurantId?: number;
  date: string;
  ttl: string;
}

export interface ICalendarDisplay {
  Fixed: string;
  Full: string;
}
export enum CalendarDisplay {
  Fixed = 'Fixed',
  Full = 'Full',
}

//Actions

export interface LocaleSetAction extends Action<{ locale: string }> {
  type: 'Calendar.LocaleSet';
}

export interface FullBookingModalOpenedAction extends Action<{ fullBookingModalOpen: boolean }> {
  type: 'Calendar.FullBookingModalOpened';
}

export interface FullBookingModalClosedAction extends Action<{ fullBookingModalOpen: boolean }> {
  type: 'Calendar.FullBookingModalClosed';
}

export interface IsLoadingFullBookingModalSetAction extends Action<{ isLoading: boolean }> {
  type: 'Calendar.IsLoadingFullBookingModalSet';
}

export interface ReservationsIsLoadingSetAction extends Action<{ isLoading: boolean }> {
  type: 'Calendar.ReservationsIsLoadingSet';
}

export interface ReservationsIsNextPageLoadingSetAction extends Action<{ isLoading: boolean }> {
  type: 'Calendar.ReservationsIsNextPageLoadingSet';
}

export interface ExtendedReservationsIsLoadingSetAction extends Action<{ isLoading: boolean }> {
  type: 'Calendar.ExtendedReservationsIsLoadingSet';
}

export interface SidebarToggledAction extends Action<{}> {
  type: 'Calendar.SidebarToggled';
}

export interface TodaySetAction extends Action<{ today: moment.Moment }> {
  type: 'Calendar.TodaySet';
}

export interface SearchToggledAction extends Action<{}> {
  type: 'Calendar.SearchToggled';
}

export interface CalendarDisplayChangedAction extends Action<{ calendarDisplay: CalendarDisplay }> {
  type: 'Calendar.CalendarDisplayChanged';
}

export interface CalendarControlsSetStartDateFactor extends Action<{ startDateFactor: number }> {
  type: 'Calendar.ControlsStartDateFactorSet';
}

export interface CalendarControlsSetSelectedDateIndex
  extends Action<{ selectedDateIndex: number }> {
  type: 'Calendar.ControlsSelectedDateIndexSet';
}

export interface RoomsLoadedAction
  extends Action<{ roomsAndTables: RoomOrTable[]; rooms: Room[] }> {
  type: 'Calendar.RoomsLoaded';
}

export interface RestaurantLoadedAction extends Action<{ restaurant: Restaurant }> {
  type: 'Calendar.RestaurantLoaded';
}

export interface RestaurantWaitersLoadedAction extends Action<{ waiters: Waiter[] }> {
  type: 'Calendar.RestaurantWaitersLoaded';
}

export interface ReservationsLoadedAction extends Action<{ reservations: TimelineReservation[] }> {
  type: 'Calendar.ReservationsLoaded';
}

export interface ReservationsPartialLoadedAction
  extends Action<{ reservations: TimelineReservation[]; date: string }> {
  type: 'Calendar.ReservationsPartialLoaded';
}

export interface CalendarSettingsAddedAction
  extends Action<{
    startTime: moment.Moment;
    endTime: moment.Moment;
    closed: boolean;
    date: moment.Moment;
  }> {
  type: 'Calendar.SettingsAdded';
}

export interface ReservationsChangeDateRangeAction
  extends Action<{ startDate: moment.Moment; endDate: moment.Moment }> {
  type: 'Calendar.ReservationsChangeDateRange';
}

export interface ReservationsChangePageAction extends Action<{ page: number }> {
  type: 'Calendar.ReservationsChangePage';
}

export interface ReservationsUpdatePaginationAction
  extends Action<{ page: number; pagesCount: number }> {
  type: 'Calendar.ReservationsUpdatePagination';
}

export interface CalendarChangeReservationListDateRangeAction
  extends Action<{ startDate: moment.Moment | null; endDate: moment.Moment | null }> {
  type: 'Calendar.ChangeReservationListDateRange';
}

export interface ReservationAddedToCalendarAction
  extends Action<{ reservation: TimelineReservation }> {
  type: 'Calendar.ReservationAddedToCalendar';
}

export interface ReservationChangedInCalendarAction
  extends Action<{ reservation: TimelineReservation }> {
  type: 'Calendar.ReservationChangedInCalendar';
}

export interface ReservationRemovedFromCalendarAction extends Action<{ reservationId: number }> {
  type: 'Calendar.ReservationRemovedFromCalendar';
}

export interface ReservationAddedToFailedListAction
  extends Action<{ reservation: TimelineReservation; errors: ApiFormError[] }> {
  type: 'Calendar.ReservationAddedToFailedList';
}

export interface ReservationRemovedFromFailedListAction
  extends Action<{ timelineReservationId: number }> {
  type: 'Calendar.ReservationRemovedFromFailedList';
}

export interface ExportListToCsvUpdateAction extends Action<{ exportListToCsv: boolean }> {
  type: 'Calendar.ExportListToCsvUpdateAction';
}

export interface LateCancellationNoShowPyamentConfirmModalOpenAction
  extends Action<{ askForPaymentModalIsOpen: boolean }> {
  type: 'Calendar.LateCancellationNoShow.PaymentConfirmationModalOpen';
}

export interface LateCancellationNoShowPyamentConfirmModalCloseAction
  extends Action<{ askForPaymentModalIsOpen: boolean }> {
  type: 'Calendar.LateCancellationNoShow.PaymentConfirmationModalClose';
}

export interface LateCancellationNoShowPyamentModalOpenAction
  extends Action<{ paymentModalIsOpen: boolean }> {
  type: 'Calendar.LateCancellationNoShow.PaymentModalOpen';
}

export interface LateCancellationNoShowPyamentModalCloseAction
  extends Action<{ paymentModalIsOpen: boolean }> {
  type: 'Calendar.LateCancellationNoShow.PaymentModalClose';
}

export interface ReservationListStatisticsSetAction extends Action<{ statistics: Statistics }> {
  type: 'Calendar.ReservationListStatisticsSet';
}

export interface ReservationListAdvancedSearchSetParams
  extends Action<{ params: AdvancedSearchParams | null }> {
  type: 'Calendar.ReservationListAdvancedSearchSetParams';
}

export interface ReservationListAdvancedSearchSetResults
  extends Action<{ results: TimelineReservation[] }> {
  type: 'Calendar.ReservationListAdvancedSearchSetResults';
}

export interface ReservationListAdvancedSearchSetLoading extends Action<{ isLoading: boolean }> {
  type: 'Calendar.ReservationListAdvancedSearchSetLoading';
}

export interface ReservationListAdvancedSearchSetNextPageLoading
  extends Action<{ isLoading: boolean }> {
  type: 'Calendar.ReservationListAdvancedSearchSetNextPageLoading';
}

export interface ReservationListAdvancedSearchUpdatePagination
  extends Action<{ page: number; pagesCount: number }> {
  type: 'Calendar.ReservationListAdvancedSearchUpdatePagination';
}

export type CalendarAction =
  | LocaleSetAction
  | FullBookingModalOpenedAction
  | FullBookingModalClosedAction
  | IsLoadingFullBookingModalSetAction
  | ReservationsIsLoadingSetAction
  | ReservationsIsNextPageLoadingSetAction
  | ExtendedReservationsIsLoadingSetAction
  | SidebarToggledAction
  | TodaySetAction
  | SearchToggledAction
  | CalendarDisplayChangedAction
  | RoomsLoadedAction
  | ReservationsPartialLoadedAction
  | ReservationsLoadedAction
  | RestaurantLoadedAction
  | RestaurantWaitersLoadedAction
  | CalendarSettingsAddedAction
  | CalendarControlsSetSelectedDateIndex
  | CalendarControlsSetStartDateFactor
  | ReservationAddedToCalendarAction
  | ReservationRemovedFromCalendarAction
  | ReservationAddedToFailedListAction
  | ReservationRemovedFromFailedListAction
  | ReservationChangedInCalendarAction
  | ReservationListStatisticsSetAction
  | ExportListToCsvUpdateAction
  | LateCancellationNoShowPyamentConfirmModalOpenAction
  | LateCancellationNoShowPyamentConfirmModalCloseAction
  | LateCancellationNoShowPyamentModalOpenAction
  | LateCancellationNoShowPyamentModalCloseAction
  | ReservationsChangeDateRangeAction
  | ReservationsChangePageAction
  | ReservationsUpdatePaginationAction
  | CalendarChangeReservationListDateRangeAction
  | ReservationListAdvancedSearchSetLoading
  | ReservationListAdvancedSearchSetNextPageLoading
  | ReservationListAdvancedSearchSetResults
  | ReservationListAdvancedSearchSetParams
  | ReservationListAdvancedSearchUpdatePagination;
