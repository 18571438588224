import React, { useState } from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Moment } from 'moment';
import { dateFormatDate } from 'utils/dates';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CalendarControlsButton from '../CalendarControls/CalendarControlsButton';
import { useTranslation } from 'react-i18next';
import './MuiDatePicker.scss';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { DateFormatKey } from 'types/dates';

type Props = {
  id?: string;
  label?: string;
  value: Moment | null;
  onChange: (date: any) => void;
  shouldDisableDate?: (day: any) => boolean;
  format?: keyof DateFormatKey;
  fullWidth?: boolean;
  hideInput?: boolean;
  disabled?: boolean;
  minDate?: Moment;
};

const textFieldStyles = {
  width: '100%',
  input: {
    cursor: 'pointer',
  },
  disabled: {
    color: 'red',
  },
  '& .MuiInputBase-input': {
    caretColor: 'transparent',
    padding: '8.5px 8px 8.5px 0',
  },
  '& .MuiInputBase-root': {
    paddingLeft: '10px',
  },
  svg: {
    color: 'brandYellow',
  },
};

const hideInputStyles = {
  position: 'absolute',
  width: 0,
  top: 0,
  left: 0,
  visibility: 'hidden',
};

export const MuiDatePicker = ({
  id,
  label,
  value,
  onChange,
  shouldDisableDate,
  format,
  fullWidth,
  hideInput,
  disabled,
  minDate,
}: Props) => {
  const { t } = useTranslation();
  const [currentError, setCurrentError] = useState<DateValidationError | string>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => !disabled && setOpen(true);
  const handleClose = () => setOpen(false);

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const hide = hideInput ? hideInputStyles : {};

  const setErrorMessage = (reason: DateValidationError) => {
    switch (reason) {
      case 'shouldDisableDate':
        setCurrentError(t('The end date cannot be earlier than the start date'));
        break;
      default:
        setCurrentError(reason);
    }
  };

  return (
    <Box sx={{ width: fullWidth ? '100%' : 'auto', position: 'relative' }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          disabled={disabled}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          label={label}
          value={value}
          onChange={onChange}
          shouldDisableDate={shouldDisableDate}
          inputFormat={dateFormatDate(format)}
          minDate={minDate}
          onError={(reason) => setErrorMessage(reason)}
          componentsProps={{
            actionBar: { actions: ['clear', 'today', 'cancel', 'accept'] },
          }}
          renderInput={(params) => (
            <Box>
              <TextField
                {...params}
                id={id}
                disabled={disabled}
                size="small"
                onClick={handleOpen}
                onKeyDown={onKeyDown}
                placeholder={label}
                helperText={currentError}
                sx={{
                  ...textFieldStyles,
                  ...hide,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonthOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {hideInput && (
                <CalendarControlsButton onClick={handleOpen}>
                  <CalendarMonthOutlinedIcon />
                </CalendarControlsButton>
              )}
            </Box>
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};
