import React, { ChangeEvent } from 'react';
import {
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import reservationActions from 'redux/actions/reservation';
import { connect } from 'react-redux';
import 'flag-icons/sass/flag-icons.scss';
import { languageToFlag } from 'utils/flag';
import { useTranslation } from 'react-i18next';
import { Language } from 'types/application';
import { Restaurant } from 'types/calendar';
import { Notifications } from 'types/reservation';

interface ComponentProps {
  isEdit: boolean;
  isReadOnly?: boolean;
}

interface StateProps {
  notifications: Notifications;
  restaurant: Restaurant;
  languages: Language[];
}

interface DispatchProps {
  updateNotifications: (notifications: Partial<Notifications>) => void;
}

type Props = StateProps & DispatchProps & ComponentProps;

const NotificationsSettings: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const updateSMS = (event: ChangeEvent<HTMLInputElement>) => {
    props.updateNotifications({ sms: event.target.checked });
  };
  const updateEmail = (event: ChangeEvent<HTMLInputElement>) => {
    props.updateNotifications({ email: event.target.checked });
  };
  const updateResend = (event: ChangeEvent<HTMLInputElement>) => {
    props.updateNotifications({ resend: event.target.checked });
  };
  const updateTableReady = (event: ChangeEvent<HTMLInputElement>) => {
    props.updateNotifications({ tableReady: event.target.checked });
  };
  const updateLanguage = (event: SelectChangeEvent) => {
    props.updateNotifications({ language: event.target.value });
  };

  const Options = props.languages.map((language) => (
    <MenuItem value={language.language} key={language.language}>
      <Typography
        component="span"
        mr={1}
        className={`fi fi-${languageToFlag(language.language)}`}
      />
      <Typography component="span">{language.language.toUpperCase()}</Typography>
    </MenuItem>
  ));

  return (
    <FormGroup row className="align-items-center modal-notification-settings">
      {props.isEdit &&
        props.restaurant.settings.sms_notify_status &&
        props.restaurant.settings.sms_notify_client_table_ready && (
          <FormControlLabel
            control={
              <Checkbox
                disabled={props.isReadOnly}
                checked={props.notifications.tableReady}
                onChange={updateTableReady}
                color="primary"
                className="color-blue-dark-2"
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />
            }
            label={t('notificationsSettingsTable')}
          />
        )}
      {props.restaurant.settings.sms_notify_status && (
        <FormControlLabel
          control={
            <Checkbox
              disabled={props.isReadOnly}
              checked={props.notifications.sms}
              onChange={updateSMS}
              className="color-blue-dark-2"
              color="primary"
              inputProps={{
                'aria-label': 'primary checkbox',
              }}
            />
          }
          label={t('notificationsSettingsSMS')}
        />
      )}

      <FormControlLabel
        control={
          <Checkbox
            disabled={props.isReadOnly}
            checked={props.notifications.email}
            onChange={updateEmail}
            color="primary"
            className="color-blue-dark-2"
            inputProps={{
              'aria-label': 'primary checkbox',
            }}
          />
        }
        label={t('notificationsSettingsEmail')}
      />

      {props.isEdit && (
        <FormControlLabel
          control={
            <Checkbox
              disabled={props.isReadOnly}
              checked={props.notifications.resend}
              onChange={updateResend}
              color="primary"
              className="color-blue-dark-2"
              inputProps={{
                'aria-label': 'primary checkbox',
              }}
            />
          }
          label={t('notificationsSettingsResend')}
        />
      )}
      <FormControl variant="outlined" size="small" sx={{ marginRight: '16px' }}>
        <Select
          disabled={props.isReadOnly}
          id="notification-language-select"
          value={props.notifications.language || ''}
          onChange={updateLanguage}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">{t('notificationsSettingsLanguage')}</MenuItem>
          {Options}
        </Select>
      </FormControl>
    </FormGroup>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    notifications: state.reservation.notifications,
    restaurant: state.calendar.restaurant,
    languages: state.application.languages,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateNotifications: bindActionCreators(reservationActions.updateNotifications, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsSettings);
