import React, { MouseEvent, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from 'types';
import notesActions from 'redux/actions/notes';
import { useTranslation } from 'react-i18next';
import { Permissions } from 'types/auth';
import { INotesColors, Note, NotesColors } from 'types/notes';
import { Box, Typography, Button } from '@mui/material';
import { useScreenSize } from 'hooks/useScreenSize';

interface StateProps {
  permissions: Permissions;
}

interface DispatchProps {
  setSelectedNote: (id: string) => void;
}

interface DefaultProps {
  note: Note;
}

type Props = DefaultProps & StateProps & DispatchProps;

const NotesCard: React.FC<Props> = ({ note, permissions, setSelectedNote }) => {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const handleEditNote = () => {
    if (!isMobile) {
      setSelectedNote(note.id);
    }
  };

  const noteText = note?.text;
  const text = showMore ? noteText : noteText?.substring(0, 200);

  const toggleShowMore = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowMore(!showMore);
  };

  return (
    <Box
      className={`notes-card`}
      style={{ backgroundColor: NotesColors[note.color as keyof INotesColors], cursor: 'pointer' }}
      onClick={handleEditNote}
    >
      <Typography
        sx={{
          marginBottom: '5px',
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        }}
      >
        {text}
      </Typography>
      <Typography sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line', fontWeight: 500 }}>
        {note.name}
      </Typography>
      {noteText?.length >= 200 ? (
        <Button onClick={toggleShowMore} variant="text" sx={{ color: 'white', marginTop: '5px' }}>
          ...{showMore ? t('less') : t('more')}
        </Button>
      ) : null}
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    permissions: state.auth.userSettings.permissions,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    setSelectedNote: bindActionCreators(notesActions.setSelectedNote, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesCard);
