import React, { useState } from 'react';
import { UnauthorizedWrapper } from 'app/components/UnauthorizedWrapper';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, FormControl, Link, TextField, Box } from '@mui/material';
import { resetPasswordCall, ResetPasswordParams } from 'api/auth';
import { useSnackbar } from 'notistack';
import { FormErrorMessage } from 'app/components/FormErrorMessage';
import {ProgressOverlay} from "../../components/ProgressOverlay";

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const defaultValues = {
  email: '',
};

const ForgotPasswordPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ResetPasswordParams) => {
    setIsLoading(true);
    resetPasswordCall(data)
      .then((response) => {
        enqueueSnackbar('Reset password succeed', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Reset password failed', { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <UnauthorizedWrapper
      title={t('forgotPasswordViewTitle')}
      subtitle={t('forgotPasswordViewSubtitle')}
    >
      <ProgressOverlay open={isLoading} />
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextField
                label={t('forgotPasswordViewEmail')}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={Boolean(errors.email)}
                placeholder={t('forgotPasswordViewEmail')}
              />
            )}
          />
          {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
        </FormControl>
        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            mb: 2,
          }}
        >
          {t('forgotPasswordViewSendResetLink')}
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Link href="/login" underline="none" color="secondary">
            {t('forgotPasswordViewBackToLogin')}
          </Link>
        </Box>
      </form>
    </UnauthorizedWrapper>
  );
};

export default ForgotPasswordPage;
