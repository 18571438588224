import React, { ReactNode, useState } from 'react';
import { AuthContext } from 'hooks/useAuth';
import { getToken, setToken, removeToken } from 'utils/tokenManagement';
import { logoutCall } from 'api/auth';

interface OwnProps {
  children: ReactNode;
}
export const TokenProvider = ({ children }: OwnProps) => {
  const [token, setLocalToken] = useState(getToken() || '');

  const setTokenToLocalStorage = (data: string) => {
    setToken(data);
    setLocalToken(data);
  };

  const handleLogout = () => {
    logoutCall().finally(() => {
      removeToken();
      setLocalToken('');
    });
  };

  return (
    <AuthContext.Provider value={{ token, setTokenToLocalStorage, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
