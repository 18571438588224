import React from 'react';
import { Box, IconButton } from '@mui/material';
import TableinLogo from 'images/icons/ic-tablein.svg';
import { ReactComponent as HamburgerIcon } from 'images/icons/ic-hamburger.svg';
import { ReactComponent as SearchIcon } from 'images/icons/ic-magnifying-glass-duotone.svg';
import { ReactComponent as MoonIcon } from 'images/icons/ic-moon-duotone.svg';
import { ReactComponent as MessageIcon } from 'images/icons/ic-comments-duotone.svg';
import { ReactComponent as NotificationIcon } from 'images/icons/ic-bell-duotone.svg';
import { ReactComponent as NotificationBadgeIcon } from 'images/icons/ic-badge-dot.svg';
import { NavLink } from 'react-router-dom';
import { PATH } from 'constants/paths';
import RestaurantSwitcher from 'app/components/Header/RestaurantSwitcher';
import { useTranslation } from 'react-i18next';

export const SettingsHeader = () => {
  const { t } = useTranslation();

  const iconButtonSx = {
    color: 'darkBlue',
  };

  return (
    <Box
      sx={{
        backgroundColor: 'brandWhite',
        minHeight: '64px',
        width: '100%',
        padding: '8px 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <NavLink to={PATH.SETTINGS_BASIC_INFORMATION}>
          <Box
            sx={{
              marginRight: '16px',
              display: { xs: 'block', md: 'none' },
            }}
          >
            <HamburgerIcon />
          </Box>
        </NavLink>
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
          }}
        >
          <RestaurantSwitcher switchButtonStyles={{ color: 'comet' }} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          justifyContent: { xs: 'flex-start', md: 'flex-end' },
        }}
      >
        <IconButton sx={iconButtonSx}>
          <SearchIcon />
        </IconButton>
        <IconButton sx={iconButtonSx}>
          <MessageIcon />
        </IconButton>
        <IconButton sx={iconButtonSx}>
          <MoonIcon />
        </IconButton>
        <IconButton
          sx={{
            ...iconButtonSx,
            position: 'relative',
          }}
        >
          <NotificationIcon />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              position: 'absolute',
              padding: '2px',
              backgroundColor: 'brandWhite',
              top: '6px',
              right: '6px',
            }}
          >
            <NotificationBadgeIcon />
          </Box>
        </IconButton>
      </Box>
      <Box
        sx={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: 'blue',
          overflow: 'hidden',
          marginLeft: '16px',
        }}
      >
        <img src={TableinLogo} alt={t('tableinLogoAlt')} loading="lazy" />
      </Box>
    </Box>
  );
};
