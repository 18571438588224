import React, { useRef, useState, RefObject, FormEventHandler } from 'react';
import { ReactComponent as SearchIcon } from 'images/icons/search-light.svg';
import { ReactComponent as TimesCircleIcon } from 'images/icons/times-circle-light.svg';
import { AppState } from 'types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AdvancedSearchParams, Restaurant } from 'types/calendar';
import {
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Box,
} from '@mui/material';
import { CustomPhoneInput } from 'app/components/CustomPhoneInput';
import AppConnectionStatusWrapper from 'app/components/AppConnectionStatusWrapper/AppConnectionStatusWrapper';
import CardWrapper from 'app/components/common/CardWrapper/CardWrapper';

interface StateProps {
  searchOpen: boolean;
  restaurant: Restaurant;
}

interface OwnProps {
  variant?: 'normal' | 'card';
  searchPhrase: string;
  onClick: () => void;
  onChange: (e: any) => void;
  advanced?: boolean;
  onAdvancedSubmit?: (params: AdvancedSearchParams) => void;
  onAdvancedReset?: () => void;
}

const SidebarSearch: React.FC<OwnProps & StateProps> = (props) => {
  const { t } = useTranslation();
  const formRef: RefObject<HTMLFormElement> = useRef(null);
  const [guestPhone, setGuestPhone] = useState('');

  const handleAdvancedSearch: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!props.advanced || !props.onAdvancedSubmit) return;
    const params: AdvancedSearchParams = {
      //@ts-ignore
      id: e.target['reservation-number'].value,
      //@ts-ignore
      name: e.target['guest-name'].value,
      //@ts-ignore
      email: e.target['guest-email'].value,
      phone: guestPhone,
      //@ts-ignore
      company: e.target['guest-company'].value,
    };
    props.onAdvancedSubmit(params);
  };

  const handleAdvancedReset = () => {
    if (!props.onAdvancedReset) return;
    formRef.current?.reset();
    setGuestPhone('');
    props.onAdvancedReset();
  };

  if (!props.searchOpen) return null;

  return (
    <CardWrapper
      className={`sidebar-search__section${
        props.variant === 'card' ? ' sidebar-search__section--card' : ''
      }`}
    >
      <TextField
        fullWidth
        size="small"
        value={props.searchPhrase}
        onChange={props.onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon height={16} />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {props.searchPhrase && (
                <InputAdornment position="end">
                  <IconButton onClick={props.onClick} sx={{ padding: 0 }}>
                    <TimesCircleIcon className="input-icon input-icon--small input-icon--default" />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
      <AppConnectionStatusWrapper>
        {props.advanced && (
          <div className="sidebar-advanced-search">
            <Accordion sx={{ background: 'transparent' }}>
              <AccordionSummary aria-controls="advanced-search">
                <Typography className="sidebar-advanced-search__title" sx={{ color: 'navyBlue' }}>
                  {t('sidebarSearchTitle')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form
                  className="sidebar-advanced-search__form"
                  autoComplete="off"
                  onSubmit={handleAdvancedSearch}
                  ref={formRef}
                >
                  <Stack spacing={2}>
                    <TextField
                      size="small"
                      name="reservation-number"
                      label={t('sidebarSearchReservationNumber')}
                      variant="outlined"
                    />
                    <TextField
                      size="small"
                      name="guest-name"
                      label={t('sidebarSearchGuestName')}
                      variant="outlined"
                    />
                    <TextField
                      size="small"
                      type="email"
                      name="guest-email"
                      label={t('sidebarSearchEmail')}
                      variant="outlined"
                    />
                    <CustomPhoneInput
                      country={props.restaurant.address?.country?.toLowerCase() || 'lt'}
                      value={guestPhone}
                      onChange={setGuestPhone}
                      dropdownStyle={{ width: formRef.current?.clientWidth || '268px' }}
                      autoFormat={true}
                    />
                    <TextField
                      size="small"
                      name="guest-company"
                      label={t('sidebarSearchCompany')}
                      variant="outlined"
                    />
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                      <Button variant="contained" color="primary" type="submit">
                        {t('sidebarSearchCompanySearchButton')}
                      </Button>
                      <Button variant="contained" color="secondary" onClick={handleAdvancedReset}>
                        {t('sidebarSearchReset')}
                      </Button>
                    </Box>
                  </Stack>
                </form>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </AppConnectionStatusWrapper>
    </CardWrapper>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    searchOpen: state.calendar.searchOpen,
    restaurant: state.calendar.restaurant,
  };
}

export default connect(mapStateToProps)(SidebarSearch);
