import React, { useState, useEffect } from 'react';
import Drawer from 'app/components/Drawer';
import DrawerCloseButton from 'app/components/Drawer/DrawerCloseButton';
import DrawerHeading from 'app/components/Drawer/DrawerHeading';
import DrawerContent from 'app/components/Drawer/DrawerContent';
import { Button, Radio, RadioGroup, TextField, Box } from '@mui/material';
import moment from 'moment';
import { bindActionCreators, Dispatch } from 'redux';
import notesActions from 'redux/actions/notes';
import { connect } from 'react-redux';
import { AppState } from 'types';
import { useTranslation } from 'react-i18next';
import { NOTES_COLOR_OPTIONS } from '../colors';
import calendarActions from 'redux/actions/calendar';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import NotesDeleteModal from '../NotesDeleteModal';
import { Permissions } from 'types/auth';
import { CalendarSettings, Restaurant } from 'types/calendar';
import { INotesColors, NewNoteParams, Note, NotesColors } from 'types/notes';
import { MuiDatePicker } from 'app/components/MuiDatePicker';
import { useStatus } from 'hooks/useStatus';
import '../Notes.scss';
import HeadingPrimary from 'app/components/common/HeadingPrimary/HeadingPrimary';
import { ProgressOverlay } from 'app/components/ProgressOverlay';

interface StateProps {
  permissions: Permissions;
  restaurant: Restaurant;
  createNoteLoading: boolean;
  selectedNote: Note | null;
  calendarSettings?: CalendarSettings;
}

interface DispatchProps {
  createNote: (note: NewNoteParams) => void;
  updateNote: (id: string, note: NewNoteParams) => void;
  setSelectedNote: (id: string | null) => void;
  setLocale: () => void;
}

interface DefaultProps {
  onClose: () => void;
  open: boolean;
  locale?: string;
}

type Props = DefaultProps & StateProps & DispatchProps;

const NotesDrawer: React.FC<Props> = (props: Props) => {
  const { isReadOnly } = useStatus();
  const { t } = useTranslation();

  const initialDate = props.calendarSettings?.date || moment().startOf('day');
  const [startDate, setStartDate] = useState(initialDate);
  const [endDate, setEndDate] = useState(initialDate);
  const [selectedWeekdays, setSelectedWeekdays] = useState<number[]>([]);
  const [selectedColor, setSelectedColor] = useState<string>(NOTES_COLOR_OPTIONS[0].value);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const isValidStartDate = startDate <= endDate;

  const weekdays = Array(7)
    .fill('')
    .map((_, i) => {
      const date = moment().day((i + 1) % 7);
      return { name: date.format('ddd'), value: date.day() };
    });

  const availableWeekdays =
    endDate.diff(startDate, 'days') > -1
      ? Array(endDate.diff(startDate, 'days') + 1)
          .fill('')
          .reduce((acc, _, i) => {
            const dayNumber = moment(startDate).add(i, 'days').day();
            return !acc.includes(dayNumber) ? [...acc, dayNumber] : acc;
          }, [])
      : [];

  const reset = () => {
    setStartDate(initialDate);
    setEndDate(initialDate);
    setSelectedWeekdays([]);
    setSelectedColor(NOTES_COLOR_OPTIONS[0].value);
  };

  useEffect(() => {
    if (props.locale === '') {
      props.setLocale();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.open) {
      if (props.selectedNote) {
        setStartDate(moment(props.selectedNote.start_date));
        setEndDate(moment(props.selectedNote.end_date));
        setSelectedWeekdays(props.selectedNote.days);
        setSelectedColor(props.selectedNote.color);
      } else {
        reset();
      }
    } else {
      props.setSelectedNote(null);
    }
    // eslint-disable-next-line
  }, [props.open, props.selectedNote]);

  const handleChangeDate = (type: string, date: string) => {
    if (!date || !moment.isMoment(date)) return;
    if (type === 'start') {
      setStartDate(date);
    } else if (type === 'end') {
      setEndDate(date);
    } else return;
  };

  const weekdayDisabledCheck = (dayNumber: number) =>
    availableWeekdays.length < 2 || !availableWeekdays.includes(dayNumber);

  const handleChangeWeekday: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const dayNumber = parseInt(e.target.value);
    if (isNaN(dayNumber)) return;

    setSelectedWeekdays((prev) =>
      prev.includes(dayNumber) ? prev.filter((d) => d !== dayNumber) : [...prev, dayNumber],
    );
  };

  const handleChangeColor = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedColor(value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const note: NewNoteParams = {
      date: {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      },
      days: selectedWeekdays.filter((d) => !weekdayDisabledCheck(d)),
      color: selectedColor,
      //@ts-ignore
      name: e.target['name'].value,
      //@ts-ignore
      text: e.target['message'].value,
    };

    if (!!props.selectedNote) {
      props.updateNote(props.selectedNote.id, note);
    } else {
      props.createNote(note);
    }
  };

  const handleDeleteNote = () => {
    if (!props.selectedNote) return;
    setDeleteDialogOpen(true);
  };

  return (
    <Drawer className="drawer notes" anchor="right" open={props.open} onClose={props.onClose}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem', gap: '20px' }}>
        <DrawerHeading>{t('noteModalTitle')}</DrawerHeading>
        <DrawerCloseButton onClick={props.onClose} />
      </Box>
      <DrawerContent>
        <form onSubmit={handleSubmit}>
          <div className="notes">
            <Box display="flex" mb={2} sx={{ gap: '10px' }}>
              <div className="notes__date-selection-column">
                <HeadingPrimary>{t('noteStartDate')}</HeadingPrimary>

                <MuiDatePicker
                  disabled={isReadOnly}
                  value={startDate}
                  format={props.restaurant.settings.date_format_key}
                  onChange={(date) => handleChangeDate('start', date)}
                />
              </div>
              <div className="notes__date-selection-column">
                <HeadingPrimary>{t('noteEndDate')}</HeadingPrimary>

                <MuiDatePicker
                  disabled={isReadOnly}
                  value={endDate}
                  format={props.restaurant.settings.date_format_key}
                  onChange={(date) => handleChangeDate('end', date)}
                  shouldDisableDate={(date) => date?.isBefore(startDate) || false}
                  minDate={startDate}
                />
              </div>
            </Box>
            <div className="notes__weekdays-selection">
              <HeadingPrimary>{t('noteRepeat')}</HeadingPrimary>

              <ul className="notes__weekdays-selection-input">
                {weekdays.map((d) => (
                  <li key={`weekdays-selection-input-day-${d.value}`}>
                    <input
                      type="checkbox"
                      name={d.name}
                      value={d.value}
                      checked={selectedWeekdays.includes(d.value)}
                      onChange={handleChangeWeekday}
                      id={`weekdays-selection-checkbox-${d.value}`}
                      disabled={weekdayDisabledCheck(d.value) || isReadOnly}
                    />
                    <label htmlFor={`weekdays-selection-checkbox-${d.value}`}>{t(d.name)}</label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="notes__color-selection">
              <HeadingPrimary>{t('noteColor')}</HeadingPrimary>

              <RadioGroup
                name="color"
                className="notes__color-selection-input"
                value={selectedColor}
                onChange={handleChangeColor}
              >
                {NOTES_COLOR_OPTIONS.map((c) => (
                  <Radio
                    disabled={isReadOnly}
                    key={c.value}
                    value={c.value}
                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    style={{ color: NotesColors[c.value as keyof INotesColors] }}
                  />
                ))}
              </RadioGroup>
            </div>
            <div className="notes__name">
              <HeadingPrimary>{t('noteName')}</HeadingPrimary>

              <TextField
                disabled={isReadOnly}
                size="small"
                name="name"
                variant="outlined"
                className="notes__name-input"
                required
                defaultValue={props.selectedNote?.name}
              />
            </div>
            <div className="notes__message">
              <HeadingPrimary>{t('noteMessage')}</HeadingPrimary>

              <TextField
                disabled={isReadOnly}
                name="message"
                variant="outlined"
                multiline
                className="notes__message-input"
                rows={5}
                required
                defaultValue={props.selectedNote?.text}
              />
            </div>
            <div className="notes__footer">
              <Button
                type="submit"
                disabled={!isValidStartDate || isReadOnly}
                variant="contained"
                color="primary"
              >
                {t('noteSave')}
              </Button>
              {!!props.selectedNote && (
                <Button
                  disabled={isReadOnly}
                  className="notes__delete-btn"
                  variant="text"
                  onClick={handleDeleteNote}
                >
                  {t('deleteNote')}
                </Button>
              )}
            </div>
            <ProgressOverlay isLoader open={props.createNoteLoading} />
          </div>
        </form>
      </DrawerContent>
      {!!props.selectedNote && (
        <NotesDeleteModal
          isOpen={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          note={props.selectedNote}
        />
      )}
    </Drawer>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    permissions: state.auth.userSettings.permissions,
    restaurant: state.calendar.restaurant,
    createNoteLoading: state.notes.createNoteLoading,
    selectedNote: state.notes.selectedNote,
    calendarSettings: state.calendar.calendarSettings,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    createNote: bindActionCreators(notesActions.createNote, dispatch),
    updateNote: bindActionCreators(notesActions.updateNote, dispatch),
    setSelectedNote: bindActionCreators(notesActions.setSelectedNote, dispatch),
    setLocale: bindActionCreators(calendarActions.setLocale, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesDrawer);
