import React from 'react'
import { Box, Typography } from '@mui/material';

export enum StatisticsType {
  Today = 'today',
  WalkIns = 'walk-ins',
  Reservations = 'reservations',
}

interface Props {
  type: StatisticsType,
  count: number,
  label: string,
}

const SidebarStatisticsRow: React.FC<Props> = (props: Props) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        sx={{
          fontWeight: 500,
          marginBottom: '5px',
          textAlign: 'center',
        }}
      >
        {props.label}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box className={`sidebar-statistics__badge sidebar-statistics__badge--${props.type}`} />
        <Typography sx={{ fontWeight: 400, fontSize: 24 }}>{props.count}</Typography>
      </Box>
    </Box>
  )
}

export default SidebarStatisticsRow
