import React from 'react';
import { TimelineReservation } from 'types/calendar';
import { PromotionTypes } from 'enums/promotionTypes';
import { ReactComponent as DepositIcon } from 'images/icons/PromotionTypes/ic-deposit.svg';
import { ReactComponent as DiscountIcon } from 'images/icons/PromotionTypes/ic-discount.svg';
import { Box } from '@mui/material';
import { ReactComponent as UsdCircleIcon } from 'images/icons/usd-circle-light.svg';
import { ReactComponent as EventIcon } from 'images/icons/PromotionTypes/ic-event.svg';
import { ReactComponent as SpecialOfferIcon } from 'images/icons/PromotionTypes/ic-offer.svg';
import { ReactComponent as SavedCardIcon } from 'images/icons/PromotionTypes/ic-saved-card.svg';

interface Props {
  item: TimelineReservation;
}

const PromotionIcons: React.FC<Props> = (props) => {
  const { item } = props;
  const promotionType = item?.reservation?.specialOffer?.promotionType;
  const hasPriceAmount = item?.reservation?.specialOffer?.price?.amount;
  const discount = item?.reservation?.specialOffer?.discount;

  const showPaidIcon = promotionType && hasPriceAmount;

  const showDepositIcon = PromotionTypes.Deposit === promotionType;

  const showDiscountIcon = PromotionTypes.Discount === promotionType && discount;

  const hasPayments = item?.reservation?.payments?.payments?.length > 0;

  const showEventIcon = PromotionTypes.Event === promotionType;

  const showSavedCardIcon = PromotionTypes.LateCancellation === promotionType;

  return (
    <>
      {showDepositIcon && (
        <li>
          <DepositIcon width={12} height={12} />
        </li>
      )}
      {showDiscountIcon && (
        <li>
          <Box sx={{ display: 'flex', gap: '3px' }}>
            {/*TODO remove in future*/}
            {/*<Typography sx={{ color: 'navyBlue', fontSize: '12px', lineHeight: 1 }}>*/}
            {/*  {discount}*/}
            {/*</Typography>*/}
            <DiscountIcon width={12} height={12} />
          </Box>
        </li>
      )}
      {showEventIcon && (
        <li>
          <EventIcon width={10} height={10} />
        </li>
      )}
      {showSavedCardIcon && (
        <li>
          <SavedCardIcon width={11} height={11} />
        </li>
      )}
      {hasPayments && !showPaidIcon && (
        <li>
          <UsdCircleIcon className="reservation-wrapper__stats-icon" />
        </li>
      )}
      {showPaidIcon && (
        <li>
          <UsdCircleIcon className="reservation-wrapper__stats-icon" />
        </li>
      )}
    </>
  );
};

export default PromotionIcons;
