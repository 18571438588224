import React from 'react';
import { oldAppUrlGenerate } from 'utils/url';
import { AppState } from 'types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { Permissions } from 'types/auth';
import { Settings } from 'types/calendar';
import { Box } from '@mui/material';

interface StateProps {
  restaurantSettings: Settings;
  permissions: Permissions;
}

interface DispatchProps {}

type Props = StateProps & DispatchProps;

const MainMenu: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { accessDashboard } = props.permissions;

  const menuItems = [
    {
      name: t('navigationFirstLink'),
      link: '/list',
      old: false,
    },
    {
      name: t('navigationSecondLink'),
      link: '/calendar',
      old: false,
    },
    {
      name: t('navigationThirdLink'),
      link: '/floor-plan',
      old: false,
    },
    {
      name: t('navigationFourthLink'),
      link: '/reports',
      old: true,
    },
    {
      name: t('navigationFifthLink'),
      link: '/calendar',
      old: true,
    },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      {menuItems.map((menuItem) => (
        <Box key={menuItem.name} sx={{ display: 'flex', alignItems: 'center' }}>
          {menuItem.old ? (
            <>
              {accessDashboard && menuItem.link === '/reports' && (
                <a href={oldAppUrlGenerate(menuItem.link, false)} className="navLink">
                  {menuItem.name}
                </a>
              )}
              {props.restaurantSettings.show_old_calendar && menuItem.link === '/calendar' && (
                <a href={oldAppUrlGenerate(menuItem.link, false)} className="navLink">
                  {menuItem.name}
                </a>
              )}
            </>
          ) : (
            <NavLink
              to={menuItem.link}
              key={menuItem.link}
              className={({ isActive }) => `navLink ${isActive ? 'navLink--active' : ''}`}
            >
              {menuItem.name}
            </NavLink>
          )}
        </Box>
      ))}
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    restaurantSettings: state.calendar.restaurant.settings,
    permissions: state.auth.userSettings.permissions,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
