import React from 'react';
import { AppState } from 'types';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Box,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { timeFormat } from 'utils/dates';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Restaurant } from 'types/calendar';
import { addTheBookingLengthToTheTimeAndSubtractOneSecond } from 'utils/addTheBookingLengthToTheTimeAndSubtractOneSecond';

interface StateProps {
  restaurant: Restaurant;
  defaultBookingLength: number;
}

interface OwnProps {
  startTime: moment.Moment;
  endTime: moment.Moment | string;
  labelStart: string;
  labelEnd: string;
  updateStartTime: (time: moment.Moment) => void;
  updateEndTime: (time?: moment.Moment) => void;
  min?: moment.Moment;
  max?: moment.Moment;
  disabled?: boolean;
  isReservationTimepicker?: boolean;
}

type Props = StateProps & OwnProps;

const timePickerWrapperStyles = {
  display: 'flex',
  width: '100%',
  gap: '5px',
};

const selectStyles = {
  '& .MuiInputBase-input': {
    padding: '8.5px 10px',
  },
  svg: {
    color: 'brandYellow',
  },
};

const TimePicker: React.FC<Props> = (props) => {
  if (props.restaurant.settings.timezone) {
    moment.tz.setDefault(props.restaurant.settings.timezone);
  }

  const min = props.min || moment().startOf('day');
  const max = props.max || moment().endOf('day');

  const minEndTime = min.clone().add(props.defaultBookingLength, 'minutes');

  const setEndTimeWithBookingLength = (time: moment.Moment) => {
    props.updateEndTime(time.clone().add(props.defaultBookingLength, 'minutes').add(-1, 'seconds'));
    props.updateEndTime(
      addTheBookingLengthToTheTimeAndSubtractOneSecond(time, props.defaultBookingLength),
    );
  };

  React.useEffect(() => {
    if (props.startTime < min) {
      props.updateStartTime(min);
      props.updateEndTime(minEndTime);
    }
    // eslint-disable-next-line
  }, [props.startTime, min]);

  React.useEffect(() => {
    if (props.endTime && props.endTime > max) {
      props.updateEndTime(undefined);
    }
    // eslint-disable-next-line
  }, [props.endTime, max]);

  if (max < min) {
    max.add(1, 'days');
  }

  const hour: Moment = moment(min);
  const hours: any = {};
  const endHours: any = {};

  while (hour <= max) {
    hours[hour.valueOf()] = moment(hour);

    const endHour = moment(hour).add(-1, 'seconds');
    endHours[endHour.valueOf()] = endHour;

    hour.add(props.restaurant.settings.increment, 'minutes');
  }

  const updateEndTime = (event: SelectChangeEvent<string | number>) => {
    props.updateEndTime(endHours[event.target.value]);
  };

  const updateStartTime = (event: SelectChangeEvent<string | number>) => {
    const starTimeByHours = hours[event.target.value];
    props.updateStartTime(starTimeByHours);
    if (starTimeByHours && props.isReservationTimepicker) {
      setEndTimeWithBookingLength(starTimeByHours);
    }
  };

  const OptionsStart = Object.keys(hours).map((key) => {
    return (
      <MenuItem value={key} key={key}>
        {hours[key].format(timeFormat(props.restaurant.settings.date_format_key))}
      </MenuItem>
    );
  });

  const OptionsEnd = Object.keys(endHours).map((key) => {
    return (
      <MenuItem value={key} key={key}>
        {moment(endHours[key])
          .add(1, 'seconds')
          .format(timeFormat(props.restaurant.settings.date_format_key))}
      </MenuItem>
    );
  });

  return (
    <Box sx={timePickerWrapperStyles}>
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel shrink htmlFor="hour-picker-start-time">
          {props.labelStart}
        </InputLabel>
        <Select
          disabled={props.disabled}
          sx={selectStyles}
          label={props.labelStart}
          id="hour-picker-start-time"
          value={props.startTime.valueOf()}
          onChange={updateStartTime}
          IconComponent={() => <></>}
          renderValue={(value: any) => {
            return (
              <Box display="flex">
                <AccessTimeOutlinedIcon />
                <Typography sx={{ marginLeft: '10px' }}>
                  {hours[value] &&
                    moment(hours[value]).format(
                      timeFormat(props.restaurant.settings.date_format_key),
                    )}
                </Typography>
              </Box>
            );
          }}
        >
          {OptionsStart}
        </Select>
      </FormControl>
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel shrink htmlFor="hour-picker-end-time">
          {props.labelEnd}
        </InputLabel>
        <Select
          disabled={props.disabled}
          sx={selectStyles}
          label={props.labelEnd}
          id="hour-picker-end-time"
          value={props.endTime.valueOf()}
          onChange={updateEndTime}
          IconComponent={() => <></>}
          renderValue={(value: any) => {
            return (
              <Box display="flex">
                <AccessTimeOutlinedIcon />
                {value !== 'empty' && (
                  <Typography sx={{ marginLeft: '10px' }}>
                    {typeof props.endTime !== 'string' &&
                      props.endTime
                        .clone()
                        .add(1, 'seconds')
                        .format(timeFormat(props.restaurant.settings.date_format_key))}
                  </Typography>
                )}
              </Box>
            );
          }}
        >
          {OptionsEnd}
        </Select>
      </FormControl>
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    restaurant: state.calendar.restaurant,
    defaultBookingLength: state.calendar.restaurant.settings.default_booking_length,
  };
}

export default connect(mapStateToProps)(TimePicker);
