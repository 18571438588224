import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import {
  Typography,
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Stack,
  Link,
  Skeleton,
  Snackbar,
  Alert,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import {
  AttachMoneyOutlined,
  DateRangeOutlined,
  DirectionsWalkOutlined,
  DoDisturbOutlined,
  FiberManualRecordOutlined,
  PermPhoneMsgOutlined,
  VisibilityOffOutlined,
} from '@mui/icons-material';
import StatsCard from 'app/components/Stats/StatsCard';
import DatePickerWrapper from 'app/components/DatepickerWrapper';
import TotalReservations from 'app/components/Stats/TotalReservations';
import BigChart from 'app/components/Stats/BigChart';
import StatsGroupCard from 'app/components/Stats/StatsGroupCard';
import { ThemeProvider } from '@mui/material';
import {
  getMonthlyCovers,
  getCovers,
  getRating,
  getGuests,
  getTotalGuests,
  getNoShowArrived,
  getReturningClients,
} from 'api/reports';
import { useSelector } from 'react-redux';
import { AppState } from 'types';
import { createTheme } from '@mui/material/styles';
import getDesignTokens from 'mui/theme';

// DayJS config
dayjs.extend(updateLocale);
dayjs.updateLocale('en', { weekStart: 1 }); // Start weekn in Monday instead of Sunday

const ReportsPage: React.FC = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<any>(dayjs().toDate());
  const [endDate, setEndDate] = useState<any>(dayjs().toDate());
  const [dataFetching, setDataFetching] = useState(true);
  const [snackOpened, setSnackOpened] = useState(false);
  const [snackText, setSnackText] = useState('');

  // Data
  const [monthlyCoversData, setMonthlyCoversData] = useState<any>();
  const [coversData, setCoversData] = useState<any>();
  const [ratingData, setRatingData] = useState<any>();
  const [guestsData, setGuestsData] = useState<any>();
  const [totalGuestsData, setTotalGuestsData] = useState<any>();
  const [noShowArrivedData, setNoShowArrivedData] = useState<any>();
  const [returningClientsData, setReturningClientsData] = useState<any>();

  const mode = useSelector((state: AppState) => state.theme.mode);
  // @ts-ignore
  const theme = createTheme(getDesignTokens(mode));

  const handleDateRangeChange = (dates: any) => {
    const [start, end] = dates;
    console.log('start: ', start);
    console.log('end:', end);
  };

  const setNext7Days = () => {
    setStartDate(dayjs().toDate());
    setEndDate(dayjs().add(7, 'day').toDate());
  };

  const setThisWeek = () => {
    setStartDate(dayjs().startOf('week').toDate());
    setEndDate(dayjs().endOf('week').toDate());
  };

  const setLastWeek = () => {
    setStartDate(dayjs().day(0).subtract(7, 'day').toDate());
    setEndDate(dayjs().day(6).subtract(7, 'day').toDate());
  };

  const setThisMonth = () => {
    setStartDate(dayjs().startOf('month').toDate());
    setEndDate(dayjs().endOf('month').toDate());
  };

  const setLastMonth = () => {
    setStartDate(dayjs().startOf('month').subtract(1, 'month').toDate());
    setEndDate(dayjs().endOf('month').subtract(1, 'month').toDate());
  };

  const handleSnackClose = () => setSnackOpened(false);

  useEffect(() => {
    setDataFetching(true);
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');

    const getAllData = async () => {
      try {
        await Promise.all([
          getMonthlyCovers(formattedStartDate, formattedEndDate).then((response) =>
            setMonthlyCoversData(response),
          ),
          getCovers(formattedStartDate, formattedEndDate).then((response) =>
            setCoversData(response),
          ),
          getRating(formattedStartDate, formattedEndDate).then((response) =>
            setRatingData(response),
          ),
          getGuests(formattedStartDate, formattedEndDate).then((response) =>
            setGuestsData(response),
          ),
          getTotalGuests(formattedStartDate, formattedEndDate).then((response) =>
            setTotalGuestsData(response),
          ),
          getNoShowArrived(formattedStartDate, formattedEndDate).then((response) =>
            setNoShowArrivedData(response),
          ),
          getReturningClients(formattedStartDate, formattedEndDate).then((response) =>
            setReturningClientsData(response),
          ),
        ]);
        setDataFetching(false);
      } catch (error) {
        setDataFetching(true);
        setSnackOpened(true);
        setSnackText('There was an error during data fetching.');
        console.error(error);
      }
    };

    getAllData().then((resp) => console.log(resp));
  }, [startDate, endDate]);

  useEffect(() => {
    // console.log('coversData: ', coversData);
    // console.log('totalGuestsData: ', totalGuestsData);
    // console.log('ratingData: ', ratingData);
    // console.log('guestsData: ', guestsData);
    // console.log('returningClientsData: ', returningClientsData);
    // console.log('noShowArrivedData: ', noShowArrivedData);

    // TODO: Remove console.log and connect data to charts
    console.log('monthlyCoversData: ', monthlyCoversData);
  }, [
    monthlyCoversData,
    coversData,
    ratingData,
    guestsData,
    totalGuestsData,
    noShowArrivedData,
    returningClientsData,
  ]);

  return (
    <ThemeProvider theme={theme}>
      {/* Error snackbar */}
      <Snackbar
        open={snackOpened}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={handleSnackClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {snackText}
        </Alert>
      </Snackbar>

      {/* Content */}
      <Box display="flex" flexDirection="column" flex={1} p={2} sx={{ overflowY: 'auto' }}>
        {/* Subpage header */}
        <Grid2
          container
          spacing={3}
          justifyContent="space-between"
          alignItems="center"
          pt={3}
          pb={5}
        >
          <Grid2 xs={12} md={3}>
            <Typography variant="h1" fontSize={24}>
              {t('Restaurant reports')}
            </Typography>
          </Grid2>
          <Grid2 xs={12} md="auto">
            <Stack spacing={3} direction={{ xs: 'column', md: 'row' }} alignItems="center">
              {/* Predefined date ranges */}
              <Stack direction="row" spacing={2}>
                <Link component="button" color="brandBlueGray" onClick={setNext7Days}>
                  {t('Next 7 days')}
                </Link>
                <Link component="button" color="brandBlueGray" onClick={setThisWeek}>
                  {t('This week')}
                </Link>
                <Link component="button" color="brandBlueGray" onClick={setLastWeek}>
                  {t('Last week')}
                </Link>
                <Link component="button" color="brandBlueGray" onClick={setThisMonth}>
                  {t('This month')}
                </Link>
                <Link component="button" color="brandBlueGray" onClick={setLastMonth}>
                  {t('Last month')}
                </Link>
              </Stack>

              {/* Date range picker */}
              <Box>
                <DatePickerWrapper>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    // selectsStart={true}
                    // selectsEnd={true}
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    id="date-range-picker"
                    onChange={handleDateRangeChange}
                    // shouldCloseOnSelect={false}
                    popperPlacement="bottom-end"
                    selectsRange
                    customInput={
                      <FormControl>
                        <OutlinedInput
                          sx={{ width: 230 }}
                          value={`${dayjs(startDate).format('DD-MM-YYYY')} - ${dayjs(
                            endDate,
                          ).format('DD-MM-YYYY')}`}
                          endAdornment={
                            <InputAdornment position="end">
                              <DateRangeOutlined />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    }
                  />
                </DatePickerWrapper>
              </Box>
            </Stack>
          </Grid2>
        </Grid2>

        {/* Subpage Content */}
        <Grid2 container spacing={3}>
          {/* Total Reservations */}
          <Grid2 xs={12} md={4}>
            {dataFetching ? (
              <Skeleton animation="wave" variant="rounded" height={180} />
            ) : (
              <TotalReservations
                topValue={totalGuestsData.guests}
                bottomValue={totalGuestsData.reservations}
              />
            )}
          </Grid2>

          {/* Average rating */}
          <Grid2 xs={6} md={2}>
            {dataFetching ? (
              <Skeleton animation="wave" variant="rounded" height={180} />
            ) : (
              <StatsCard
                title={t('reportsPageAverageRating')}
                mainValue={ratingData[0].average_rating}
                extraValue={`${ratingData[0].reviews_count} ${t('reportPageReviews')}`}
                icon={<AttachMoneyOutlined />}
                color="brandYellow"
              />
            )}
          </Grid2>

          {/* Guest database */}
          <Grid2 xs={6} md={2}>
            {dataFetching ? (
              <Skeleton animation="wave" variant="rounded" height={180} />
            ) : (
              <StatsCard
                title={t('reportsPageGuestDatabase')}
                mainValue={guestsData[0].guests_total}
                extraValue={`${guestsData[0].guests_subscribed} ${t('emails')}`}
                icon={<AttachMoneyOutlined />}
                color="green"
              />
            )}
          </Grid2>

          {/* Returning */}
          <Grid2 xs={6} md={2}>
            {dataFetching ? (
              <Skeleton animation="wave" variant="rounded" height={180} />
            ) : (
              <StatsCard
                title={t('Returning')}
                mainValue={`${returningClientsData[0].clients_return}%`}
                extraValue={`${returningClientsData[0].ratio}%`}
                icon={<AttachMoneyOutlined />}
                color="brandBlue"
              />
            )}
          </Grid2>

          {/* No shows */}
          <Grid2 xs={6} md={2}>
            {dataFetching ? (
              <Skeleton animation="wave" variant="rounded" height={180} />
            ) : (
              <StatsCard
                title={t('No shows')}
                mainValue={`${noShowArrivedData[0].ratio}%`}
                extraValue={`${noShowArrivedData[0].reservations_no_show}`}
                icon={<AttachMoneyOutlined />}
                color="red"
              />
            )}
          </Grid2>
        </Grid2>

        {/* Big chart */}
        <Grid2 container spacing={3} mt={2}>
          <Grid2 xs={12} md={9}>
            {dataFetching ? (
              <Skeleton animation="wave" variant="rounded" height={550} />
            ) : (
              <BigChart />
            )}
          </Grid2>

          <Grid2 xs={12} md={3}>
            {dataFetching ? (
              <Skeleton animation="wave" variant="rounded" height={550} />
            ) : (
              <StatsGroupCard
                title={t('Total covers')}
                value={coversData[0].guests_total}
                stats={[
                  {
                    title: t('Online'),
                    value: coversData[0].guests_online,
                    primaryColor: '#0700c5',
                    secondaryColor: '#bad1ff',
                    icon: <FiberManualRecordOutlined />,
                  },
                  {
                    title: t('Phone'),
                    value: coversData[0].guests_phone,
                    primaryColor: '#ffb700',
                    secondaryColor: '#ffebc6',
                    icon: <PermPhoneMsgOutlined />,
                  },
                  {
                    title: t('Walk in'),
                    value: coversData[0].guests_walk_in,
                    primaryColor: '#6500d1',
                    secondaryColor: '#eaccff',
                    icon: <DirectionsWalkOutlined />,
                  },
                  {
                    title: t('No show'),
                    value: coversData[0].guests_no_show,
                    primaryColor: '#e60000',
                    secondaryColor: '#fdd8dc',
                    icon: <VisibilityOffOutlined />,
                  },
                  {
                    title: t('Cancelled'),
                    value: coversData[0].guests_cancelled,
                    primaryColor: '#747a99',
                    secondaryColor: '#eaeefa',
                    icon: <DoDisturbOutlined />,
                  },
                ]}
              />
            )}
          </Grid2>
        </Grid2>
      </Box>
    </ThemeProvider>
  );
};

export default ReportsPage;
