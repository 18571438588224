import React, { lazy, Suspense } from 'react';
import { PrivateRoute } from './components/PrivateRoute';
import LoginPage from './pages/Login';
import ForgotPasswordPage from './pages/ForgotPassword';
import MozrestIntegrationPage from './pages/MozrestIntegration';
import NotificationsProvider from './components/Notifications/NotificationsProvider';
import getDesignTokens from '../mui/theme';
import { TokenProvider } from './components/TokenProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Backdrop, CssBaseline } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from 'types';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import Authenticated from './components/Authenticated';
import CalendarPage from './pages/Calendar/CalendarPage';
import FloorPlanPage from './pages/FloorPlan/FloorPlanPage';
import ReportsPage from './pages/Reports/ReportsPage';
import SettingsWrapper from './pages/RestaurantSettings/_components/SettingsWrapper';
import NavigationWrapper from './pages/RestaurantSettings/_components/NavigationWrapper';
import { MobileReservationList } from './components/MobileReservationList';
import { PATH } from 'constants/paths';
import QuickActionsPage from './pages/RestaurantSettings/QuickActions';
const ReservationListPage = lazy(() => import('./pages/ReservationList/ReservationListPage'));
const BasicInformationPage = lazy(() => import('./pages/RestaurantSettings/BasicInformation'));
const RestaurantProfilePage = lazy(() => import('./pages/RestaurantSettings/RestaurantProfile'));
const TableManagementSettingsPage = lazy(
  () => import('./pages/RestaurantSettings/TableManagement'),
);
const TimeAndLanguagePage = lazy(() => import('./pages/RestaurantSettings/TimeAndLanguage'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path={PATH.LOGIN} element={<LoginPage />} />
      <Route path={PATH.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route path={PATH.MOZREST_INTEGRATION} element={<MozrestIntegrationPage />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Authenticated />
          </PrivateRoute>
        }
      >
        <Route path="/" element={<Navigate to={PATH.CALENDAR} />} />
        <Route path={PATH.LIST} element={<ReservationListPage />} />
        <Route
          path={PATH.CALENDAR}
          element={
            <MobileReservationList>
              <CalendarPage />
            </MobileReservationList>
          }
        />
        <Route
          path={PATH.FLOOR_PLAN}
          element={
            <MobileReservationList>
              <FloorPlanPage />
            </MobileReservationList>
          }
        />
        <Route path={PATH.REPORTS} element={<ReportsPage />} />
        <Route path={PATH.SETTINGS} element={<NavigationWrapper />}>
          <Route index element={<Navigate to={PATH.SETTINGS_BASIC_INFORMATION} />} />
          <Route path={PATH.SETTINGS_OPTION} element={<SettingsWrapper />}>
            <Route path={PATH.RESTAURANT_PROFILE} element={<RestaurantProfilePage />} />
            <Route path={PATH.BASIC_INFORMATION} element={<BasicInformationPage />} />
            <Route path={PATH.TABLE_MANAGEMENT} element={<TableManagementSettingsPage />} />
            <Route path={PATH.TIME_AND_LANGUAGE} element={<TimeAndLanguagePage />} />
          </Route>
          <Route path={PATH.QUICK_ACTIONS} element={<QuickActionsPage />} />
        </Route>
      </Route>
    </Route>,
  ),
);

const App = () => {
  const mode = useSelector((state: AppState) => state.theme.mode);
  // @ts-ignore
  const theme = createTheme(getDesignTokens(mode));

  return (
    <TokenProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NotificationsProvider>
          <Suspense fallback={<Backdrop open={true} />}>
            <RouterProvider router={router} />
          </Suspense>
        </NotificationsProvider>
      </ThemeProvider>
    </TokenProvider>
  );
};

export default App;
