import React, { ReactNode } from 'react'
import { Typography } from '@mui/material';

interface Props {
  children: ReactNode | ReactNode[];
}

const DrawerHeading: React.FC<Props> = props => {
  return (
    <Typography
      variant="h2"
      sx={{ color: 'navyBlue' }}
    >
      {props.children}
    </Typography>
  )
}

export default DrawerHeading
